import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import Button, { ButtonTheme } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccountSettings } from "../../utils/hooks";
import PageWizardsModal from "../../modals/PageWizardsModal";
import PreviewItem from "../PreviewItem";
import UpdateItemModal from "../../modals/UpdateItemModal";
import { PageComponentProps } from "../../utils/types";
import { getProjectClient } from "../../utils/graphand";
import { GraphandModelDataModel } from "graphand-js";
import ConfigureDataModelModal from "../../modals/ConfigureDataModelModal";
import { useTranslation } from "react-i18next";
import ModelHistoryModal from "../../modals/ModelHistoryModal";

const PageComponentSingle: React.FunctionComponent<PageComponentProps> = ({
  model,
}) => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const scope = String(model.scope);
  const [ignoredWizards] = useAccountSettings<string[] | undefined>(
    "ignoredWizards",
    undefined
  );
  const [wizardsOpen, setWizardsOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [historyModelOpen, setHistoryModelOpen] = useState(false);
  const [configureModelOpen, setConfigureModelOpen] = useState(false);
  const [pinnedModels, setPinnedModels] = useAccountSettings<string[]>(
    "pinnedModels",
    ["Account", "Media"]
  );

  const _handleTogglePinned = async () => {
    const pinned = new Set(pinnedModels);
    if (pinned.has(scope)) {
      pinned.delete(scope);
    } else {
      pinned.add(scope);
    }

    setPinnedModels(Array.from(pinned));
  };

  // const wizardsKeys = Object.keys(constructor.wizards).filter(
  //   (key) => !ignoredWizards?.includes(key)
  // );

  let dataModel: GraphandModelDataModel | undefined;

  if (scope === "Account") {
    dataModel = client?.getModel("DataModel").get("_dataModelAccount");
  } else if (scope === "Media") {
    dataModel = client?.getModel("DataModel").get("_dataModelMedia");
  } else {
    dataModel = model.__dataModel;
  }

  if (!dataModel) {
    return null;
  }

  return (
    <section className="py-6 px-4 max-w-screen-2xl mx-auto space-y-6">
      <h1 className="flex items-center justify-between">
        <Transition.Child
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-100 -translate-y-4"
        >
          <div className="text-2xl sm:text-3xl font-bold w-full flex items-center truncate space-x-3">
            <div>{dataModel?.renderFieldView("_icon", { theme: "fad" })}</div>
            <div>{dataModel?.renderFieldView("name")}</div>
          </div>
        </Transition.Child>

        <div className="flex items-center divide-x divide-gray-300 -mx-4">
          <div className="flex items-center px-4 space-x-2">
            <Button
              tooltip={t("actions.update")}
              theme={ButtonTheme.inline}
              onClick={() => setUpdateModalOpen(true)}
              className={updateModalOpen ? "text-primary" : ""}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "edit"]} />
              </div>
            </Button>
            <Button
              tooltip={t(
                pinnedModels.includes(scope)
                  ? "actions.unpin_model"
                  : "actions.pin_model"
              )}
              theme={ButtonTheme.inline}
              onClick={() => _handleTogglePinned()}
              className={`${
                pinnedModels.includes(scope) ? "text-primary" : ""
              } hidden sm:flex`}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "thumbtack"]} />
              </div>
            </Button>
            {dataModel?.isTemporary() ? null : (
              <Button
                labelCount={dataModel?.isConfigured ? 0 : 1}
                tooltip={t("actions.configure_model")}
                theme={ButtonTheme.inline}
                onClick={() => setConfigureModelOpen(true)}
                className={`${configureModelOpen ? "text-primary" : ""}`}
              >
                <div className="h-4 w-4 flex items-center justify-center">
                  <FontAwesomeIcon icon={["fad", "screwdriver-wrench"]} />
                </div>
              </Button>
            )}
            <Button
              tooltip={t("actions.history_model")}
              theme={ButtonTheme.inline}
              onClick={() => setHistoryModelOpen(true)}
              className={`${historyModelOpen ? "text-primary" : ""}`}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "history"]} />
              </div>
            </Button>
            <Button
              tooltip="Centre d'aide"
              theme={ButtonTheme.inline}
              onClick={() => setWizardsOpen(true)}
              className={wizardsOpen ? "text-primary" : ""}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "info-circle"]} />
              </div>
            </Button>
          </div>
        </div>
      </h1>

      <Transition.Child
        className="pb-6"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-100 -translate-y-6"
      >
        {model.get().suspense((item) => {
          if (!item) {
            return null;
          }

          return (
            <>
              <PreviewItem item={item} model={model} />

              <UpdateItemModal
                isOpen={updateModalOpen}
                onClose={setUpdateModalOpen}
                model={model}
                item={item}
              />
            </>
          );
        })}
      </Transition.Child>

      <ConfigureDataModelModal
        dataModel={dataModel}
        isOpen={configureModelOpen}
        onClose={setConfigureModelOpen}
      />

      <PageWizardsModal
        isOpen={wizardsOpen}
        onClose={setWizardsOpen}
        model={model}
      />

      <ModelHistoryModal
        isOpen={historyModelOpen}
        onClose={setHistoryModelOpen}
        model={model.HistoryModel}
      />
    </section>
  );
};

export default PageComponentSingle;
