import React, { useEffect, useRef, useState } from "react";
import { ViewComponentProps } from "graphand-react";

const SockethookTitle: React.FunctionComponent<ViewComponentProps> = ({
  item,
  options,
}) => {
  const [responding, setResponding] = useState({});
  const buttonRef = useRef<any>();

  const _ping = async () => {
    try {
      const res = await item.ping();
      setResponding(res);
    } catch (e) {
      setResponding({});
    }

    setTimeout(() => {
      if (buttonRef.current) {
        _ping();
      }
    }, 1000);
  };

  useEffect(() => {
    _ping();
  }, []);

  return (
    <div className="flex items-center justify-start space-x-1.5">
      <button
        ref={buttonRef}
        type="button"
        className={`h-6 w-6 flex-shrink-0 rounded-full flex items-center justify-center overflow-hidden text-xs text-white ${
          responding && Object.keys(responding).length
            ? "bg-green-500 animate-pulse"
            : "bg-orange-500"
        }`}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          const res = await item.selectedHost();
          alert(JSON.stringify(res));
        }}
      >
        {responding ? Object.keys(responding).length : 0}
      </button>

      {item.renderFieldView("identifier", options)}
    </div>
  );
};

export default SockethookTitle;
