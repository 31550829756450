import React, { Fragment, FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { GraphandValidationError } from "graphand-js";
import { capitalizeFirstLetter } from "../../../utils/formatter";

const InputBooleanCheckbox: FunctionComponent<Partial<InputComponentProps>> = ({
  id,
  slug,
  value,
  field,
  onChange,
  options,
  errors,
}) => {
  const { t } = useTranslation();
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  return (
    <div className="px-1">
      <div className="group flex items-center">
        <div className="relative flex items-center">
          {value === undefined ? (
            <div className="h-2 w-2 rounded-full bg-gray-200 mr-2 -ml-4" />
          ) : null}
          <div className="flex items-center h-5">
            <input
              tabIndex={1}
              id={id}
              name={id}
              type="checkbox"
              className="h-4 w-4 text-button border-gray-300 rounded-md"
              disabled={options.disabled}
              checked={Boolean(value)}
              onChange={({ currentTarget: { checked } }) => {
                !options.disabled && onChange?.(checked);
              }}
            />
          </div>
          <label htmlFor={id} className="ml-2">
            <div className="text-gray-700 truncate">
              {capitalizeFirstLetter(label)}
            </div>
          </label>
        </div>

        <Menu as="div" className="relative inline-block text-left w-full">
          {({ open }) => (
            <>
              <Menu.Button
                type="button"
                className="cursor-pointer text-sm font-medium text-gray-700 px-1 flex items-center focus:outline-none"
                tabIndex={0}
              >
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`ml-2 transition transform ${
                    open
                      ? "opacity-100 -rotate-180"
                      : "opacity-0 group-hover:opacity-100 rotate-0"
                  }`}
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-20 left-0 mt-1.5 ml-px w-56 origin-top-left divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item disabled={value === undefined}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`${
                            active ? "bg-button text-white" : "text-gray-900"
                          } ${
                            value === undefined ? "opacity-50" : ""
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => onChange?.(undefined)}
                        >
                          Réinitialiser la valeur
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>

      {errors?.length ? (
        <div className="mt-1 text-sm text-red-600 space-y-1">
          {errors.map((e: GraphandValidationError) => (
            <p>
              {t(`errors.fields.${e.code}`, {
                interpolation: { escapeValue: false },
              })}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InputBooleanCheckbox;
