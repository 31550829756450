import { GraphandModelSockethookHost, GraphandField } from "graphand-js";
import SockethookHostTitle from "../fieldsViews/SockethookHostTitle";
import PreviewSockethookHost from "../components/PreviewSockethookHost";

class SockethookHost extends GraphandModelSockethookHost {
  static previewField = "name";
  static previewItemComponent = PreviewSockethookHost;
}

SockethookHost.customFields({
  _title: new GraphandField({
    ViewComponent: SockethookHostTitle,
  }),
});

export default SockethookHost;
