import React, { useEffect, useRef } from "react";
import FieldTypeForm from "./FieldTypeForm";
import { UpdateItemComponentProps } from "../utils/types";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { getProjectClient } from "../utils/graphand";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UpdateDataField: React.FunctionComponent<UpdateItemComponentProps> = ({
  setModalProps,
  item,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const client = getProjectClient();

  useEffect(() => {
    setModalProps?.({
      title: "Modifier le champ",
      className: "max-w-screen-sm",
    });
  }, []);

  const { current: template } = useRef(
    ({
      formRef,
      isLoading,
      handleSubmit,
      fields,
    }: GraphandFormTemplateParams) => {
      delete fields._name;

      return (
        <form
          ref={formRef}
          onSubmit={isLoading ? null : handleSubmit}
          className={`space-y-8 ${
            isLoading ? "cursor-progress opacity-50" : "opacity-100"
          }`}
        >
          <div className="space-y-4">
            {fields.render("_name", { label: t("labels.fields.name.default") })}
            {fields.renderDefault()}
          </div>
          <Button type="submit">
            <FontAwesomeIcon
              icon={faLoader}
              size="lg"
              className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                isLoading ? "opacity-100" : "opacity-0"
              }`}
            />
            {t("actions.validate")}
          </Button>
        </form>
      );
    }
  );

  if (!client) {
    return null;
  }

  const DataField = client.getModel("DataField");

  const _handleSubmit = async ({ _name, ...configuration }: any) => {
    await item.update({
      set: {
        name: _name,
        configuration: Object.assign({}, item.configuration, configuration),
      },
    });
    onUpdate(item);
  };

  return (
    <FieldTypeForm
      fieldType={item.graphandType}
      values={{ ...item.configuration, _name: item.name }}
      onSubmit={_handleSubmit}
      FormComponent={({ fields, values, onSubmit }) => (
        <GraphandForm
          values={values}
          onSubmit={onSubmit}
          fields={{
            ...fields,
            _name: DataField.schema.name,
          }}
          template={template}
        />
      )}
    />
  );
};

export default UpdateDataField;
