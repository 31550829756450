import React, { useEffect, useRef, useState } from "react";
import { GraphandForm, GraphandFormOptions } from "graphand-react";
import { CreateItemComponentProps } from "../utils/types";
import ConfirmQuitFormModal from "../modals/ConfirmQuitFormModal";
import { Subject } from "rxjs";
import { preventWindowUnload, stopPreventWindowUnload } from "../utils/tools";
import ErrorCreateModal, { CreateErrorType } from "../modals/ErrorCreateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { ModalCloseType } from "./Modal";

const CreateItemWrapper: React.FunctionComponent<
  CreateItemComponentProps & {
    template: GraphandFormOptions["template"];
    title?: string;
    initialValues?: any;
    formProps?: Partial<GraphandFormOptions>;
    beforeCreate?: (values: any) => void;
  }
> = ({
  model,
  onCreate,
  setModalProps,
  template,
  initialValues,
  title,
  formProps = {},
  beforeCreate,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues ?? {});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const confirmCloseModalSubjectRef = useRef(new Subject());
  const [error, setError] = useState<CreateErrorType>();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const containerRef = useRef<any>();

  useEffect(() => {
    setModalProps?.((s) => {
      const _s = { ...s };

      _s.closeType = ModalCloseType.BACK;
      _s.topActions = [
        {
          helper: t("actions.confirm"),
          label: isLoading ? (
            <div
              className={`h-6 w-6 flex items-center justify-center text-button-hover animate-spin`}
            >
              <FontAwesomeIcon icon={["far", "loader"]} />
            </div>
          ) : (
            <div
              className={`h-6 w-6 flex items-center justify-center text-button hover:text-button-hover`}
            >
              <FontAwesomeIcon icon={["far", "check"]} />
            </div>
          ),
          onClick: () =>
            !isLoading &&
            containerRef.current?.querySelector("[type=submit]")?.click(),
        },
      ];
      if (title) _s.title = title;

      return _s;
    });

    return () => {
      confirmCloseModalSubjectRef.current.next(false);
      stopPreventWindowUnload();
      setConfirmModalOpen(false);
    };
  }, []);

  useEffect(() => {
    if (isModified) {
      preventWindowUnload();
      setModalProps?.((s) => {
        const _s = { ...s };
        _s.beforeClose = async () => {
          setConfirmModalOpen(true);
          return new Promise((resolve) => {
            const sub = confirmCloseModalSubjectRef.current.subscribe((r) => {
              sub.unsubscribe();
              resolve(Boolean(r));
            });
          });
        };
        return _s;
      });
    } else {
      stopPreventWindowUnload();
      setModalProps?.((s) => {
        const _s = { ...s };
        delete _s.beforeClose;
        return _s;
      });
    }
  }, [isModified]);

  const _handleSubmit = async (v: any) => {
    setIsLoading(true);
    try {
      const _values = { ...initialValues, ...v };
      await beforeCreate?.(_values);
      const item = await model.create(_values);
      setIsLoading(false);
      onCreate(item);
    } catch (e: any) {
      if (e.response?.status === 403 || e.response?.status === 401) {
        return setError(CreateErrorType.unauthorized);
      }

      setIsLoading(false);
      throw e;
    }
  };

  return (
    <div ref={containerRef}>
      <GraphandForm
        values={values}
        onChange={(values: any, { modified }) => {
          setValues(values);
          setIsModified(Boolean(modified?.length));
        }}
        model={model}
        template={template}
        onSubmit={_handleSubmit}
        {...formProps}
      />

      <ErrorCreateModal
        error={error}
        isOpen={Boolean(error)}
        onClose={() => {
          setError(undefined);
          onCreate();
        }}
      />

      <ConfirmQuitFormModal
        isOpen={confirmModalOpen}
        onClose={() => {
          confirmCloseModalSubjectRef.current.next(false);
          stopPreventWindowUnload();
          setConfirmModalOpen(false);
        }}
        onConfirm={() => {
          confirmCloseModalSubjectRef.current.next(true);
          stopPreventWindowUnload();
          setConfirmModalOpen(false);
        }}
      />
    </div>
  );
};

export default CreateItemWrapper;
