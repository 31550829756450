import React from "react";
import { GraphandModel } from "graphand-js";
import { GraphandModelList } from "graphand-js";

const ModelGridPageComponent: React.FunctionComponent<any> = ({
  list,
  getItemProps,
  model,
  querySelection,
}) => {
  const ids = list.ids ?? list.map((i: GraphandModel) => i._id);

  return (
    <>
      {model
        .getList({
          query: { $and: [{ _id: { $in: ids } }, querySelection] },
        })
        .suspense(
          (selectionPage: GraphandModelList<GraphandModel>) => {
            return list.map((item: GraphandModel, index: number) => {
              const props = getItemProps(item, index);

              const checked = selectionPage.ids.includes(item._id);
              return props.renderItem({ checked });
            });
          },
          {
            fallback: list.map((item: GraphandModel, index: number) => {
              const props = getItemProps(item, index);

              return props.renderItem();
            }),
          }
        )}
    </>
  );
};

export default ModelGridPageComponent;
