import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SidebarLink from "./SidebarLink";
import { useTranslation } from "react-i18next";

const SidebarSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="hidden sm:block p-2 md:p-4 w-18 md:w-80 flex-shrink-0 border-r border-gray-300 bg-background overflow-auto">
      <Link to="/settings" className="block text-xl mb-2 md:mb-4">
        <span className="hidden md:flex items-center">
          <FontAwesomeIcon icon={["far", "angle-left"]} className="mr-2" />
          Paramètres
        </span>
        <span className="block md:hidden w-full text-center">
          <FontAwesomeIcon icon={["far", "angle-left"]} />
        </span>
      </Link>
      <div className="space-y-1">
        <SidebarLink to="/settings/general" icon={["fad", "cog"]}>
          {t("labels.settings.general")}
        </SidebarLink>
        <SidebarLink to="/settings/schema" icon={["fad", "screwdriver-wrench"]}>
          {t("labels.settings.schema")}
        </SidebarLink>
        <SidebarLink to="/settings/security" icon={["fad", "shield"]}>
          {t("labels.settings.security")}
        </SidebarLink>
        <SidebarLink to="/settings/tokens" icon={["fad", "key"]}>
          {t("labels.settings.tokens")}
        </SidebarLink>
        <SidebarLink
          to="/settings/sockethooks"
          icon={["fad", "arrow-down-arrow-up"]}
        >
          {t("labels.settings.sockethooks")}
        </SidebarLink>
        <SidebarLink
          to="/settings/webhooks"
          icon={["fad", "arrow-up-left-from-circle"]}
        >
          {t("labels.settings.webhooks")}
        </SidebarLink>
        <SidebarLink to="/settings/environments" icon={["fad", "code-merge"]}>
          {t("labels.settings.environments")}
        </SidebarLink>
        <SidebarLink
          to="/settings/aggregations"
          icon={["fad", "calculator-simple"]}
        >
          {t("labels.settings.aggregations")}
        </SidebarLink>
        <SidebarLink to="/settings/search" icon={["fad", "search"]}>
          {t("labels.settings.search")}
        </SidebarLink>
        <SidebarLink to="/settings/usage" icon={["fad", "chart-line"]}>
          {t("labels.settings.usage")}
        </SidebarLink>
        <SidebarLink to="/settings/interface" icon={["fad", "display"]}>
          {t("labels.settings.interface")}
        </SidebarLink>
        <SidebarLink to="/settings/secrets" icon={["fad", "eye-slash"]}>
          {t("labels.settings.secrets")}
        </SidebarLink>
      </div>
    </div>
  );
};

export default SidebarSettings;
