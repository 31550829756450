import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CreateItemComponentProps } from "../utils/types";
import { GraphandField } from "graphand-js";
import InputFile from "../fields/inputs/File";
import CreateItemWrapper from "./CreateItemWrapper";

const CreateMediaFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("private", { theme: "switch" })}
        {fields.render("file")}
        {fields.render("name")}
        {fields.render("description", { theme: "textarea" })}
        {Object.keys(fields)
          .filter(
            (f) =>
              ![
                "private",
                "file",
                "name",
                "description",
                "url",
                "mimetype",
                "size",
                "height",
                "width",
                "originalname",
                "duration",
              ].includes(f)
          )
          .map((f) => fields.render(f))}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.createLoading" : "actions.create")}
      </Button>
    </form>
  );
};

const CreateMedia: React.FunctionComponent<CreateItemComponentProps> = (
  props
) => {
  return (
    <CreateItemWrapper
      {...props}
      template={CreateMediaFormTemplate}
      formProps={{
        fields: (f: any) => ({
          ...f,
          file: new GraphandField({ InputComponent: InputFile }),
        }),
      }}
    />
  );
};

export default CreateMedia;
