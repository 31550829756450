import React, { useState } from "react";
import { ViewComponentProps } from "graphand-react";
import { GraphandFieldRelation, GraphandModel } from "graphand-js";
import { useTranslation } from "react-i18next";
import { GraphandModelConstructor } from "../../../utils/types";
import { getProjectClient } from "../../../utils/graphand";
import ViewRelationSingle from "./_single";
import { ViewComponentContext } from "../../../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModelListModal from "../../../modals/ModelListModal";
import FieldViewContainer from "../../../components/FieldViewContainer";

const ViewRelationMultiple: React.FunctionComponent<ViewComponentProps> = (
  props
) => {
  const [listModalOpen, setListModalOpen] = useState(false);
  const { slug, item, options } = props;
  const client = getProjectClient();
  const { t } = useTranslation();

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;
  const model = client.getModel(field.ref) as GraphandModelConstructor;

  if (!item[slug]) {
    return null;
  }

  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  return (
    <>
      {model
        .getList({
          ids: item[slug]?.ids,
          pageSize:
            options.context === ViewComponentContext.PREVIEW_LINE ||
            model.scope === "Media"
              ? 3
              : 1,
          count: true,
        })
        .suspense(
          (res: any) => {
            if (!res.count) {
              return null;
            }

            const content = (
              <ul className="-m-1 max-w-full inline-flex items-center flex-wrap">
                {res.map((i: GraphandModel) => {
                  const _options = {
                    ...props.options,
                    instance: i,
                    context: ViewComponentContext.TABLE_CELL_MULTIPLE,
                  };

                  return (
                    <li className="inline-flex m-1">
                      <ViewRelationSingle {...props} options={_options} />
                    </li>
                  );
                })}

                {res.count > res.length ? (
                  <>
                    <button
                      type="button"
                      className="text-button hover:text-button-hover flex items-center p-3"
                      onClick={() => setListModalOpen(true)}
                    >
                      <FontAwesomeIcon
                        icon={["far", "plus"]}
                        className="mr-2"
                      />
                      {res.count - res.length}
                    </button>
                  </>
                ) : null}

                <ModelListModal
                  isOpen={listModalOpen}
                  onClose={setListModalOpen}
                  props={{
                    model,
                    filter: { query: { _id: { $in: item[slug]?.ids } } },
                  }}
                />
              </ul>
            );

            if (options.context === ViewComponentContext.PREVIEW_LINE) {
              return (
                <FieldViewContainer
                  {...props}
                  label={label ? `${label} (${res.count})` : undefined}
                  rawValue={JSON.stringify(item[slug]?.ids)}
                >
                  {content}
                </FieldViewContainer>
              );
            }

            return content;
          },
          { subscribe: true }
        ) || null}
    </>
  );
};

export default ViewRelationMultiple;
