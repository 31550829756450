import Modal, { ModalProps } from "../components/Modal";
import React, { useEffect, useRef, useState } from "react";
import ConfigureDataModelLabel from "../components/ConfigureDataModelLabel";
import { GraphandModelConstructor } from "../utils/types";
import { useTranslation } from "react-i18next";
import ConfigureDataModelIcon from "../components/ConfigureDataModelIcon";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GraphandModelDataModel } from "graphand-js";

const ConfigureDataModelModal: React.FunctionComponent<
  ModalProps & {
    dataModel: GraphandModelDataModel;
  }
> = (props) => {
  const { dataModel: _dataModel, ...__modalProps } = props;
  const [_modalProps, setModalProps] = useState<Partial<ModalProps>>({});
  const [dataModel, setDataModel] = useState(_dataModel);
  const { t } = useTranslation();
  const swiperRef = useRef<any>();

  useEffect(() => {
    if (_dataModel && _dataModel !== dataModel) {
      setDataModel(_dataModel);
    }
  }, [_dataModel]);

  const _handleNext = () => {
    const slided = swiperRef.current.slideNext();
    if (!slided) {
      __modalProps.onClose(false);
    }
  };

  const steps: string[] = [];

  if (dataModel) {
    if (!dataModel.multiple || !dataModel.configuration?.icon) {
      steps.push("icon");
    }

    if (dataModel.multiple) {
      steps.push("label");
    }
  }

  const _renderStep = (step: string) => {
    switch (step) {
      case "icon":
        return (
          <ConfigureDataModelIcon
            autoSubmit
            model={dataModel.constructor as GraphandModelConstructor}
            item={dataModel}
            onUpdate={_handleNext}
          />
        );
      case "label":
        return (
          <ConfigureDataModelLabel
            autoSubmit
            model={dataModel.constructor as GraphandModelConstructor}
            item={dataModel}
            onUpdate={_handleNext}
          />
        );
    }
  };

  const _handleSlideChange = (swiper: any) => {
    const step = steps[swiper.activeIndex];
    let title: string = "";
    switch (step) {
      case "icon":
        title = t("actions.configure_model_icon");
        break;
      case "label":
        title = t("actions.configure_model_label");
        break;
    }

    setModalProps((p) => ({ ...p, title }));
  };

  const modalProps = { ...__modalProps, ..._modalProps };

  return (
    <Modal title="" className="max-w-screen-sm" expandable {...modalProps}>
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <Swiper
          allowTouchMove={false}
          autoHeight
          spaceBetween={50}
          slidesPerView={1}
          effect="cards"
          modules={[EffectCards]}
          cardsEffect={{
            slideShadows: false,
          }}
          onSlideChange={_handleSlideChange}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            _handleSlideChange(swiper);
            setInterval(() => swiper.slideReset(0), 100);
          }}
        >
          {steps.map((step: string) => (
            <SwiperSlide className="bg-white">{_renderStep(step)}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  );
};

export default ConfigureDataModelModal;
