import React, { Fragment, useEffect, useRef, useState } from "react";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ListPicker from "./ListPicker";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import Button, { ButtonTheme } from "./Button";
import CreateItemModal from "../modals/CreateItemModal";
import { ModalCloseType } from "./Modal";
import { SelectOptionComponentProps } from "./Select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import DataModel from "../models/DataModel";
import { getProjectClient } from "../utils/graphand";
import { CreateItemComponentProps } from "../utils/types";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Transition } from "@headlessui/react";

enum CreateDataModelType {
  COLLECTION = "collection",
  PAGE = "page",
}

const CreateDataModelSelectTypeOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option, handleClick, selected }) => {
  const { t } = useTranslation();

  return (
    <li
      onClick={handleClick}
      className="w-full bg-white border border-gray-200 rounded-2xl p-6 cursor-pointer hover:bg-gray-100"
    >
      <div className="ml-3 text-sm">
        <label htmlFor={option} className="flex items-center text-lg mb-1">
          <FontAwesomeIcon
            className="mr-2"
            icon={[
              "far",
              t(`enums.CreateDataModelType.${option}.icon`) as IconName,
            ]}
          />
          <div className="font-medium text-gray-700">
            {t(`enums.CreateDataModelType.${option}.title`)}
          </div>
        </label>
        <p className="text-gray-500">
          {t(`enums.CreateDataModelType.${option}.description`)}
        </p>
      </div>
    </li>
  );
};

const CreateDataModelFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams & {
    setModalProps: CreateItemComponentProps["setModalProps"];
  }
> = ({
  formRef,
  handleSubmit,
  fields,
  isLoading,
  values,
  handleFieldChange,
  setModalProps,
}) => {
  const swiperRef = useRef<any>();
  const { t } = useTranslation();

  const _handleSlideChange = (swiper: any) => {
    if (swiper.activeIndex) {
      setModalProps?.((p) => {
        delete p.className;

        return {
          ...p,
          title: values.type
            ? t(`enums.CreateDataModelType.${values.type}.titleModal`)
            : p.title,
          closeType: ModalCloseType.BACK,
          onClose: () => swiperRef.current.slideTo(swiper.activeIndex - 1),
        };
      });
    } else {
      setModalProps?.((p) => {
        delete p.onClose;

        return {
          ...p,
          className: "max-w-screen-sm",
          title: "Créer un nouveau modèle",
          closeType: ModalCloseType.DEFAULT,
        };
      });
    }
  };

  useEffect(() => {
    _handleSlideChange(swiperRef.current);
  }, []);

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`${isLoading ? "cursor-progress" : ""}`}
    >
      <Swiper
        allowTouchMove={false}
        autoHeight
        spaceBetween={50}
        slidesPerView={1}
        effect="cards"
        modules={[EffectCards]}
        cardsEffect={{
          slideShadows: false,
        }}
        onSlideChange={_handleSlideChange}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setInterval(() => swiper.slideReset(), 100);
        }}
      >
        <SwiperSlide>
          <ListPicker
            value={values?.type}
            onChange={(v) => {
              handleFieldChange("type", v);
              setTimeout(() => {
                swiperRef.current.slideTo(1);
              });
            }}
            options={Object.values(CreateDataModelType)}
            ContainerComponent={({ children }) => (
              <ul className="space-y-4">{children}</ul>
            )}
            OptionComponent={CreateDataModelSelectTypeOptionComponent}
          />
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-8">
            <div className="space-y-4">
              {fields.render("name")}
              {fields.render("slug", { beforeHelper: "Data:" })}
            </div>

            <Button type="submit" className={isLoading ? "opacity-50" : ""}>
              <FontAwesomeIcon
                icon={faLoader}
                size="lg"
                className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                  isLoading ? "opacity-100" : "opacity-0"
                }`}
              />
              {isLoading ? t("actions.createLoading") : t("actions.create")}
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    </form>
  );
};

const CreateDataModel: React.FunctionComponent<CreateItemComponentProps> = ({
  model,
  setModalProps,
  onCreate,
}) => {
  const [dataModel, setDataModel] = useState<DataModel>();
  const [createFieldModal, setCreateFieldModal] = useState(false);

  const _handleSubmit = async (values: any) => {
    const { name, slug } = values;
    const multiple = values.type !== CreateDataModelType.PAGE;
    console.log(values);
    try {
      const dataModel = await model.create({ name, slug, multiple });
      console.log(dataModel);
      setModalProps?.((p) => {
        return {
          ...p,
          onClose: () => onCreate(dataModel),
          closeType: ModalCloseType.DEFAULT,
          className: "max-w-screen-sm",
          title: "Le modèle a bien été créé",
        };
      });
      setTimeout(() => setDataModel(dataModel as DataModel));
    } catch (e) {
      console.log(e);
    }
  };

  const { current: template } = useRef((params: GraphandFormTemplateParams) => (
    <CreateDataModelFormTemplate
      key="CreateDataModelFormTemplate"
      {...params}
      setModalProps={setModalProps}
    />
  ));

  const client = getProjectClient();

  if (!client) {
    return null;
  }

  const DataField = client.getModel("DataField");

  return (
    <>
      <div className="relative">
        <Transition
          as="div"
          className="absolute inset-0 z-10 bg-white flex items-center justify-center -m-4"
          show={Boolean(dataModel)}
          appear
          enter="transition transform ease-out duration-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
        >
          <div className="space-y-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="transition transform ease-out duration-200 delay-300"
              enterFrom="opacity-0 scale-90 -rotate-90"
              enterTo="opacity-100 scale-100 rotate-0"
            >
              <div className="w-16 h-16 flex items-center justify-center bg-green-500 rounded-full mx-auto text-2xl text-white">
                <FontAwesomeIcon icon={["far", "check"]} />
              </div>
            </Transition.Child>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut
              volutpat eros. Sed bibendum sed lectus nec mollis. Sed at
              venenatis dolor, eu malesuada lacus.
            </p>
            <div className="inline-block">
              <Button
                theme={ButtonTheme.default_green}
                onClick={() => {
                  if (dataModel) {
                    onCreate(dataModel);
                  }

                  setCreateFieldModal(true);
                }}
              >
                Créer un premier champ
              </Button>
            </div>
          </div>
        </Transition>

        <div className={dataModel ? "invisible" : ""}>
          <GraphandForm
            model={model}
            onSubmit={_handleSubmit}
            template={template}
          />
        </div>
      </div>

      <CreateItemModal
        model={DataField}
        isOpen={createFieldModal}
        onClose={() => {
          setCreateFieldModal(false);

          if (dataModel) {
            setTimeout(() => {
              onCreate(dataModel);
              setCreateFieldModal(true);
            }, 300);
          }
        }}
        props={{
          dataModel,
        }}
      />
    </>
  );
};

export default CreateDataModel;
