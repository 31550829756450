import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import getIconForMimetype from "../utils/getIconForMimetype";
import getColorForMimetype from "../utils/getColorForMimetype";
import FieldViewContainer from "../components/FieldViewContainer";
import ViewText from "../fields/views/Text";

const loadedUrls = new Set<string>();

const MediaPreviewView: React.FunctionComponent<ViewComponentProps> = (
  props
) => {
  const { item, options, field, slug } = props;
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();

  const _getPreviewUrl = () => {
    let url;

    switch (options.context) {
      case ViewComponentContext.LIST_LABEL_IMAGE:
      case ViewComponentContext.TABLE_CELL:
      case ViewComponentContext.INPUT_QUERY_BUILDER_TILE:
      case ViewComponentContext.INPUT_INLINE:
      case ViewComponentContext.INPUT_INLINE_MULTIPLE:
        if (item?.mimetype?.includes("image")) {
          url = item.getUrl({ w: 100, h: 100, fit: "cover" });
        }
        break;
      case ViewComponentContext.GRID_LABEL_IMAGE:
        if (item?.mimetype?.includes("image")) {
          url = item.getUrl({
            w: Math.min(item.width, 500),
            h: Math.min(item.height, 500),
          });
        }
        break;
      case ViewComponentContext.PREVIEW_LINE:
        url = item.getUrl({ h: 200, fit: "cover" });
        break;
      default:
        break;
    }

    return url;
  };

  const [preview, setPreview] = useState(loadedUrls.has(_getPreviewUrl()));

  const _initPreview = () => {
    const url = _getPreviewUrl();

    if (url) {
      fetch(url).then(() => {
        loadedUrls.add(url);
        setPreview(true);
      });
    }
  };

  useEffect(() => {
    if (!preview) {
      _initPreview();
    }
  }, []);

  switch (options.context) {
    case ViewComponentContext.LIST_LABEL_IMAGE:
    case ViewComponentContext.TABLE_CELL:
    case ViewComponentContext.TABLE_CELL_MULTIPLE:
      return item?.mimetype?.includes("image") && preview ? (
        <div
          className="h-16 w-16 flex items-center justify-center flex-shrink-0 cursor-pointer"
          onClick={options.onClick}
        >
          <img
            className="h-full w-full rounded-lg"
            src={item.getUrl({ w: 100, h: 100, fit: "cover" })}
          />
        </div>
      ) : (
        <div
          className="h-16 w-16 flex items-center justify-center text-4xl flex-shrink-0 cursor-pointer text-primary"
          onClick={options.onClick}
          style={{ color: getColorForMimetype(item?.mimetype) }}
        >
          <FontAwesomeIcon icon={["fad", getIconForMimetype(item?.mimetype)]} />
        </div>
      );
    case ViewComponentContext.GRID_LABEL_IMAGE:
      return item?.mimetype?.includes("image") && preview ? (
        <div
          ref={containerRef as MutableRefObject<HTMLDivElement>}
          className="h-full w-full flex items-center justify-center flex-shrink-0 cursor-pointer relative"
          onClick={options.onClick}
        >
          <div className="absolute inset-0 bg-grid-texture -z-10 opacity-30" />
          <img
            className="w-full h-full object-cover"
            style={{ maxWidth: item.width, maxHeight: item.height }}
            src={item.getUrl({
              w: Math.min(item.width, 500),
              h: Math.min(item.height, 500),
            })}
          />
        </div>
      ) : (
        <div
          className="h-full w-full flex items-center justify-center text-7xl flex-shrink-0 cursor-pointer text-primary relative"
          onClick={options.onClick}
          style={{ color: getColorForMimetype(item?.mimetype) }}
        >
          <div className="absolute inset-0 bg-grid-texture -z-10 opacity-30" />
          <FontAwesomeIcon icon={["fad", getIconForMimetype(item?.mimetype)]} />
        </div>
      );
    case ViewComponentContext.PREVIEW_LINE:
      if (!item) {
        return null;
      }

      let label = "label" in options ? options.label : field?.__dataField?.name;
      if (label === undefined) {
        label = t(`labels.fields.${slug}.default`);
      }

      return (
        <FieldViewContainer {...props} label={label} rawValue={item._id}>
          {item?.mimetype?.includes("image") && preview ? (
            <div
              title={options.title}
              className="h-40 flex items-center flex-shrink-0 cursor-pointer"
              onClick={options.onClick}
            >
              <img
                className="max-h-full max-w-full rounded-lg"
                src={item.getUrl({ h: 200, fit: "cover" })}
              />
            </div>
          ) : (
            <div
              title={options.title}
              className="relative h-40 w-40 flex items-center justify-center cursor-pointer rounded-lg border border-gray-200"
              onClick={options.onClick}
              style={{ color: getColorForMimetype(item?.mimetype) }}
            >
              <div className="absolute inset-0 bg-grid-texture -z-10 opacity-30" />
              <FontAwesomeIcon
                icon={["fad", getIconForMimetype(item?.mimetype)]}
                className="text-5xl"
              />
            </div>
          )}
        </FieldViewContainer>
      );
    case ViewComponentContext.INPUT_QUERY_BUILDER_TILE:
      return (
        <div className="flex items-center max-w-full">
          {item?.mimetype?.includes("image") && preview ? (
            <div
              className="h-input-1 w-input-1 flex items-center justify-center flex-shrink-0 cursor-pointer"
              onClick={options.onClick}
            >
              <img
                className="h-full w-full"
                src={item.getUrl({ w: 100, h: 100, fit: "cover" })}
              />
            </div>
          ) : (
            <div
              className="h-input-1 w-input-1 flex items-center justify-center text-2xl flex-shrink-0 cursor-pointer text-primary"
              onClick={options.onClick}
              style={{ color: getColorForMimetype(item?.mimetype) }}
            >
              <FontAwesomeIcon
                icon={["fad", getIconForMimetype(item?.mimetype)]}
              />
            </div>
          )}
          <div className="truncate w-full ml-1.5">{item.name}</div>
        </div>
      );
    case ViewComponentContext.INPUT_INLINE:
      return (
        <div className="flex items-center max-w-full">
          {item?.mimetype?.includes("image") && preview ? (
            <div
              className="h-input w-input flex items-center justify-center flex-shrink-0 cursor-pointer"
              onClick={options.onClick}
            >
              <img
                className="h-full w-full"
                src={item.getUrl({ w: 100, h: 100, fit: "cover" })}
              />
            </div>
          ) : (
            <div
              className="h-input w-input flex items-center justify-center text-2xl flex-shrink-0 cursor-pointer text-primary"
              onClick={options.onClick}
              style={{ color: getColorForMimetype(item?.mimetype) }}
            >
              <FontAwesomeIcon
                icon={["fad", getIconForMimetype(item?.mimetype)]}
              />
            </div>
          )}
          <div className="truncate w-full ml-1.5">{item.name}</div>
        </div>
      );
    case ViewComponentContext.INPUT_INLINE_MULTIPLE:
      return (
        <div className="flex items-center">
          {item?.mimetype?.includes("image") && preview ? (
            <div
              className="h-6 w-6 flex items-center justify-center flex-shrink-0 cursor-pointer"
              onClick={options.onClick}
            >
              <img
                className="h-full w-full"
                src={item.getUrl({ w: 100, h: 100, fit: "cover" })}
              />
            </div>
          ) : (
            <div
              className="h-6 w-6 flex items-center justify-center text-2xl flex-shrink-0 cursor-pointer text-primary"
              onClick={options.onClick}
              style={{ color: getColorForMimetype(item?.mimetype) }}
            >
              <FontAwesomeIcon
                icon={["fad", getIconForMimetype(item?.mimetype)]}
              />
            </div>
          )}
          <div className="truncate w-full ml-1.5">{item.name}</div>
        </div>
      );
    default:
      break;
  }

  return <ViewText {...props} value={item.name} />;
};

export default MediaPreviewView;
