import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CreateItemComponentProps } from "../utils/types";
import CreateItemWrapper from "./CreateItemWrapper";

const CreateWebhookFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("name")}
        {fields.render("description", { theme: "textarea" })}
        {fields.render("endpoint")}
        {fields.render("method")}
        {fields.render("scope")}
        {fields.render("actions")}
        {fields.render("await")}
        {fields.render("timeout")}
        {fields.render("priority")}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.createLoading" : "actions.create")}
      </Button>
    </form>
  );
};

const CreateWebhook: React.FunctionComponent<CreateItemComponentProps> = (
  props
) => {
  return <CreateItemWrapper {...props} template={CreateWebhookFormTemplate} />;
};

export default CreateWebhook;
