import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";

const UserPicture: React.FunctionComponent<any> = ({ src, alt }) => {
  return src ? (
    <img
      src={src}
      alt={alt}
      className="h-full w-full rounded-full object-cover"
    />
  ) : (
    <FontAwesomeIcon className="h-full w-full" icon={faUserCircle} />
  );
};

export default UserPicture;
