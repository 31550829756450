import { GraphandForm } from "graphand-react";
import { getProjectClient } from "../../../utils/graphand";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Transition } from "@headlessui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCurrentProject } from "../../../utils/hooks";
import Button, { ButtonTheme } from "../../../components/Button";
import { oauthGlobal } from "../../../utils/tools";

const Join: FunctionComponent<{
  loading?: boolean;
  ready: boolean;
}> = ({ loading, ready }) => {
  const client = getProjectClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [_loading, setLoading] = useState(client?.authmanager.loading);
  const User = client?.getModel("User");
  const paramsCode = searchParams.get("c");
  const paramsEmail = searchParams.get("e");
  const paramsFirstname = searchParams.get("f");
  const paramsLastname = searchParams.get("l");
  const { t } = useTranslation();
  const { project } = useCurrentProject();

  useEffect(() => {
    const sub = client?.authmanager.loadingSubject.subscribe(setLoading);

    return () => sub?.unsubscribe();
  }, [client]);

  if (!paramsCode || !paramsEmail) {
    navigate("/");
    return null;
  }

  const defaultValues = {
    email: paramsEmail,
    firstname: paramsFirstname,
    lastname: paramsLastname,
  };

  const _handleSubmit = async (values: any) => {
    if (!client) {
      return;
    }

    values = Object.assign({}, defaultValues, values);

    await client.login({ method: "invitation", code: paramsCode, ...values });
    await client.authmanager.sync();
    navigate("/");
  };

  const { firstname, lastname, email, password } = User?.fields;
  return (
    <>
      <div className="w-full flex flex-grow items-center max-w-lg mx-auto">
        <Transition
          show={ready}
          className="w-full"
          enter="transition ease-out duration-500 delay-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <GraphandForm
            fields={{
              firstname,
              lastname,
              email,
              password,
            }}
            model={User}
            onSubmit={_handleSubmit}
            values={defaultValues}
          >
            {({ formRef, handleSubmit, fields, isLoading }) => {
              isLoading = Boolean(isLoading || _loading || loading);

              return (
                <form
                  ref={formRef}
                  onSubmit={isLoading ? null : handleSubmit}
                  className={isLoading ? "cursor-progress w-full" : "w-full"}
                >
                  <h1 className="text-center text-3xl font-bold mb-4">
                    ✉️ {t("actions.join_project")}{" "}
                    <strong className="text-primary">{project?.name}</strong>
                  </h1>
                  <p className="mb-8">
                    {paramsFirstname}, Vous avez reçu une invitation à rejoindre
                    le projet <strong>{project?.name}</strong>. Vous pouvez dès
                    maintenant compléter les informtions ci-dessous pour
                    rejoindre le projet
                  </p>
                  <div
                    className={`space-y-6 mb-8 transition ${
                      isLoading ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="w-full">
                        {fields.render("firstname", {
                          placeholder: "Nouveau prénom",
                          disabled: isLoading,
                        })}
                      </div>
                      <div className="w-full">
                        {fields.render("lastname", {
                          placeholder: "Nouveau nom",
                          disabled: isLoading,
                        })}
                      </div>
                    </div>
                    {fields.render("email", {
                      type: "email",
                      placeholder: "Votre adresse email",
                      disabled: isLoading || paramsEmail,
                    })}
                    {fields.render("password", {
                      theme: "password",
                      placeholder: "Votre mot de passe",
                      disabled: isLoading,
                    })}
                  </div>
                  <Button
                    type="submit"
                    className={isLoading ? "opacity-50" : ""}
                  >
                    <FontAwesomeIcon
                      icon={faLoader}
                      size="lg"
                      className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                        isLoading ? "opacity-100" : "opacity-0"
                      }`}
                    />
                    {t("actions.join")}
                  </Button>
                  <div className="w-full flex items-center mt-3 space-x-2">
                    <Button
                      className={isLoading ? "opacity-50" : ""}
                      theme={ButtonTheme.light}
                      onClick={async () => {
                        const data: any = await oauthGlobal();
                        if (!data?.accessToken || !client) {
                          return;
                        }

                        try {
                          await client.login({
                            method: "invitation",
                            code: paramsCode,
                            email: paramsEmail,
                            userToken: data.accessToken,
                          });
                          await client.authmanager.sync();
                          navigate("/");
                        } catch (e) {
                          alert("Une erreur est survenue");
                          console.log(e);
                        }
                      }}
                    >
                      <img
                        alt="logo"
                        src="/images/icon.png"
                        className="h-6 mr-2"
                      />
                      Continuer avec Graphand
                    </Button>
                  </div>
                </form>
              );
            }}
          </GraphandForm>
        </Transition>
      </div>
      <Transition
        show={ready}
        as={Fragment}
        enter="transition ease-out duration-500 delay-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
      >
        <div className="w-full max-w-lg mx-auto text-center mt-8">
          <Link to="/" className="group">
            Déjà inscrit ?{" "}
            <span className="text-button group-hover:underline">
              Connectez-vous
            </span>
          </Link>
        </div>
      </Transition>
    </>
  );
};

export default Join;
