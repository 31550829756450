import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import Tippy from "@tippyjs/react";

export type SidebarLinkSublink = {
  label: any;
  anchor: string | null;
};

const SidebarLinkSublinks: React.FunctionComponent<{
  links: SidebarLinkSublink[];
  to: string;
  minified: boolean;
  location: Location;
}> = ({ links, to, minified, location }) => {
  const { hash } = location;
  const isActive = location.pathname === to;

  return (
    <Transition
      as="div"
      show={isActive}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {links.map((sublink) => {
        const isSublinkActive =
          isActive &&
          ((!hash && !sublink.anchor) || hash === `#${sublink.anchor}`);
        const linkTo = sublink.anchor ? `${to}#${sublink.anchor}` : to;
        const content = (
          <Link to={linkTo}>
            <div
              className={`flex rounded-2xl transition duration-75 truncate items-center justify-center px-4 h-12 md:h-14 ${
                isSublinkActive
                  ? "text-blue-500"
                  : "text-gray-600 hover:bg-gray-200"
              }`}
            >
              <div
                className={`h-8 w-8 flex items-center justify-center flex-shrink-0 text-xs`}
              >
                <FontAwesomeIcon icon={["fad", "circle-dot"]} />
              </div>
              <span
                className={`hidden ml-2 w-full truncate ${
                  minified ? "" : "md:block"
                }`}
              >
                {sublink.label}
              </span>
            </div>
          </Link>
        );

        return minified ? (
          <Tippy content={sublink.label} placement="right">
            {content}
          </Tippy>
        ) : (
          content
        );
      })}
    </Transition>
  );
};

const SidebarLinkButton: React.FunctionComponent<{
  to: string;
  _IconComponent: any;
  caret: boolean;
  children: any;
  minified: boolean;
  location: Location;
  loading?: boolean;
}> = ({ to, _IconComponent, caret, children, minified, location, loading }) => {
  const { hash } = location;
  const navigate = useNavigate();

  const isActive = location.pathname === to;
  const content = (
    <div
      className={`flex rounded-2xl transition duration-75 truncate items-center justify-center px-4 h-12 md:h-14 text-lg ${
        isActive ? "bg-blue-100 text-blue-500" : "hover:bg-gray-200"
      }`}
      onClick={(e) => {
        if (isActive && !hash) {
          e.preventDefault();
          navigate("/");
        }
      }}
    >
      <div className="h-8 w-8 flex items-center justify-center flex-shrink-0">
        <_IconComponent />
      </div>

      <span
        className={`hidden ml-2 w-full truncate ${minified ? "" : "md:block"}`}
      >
        {children}
      </span>
      {loading ? (
        <div
          className={`h-8 w-8 hidden items-center justify-center duration-200 transition animate-spin ${
            minified ? "" : "md:flex"
          }`}
        >
          <FontAwesomeIcon icon={["far", "loader"]} />
        </div>
      ) : caret ? (
        <div
          className={`h-8 w-8 hidden items-center justify-center duration-200 transition transform ${
            minified ? "" : "md:flex"
          } ${isActive ? "rotate-90" : "rotate-0"}`}
        >
          <FontAwesomeIcon icon={["far", "angle-right"]} />
        </div>
      ) : null}
    </div>
  );

  return minified ? (
    <Tippy content={children} placement="right">
      {content}
    </Tippy>
  ) : (
    content
  );
};

const SidebarLink: React.FunctionComponent<{
  to: string;
  children: any;
  icon?: IconProp;
  className?: string;
  IconComponent?: React.FunctionComponent<any>;
  minified?: boolean;
  sublinks?: SidebarLinkSublink[];
  loading?: boolean;
}> = ({
  to,
  className,
  children,
  icon,
  IconComponent,
  minified,
  sublinks,
  loading,
}) => {
  const location = useLocation();
  const _IconComponent =
    IconComponent ?? (() => (icon ? <FontAwesomeIcon icon={icon} /> : null));

  return (
    <Link key={to} to={to} className={`block group space-y-1 ${className}`}>
      <SidebarLinkButton
        location={location}
        _IconComponent={_IconComponent}
        to={to}
        caret={Boolean(sublinks?.length)}
        loading={loading}
        minified={Boolean(minified)}
      >
        {children}
      </SidebarLinkButton>

      {sublinks?.length ? (
        <SidebarLinkSublinks
          location={location}
          links={sublinks}
          to={to}
          minified={Boolean(minified)}
        />
      ) : null}
    </Link>
  );
};

export default SidebarLink;
