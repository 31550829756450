import React, { useEffect, useRef, useState } from "react";
import { PreviewItemComponentProps } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuList from "./MenuList";
import HistoryList from "./HistoryList";

enum PreviewItemMenu {
  PREVIEW = "preview",
  INFOS = "infos",
  HISTORY = "history",
  RELATIONS = "relations",
}

const PreviewItem: React.FunctionComponent<PreviewItemComponentProps> = ({
  model,
  item,
  setModalProps,
  ignoreFields = [],
}) => {
  const [previewMenu, setPreviewMenu] = useState(PreviewItemMenu.PREVIEW);
  const swiperRef = useRef<any>();
  const intervalRef = useRef<any>();

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    _syncMenu();
  }, [previewMenu, swiperRef.current]);

  const _syncMenu = () => {
    if (!swiperRef.current) {
      return;
    }

    const slides = Array.from(swiperRef.current.slides) as HTMLDivElement[];
    const menuToSlide = slides.find(
      (s) => s.children[0]?.getAttribute("data-key") === previewMenu
    );
    const activeSlideIndex = menuToSlide && slides.indexOf(menuToSlide);
    if (activeSlideIndex !== swiperRef.current.activeIndex) {
      swiperRef.current.slideTo(activeSlideIndex);
    }
  };

  const _syncSlide = () => {
    if (!swiperRef.current) {
      return;
    }

    const { activeIndex } = swiperRef.current;

    const slideToMenu = Object.values(PreviewItemMenu)[activeIndex];
    if (slideToMenu !== previewMenu) {
      setPreviewMenu(slideToMenu);
    }
  };

  const fields = Object.keys(model.fields).filter((f) => {
    return (
      !f.startsWith("_") &&
      !["createdAt", "createdBy", "updatedAt", "updatedBy"]
        .concat(ignoreFields)
        .includes(f)
    );
  });

  return (
    <div>
      <div className="w-full overflow-auto -mt-2 lg:-mt-4 mb-4 border-b border-gray-200 no-scrollbar">
        <MenuList
          activeKey={previewMenu}
          onClick={(i) => setPreviewMenu(i.key as PreviewItemMenu)}
          ContainerComponent={({ children }) => (
            <ul className="relative flex items-center space-x-4">{children}</ul>
          )}
          list={[
            {
              key: PreviewItemMenu.PREVIEW,
              label: (
                <span className="inline-flex flex items-center">
                  <FontAwesomeIcon
                    icon={["fad", "eye"]}
                    className="mr-2 text-sm"
                  />
                  Preview
                </span>
              ),
            },
            {
              key: PreviewItemMenu.INFOS,
              label: (
                <span className="inline-flex flex items-center">
                  <FontAwesomeIcon
                    icon={["fad", "circle-info"]}
                    className="mr-2 text-sm"
                  />
                  Infos
                </span>
              ),
            },
            {
              key: PreviewItemMenu.HISTORY,
              label: (
                <span className="inline-flex flex items-center">
                  <FontAwesomeIcon
                    icon={["fad", "history"]}
                    className="mr-2 text-sm"
                  />
                  Historique
                </span>
              ),
            },
            {
              key: PreviewItemMenu.RELATIONS,
              label: (
                <span className="inline-flex flex items-center">
                  <FontAwesomeIcon
                    icon={["fad", "paperclip"]}
                    className="mr-2 text-sm"
                  />
                  Relations
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className="overflow-hidden">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            intervalRef.current = setInterval(
              () => swiperRef.current.slideReset(0),
              100
            );
          }}
          allowTouchMove={false}
          onSlideChange={_syncSlide}
          autoHeight
          spaceBetween={20}
          slidesPerView={1}
        >
          <SwiperSlide>
            <div className="space-y-3" data-key={PreviewItemMenu.PREVIEW}>
              {fields.map((f) => {
                return item.renderFieldView(f, {
                  context: ViewComponentContext.PREVIEW_LINE,
                  setModalProps,
                });
              })}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="space-y-3" data-key={PreviewItemMenu.INFOS}>
              {["_id", "createdAt", "createdBy", "updatedAt", "updatedBy"].map(
                (f) => {
                  return item.renderFieldView(f, {
                    context: ViewComponentContext.PREVIEW_LINE,
                    setModalProps,
                  });
                }
              )}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="space-y-3" data-key={PreviewItemMenu.HISTORY}>
              <HistoryList historyModel={item.HistoryModel} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="space-y-3"
              data-key={PreviewItemMenu.RELATIONS}
            ></div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default PreviewItem;
