import React, { useEffect, useRef, useState } from "react";
import DataModel from "../models/DataModel";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { GraphandFieldText } from "graphand-js";
import { SelectOptionComponentProps } from "./Select";
import { InputTextOptionsListContainerComponentProps } from "../fields/inputs/Text/_optionsList";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FieldTypeForm from "./FieldTypeForm";
import { getProjectClient } from "../utils/graphand";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { DataFieldFieldType } from "../utils/enums";
import Icon from "./Icon";
import { CreateItemComponentProps } from "../utils/types";
import { assignDataField, dataFieldTypes } from "../utils/fields";

const CreateDataFieldFieldTypeOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option, handleClick, selected }) => {
  const { t } = useTranslation();
  const uiDataField = dataFieldTypes.get(option as DataFieldFieldType);

  if (!uiDataField) {
    return null;
  }

  return (
    <div className="h-0 pb-full relative">
      <div
        className={`absolute inset-0 flex p-2 sm:p-3 lg:p-4 items-center justify-center flex flex-col space-y-2 text-center rounded-lg cursor-pointer bg-gray-100 select-none ${
          selected ? "bg-blue-100 text-blue-500" : "hover:bg-gray-200"
        }`}
        onClick={handleClick}
      >
        <div className="text-2xl">
          <Icon name={uiDataField.icon || "question-circle"} />
        </div>
        <div className="text-xs">{t(`enums.DataFieldFieldType.${option}`)}</div>
      </div>
    </div>
  );
};

const CreateDataFieldTypeFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`${isLoading ? "cursor-progress" : ""}`}
    >
      {fields.render("fieldType", {
        theme: "list",
        label: null,
        OptionComponent: CreateDataFieldFieldTypeOptionComponent,
        ContainerComponent: ({
          children,
        }: InputTextOptionsListContainerComponentProps) => (
          <div className="grid grid-cols-sm gap-2 sm:gap-3 lg:gap-4 mt-5 relative">
            {children}
          </div>
        ),
      })}
    </form>
  );
};

const CreateDataFieldFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("name")}
        {fields.render("slug")}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.createLoading" : "actions.create")}
      </Button>
    </form>
  );
};

const CreateDataField: React.FunctionComponent<
  CreateItemComponentProps & {
    dataModel?: DataModel;
    query?: any;
  }
> = ({ dataModel, setModalProps, onCreate, query }) => {
  const client = getProjectClient();
  const [fieldType, setFieldType] = useState();
  const [values, setValues] = useState<any>({});
  const displayFieldType = useRef<boolean>(true);
  const swiperRef = useRef<any>();
  const updateIntervalRef = useRef<any>();

  useEffect(() => {
    if (
      fieldType &&
      Object.keys(values).length &&
      swiperRef.current &&
      !swiperRef.current.activeSlide
    ) {
      swiperRef.current.slideNext();
    }
  }, [fieldType]);

  useEffect(() => {
    if (client && query) {
      const DataField = client.getModel("DataField");
      const field = new DataField({});
      field.assign(query);
      const _fieldType = field.graphandType;
      if (_fieldType) {
        displayFieldType.current = false;
        setFieldType(field.graphandType);
      }
    }
  }, []);

  if (!client) {
    return null;
  }

  const DataField = client.getModel("DataField");

  enum STEPS {
    "infos" = "infos",
    "fieldType" = "fieldType",
    "configuration" = "configuration",
  }

  let steps = Object.values(STEPS) as Array<STEPS>;

  if (!displayFieldType.current) {
    steps = steps.filter((s) => s !== STEPS.fieldType);
  }

  const _handleSlideChange = (swiper: any) => {
    clearInterval(updateIntervalRef.current);

    if (!setModalProps) {
      return;
    }

    const activeStep = steps[swiper.activeIndex];

    if (activeStep === STEPS.infos) {
      setModalProps((p) => {
        if (p.title || p.className) {
          return {};
        }

        return p;
      });
    } else if (activeStep === STEPS.fieldType) {
      setModalProps((p) => ({
        ...p,
        title: "Sélectionner le type de champ",
      }));
    } else if (activeStep === STEPS.configuration) {
      updateIntervalRef.current = setInterval(
        () => swiperRef.current?.update(),
        100
      );

      setModalProps((p) => ({
        ...p,
        title: "Configurer le champ",
      }));
    }
  };

  const _handleSubmit = async (step: STEPS, data: any) => {
    if (step === STEPS.infos) {
      setValues(data);
      swiperRef.current?.slideNext();
    } else if (step === STEPS.configuration) {
      const _values: any = { ...values };

      if (fieldType) {
        assignDataField(fieldType, _values);
        setValues(_values);
      }

      const scope = query?.scope || dataModel?._scope;
      const dataField = await DataField.create({
        ..._values,
        configuration: data,
        scope,
      });
      onCreate?.(dataField);
    }
  };

  const _renderStep = (step: STEPS) => {
    switch (step) {
      case STEPS.infos:
        return (
          <GraphandForm
            values={values}
            model={DataField}
            template={CreateDataFieldFormTemplate}
            onSubmit={(values: any) => _handleSubmit(step, values)}
          />
        );
      case STEPS.fieldType:
        return (
          <GraphandForm
            values={{ fieldType }}
            onChange={({ fieldType: _f }: any) => {
              setFieldType(_f);
              swiperRef.current?.slideNext();
            }}
            fields={{
              fieldType: new GraphandFieldText({
                options: Array.from(dataFieldTypes.keys()),
              }),
            }}
            template={CreateDataFieldTypeFormTemplate}
          />
        );
      case STEPS.configuration:
        const field = new DataField({});
        field.assign(query);

        return (
          <FieldTypeForm
            fieldType={fieldType}
            values={field.configuration}
            onSubmit={(values: any) => _handleSubmit(step, values)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Swiper
      autoHeight
      spaceBetween={50}
      slidesPerView={1}
      effect="cards"
      modules={[EffectCards]}
      cardsEffect={{
        slideShadows: false,
      }}
      onTouchMove={() => clearInterval(updateIntervalRef.current)}
      onTouchEnd={() => {
        updateIntervalRef.current = setInterval(
          () => swiperRef.current?.update(),
          100
        );
      }}
      onSlideChange={_handleSlideChange}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;

        _handleSlideChange(swiper);
      }}
    >
      {steps.map((step) => (
        <SwiperSlide key={step}>{_renderStep(step)}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CreateDataField;
