import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import {
  getGlobalClient,
  getProjectClient,
  keepConnectedSubject,
} from "../../../utils/graphand";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Transition } from "@headlessui/react";
import { GraphandFieldBoolean } from "graphand-js";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { useCurrentUser } from "../../../utils/hooks";

const Login: FunctionComponent<{
  loading?: boolean;
  ready: boolean;
}> = ({ loading, ready }) => {
  const globalClient = getGlobalClient();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [_loading, setLoading] = useState(globalClient.authmanager.loading);
  const User = globalClient.getModel("User");
  const paramsEmail = searchParams.get("email");
  const { user } = useCurrentUser();

  useEffect(() => {
    const sub = globalClient.authmanager.loadingSubject.subscribe(setLoading);

    return () => sub.unsubscribe();
  }, [globalClient]);

  const _handleSubmit = async (credentials: any) => {
    if ("keepConnected" in credentials) {
      keepConnectedSubject.next(credentials.keepConnected);
      delete credentials.keepConnected;
    }

    if (paramsEmail) {
      credentials.email = paramsEmail;
    }

    await globalClient.authmanager.login(credentials);

    const projectClient = getProjectClient();
    if (projectClient) {
      projectClient.setAccessToken(globalClient.getAccessToken());
      projectClient.setRefreshToken(globalClient.getRefreshToken());
      await projectClient.authmanager.sync();
    }

    await globalClient.authmanager.sync();
  };

  const { email, password } = User.fields;
  return (
    <>
      <div className="w-full flex flex-grow items-center max-w-lg mx-auto">
        <Transition
          show={ready}
          className="w-full"
          enter="transition ease-out duration-500 delay-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <GraphandForm
            fields={{
              email,
              password,
              keepConnected: new GraphandFieldBoolean(),
            }}
            model={User}
            onSubmit={_handleSubmit}
            values={{
              email: user?.email || paramsEmail,
              keepConnected: keepConnectedSubject.getValue(),
            }}
            template={({ formRef, handleSubmit, fields, isLoading }) => {
              isLoading = Boolean(isLoading || _loading || loading);

              return (
                <form
                  ref={formRef}
                  onSubmit={isLoading ? null : handleSubmit}
                  className={isLoading ? "cursor-progress w-full" : "w-full"}
                >
                  <h1 className="text-center text-3xl font-bold mb-4">
                    Connexion à Graphand
                  </h1>
                  <div
                    className={`space-y-6 mb-8 transition ${
                      isLoading ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    {fields.render("email", {
                      type: "email",
                      placeholder: "Votre adresse email",
                      disabled: isLoading || paramsEmail,
                    })}
                    {fields.render("password", {
                      theme: "password",
                      placeholder: "Votre mot de passe",
                      disabled: isLoading,
                    })}
                    <div className="text-sm flex items-center justify-between space-x-4">
                      {fields.render("keepConnected", {
                        disabled: isLoading,
                      })}
                      <Link
                        to="/recover"
                        className="text-button hover:underline text-right"
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className={isLoading ? "opacity-50" : ""}
                  >
                    <FontAwesomeIcon
                      icon={faLoader}
                      size="lg"
                      className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                        isLoading ? "opacity-100" : "opacity-0"
                      }`}
                    />
                    {isLoading ? t("actions.loginLoading") : t("actions.login")}
                  </Button>
                </form>
              );
            }}
          />
        </Transition>
      </div>
      <Transition
        show={ready}
        as={Fragment}
        enter="transition ease-out duration-500 delay-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
      >
        <div className="w-full max-w-lg mx-auto text-center mt-8">
          <Link to="/signup" className="group">
            Pas encore inscrit ?{" "}
            <span className="text-button group-hover:underline">
              Inscrivez-vous
            </span>
          </Link>
        </div>
      </Transition>
    </>
  );
};

export default Login;
