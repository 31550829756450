import React, { useState, useEffect } from "react";
import fileSize from "filesize";
import numeral from "numeral";
import { getProjectClient } from "../../../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const colors = [
  "bg-green-700",
  "bg-orange-700",
  "bg-orange-700",
  "text-green-700",
  "text-orange-700",
  "text-orange-700",
];

const SettingsUsageLine: React.FunctionComponent<{
  title?: string;
  usage: number;
  limit?: number;
  format?: (value: number) => string;
}> = ({
  title,
  usage = 0,
  limit = 1,
  format = (v) => numeral(v).format("0a"),
}) => {
  limit = !limit ? Infinity : limit;
  const ratio = usage / limit;

  let color = "red-700";

  if (ratio < 0.6) {
    color = "green-700";
  } else if (ratio < 0.8) {
    color = "orange-700";
  }

  return (
    <div>
      {title ? <div className="text-gray-500">{title}</div> : null}
      <div className="flex items-center space-x-4">
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className={`h-2.5 transition-all rounded-full bg-${color}`}
            style={{ width: `${(ratio < 0.025 ? 0.025 : ratio) * 100}%` }}
          />
        </div>
        <div className={`text-sm text-${color} w-16 text-left truncate`}>
          {limit === Infinity ? (
            <FontAwesomeIcon icon={faInfinity} />
          ) : (
            format(limit)
          )}
        </div>
      </div>
      <div className={`text-sm text-${color}`}>
        Utilisation : {format(usage)} ({Math.floor(ratio * 1000) / 10}%)
      </div>
    </div>
  );
};

const SettingsUsage = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<{ stats: any; loading: boolean }>({
    stats: null,
    loading: false,
  });

  const _reload = async () => {
    const client = getProjectClient();
    if (!client) {
      return;
    }

    setState((s) => ({ ...s, loading: true }));
    try {
      const stats = await client.getStats();
      setState((s) => ({ ...s, stats, loading: false }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false }));
    }
  };

  useEffect(() => {
    _reload();
  }, []);

  const { stats, loading } = state;

  if (!stats) {
    return null;
  }

  return (
    <div className="sm:p-4 lg:p-6">
      <div className="w-full max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          📈 {t("labels.settings.usage")}
        </h1>

        <div className="space-y-4">
          <h2 className="block text-xl">Plan: {stats.plan}</h2>

          <SettingsUsageLine
            title="Nombre d'appels sur les 30 derniers jours"
            usage={stats.usage.callsCount}
            limit={stats.limits.callsCount}
          />
          <SettingsUsageLine
            title="Nombre de comptes"
            usage={stats.usage.accountsCount}
            limit={stats.limits.accountsCount}
          />
          <SettingsUsageLine
            title="Traffic sortant sur les 30 derniers jours"
            usage={stats.usage.trafficSize}
            limit={stats.limits.trafficSize}
            format={(v) => fileSize(v)}
          />
          <SettingsUsageLine
            title="Stockage utilisé"
            usage={stats.usage.storageSize}
            limit={stats.limits.storageSize}
            format={(v) => fileSize(v)}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsUsage;
