import Wizard from "../components/Wizard";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { ModelPageWizardProps } from "../pages/project/ModelPage";
import AddFilterModal from "../modals/AddFilterModal";
import { GraphandModelConstructor } from "../utils/types";
import { useModelFilters } from "../utils/hooks";

const CreateFiltersWizard = (props: ModelPageWizardProps) => {
  const { t } = useTranslation();
  const model = props.model as GraphandModelConstructor;
  const [addFilterOpen, setAddFilterOpen] = useState(false);
  const { filters, addFilter } = useModelFilters(model?.__dataModel);

  return (
    <>
      <Wizard
        condition={model.__dataModel?.multiple && !filters?.length}
        wizardKey={props.wizardKey}
        ContainerComponent={props.ContainerComponent}
      >
        <div className="font-bold mb-2">
          Vous pouvez créer vos propres filtres
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
          beatae corporis deserunt eveniet fuga illum ipsam libero, magnam nemo
          nesciunt odio placeat quaerat quia quisquam, quo sunt tenetur, vero
          voluptatibus!
        </p>
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="flex items-center w-full text-right justify-end mt-6 text-button hover:underline"
            onClick={() => setAddFilterOpen(true)}
          >
            {t("actions.add_filter")}
            <FontAwesomeIcon icon={["far", "arrow-right"]} className="ml-2" />
          </button>
        </div>
      </Wizard>

      <AddFilterModal
        onClose={setAddFilterOpen}
        isOpen={addFilterOpen}
        model={model}
        onSave={async (conf) => {
          await addFilter(conf);
          setAddFilterOpen(false);
        }}
      />
    </>
  );
};

export default CreateFiltersWizard;
