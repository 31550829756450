import React, { useEffect, useState } from "react";
import { useLogged, useProject } from "./utils/hooks";
import Project from "./Project";
import Global from "./Global";
import { BrowserRouter } from "react-router-dom";
import { getProjectMatch } from "./utils/getProjectMatch";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";
import OAuth from "./pages/OAuth";

let animationStageTimeout: any;

function App() {
  const project = useProject();
  const _logged = useLogged();
  const [animationStage, setAnimationStage] = useState<number>(0);
  const [logged, setLogged] = useState<boolean>(!!_logged);

  useEffect(() => {
    if (logged !== _logged) {
      setAnimationStage(0);
    }
  }, [_logged]);

  useEffect(() => {
    if (animationStageTimeout) {
      clearTimeout(animationStageTimeout);
    }

    if (animationStage === 0) {
      animationStageTimeout = setTimeout(() => setAnimationStage(1), 800);
    } else if (animationStage === 1) {
      animationStageTimeout = setTimeout(() => setAnimationStage(2), 300);
    } else if (animationStage === 2) {
      setLogged(!!_logged);
    } else {
      animationStageTimeout = setTimeout(() => setAnimationStage(0), 300);
    }
  }, [animationStage]);

  if (getProjectMatch() && !project) {
    return null;
  }

  if (window.opener) {
    return <OAuth logged={Boolean(_logged)} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="relative">
        <BrowserRouter>
          {getProjectMatch() || (_logged && project) ? (
            <Project
              logged={Boolean(_logged)}
              animationStage={animationStage}
              setAnimationStage={setAnimationStage}
              ready={animationStage === 2}
            />
          ) : (
            <Global
              logged={Boolean(_logged)}
              animationStage={animationStage}
              setAnimationStage={setAnimationStage}
              ready={animationStage === 2}
            />
          )}
        </BrowserRouter>

        <div id="headlessui-portal-root">
          <div />
        </div>
      </div>

      <ToastContainer />
    </DndProvider>
  );
}

export default App;
