import React, { FunctionComponent, useEffect } from "react";
import { controlSubject, readySubject } from "../../components/LayoutGlobal";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUserCircle,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { setOrganization, useOrganization } from "../../utils/hooks";
import ProjectsList from "./ProjectsList";
import Button from "../../components/Button";
import { getGlobalClient } from "../../utils/graphand";
import { InfiniteList } from "graphand-react";
import { GraphandModelUser } from "graphand-js";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownPosition } from "../../components/Dropdown";

const MembersListTile: FunctionComponent<{ item: GraphandModelUser }> = ({
  item: user,
}) => {
  const navigate = useNavigate();
  const client = getGlobalClient();
  const { organization } = useOrganization();

  const _handleLeave = async () => {
    if (!organization) {
      return;
    }

    await organization.leave();
    setOrganization(null);
    navigate("/");
  };

  return (
    <div className="w-full flex items-center py-3">
      <div className="bg-global-inset h-14 w-14 flex items-center justify-center rounded-full text-white select-none flex-shrink-0">
        {user?.picture ? (
          <img
            src={`${user.picture}?u=${user.updatedAt.toString()}&h=100&w=100`}
            alt={user.fullname}
            className="h-full w-full rounded-full object-cover"
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} size="2x" />
        )}
      </div>
      <div className="ml-3 w-full">
        <div className="text-lg font-bold truncate">{user.fullname}</div>
        <div className="text-sm text-gray-500 truncate">{user.email}</div>
      </div>
      <div className="flex items-center justify-end space-x-4 cursor-pointer">
        {user._id === client.authmanager.user?._id ? (
          <Dropdown
            position={DropdownPosition.RIGHT}
            button={
              <button
                type="button"
                className="text h-10 w-10 rounded-md flex items-center justify-center focus:outline-none select-none text-xl text-gray-700 hover:bg-gray-200"
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </button>
            }
            className="w-72"
          >
            <div className="p-4 space-y-4">
              <div>
                Voulez-vous vraiment quitter l'organisation "
                {organization?.name}" ?
              </div>
              <button
                type="button"
                className="bg-red-500 h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button-red rounded-xl border border-white text-white"
                onClick={_handleLeave}
              >
                Confirmer
              </button>
            </div>
          </Dropdown>
        ) : null}
      </div>
    </div>
  );
};

const MembersList = () => {
  const { organization } = useOrganization();
  const client = getGlobalClient();
  const User = client.getModel("User");
  const { t } = useTranslation();

  useEffect(() => {
    if (organization) {
      controlSubject.next(
        <div>
          <Link to="/invitations/new" className="inline-block">
            <Button>
              <FontAwesomeIcon icon={faUserPlus} className="w-6 h-6 mr-2" />
              Inviter des membres
            </Button>
          </Link>
        </div>
      );
    }

    readySubject.next(true);

    return () => {
      controlSubject.next(null);
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return <ProjectsList />;
  }

  return (
    <div className="flex flex-col divide-y divide-gray-200">
      <InfiniteList
        model={User}
        ItemComponent={MembersListTile}
        opts={{
          ids: organization.users.ids,
        }}
      />
    </div>
  );
};

export default MembersList;
