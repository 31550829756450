import Modal, { ModalCloseType, ModalProps } from "../../components/Modal";
import React, { useEffect, useRef, useState } from "react";
import ListPicker from "../../components/ListPicker";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import AddFilterSelectTypeOptionComponent from "./SelectTypeOption";
import AddFilterSelection from "./Selection";
import AddFilterStatic from "./Static";
import AddFilterIntelligent from "./Intelligent";
import { useTranslation } from "react-i18next";
import {
  FilterConf,
  FilterItem,
  GraphandModelConstructor,
  SelectionStackItem,
} from "../../utils/types";
import AddFilterMediaType from "./MediaType";

export enum FilterModalType {
  STATIC = "static",
  INTELLIGENT = "intelligent",
  SELECTION = "selection",
  MEDIA_TYPE = "mediaType",
}

export type AddFilterModalProps = Omit<ModalProps, "isOpen"> & {
  isOpen: boolean | FilterModalType;
  filter?: FilterItem;
  model: GraphandModelConstructor;
  selectionStack?: SelectionStackItem[];
  onSave: (filterConf: FilterConf<any>) => void;
};

const AddFilterModal: React.FunctionComponent<AddFilterModalProps> = (
  props
) => {
  const swiperRef = useRef<any>();
  const [type, _setType] = useState<FilterModalType>();
  const { isOpen, model, selectionStack, onSave, filter, ...modalProps } =
    props;
  const { t } = useTranslation();

  const setType = (type?: FilterModalType) => {
    _setType(type);

    setTimeout(() => {
      if (!swiperRef.current) {
        return;
      }

      if (!type) {
        swiperRef.current.slideTo(0);
      } else if (type) {
        swiperRef.current.slideTo(1);
      }
    });
  };

  useEffect(() => {
    if (
      typeof isOpen === "string" &&
      Object.values(FilterModalType).includes(isOpen)
    ) {
      setType(isOpen);
    } else if (!isOpen) {
      setTimeout(() => _setType(undefined), 300);
    }
  }, [isOpen]);

  const filters = [FilterModalType.STATIC, FilterModalType.INTELLIGENT];

  if (selectionStack?.length) {
    filters.push(FilterModalType.SELECTION);
  }

  return (
    <Modal
      {...modalProps}
      onClose={
        typeof isOpen !== "string" && type
          ? () => {
              swiperRef.current.slideTo(0);
              setType(undefined);
            }
          : modalProps.onClose
      }
      closeType={
        typeof isOpen !== "string" && type
          ? ModalCloseType.BACK
          : ModalCloseType.DEFAULT
      }
      isOpen={Boolean(isOpen)}
      title={
        type
          ? t(`enums.FilterModalType.${type}.titleModal`)
          : t(`actions.add_filter`)
      }
      className="max-w-screen-sm"
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <Swiper
          allowTouchMove={false}
          autoHeight
          spaceBetween={50}
          slidesPerView={1}
          effect="cards"
          modules={[EffectCards]}
          cardsEffect={{
            slideShadows: false,
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setInterval(() => swiper.slideReset(0), 100);
            if (type && swiperRef.current?.activeIndex === 0) {
              swiperRef.current.slideTo(1);
            }
          }}
        >
          <SwiperSlide className="bg-white">
            <ListPicker
              value={type}
              onChange={setType}
              options={filters.concat(model.filtersOptions || [])}
              ContainerComponent={({ children }) => (
                <ul className="space-y-4">{children}</ul>
              )}
              OptionComponent={AddFilterSelectTypeOptionComponent}
            />
          </SwiperSlide>
          <SwiperSlide className="bg-white">
            {(() => {
              switch (type) {
                case FilterModalType.SELECTION:
                  return <AddFilterSelection {...props} />;
                case FilterModalType.STATIC:
                  return <AddFilterStatic {...props} />;
                case FilterModalType.INTELLIGENT:
                  return <AddFilterIntelligent {...props} />;
                case FilterModalType.MEDIA_TYPE:
                  return <AddFilterMediaType {...props} />;
                default:
                  return null;
              }
            })()}
          </SwiperSlide>
        </Swiper>
      </div>
    </Modal>
  );
};

export default AddFilterModal;
