import { GraphandForm } from "graphand-react";
import {
  getGlobalClient,
  getProjectClient,
  keepConnectedSubject,
} from "../../../utils/graphand";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Transition } from "@headlessui/react";
import { GraphandFieldBoolean } from "graphand-js";
import { Link, useSearchParams } from "react-router-dom";
import { useCurrentAccount, useCurrentProject } from "../../../utils/hooks";
import Button, { ButtonTheme } from "../../../components/Button";
import { oauthGlobal } from "../../../utils/tools";
import { useTranslation } from "react-i18next";

const Login: FunctionComponent<{
  loading?: boolean;
  ready: boolean;
}> = ({ loading, ready }) => {
  const globalClient = getGlobalClient();
  const projectClient = getProjectClient();
  const [searchParams] = useSearchParams();
  const [_loading, setLoading] = useState(projectClient?.authmanager.loading);
  const User = globalClient.getModel("User");
  const paramsEmail = searchParams.get("email");
  const { project } = useCurrentProject();
  const { account } = useCurrentAccount();
  const { t } = useTranslation();

  useEffect(() => {
    const sub = projectClient?.authmanager.loadingSubject.subscribe(setLoading);

    return () => sub?.unsubscribe();
  }, [projectClient]);

  const _handleSubmit = async (credentials: any) => {
    if ("keepConnected" in credentials) {
      keepConnectedSubject.next(credentials.keepConnected);
      delete credentials.keepConnected;
    }

    if (paramsEmail) {
      credentials.email = paramsEmail;
    }

    await projectClient?.authmanager.login(credentials);
    await projectClient?.authmanager.sync();
  };

  const { email, password } = User.fields;

  return (
    <div className="w-full flex flex-grow items-center max-w-lg mx-auto">
      <Transition
        show={ready}
        className="w-full"
        enter="transition ease-out duration-500 delay-300"
        enterFrom="opacity-0 scale-90"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-90"
      >
        <GraphandForm
          key={account?.email}
          fields={{
            email,
            password,
            keepConnected: new GraphandFieldBoolean(),
          }}
          model={User}
          onSubmit={_handleSubmit}
          values={{
            email: account?.email || paramsEmail,
            keepConnected: keepConnectedSubject.getValue(),
          }}
        >
          {({ formRef, handleSubmit, fields, isLoading }) => {
            isLoading = Boolean(isLoading || _loading || loading);

            return (
              <form
                ref={formRef}
                onSubmit={isLoading ? null : handleSubmit}
                className={isLoading ? "cursor-progress w-full" : "w-full"}
              >
                <h1 className="text-center text-3xl font-bold mb-4">
                  Connexion à{" "}
                  <strong className="text-primary">{project?.name}</strong>
                </h1>
                <div
                  className={`space-y-6 mb-8 transition ${
                    isLoading ? "opacity-50" : "opacity-100"
                  }`}
                >
                  {fields.render("email", {
                    type: "email",
                    placeholder: "Votre adresse email",
                    disabled: isLoading || paramsEmail,
                  })}
                  {fields.render("password", {
                    theme: "password",
                    placeholder: "Votre mot de passe",
                    disabled: isLoading,
                  })}
                  <div className="text-sm flex items-center justify-between space-x-4">
                    {fields.render("keepConnected", {
                      disabled: isLoading,
                    })}
                    <Link
                      to="/recover"
                      className="text-button hover:underline text-right"
                    >
                      Mot de passe oublié ?
                    </Link>
                  </div>
                </div>
                <Button type="submit" className={isLoading ? "opacity-50" : ""}>
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="lg"
                    className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                      isLoading ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  {isLoading ? t("actions.loginLoading") : t("actions.login")}
                </Button>
                <div className="w-full flex items-center mt-3 space-x-2">
                  <Button
                    className={isLoading ? "opacity-50" : ""}
                    theme={ButtonTheme.light}
                    onClick={async () => {
                      const data: any = await oauthGlobal();
                      if (!data?.accessToken) {
                        return;
                      }

                      try {
                        await projectClient?.setAccessToken(data.accessToken);
                        await projectClient?.setRefreshToken(data.refreshToken);
                        await projectClient?.authmanager.sync();

                        if (!projectClient?.authmanager.user) {
                          throw new Error();
                        }
                      } catch (e) {
                        alert("Une erreur est survenue");
                        console.log(e);
                      }
                    }}
                  >
                    <img
                      alt="logo"
                      src="/images/icon.png"
                      className="h-6 mr-2"
                    />
                    Connexion avec Graphand
                  </Button>
                </div>
              </form>
            );
          }}
        </GraphandForm>
      </Transition>
    </div>
  );
};

export default Login;
