import Modal, { ModalProps } from "../components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { getProjectClient } from "../utils/graphand";
import { useCurrentProject } from "../utils/hooks";
import { GraphandModelDataField } from "graphand-js";
import Button from "../components/Button";

const InviteAccountFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const { project } = useCurrentProject();
  const DataField = client?.getModel("DataField");

  const _render = (accountField?: GraphandModelDataField) => {
    return (
      <form
        ref={formRef}
        onSubmit={isLoading ? null : handleSubmit}
        className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
      >
        <div className="space-y-4">
          <div className="flex items-center w-full space-x-4">
            <div className="w-full">{fields.render("firstname")}</div>
            <div className="w-full">{fields.render("lastname")}</div>
          </div>
          {fields.render("email")}
          {fields.render("role", { theme: "select" })}
          {Object.keys(fields)
            .filter(
              (f) =>
                ![
                  accountField?.slug,
                  "firstname",
                  "lastname",
                  "password",
                  "_fullname",
                  "role",
                  "email",
                  "invite",
                  "settings",
                ].includes(f)
            )
            .map((f) => fields.render(f))}
        </div>
        <Button type="submit">
          {t(isLoading ? "actions.createLoading" : "actions.create")}
        </Button>
      </form>
    );
  };

  if (!project?.configuration.accountPictureField) {
    return _render();
  }

  return (
    <>
      {DataField?.get(
        project.configuration.accountPictureField.toString()
      )?.suspense(
        (field) => {
          return _render(field as GraphandModelDataField);
        },
        { subscribe: true }
      )}
    </>
  );
};

const InviteAccountModal: React.FunctionComponent<ModalProps & {}> = ({
  ...modalProps
}) => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  const model = client.getModel("Account");

  const _handleSubmit = async (values: any) => {
    const item = await model.create({ ...values, invite: true });
    modalProps.onClose(false);
    // onCreate(item);
  };

  return (
    <Modal
      title={t("actions.invite_account")}
      className="max-w-screen-md"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <GraphandForm
          model={model}
          template={InviteAccountFormTemplate}
          onSubmit={_handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default InviteAccountModal;
