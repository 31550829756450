import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import SettingsGeneral from "./settings/SettingsGeneral";
import SettingsUsage from "./settings/SettingsUsage";
import SettingsHome from "./settings/SettingsHome";
import { SidebarProjectMinifiedSubject } from "../../components/SidebarProject";
import SettingsSchema from "./settings/SettingsSchema";
import SettingsSecurity from "./settings/SettingsSecurity";
import SettingsTokens from "./settings/SettingsTokens";
import SettingsSockethooks from "./settings/SettingsSockethooks";
import SettingsWebhooks from "./settings/SettingsWebhooks";
import SettingsEnvironments from "./settings/SettingsEnvironments";
import SettingsAggregations from "./settings/SettingsAggregations";
import SettingsSearch from "./settings/SettingsSearch";
import SettingsInterface from "./settings/SettingsInterface";
import { Transition } from "@headlessui/react";
import SidebarSettings from "../../components/SidebarSettings";
import {
  projectRouterLocationSubject,
  projectRouterNextLocationSubject,
} from "../../Project";
import SettingsSecrets from "./settings/SettingsSecrets";

const Settings = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  useEffect(() => {
    SidebarProjectMinifiedSubject.next(true);
    const sub1 = projectRouterNextLocationSubject.subscribe((l) =>
      projectRouterLocationSubject.next(l)
    );

    return () => {
      sub1.unsubscribe();
      SidebarProjectMinifiedSubject.next(false);
    };
  }, []);

  return (
    <div className="flex h-full">
      <SidebarSettings />

      <Transition
        as="div"
        className="w-full h-full overflow-auto"
        show={location.pathname === displayLocation?.pathname}
        appear
        enter="transition transform ease-out duration-200"
        enterFrom="opacity-0 -translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="transition transform ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-4"
        afterLeave={() => {
          if (location.pathname !== displayLocation?.pathname) {
            setDisplayLocation(location);
          }
        }}
      >
        {location.pathname.startsWith("/settings") ? (
          <Routes key={displayLocation.pathname} location={displayLocation}>
            <Route index element={<SettingsHome />} />
            <Route path="usage" element={<SettingsUsage />} />
            <Route path="general" element={<SettingsGeneral />} />
            <Route path="schema" element={<SettingsSchema />} />
            <Route path="security" element={<SettingsSecurity />} />
            <Route path="tokens" element={<SettingsTokens />} />
            <Route path="sockethooks" element={<SettingsSockethooks />} />
            <Route path="webhooks" element={<SettingsWebhooks />} />
            <Route path="environments" element={<SettingsEnvironments />} />
            <Route path="aggregations" element={<SettingsAggregations />} />
            <Route path="search" element={<SettingsSearch />} />
            <Route path="interface" element={<SettingsInterface />} />
            <Route path="secrets" element={<SettingsSecrets />} />
          </Routes>
        ) : null}
      </Transition>
    </div>
  );
};

export default Settings;
