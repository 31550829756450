import React, { FunctionComponent, useEffect, useState } from "react";
import { generateBorderDashed } from "../utils/tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { useDropzone } from "react-dropzone";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";

type ImagePickerProps = {
  icon?: any;
  value?: any;
  onChange: (file: File | null) => void;
  defaultPreview?: string;
  loading?: boolean;
};

const ImagePicker: FunctionComponent<ImagePickerProps> = ({
  icon,
  value,
  onChange,
  defaultPreview,
  loading,
}) => {
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.onload = function () {
        setPreview(reader.result);
      };
      reader.readAsDataURL(value);
    } else {
      setPreview(null);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.onload = function () {
        setPreview(reader.result);
      };
      reader.readAsDataURL(value);
    } else {
      setPreview(undefined);
    }
  }, [value]);

  const onDrop = (files: any) => {
    const [file] = files;
    if (!file) {
      return;
    }

    onChange(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const _preview = preview || defaultPreview;

  return (
    <div
      className={`h-36 w-36 p-3 rounded-full inline-flex justify-center group cursor-pointer select-none ${
        _preview && !loading ? "items-end" : "items-center"
      }`}
      style={{
        backgroundImage: _preview
          ? `url("${_preview}")`
          : generateBorderDashed({ color: "#D1D5DB" }),
        backgroundSize: "cover",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        className={`w-8 h-8 flex items-center justify-center rounded-full ${
          loading
            ? "bg-button text-white"
            : "bg-gray-500 text-gray-300 group-hover:bg-button group-hover:text-white group-hover:opacity-100 opacity-80"
        }`}
      >
        {loading ? (
          <FontAwesomeIcon icon={faLoader} className="animate-spin" />
        ) : (
          <FontAwesomeIcon icon={_preview ? faPencil : icon ?? faCamera} />
        )}
      </div>
    </div>
  );
};

export default ImagePicker;
