import React from "react";
import MenuList, { MenuListProps } from "./MenuList";

const SwitchList = (props: MenuListProps) => {
  return (
    <MenuList
      {...props}
      IndicatorComponent={({ width, height, left }) => (
        <div
          className="absolute top-0 bg-white z-0 rounded-lg shadow transition-all"
          style={{
            width,
            height,
            left,
          }}
        />
      )}
      ContainerComponent={({ children }) => (
        <ul className="relative flex items-center space-x-1">{children}</ul>
      )}
    />
  );
};

export default SwitchList;
