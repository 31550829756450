import React, { useCallback, useState } from "react";
import { GraphandFieldText, GraphandValidationError } from "graphand-js";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { AddFilterModalProps } from "./index";
import { InputTextOptionsListOption } from "../../fields/inputs/Text/_optionsList";
import { capitalizeFirstLetter } from "../../utils/formatter";
import { useTranslation } from "react-i18next";
import { Combobox } from "@headlessui/react";
import QueryBuilder from "../../components/QueryBuilder";
import { deepEqual } from "fast-equals";
import { FilterConfType } from "../../utils/types";

const AddFilterIntelligent: React.FunctionComponent<AddFilterModalProps> = ({
  model,
  onSave,
}) => {
  const [query, setQuery] = useState({});

  const _handleSubmit = (values: any) => {
    const errors = [];

    if (!values.field) {
      errors.push(
        new GraphandValidationError(
          "Field field is required",
          "field",
          "required"
        )
      );
    }

    if (errors.length) {
      throw errors;
    }

    const selectedField = values?.field && model.fields[values.field];
    let type;
    if (selectedField) {
      if (selectedField.ref) {
        type = FilterConfType.INTELLIGENT_RELATION;
      } else if (selectedField.options) {
        type = FilterConfType.INTELLIGENT_OPTIONS;
      }
    }

    if (!type) {
      return;
    }

    const moduleFilter = {
      ...values,
      type,
      query,
    };

    onSave(moduleFilter);
  };

  const _formIntelligentOptions = ({ values }: GraphandFormTemplateParams) => {
    return (
      <ul className="w-full space-y-2">
        {model.fields[values.field].options.map((option: string) => (
          <li className="flex items-center w-full text-gray-600">
            <FontAwesomeIcon
              icon={["fad", "wand-magic-sparkles"]}
              className="mr-2"
            />
            <div className="w-full truncate">{option}</div>
          </li>
        ))}
      </ul>
    );
  };

  const _formIntelligentRelation = ({ values }: GraphandFormTemplateParams) => {
    const targetModel = model._client.getModel(model.fields[values.field].ref);

    if (!targetModel) {
      return null;
    }

    return (
      <>
        <QueryBuilder
          InputContainerComponent={({ children }) => (
            <div className="flex space-x-3 relative z-10">
              <div className="w-full">{children}</div>
              <Combobox.Button as={React.Fragment}>
                {({ open }) => (
                  <button
                    type="button"
                    className="border border-gray-200 flex-grow rounded-xl flex-shrink-0 border bg-white px-4 flex items-center truncate hover:bg-gray-100"
                  >
                    <span className="mr-1.5">Filtrer</span>
                    <FontAwesomeIcon
                      icon={["far", "angle-down"]}
                      className={`transition ${open ? "rotate-180" : ""}`}
                    />
                  </button>
                )}
              </Combobox.Button>
            </div>
          )}
          model={targetModel}
          // reload={values.field}
          onChange={(queryCreator) => {
            const q = queryCreator();
            if (deepEqual(q, query)) {
              return;
            }

            setQuery(q);
          }}
        />

        {/*{targetModel*/}
        {/*  .getList({ query, count: true, pageSize: 10 })*/}
        {/*  .suspense((list) => {*/}
        {/*    return (*/}
        {/*      <ul className="w-full space-y-2">*/}
        {/*        {list.map((i) => (*/}
        {/*          <li className="flex items-center w-full text-gray-600">*/}
        {/*            <FontAwesomeIcon*/}
        {/*              icon={["fad", "wand-magic-sparkles"]}*/}
        {/*              className="mr-2"*/}
        {/*            />*/}
        {/*            <div className="w-full truncate">*/}
        {/*              {i.renderDefaultFieldView({*/}
        {/*                context: ViewComponentContext.LIST_ROW,*/}
        {/*              })}*/}
        {/*            </div>*/}
        {/*          </li>*/}
        {/*        ))}*/}
        {/*      </ul>*/}
        {/*    );*/}
        {/*  })}*/}
      </>
    );
  };

  const template = useCallback((params: GraphandFormTemplateParams) => {
    const { formRef, handleSubmit, fields, isLoading, values } = params;
    const selectedField = values?.field && model.fields[values.field];
    let type;
    if (selectedField) {
      if (selectedField.ref) {
        type = FilterConfType.INTELLIGENT_RELATION;
      } else if (selectedField.options) {
        type = FilterConfType.INTELLIGENT_OPTIONS;
      }
    }

    return (
      <form
        ref={formRef}
        onSubmit={isLoading ? null : handleSubmit}
        className={`${isLoading ? "cursor-progress" : ""}`}
      >
        <div
          className={`space-y-6 transition mb-8 ${
            isLoading ? "opacity-50" : "opacity-100"
          }`}
        >
          {fields.render("field", {
            disabled: isLoading,
            theme: "list",
            ContainerComponent: ({ children }) => (
              <div className="-m-2">
                <ul className="w-full flex flex-wrap">{children}</ul>
              </div>
            ),
            OptionComponent: ({ option, handleClick, selected }) => {
              const { t } = useTranslation();
              return (
                <li
                  onClick={handleClick}
                  className="w-28 py-4 px-2 m-2 flex-grow border border-gray-200 rounded-2xl overflow-hidden cursor-pointer hover:bg-gray-100 relative flex flex-col items-center justify-center space-y-2"
                >
                  <div className="flex items-center h-5">
                    <input
                      id={option}
                      name={option}
                      type="radio"
                      checked={selected}
                      className="focus:ring-indigo-500 h-4 w-4 text-button border-gray-200"
                    />
                  </div>
                  <label
                    htmlFor={option}
                    className="flex items-center justify-center cursor-pointer space-x-1 w-full text-sm"
                  >
                    {/*<GraphandFieldIcon*/}
                    {/*  field={model.fields[option]}*/}
                    {/*  slug={option}*/}
                    {/*/>*/}
                    <div className="truncate">
                      {capitalizeFirstLetter(
                        model.fields[option]?.__dataField?.name ||
                          t(`labels.fields.${option}.default`)
                      )}
                    </div>
                  </label>
                </li>
              );
            },
          } as InputTextOptionsListOption)}

          {type
            ? (() => {
                switch (type) {
                  case FilterConfType.INTELLIGENT_RELATION:
                    return _formIntelligentRelation(params);
                  case FilterConfType.INTELLIGENT_OPTIONS:
                    return _formIntelligentOptions(params);
                  default:
                    return null;
                }
              })()
            : null}
        </div>
        <Button
          type="submit"
          className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
        >
          <FontAwesomeIcon
            icon={faLoader}
            size="lg"
            className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
              isLoading ? "opacity-100" : "opacity-0"
            }`}
          />
          Enregistrer
        </Button>
      </form>
    );
  }, []);

  return (
    <GraphandForm
      fields={{
        field: new GraphandFieldText({
          options: Object.keys(model.fields).filter(
            (slug) =>
              (model.fields[slug].ref !== "Media" && model.fields[slug].ref) ||
              model.fields[slug].options?.length
          ),
        }),
      }}
      template={template}
      onSubmit={_handleSubmit}
    />
  );
};

export default AddFilterIntelligent;
