import Wizard from "../components/Wizard";
import React from "react";

const WelcomeSettingsWizard = (props: any) => {
  return (
    <Wizard wizardKey="welcome-settings" {...props}>
      <div className="font-bold mb-2">
        Bienvenue dans les paramètres du projet
      </div>
      <p>
        C'est ici que vous pouvez modifier les paramètres, configurer votre
        schema de données, etc.
        <br />
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi
        consequuntur dolor eaque excepturi fuga fugiat illo laudantium maxime
        minus, nesciunt nobis optio quis rem rerum suscipit tempore, tenetur
        voluptate? Unde.
      </p>
    </Wizard>
  );
};

export default WelcomeSettingsWizard;
