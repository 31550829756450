import React, { useEffect } from "react";
import { GraphandForm } from "graphand-react";
import { getGlobalClient } from "../../utils/graphand";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { setOrganization, useCurrentUser } from "../../utils/hooks";
import { slugCase } from "../../utils/formatter";
import { useNavigate } from "react-router-dom";
import { sendConfirmationLink } from "../../utils/tools";

const CreateOrganization = () => {
  const navigate = useNavigate();
  const client = getGlobalClient();
  const { user } = useCurrentUser();
  const Organization = client.getModel("Organization");

  useEffect(() => {
    titleSubject.next("Créer une organisation");
    readySubject.next(true);

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  });

  const _handleSubmit = async (
    values: Partial<InstanceType<typeof Organization>>
  ) => {
    const organization = await Organization.create(values);
    setOrganization(organization);
    navigate(-1);
  };

  if (user.status === "new") {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-2">
          ⏱ Une petite seconde, {user.firstname}
        </h1>
        <p>
          Avant de pouvoir créer une nouvelle organisation, vous devez{" "}
          <strong>confirmer votre adresse email</strong>. Un email contenant un
          lien de confirmation vous a été envoyé à l'adresse{" "}
          <strong>{user.email}</strong>. Si vous n'avez pas reçu cet email ou
          que le lien contenu dans celui-ci n'est plus valide, vous pouvez{" "}
          <button
            type="button"
            className="text-button hover:underline"
            onClick={sendConfirmationLink}
          >
            en recevoir un nouveau en cliquant ici
          </button>
          .
        </p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-2">🏛 Prêts, feu, collaborez !</h1>
      <p className="mb-8">
        Sur graphand, les projets et factures sont regroupées au sein
        d'organisations. C'est donc ici que vous pourrez créer des groupes de
        collaboration afin d'organiser vos projets communs et de regrouper vos
        factures.
      </p>
      <div className="max-w-lg mx-auto">
        <GraphandForm model={Organization} onSubmit={_handleSubmit}>
          {({ formRef, handleSubmit, fields, isLoading }) => {
            return (
              <form
                ref={formRef}
                onSubmit={isLoading ? null : handleSubmit}
                className={isLoading ? "cursor-progress" : ""}
              >
                <div
                  className={`space-y-6 mb-8 transition ${
                    isLoading ? "opacity-50" : "opacity-100"
                  }`}
                >
                  {fields.render("name", {
                    disabled: isLoading,
                    placeholder: `Organisation de ${user.fullname}`,
                  })}
                  {fields.render("slug", {
                    disabled: isLoading,
                    placeholder: slugCase(`Organisation de ${user.fullname}`),
                  })}
                </div>
                <button
                  type="submit"
                  className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
                >
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="lg"
                    className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                      isLoading ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  Valider
                </button>
              </form>
            );
          }}
        </GraphandForm>
      </div>
    </div>
  );
};

export default CreateOrganization;
