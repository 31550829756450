import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GraphandForm } from "graphand-react";
import { GraphandFieldText } from "graphand-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { getGlobalClient } from "../../../utils/graphand";

const Signup: FunctionComponent<{
  loading?: boolean;
  ready: boolean;
}> = ({ loading, ready }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const client = getGlobalClient();
  const [_loading, setLoading] = useState(client.authmanager.loading);
  const User = client.getModel("User");
  const paramsEmail = searchParams.get("email");

  useEffect(() => {
    const sub = client.authmanager.loadingSubject.subscribe(setLoading);

    return () => sub.unsubscribe();
  }, [client]);

  const _handleSubmit = async (values: any) => {
    if (paramsEmail) {
      values.email = paramsEmail;
    }

    const User = client.getModel("User");
    await User.register(values);
    await client.authmanager.login(values);
    navigate("/");
  };

  const { firstname, lastname, email, password } = User.fields;
  return (
    <>
      <div className="w-full flex flex-grow items-center max-w-lg mx-auto">
        <Transition
          show={ready}
          className="w-full"
          enter="transition ease-out duration-500 delay-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <GraphandForm
            fields={{
              firstname,
              lastname,
              email,
              password,
              job: new GraphandFieldText(),
            }}
            model={User}
            onSubmit={_handleSubmit}
            values={{ email: paramsEmail || undefined }}
          >
            {({ formRef, handleSubmit, fields, isLoading }) => {
              isLoading = Boolean(isLoading || _loading || loading);

              return (
                <form
                  ref={formRef}
                  onSubmit={isLoading ? null : handleSubmit}
                  className={isLoading ? "cursor-progress w-full" : "w-full"}
                >
                  <div
                    className={`space-y-4 mb-8 transition ${
                      isLoading ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="w-full">
                        {fields.render("firstname", {
                          placeholder: "Nouveau prénom",
                          disabled: isLoading,
                        })}
                      </div>
                      <div className="w-full">
                        {fields.render("lastname", {
                          placeholder: "Nouveau nom",
                          disabled: isLoading,
                        })}
                      </div>
                    </div>
                    {fields.render("email", {
                      type: "email",
                      placeholder: "Votre adresse email",
                      disabled: isLoading || paramsEmail,
                    })}
                    {fields.render("password", {
                      theme: "password",
                      placeholder: "Votre mot de passe",
                      disabled: isLoading,
                    })}
                    {fields.render("job", {
                      placeholder: "Votre poste dans votre entreprise",
                      disabled: isLoading,
                    })}
                  </div>
                  <button
                    type="submit"
                    className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-xl border border-white text-white"
                  >
                    <FontAwesomeIcon
                      icon={faLoader}
                      size="lg"
                      className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                        isLoading ? "opacity-100" : "opacity-0"
                      }`}
                    />
                    Inscription
                  </button>
                </form>
              );
            }}
          </GraphandForm>
        </Transition>
      </div>
      <Transition
        show={ready}
        as={Fragment}
        enter="transition ease-out duration-500 delay-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
      >
        <div className="w-full max-w-lg mx-auto text-center mt-8">
          <Link to="/" className="group">
            Déjà inscrit ?{" "}
            <span className="text-button group-hover:underline">
              Connectez-vous
            </span>
          </Link>
        </div>
      </Transition>
    </>
  );
};

export default Signup;
