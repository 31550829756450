import React from "react";
import { getGlobalClient } from "../utils/graphand";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../utils/hooks";
import Dropdown, { DropdownPosition } from "./Dropdown";
import UserPicture from "./UserPicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarGlobal = () => {
  const client = getGlobalClient();
  const OrgInvitation = client.getModel("OrgInvitation");
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  return (
    <div className="h-16 px-4 flex items-center justify-between border-b border-gray-300 bg-background">
      <div>
        <Logo />
      </div>
      <div>
        {OrgInvitation.getList({
          query: { email: "$currentUser.email" },
          count: true,
        }).suspense((list) => {
          return (
            <Dropdown
              position={DropdownPosition.RIGHT}
              button={
                <button
                  type="button"
                  className="h-16 inline-flex items-center justify-center text-md"
                >
                  <div className="h-10 w-10 rounded-full overflow-hidden flex items-center justify-center text-sm">
                    {user.picture ? (
                      <UserPicture
                        src={`${
                          user.picture
                        }?u=${user.updatedAt.getTime()}&h=100&w=100`}
                        alt={user.fullname}
                      />
                    ) : (
                      <FontAwesomeIcon
                        size="2x"
                        icon={["fal", "user-circle"]}
                        className="text-gray-400"
                      />
                    )}
                  </div>
                </button>
              }
              links={[
                list.count
                  ? { label: `${list.count} invitation(s)`, link: "/join" }
                  : null,
                [
                  { label: t("actions.user_settings"), link: "/account" },
                  {
                    label: t("actions.organization_settings"),
                    link: "/settings",
                  },
                ],
                {
                  label: "Déconnexion",
                  onClick: () => getGlobalClient().logout(),
                },
              ].filter(Boolean)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NavbarGlobal;
