import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button, { ButtonTheme } from "../Button";
import Dropdown, { DropdownPosition } from "../Dropdown";
import { ViewComponentContext } from "../../utils/enums";
import UpdateItemModal from "../../modals/UpdateItemModal";
import { GraphandModel } from "graphand-js";
import PreviewItemModal from "../../modals/PreviewItemModal";
import { SelectionStackItem } from "../../utils/types";
import { copyToClipboard } from "../../utils/tools";
import CreateItemModal from "../../modals/CreateItemModal";

let itemCheckedCache = new Set<string[]>([]);

const ModelGridItemComponent = ({
  item,
  isActive,
  watcherRef,
  checked,
  setSelectionStack,
  displaySelection,
  listActions,
  onClick = "preview",
  imageField,
  titleField,
  subtitleField,
  infoField,
}: any) => {
  const { t } = useTranslation();
  const [dropdownActive, setDropdownActive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  useEffect(() => {
    if (displaySelection && checked !== undefined) {
      if (checked) {
        itemCheckedCache.add(item._id);
      } else {
        itemCheckedCache.delete(item._id);
      }
    }
  }, [checked]);

  useEffect(() => {
    if (dropdownActive) {
      setConfirmDelete(false);
    }
  }, [dropdownActive]);

  const _handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick === "update") {
      setUpdateModalOpen(true);
    } else if (onClick === "preview") {
      setPreviewModalOpen(true);
    } else if (typeof onClick === "function") {
      onClick(item);
    }
  };

  const actions = [
    {
      label: t("actions.copy_id"),
      onClick: () => copyToClipboard(item._id),
    },
    item.constructor.createItemComponent
      ? {
          label: t("actions.duplicate"),
          onClick: () => setCreateModalOpen(true),
        }
      : null,
    item.constructor.previewItemComponent
      ? {
          label: t("actions.preview"),
          onClick: () => setPreviewModalOpen(true),
        }
      : null,
    item.constructor.updateItemComponent
      ? {
          label: t("actions.update"),
          onClick: () => setUpdateModalOpen(true),
        }
      : null,
    confirmDelete
      ? {
          label: t("actions.confirm_delete"),
          theme: "danger",
          onClick: async () => {
            try {
              await item.delete();
            } catch (e) {
              console.error(e);
              alert(
                "Une erreur est survenue, impossible de supprimer cet élément"
              );
            }
          },
        }
      : {
          label: t("actions.delete"),
          onClick: (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setConfirmDelete(true);
          },
        },
  ].filter(Boolean);

  return (
    <>
      <div
        className={`relative group transition-all duration-500 origin-top-left ${
          isActive
            ? "translate-y-0 opacity-100 scale-100"
            : "-translate-y-2 opacity-0 scale-90"
        } ${dropdownActive ? "z-30" : ""}`}
        ref={watcherRef}
      >
        <div className="hover:opacity-75" onClick={_handleClick}>
          <div className="h-0 pb-full relative">
            <div className="absolute inset-0 flex items-center justify-center border border-gray-200 rounded-lg overflow-hidden">
              {imageField ? (
                item.renderFieldView(imageField, {
                  context: ViewComponentContext.GRID_LABEL_IMAGE,
                  fallback: (
                    <div className="h-full w-full flex items-center justify-center text-7xl flex-shrink-0 cursor-pointer text-primary relative">
                      <div className="absolute inset-0 bg-grid-texture -z-10 opacity-30" />
                      <img
                        src="/images/icon.png"
                        alt="Graphand"
                        className="h-14 opacity-75"
                      />
                    </div>
                  ),
                })
              ) : (
                <div className="h-full w-full flex items-center justify-center text-7xl flex-shrink-0 cursor-pointer text-primary relative">
                  <div className="absolute inset-0 bg-grid-texture -z-10 opacity-30" />
                  <img
                    src="/images/icon.png"
                    alt="Graphand"
                    className="h-14 opacity-75"
                  />
                </div>
              )}
            </div>

            {infoField ? (
              <div className={`absolute bottom-2 right-2`}>
                {item.renderFieldView(infoField, {
                  context: ViewComponentContext.GRID_LABEL_INFO,
                })}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col flex-shrink-0 w-full p-1">
            {titleField
              ? item.renderFieldView(titleField, {
                  context: ViewComponentContext.GRID_LABEL_TITLE,
                })
              : null}
            {subtitleField
              ? item.renderFieldView(subtitleField, {
                  context: ViewComponentContext.GRID_LABEL_SUBTITLE,
                })
              : null}
          </div>
        </div>

        {displaySelection ? (
          <div className="absolute top-1.5 left-2 p-2">
            <input
              type="checkbox"
              className={`h-5 w-5 block text-button border-gray-300 rounded-md transition ${
                checked === undefined ? "opacity-50" : ""
              }`}
              checked={checked ?? itemCheckedCache.has(item._id)}
              onChange={() => {
                if (checked === undefined) {
                  return;
                }

                if (itemCheckedCache.has(item._id)) {
                  itemCheckedCache.delete(item._id);
                } else {
                  itemCheckedCache.add(item._id);
                }

                setSelectionStack((s: SelectionStackItem[]) => {
                  return s.concat({
                    query: { _id: { $eq: item._id } },
                    inverse: checked,
                  });
                });
              }}
            />
          </div>
        ) : null}

        <div className={`absolute top-2 right-2`}>
          <Dropdown
            position={DropdownPosition.RIGHT}
            onOpen={setDropdownActive}
            onClose={setDropdownActive}
            button={
              <div
                className={`bg-gray-200 bg-opacity-60 rounded-xl ${
                  dropdownActive
                    ? "opacity-100"
                    : "opacity-100 sm:opacity-0 group-hover:opacity-100"
                }`}
              >
                <Button
                  theme={ButtonTheme.inline}
                  className="h-8 w-8 flex-shrink-0 bg-gray-200 text-sm"
                >
                  <FontAwesomeIcon
                    icon={["far", "colon"]}
                    className="rotate-90"
                  />
                </Button>
              </div>
            }
            links={[listActions, actions].filter(Boolean)}
          />
        </div>
      </div>

      <CreateItemModal
        isOpen={createModalOpen}
        onClose={setCreateModalOpen}
        model={item.constructor as typeof GraphandModel}
        defaults={item.toJSON()}
      />

      <UpdateItemModal
        isOpen={updateModalOpen}
        onClose={setUpdateModalOpen}
        model={item.constructor as typeof GraphandModel}
        item={item}
      />

      <PreviewItemModal
        isOpen={previewModalOpen}
        onClose={setPreviewModalOpen}
        model={item.constructor as typeof GraphandModel}
        item={item}
      />
    </>
  );
};

export default ModelGridItemComponent;
