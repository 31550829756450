import { GraphandModelOrganization } from "graphand-js";
import { GraphandFormTemplateParams } from "graphand-react";
import { slugCase } from "../utils/formatter";

class Organization extends GraphandModelOrganization {
  static previewField = "name";
}

Organization.hook(
  "formChange",
  (values: any, { instance, isModified }: GraphandFormTemplateParams) => {
    if ((!instance || instance.isTemporary()) && !isModified("slug")) {
      values.slug = slugCase(values.name);
    }
  }
);

Organization.hook(
  "formSubmit",
  (values: any, { instance, isModified }: GraphandFormTemplateParams) => {
    if ((!instance || instance.isTemporary()) && !isModified("slug")) {
      values.slug = slugCase(values.name);
    }
  }
);

export default Organization;
