import React from "react";
import { QueryBuilderFilterTileProps } from "./QueryBuilderFilterTile";
import { useQueryBuilderFilterTile } from "../../utils/hooks";
import InputRelation from "../../fields/inputs/Relation";
import { GraphandFieldRelation } from "graphand-js";
import { InputComponentContext } from "../../utils/enums";

const QueryBuilderFilterTileFieldRelation: React.FunctionComponent<
  QueryBuilderFilterTileProps
> = (props) => {
  const { model } = props;

  const modelField = model.fields[props.filter.payload.field];

  const {
    state,
    setState,
    inputRef,
    renderDropdown,
    renderCloseButton,
    renderLabel,
    disabled,
  } = useQueryBuilderFilterTile(props, {
    operators: modelField.multiple
      ? ["$in", "$nin"]
      : ["$eq", "$ne", "$in", "$nin"],
    defaultState: {
      operator: modelField.multiple ? "$in" : "$eq",
      value: "",
    },
    validateChange: (state) => state.value?.length,
    proxyAssign: (state, props) => {
      const { operator, value } = state;
      const { field } = props.filter.payload;

      if (!field || !operator) {
        return (q) => q;
      }

      const res = {
        [operator]: value,
      };

      return (query) => {
        query[field] = res;
        return query;
      };
    },
  });

  if (!modelField) {
    return null;
  }

  return (
    <div className="flex items-center bg-gray-200 rounded-lg py-2 px-3 select-none text-gray-600">
      <div className="flex items-center space-x-3">
        {renderLabel}
        {renderDropdown}
        <div className="relative flex items-center h-7">
          <InputRelation
            inputRef={inputRef}
            value={state.value}
            onChange={(v) => setState((s) => ({ ...s, value: v }))}
            field={
              new GraphandFieldRelation({
                ref: modelField.ref,
                multiple:
                  modelField.multiple ||
                  ["$in", "$nin"].includes(String(state.operator)),
              })
            }
            model={model}
            options={{
              label: null,
              disabled,
              context: InputComponentContext.QUERY_BUILDER_TILE,
              open: !state.value,
            }}
          />
        </div>
      </div>
      {renderCloseButton}
    </div>
  );
};

export default QueryBuilderFilterTileFieldRelation;
