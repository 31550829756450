import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonTheme } from "../Button";
import Dropdown, { DropdownPosition } from "../Dropdown";
import { ViewComponentContext } from "../../utils/enums";
import UpdateItemModal from "../../modals/UpdateItemModal";
import { GraphandModel } from "graphand-js";
import PreviewItemModal from "../../modals/PreviewItemModal";
import { SelectionStackItem } from "../../utils/types";
import { copyToClipboard } from "../../utils/tools";
import CreateItemModal from "../../modals/CreateItemModal";

let itemCheckedCache = new Set<string[]>([]);

const ModelListItemComponent = ({
  item,
  isActive,
  watcherRef,
  checked,
  setSelectionStack,
  displayFields,
  LabelComponent,
  displaySelection,
  listActions,
  inlineActions,
  onClick,
}: any) => {
  const { t } = useTranslation();
  const [dropdownActive, setDropdownActive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    if (displaySelection && checked !== undefined) {
      if (checked) {
        itemCheckedCache.add(item._id);
      } else {
        itemCheckedCache.delete(item._id);
      }
    }
  }, [checked]);

  useEffect(() => {
    if (dropdownActive) {
      setConfirmDelete(false);
    }
  }, [dropdownActive]);

  const _handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick === "update") {
      setUpdateModalOpen(true);
    } else if (onClick === "preview") {
      setPreviewModalOpen(true);
    } else if (typeof onClick === "function") {
      onClick(item);
    }
  };

  const actions = [
    !item?.isTemporary()
      ? {
          label: t("actions.copy_id"),
          onClick: () => copyToClipboard(item._id),
        }
      : null,
    item.constructor.createItemComponent
      ? {
          label: t("actions.duplicate"),
          onClick: () => setCreateModalOpen(true),
        }
      : null,
    ,
    item.constructor.previewItemComponent
      ? {
          label: t("actions.preview"),
          onClick: () => setPreviewModalOpen(true),
        }
      : null,
    item.constructor.updateItemComponent && !item?.isTemporary()
      ? {
          label: t("actions.update"),
          onClick: () => setUpdateModalOpen(true),
        }
      : null,
    !item?.isTemporary()
      ? confirmDelete
        ? {
            label: t("actions.confirm_delete"),
            theme: "danger",
            onClick: async () => {
              try {
                await item.delete();
              } catch (e) {
                console.error(e);
                alert(
                  "Une erreur est survenue, impossible de supprimer cet élément"
                );
              }
            },
          }
        : {
            label: t("actions.delete"),
            onClick: (e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setConfirmDelete(true);
            },
          }
      : null,
  ].filter(Boolean);

  return (
    <>
      <tr
        className={`transition duration-300 group ${
          onClick ? "cursor-pointer" : ""
        } ${isActive ? "" : "opacity-0 -translate-x-2"}`}
        ref={watcherRef}
        onClick={_handleClick}
      >
        {displaySelection ? (
          <td className="w-10 sm:w-12 lg:w-14 flex items-center justify-center">
            <input
              type="checkbox"
              className={`h-5 w-5 text-button border-gray-300 rounded-md transition ${
                checked === undefined ? "opacity-50" : ""
              }`}
              checked={checked ?? itemCheckedCache.has(item._id)}
              onChange={() => {
                if (checked === undefined) {
                  return;
                }

                if (itemCheckedCache.has(item._id)) {
                  itemCheckedCache.delete(item._id);
                } else {
                  itemCheckedCache.add(item._id);
                }

                setSelectionStack((s: SelectionStackItem[]) => {
                  return s.concat({
                    query: { _id: { $eq: item._id } },
                    inverse: checked,
                  });
                });
              }}
            />
          </td>
        ) : null}
        {displayFields.map((slug: string, index: number) => {
          return (
            <td className={index ? "hidden sm:table-cell" : ""}>
              {slug === "_label" ? (
                <LabelComponent
                  item={item}
                  onClick={() => !onClick && setPreviewModalOpen(true)}
                />
              ) : (
                item.renderFieldView(slug, {
                  context: ViewComponentContext.TABLE_CELL,
                })
              )}
            </td>
          );
        })}
        <td
          className={`w-16 sm:w-18 lg:w-20 flex items-center justify-center ${
            dropdownActive ? "z-30" : ""
          }`}
        >
          {inlineActions?.map((a: any, index: number) => (
            <Button
              onClick={a.onClick}
              theme={ButtonTheme.inline}
              className="h-8 w-8 flex-shrink-0 absolute text-xs text-gray-400 hidden sm:flex"
              style={{ right: index * 40 + 70 }}
            >
              <FontAwesomeIcon icon={["far", a.icon || "question"]} />
            </Button>
          )) ||
            (item.constructor.updateItemComponent && !item?.isTemporary() ? (
              <Button
                onClick={() => setUpdateModalOpen(true)}
                theme={ButtonTheme.inline}
                className="h-8 w-8 flex-shrink-0 absolute text-xs text-gray-400 opacity-0 group-hover:opacity-100 hidden sm:flex"
                style={{ right: 70 }}
              >
                <FontAwesomeIcon icon={["far", "edit"]} />
              </Button>
            ) : null)}
          {listActions?.length || actions?.length ? (
            <Dropdown
              position={DropdownPosition.RIGHT}
              onOpen={setDropdownActive}
              onClose={setDropdownActive}
              button={
                <Button
                  theme={ButtonTheme.inline}
                  className="h-12 w-12 flex-shrink-0"
                >
                  <FontAwesomeIcon
                    icon={["far", "colon"]}
                    className="rotate-90"
                  />
                </Button>
              }
              links={[listActions, actions].filter(Boolean)}
            />
          ) : null}
        </td>
      </tr>

      <CreateItemModal
        isOpen={createModalOpen}
        onClose={setCreateModalOpen}
        model={item.constructor as typeof GraphandModel}
        defaults={item.toJSON()}
      />

      <UpdateItemModal
        isOpen={updateModalOpen}
        onClose={setUpdateModalOpen}
        model={item.constructor as typeof GraphandModel}
        item={item}
      />

      <PreviewItemModal
        isOpen={previewModalOpen}
        onClose={setPreviewModalOpen}
        model={item.constructor as typeof GraphandModel}
        item={item}
      />
    </>
  );
};

export default ModelListItemComponent;
