import Modal, { ModalProps, ModalTransitionType } from "../components/Modal";
import React from "react";
import Button, { ButtonTheme } from "../components/Button";

const ConfirmQuitFormModal: React.FunctionComponent<
  ModalProps & {
    onConfirm: (confirm: boolean) => void;
  }
> = ({ onConfirm, ...modalProps }) => {
  return (
    <Modal
      title="Voulez-vous quitter ?"
      transitionType={ModalTransitionType.SCALE}
      onConfirm={() => {
        onConfirm(true);
      }}
      className="max-w-xl"
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2 space-y-6">
        <p>
          En cliquant sur <strong>Continuer</strong>, les données en cours de
          modification ne seront pas enregistrées. Êtes-vous sur de vouloir
          continuer ?
        </p>
        <div className="flex items-center justify-center space-x-2">
          <Button
            theme={ButtonTheme.light}
            onClick={() => modalProps.onClose(false)}
          >
            Annuler
          </Button>
          <Button
            theme={ButtonTheme.default_green}
            onClick={() => onConfirm(true)}
          >
            Continuer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmQuitFormModal;
