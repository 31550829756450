import React, { Fragment, useEffect, useRef, useState } from "react";
import { getProjectClient } from "../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewComponentContext } from "../utils/enums";
import getIconForMimetype from "../utils/getIconForMimetype";
import { uploadingFilesSubject } from "./LayoutProject";
import { Dialog, Transition } from "@headlessui/react";
import fileSize from "filesize";
import Button, { ButtonTheme } from "./Button";
import PreviewItemModal from "../modals/PreviewItemModal";

const MediaUploadMenuItem: React.FunctionComponent<{
  item: {
    name: string;
    bytesReceived: number;
    bytesExpected: number;
    status: "end" | "progress" | "start" | "error" | "aborted";
    mediaId?: string;
  };
}> = ({ item }) => {
  const client = getProjectClient();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  if (!client) {
    return null;
  }

  const Media = client.getModel("Media");

  let progress = item.bytesReceived / item.bytesExpected || 0;

  if (item.status === "end") {
    progress = 1;
  }

  const file = uploadingFilesSubject
    .getValue()
    .find((f) => f.name === item.name);

  return (
    <div className="py-4 flex items-center space-x-4">
      {item.mediaId ? (
        Media.get(item.mediaId).suspense((media) =>
          media.renderFieldView("_preview", {
            context: ViewComponentContext.TABLE_CELL,
          })
        )
      ) : (
        <div className="h-16 w-16 flex items-center justify-center text-4xl flex-shrink-0 cursor-pointer text-primary">
          <FontAwesomeIcon
            icon={[
              "fad",
              file?.type ? getIconForMimetype(file.type) : "photo-film",
            ]}
          />
        </div>
      )}
      <div className="w-full overflow-hidden">
        <div className="text-lg truncate select-none">{item.name}</div>
        <div className="h-5 flex items-center relative">
          <Transition
            show={item.status === "progress"}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="absolute inset-0 bg-white flex items-center">
              <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                <div
                  className="bg-green-500 h-2.5 rounded-full"
                  style={{ width: `${Math.max(progress, 0.025) * 100}%` }}
                />
              </div>
            </div>
          </Transition>
          {item.status === "error" || item.status === "aborted" ? (
            <div className="text-sm text-red-500">Une erreur est survenue</div>
          ) : item.mediaId ? (
            Media.get(item.mediaId).suspense((media) => (
              <div className="text-sm text-gray-500">
                {fileSize(media.size)}
              </div>
            ))
          ) : file ? (
            <div className="text-sm text-gray-500">{fileSize(file.size)}</div>
          ) : (
            <div className="text-sm text-gray-500">{item.status}</div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {item.mediaId ? (
          <>
            {Media.get(item.mediaId).suspense((media) => {
              if (!media) {
                return null;
              }

              return (
                <>
                  <Button
                    theme={ButtonTheme.inline}
                    onClick={() => setPreviewModalOpen(true)}
                  >
                    <FontAwesomeIcon icon={["far", "eye"]} />
                  </Button>

                  <PreviewItemModal
                    isOpen={previewModalOpen}
                    onClose={setPreviewModalOpen}
                    model={Media}
                    item={media}
                  />
                </>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

const MediaUploadMenu: React.FunctionComponent<any> = () => {
  const client = getProjectClient();
  const closeButtonRef = useRef(null);
  const [mediasQueue, setMediaQueue] = useState(
    client?._mediasQueueSubject.getValue()
  );
  const prevQueueLength = useRef(mediasQueue?.length);
  const [isOpen, setIsOpen] = useState(true);
  const lastUpdateRef = useRef(new Date());
  const lastReceivedRef = useRef<number>();
  const [speed, setSpeed] = useState<string>("");
  const [newUpload, setNewUpload] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      if (now !== lastUpdateRef.current) {
        const v = client?._mediasQueueSubject.getValue();
        const pending = v?.filter((i) => i.status !== "end") || [];
        const received = pending.reduce(
          (total, i) => total + i.bytesReceived,
          1
        );

        const receivedSinceLastUpdate =
          received - (lastReceivedRef.current || 0);
        const timeSinceLastUpdate =
          now.getTime() - lastUpdateRef.current.getTime();

        setSpeed(
          `${fileSize(
            (receivedSinceLastUpdate / timeSinceLastUpdate) * 1000
          )}/s`
        );

        lastUpdateRef.current = now;
        lastReceivedRef.current = received;
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    const sub = client._mediasQueueSubject.subscribe(setMediaQueue);

    return () => sub?.unsubscribe();
  }, [client]);

  useEffect(() => {
    if (!isOpen && newUpload) {
      setNewUpload(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (mediasQueue?.length !== prevQueueLength.current) {
      prevQueueLength.current = mediasQueue?.length;
      if (!isOpen && !newUpload) {
        setNewUpload(true);
      }
    }
  }, [mediasQueue]);

  const pending = mediasQueue?.filter((i) => i.status !== "end") || [];
  const received = pending.reduce((total, i) => total + i.bytesReceived, 1);
  const expected = pending.reduce((total, i) => total + i.bytesExpected, 1);
  const progress = received / expected;

  return (
    <>
      <Transition
        show={Boolean(isOpen && mediasQueue?.length)}
        as={React.Fragment}
      >
        <Dialog
          initialFocus={closeButtonRef}
          as={React.Fragment}
          onClose={() => null}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-2"
          >
            <div className="absolute bottom-0 right-0 z-50 p-2 sm:p-6 max-w-full max-h-[50%] origin-bottom-right flex">
              <div className="relative w-full bg-white rounded-2xl sm:rounded-xl lg:rounded-lg shadow-2xl w-upload-menu max-w-full overflow-auto p-3 px-4 sm:p-4 lg:p-6">
                <div className="space-x-4 flex items-start justify-between w-full overflow-hidden mb-2">
                  <div className="text-2xl font-medium w-full flex-shrink overflow-hidden space-y-1">
                    <div className="w-full truncate">
                      Envoi de {mediasQueue?.length} fichiers
                    </div>
                    <div className="flex items-center">
                      <div className="w-20 text-sm truncate text-primary">
                        {Math.ceil(progress * 1000) / 10 || 0} %
                      </div>
                      {pending.length ? (
                        <div className="w-20 text-sm truncate text-gray-500">
                          {speed}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex items-center justify-center space-x-3">
                    <button
                      ref={closeButtonRef}
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="h-6 w-6 flex items-center justify-center text-xl flex-shrink-0"
                    >
                      <FontAwesomeIcon icon={["far", "xmark"]} />
                    </button>
                  </div>
                </div>
                {[...(mediasQueue || [])].reverse().map((i) => (
                  <MediaUploadMenuItem item={i} />
                ))}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>

      <Transition
        as={React.Fragment}
        show={Boolean(!isOpen && mediasQueue?.length)}
        enter="ease-out duration-100 delay-300"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-50"
      >
        <div className="absolute bottom-0 right-0 z-30 m-2 sm:m-6">
          <Button
            className="relative rounded-full h-16 w-16 text-2xl"
            onClick={() => setIsOpen(true)}
          >
            <FontAwesomeIcon icon={["fad", "upload"]} />
          </Button>
          {newUpload ? (
            <div className="absolute rounded-full bg-primary h-6 w-6 top-5 right-5 flex items-center justify-center text-white text-sm">
              <FontAwesomeIcon icon={["fal", "bell"]} />
            </div>
          ) : null}
        </div>
      </Transition>
    </>
  );
};

export default MediaUploadMenu;
