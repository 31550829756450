import React from "react";
import { GraphandField } from "graphand-js";
import { capitalizeFirstLetter } from "../../utils/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const ModelListHeaderField: React.FunctionComponent<{
  slug: string;
  field: GraphandField;
  sort: string;
  setSort: (sort: string) => void;
  labelField?: string;
  index: number;
  disableHeader?: boolean;
}> = ({ slug, field, sort, setSort, labelField, index }) => {
  const { t } = useTranslation();

  let sortSlug = slug;
  if (slug === "_label" && labelField) {
    sortSlug = labelField;
  }

  const match = sort.match(new RegExp(`(\-)?${sortSlug}`));

  let isSorted: boolean = false;
  let inverted: boolean = false;

  if (match) {
    const { 1: _inverted } = match;
    isSorted = true;
    inverted = !_inverted;
  }

  const _handleClick = () => {
    if (!isSorted) {
      setSort("-" + sortSlug);
    } else if (!inverted) {
      setSort(sortSlug);
    } else {
      setSort("-_id");
    }
  };

  return (
    <th
      className={`text-left ${index ? "hidden sm:table-cell" : ""}`}
      onClick={_handleClick}
    >
      <div className="flex items-center space-x-1">
        <div className="text-gray-600">
          {capitalizeFirstLetter(
            field?.__dataField?.name || t(`labels.fields.${slug}.default`)
          )}
        </div>
        <div
          className={`h-5 w-5 flex items-center justify-center text-sm transition ${
            inverted ? "rotate-90" : "-rotate-90"
          } ${isSorted ? "opacity-100" : "opacity-0"}`}
        >
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </div>
      </div>
    </th>
  );
};

export default ModelListHeaderField;
