import React, { useRef } from "react";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { QuickCreateItemComponentProps } from "../utils/types";
import { GraphandField } from "graphand-js";
import InputFile from "../fields/inputs/File";

const CreateMediaFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const submitButtonRef = useRef<any>();

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={isLoading ? "cursor-progress" : ""}
    >
      {fields.render("file", {
        label: null,
        onChange: () => {
          setTimeout(() => {
            submitButtonRef.current?.click();
          });
        },
      })}
      <input type="submit" ref={submitButtonRef} className="hidden" />
    </form>
  );
};

const QuickCreateMedia: React.FunctionComponent<
  QuickCreateItemComponentProps
> = ({ model, onCreate }) => {
  const _handleSubmit = async (values: any) => {
    if (!values.file) {
      return;
    }

    const item = await model.create(values);
    onCreate(item);
  };

  return (
    <GraphandForm
      onSubmit={_handleSubmit}
      model={model}
      template={CreateMediaFormTemplate}
      resetAfterSubmit={false}
      fields={(f: any) => ({
        ...f,
        file: new GraphandField({ InputComponent: InputFile }),
      })}
    />
  );
};

export default QuickCreateMedia;
