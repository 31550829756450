import React from "react";
import { GraphandModel } from "graphand-js";
import { ViewComponentContext } from "../../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModelListLabelComponent: React.FunctionComponent<{
  item?: GraphandModel;
  image?: string;
  title?: string;
  subtitle?: string;
  placeholders?: boolean;
  onClick?: () => void;
}> = ({ item, image, title, subtitle, placeholders = false, onClick }) => {
  return (
    <div
      className={`flex items-center space-x-3 ${
        onClick ? "cursor-pointer select-none" : ""
      }`}
      onClick={onClick}
    >
      {image && item ? (
        item.renderFieldView(image, {
          context: ViewComponentContext.LIST_LABEL_IMAGE,
          fallback: (
            <div className="h-16 w-16 flex items-center justify-center text-4xl flex-shrink-0 cursor-pointer text-primary">
              <FontAwesomeIcon icon={["fad", "file-image"]} />
            </div>
          ),
        })
      ) : placeholders ? (
        <div className="h-16 w-16 border border-gray-200 rounded-full flex-shrink-0" />
      ) : null}
      <div className="flex flex-col overflow-hidden">
        {title && item ? (
          item.renderFieldView(title, {
            context: ViewComponentContext.LIST_LABEL_TITLE,
          })
        ) : placeholders ? (
          <div className="truncate text-lg w-full" title={title}>
            {title || "---"}
          </div>
        ) : null}
        {subtitle && item ? (
          item.renderFieldView(subtitle, {
            context: ViewComponentContext.LIST_LABEL_SUBTITLE,
          })
        ) : placeholders ? (
          <div className="truncate text-sm text-gray-500" title={subtitle}>
            {subtitle || "---"}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModelListLabelComponent;
