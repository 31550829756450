import React, { MutableRefObject, useEffect, useRef } from "react";
import DataModel from "../models/DataModel";
import { snakeCase } from "lodash";
import { Link } from "react-router-dom";
import Container from "./Container";

const AppListCard: React.FunctionComponent<{
  item: DataModel;
  isActive: boolean;
  isFocused: boolean;
  watcherRef: React.Ref<any>;
  firstAppRef: React.Ref<any>;
  onMouseEnter: () => void;
  display: boolean;
  setDisplay: (d: boolean) => void;
  search?: string;
  className?: string;
}> = ({
  item: dataModel,
  onMouseEnter,
  isActive,
  isFocused,
  watcherRef,
  firstAppRef,
  search,
  display,
  setDisplay,
  className = "",
}) => {
  const nameContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let _display = true;
    if (search?.length) {
      const _search = snakeCase(search);
      _display = snakeCase(
        nameContainerRef?.current?.textContent || ""
      ).includes(_search);
    }

    if (_display !== display) {
      setDisplay(_display);
    }
  }, [search]);

  return (
    <Link
      id={`app-${dataModel?._id}`}
      data-id={dataModel?._id}
      ref={firstAppRef}
      to={dataModel?.getLink?.()}
      onMouseEnter={onMouseEnter}
      className={`h-0 pb-full w-full rounded-md relative ${
        display ? "" : "hidden"
      } ${className}`}
    >
      <div
        ref={watcherRef}
        className={`absolute inset-0 transition-all p-1 sm:p-2 transition-all duration-500 origin-top-left ${
          isActive
            ? "translate-y-0 opacity-100 scale-100"
            : "-translate-y-2 opacity-0 scale-90"
        } `}
      >
        <Container
          className="w-full h-full"
          contentClassName={`${isFocused ? "bg-blue-100 text-blue-500" : ""}`}
          backdropClassName="opacity-50"
        >
          <div className="flex items-center h-full w-full justify-center text-center p-2">
            <div className="w-full space-y-2">
              <div className="text-2xl sm:text-3xl lg:text-4xl">
                {dataModel?.renderFieldView?.("_icon", { theme: "fad" })}
              </div>
              <div
                className="w-full text-sm line-clamp-2"
                ref={nameContainerRef as MutableRefObject<HTMLDivElement>}
              >
                {dataModel?.renderFieldView("name", { className: "test" })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Link>
  );
};

export default AppListCard;
