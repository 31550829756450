import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import ModelList, { ModelListWrapperRaw } from "../../../components/ModelList";
import { getProjectClient } from "../../../utils/graphand";

const SettingsEnvironments = () => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  return (
    <div className="sm:p-4 lg:p-6">
      <div className="w-full max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          🔗 {t("labels.settings.environments")}
        </h1>

        <Container contentClassName="p-2 sm:p-4 lg:p-6 rounded-none sm:rounded-xl lg:rounded-lg">
          <ModelList
            WrapperComponent={ModelListWrapperRaw}
            model={client.getModel("Environment")}
            defaultListFields={["_label"]}
            labelField={{
              title: "_name",
              subtitle: "status",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default SettingsEnvironments;
