import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import FieldViewContainer from "../../components/FieldViewContainer";

const ViewText: React.FunctionComponent<
  ViewComponentProps & {
    rawValue?: any;
  }
> = (props) => {
  const { options, slug, field } = props;
  const { t } = useTranslation();
  let value = props.value ? props.value : options.fallback || props.value;
  value = value?.join?.(", ") || value;

  let color = undefined;

  if (field?.theme === "color") {
    color = value;
  }

  switch (options.context) {
    case ViewComponentContext.LIST_LABEL_TITLE:
    case ViewComponentContext.GRID_LABEL_TITLE:
      return (
        <div
          className="truncate text-lg w-full"
          title={options.title}
          style={{ color }}
        >
          {value}{" "}
          {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
        </div>
      );
    case ViewComponentContext.LIST_LABEL_SUBTITLE:
    case ViewComponentContext.GRID_LABEL_SUBTITLE:
      return (
        <div
          className="truncate text-sm text-gray-500"
          title={options.title}
          style={{ color }}
        >
          {value}{" "}
          {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
        </div>
      );
    case ViewComponentContext.PREVIEW_LINE:
      if (!value?.length) {
        return null;
      }

      let label = "label" in options ? options.label : field?.__dataField?.name;
      if (label === undefined) {
        label = t(`labels.fields.${slug}.default`);
      }

      let content = (
        <div
          title={options.title}
          className={`inline-block max-w-full break-all ${
            options.className || ""
          } ${
            options.onClick ? "cursor-pointer underline hover:text-primary" : ""
          }`}
          onClick={options.onClick}
        >
          {value}
          {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
        </div>
      );

      if (field?.theme === "color") {
        content = (
          <div
            title={options.title}
            className={`inline-flex items-center space-x-2 max-w-full break-all ${
              options.className || ""
            } ${
              options.onClick
                ? "cursor-pointer underline hover:text-primary"
                : ""
            }`}
            onClick={options.onClick}
            style={{ color: value }}
          >
            <div
              title={options.title}
              className={`h-6 w-6 flex-shrink-0 rounded-full ${
                options.onClick ? "cursor-pointer" : ""
              }`}
              onClick={options.onClick}
              style={{ backgroundColor: value }}
            />
            <div>{value}</div>
          </div>
        );
      }

      if (field?.theme === "rte") {
        content = (
          <div
            className="prose max-w-none"
            onClick={options.onClick}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      }

      return (
        <FieldViewContainer {...props} label={label}>
          {content}
        </FieldViewContainer>
      );
    case ViewComponentContext.MODAL_TITLE:
      return (
        <div
          title={options.title}
          className={`truncate ${options.className || ""}`}
          style={{ color }}
        >
          {value || "Preview"}{" "}
          {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
        </div>
      );
    case ViewComponentContext.TABLE_CELL:
      if (field?.theme === "color") {
        return (
          <div
            title={options.title}
            className={`h-6 w-6 flex-shrink-0 rounded-full ${
              options.onClick ? "cursor-pointer" : ""
            }`}
            onClick={options.onClick}
            style={{ backgroundColor: value }}
          />
        );
      }

      return (
        <div
          title={options.title}
          className={`truncate ${options.className || ""} ${
            options.onClick ? "cursor-pointer underline hover:text-primary" : ""
          }`}
          onClick={options.onClick}
          style={{ color }}
        >
          {value}{" "}
          {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
        </div>
      );
    default:
      break;
  }

  return (
    <div
      title={options.title}
      className={`inline ${options.className || "truncate"} ${
        options.onClick ? "cursor-pointer underline hover:text-primary" : ""
      }`}
      onClick={options.onClick}
      style={{ color }}
    >
      {value}{" "}
      {field?.unit ? <span className="text-sm">{field.unit}</span> : null}
    </div>
  );
};

export default ViewText;
