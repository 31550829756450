import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { InputComponentProps } from "graphand-react";
import InputTextSolid from "../Text/_solid";
import { format, isValid, parse } from "date-fns";

const InputDate: React.FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  let date;

  if (props.value) {
    if (typeof props.value === "string") {
      date = new Date(props.value);
    } else {
      date = props.value;
    }
  }

  if (!isValid(date)) {
    date = undefined;
  }

  let type = "date";
  let pattern = "[0-9]{4}-[0-9]{2}-[0-9]{2}";
  let formatStr = "yyyy-MM-dd";

  if (props.field?.time) {
    formatStr = "yyyy-MM-dd'T'HH:mm";
    type = "datetime-local";
    pattern = "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}";
  }

  const _handleChange = (value: string) => {
    try {
      const parsedDate = parse(value, formatStr, new Date());
      props.onChange?.(parsedDate);
    } catch (e) {}
  };

  return (
    <InputTextSolid
      {...props}
      value={date && format(date, formatStr)}
      onChange={_handleChange}
      options={{
        ...props.options,
        type,
        pattern,
        inputClassName: date ? "" : "text-gray-300 opacity-0 focus:opacity-100",
        beforeHelper: (
          <div className="h-4 w-4 flex items-center justify-center">
            <FontAwesomeIcon icon={["far", "calendar"]} />
          </div>
        ),
      }}
    />
  );
};

export default InputDate;
