import React from "react";
import { PreviewItemComponentProps } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";

const PreviewDataModel: React.FunctionComponent<PreviewItemComponentProps> = ({
  model,
  item,
  setModalProps,
}) => {
  // useEffect(() => {
  //   setModalProps?.({
  //     topActions: [
  //       {
  //         label: <DownloadButton item={item as GraphandModelMedia} />,
  //         onClick: () => null,
  //       },
  //     ],
  //   });
  // }, []);

  const fields = Object.keys(model.fields).filter((f) => {
    return (
      !f.startsWith("_") &&
      !["createdAt", "createdBy", "updatedAt", "updatedBy"].includes(f)
    );
  });

  return (
    <div className="space-y-3">
      <div className="-mx-3">
        {item.renderFieldView("_fields", {
          context: ViewComponentContext.TABLE_CELL,
        })}
      </div>
      {fields.map((f) => {
        return item.renderFieldView(f, {
          context: ViewComponentContext.PREVIEW_LINE,
        });
      })}
    </div>
  );
};

export default PreviewDataModel;
