import {
  GraphandFieldJSON,
  GraphandFieldRelation,
  GraphandModelProject,
} from "graphand-js";
import { GraphandFormTemplateParams } from "graphand-react";
import { slugCase } from "../utils/formatter";

class Project extends GraphandModelProject {
  static previewField = "name";
}

Project.hook(
  "formChange",
  (values: any, { instance, isModified }: GraphandFormTemplateParams) => {
    if ((!instance || instance.isTemporary()) && !isModified("slug")) {
      values.slug = slugCase(values.name);
    }
  }
);

Project.hook(
  "formSubmit",
  (values: any, { instance, isModified }: GraphandFormTemplateParams) => {
    if ((!instance || instance.isTemporary()) && !isModified("slug")) {
      values.slug = slugCase(values.name);
    }
  }
);

Project.customFields({
  configuration: new GraphandFieldJSON({
    fields: {
      accountPictureField: new GraphandFieldRelation({
        ref: "DataField",
        query: {
          type: "Relation",
          scope: "Account",
          "configuration.ref": "Media",
          "configuration.multiple": false,
        },
      }),
    },
  }),
});

export default Project;
