import React from "react";
import { FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import InputTextSolid from "./_solid";
import InputTextPassword from "./_password";
import InputTextTextarea from "./_textarea";
import InputTextNumber from "./_number";
import { GraphandFieldText } from "graphand-js";
import InputTextOptionsSelect from "./_optionsSelect";
import InputTextOptionsList from "./_optionsList";
import InputTextRte from "./_rte";
import InputTextColor from "./_color";

const InputText: FunctionComponent<Partial<InputComponentProps>> = (props) => {
  let Comp;

  const field = props.field as GraphandFieldText;
  // @ts-ignore
  const theme = field?.theme ?? props.options?.theme;

  if (field.options?.length || field.multiple) {
    switch (theme) {
      case "list":
        Comp = InputTextOptionsList;
        break;
      case "select":
      default:
        Comp = InputTextOptionsSelect;
        break;
    }

    return React.createElement(Comp, props);
  } else {
    switch (theme) {
      case "password":
        Comp = InputTextPassword;
        break;
      case "textarea":
        Comp = InputTextTextarea;
        break;
      case "rte":
        Comp = InputTextRte;
        break;
      case "color":
        Comp = InputTextColor;
        break;
      case "number":
        Comp = InputTextNumber;
        break;
      case "solid":
      default:
        Comp = InputTextSolid;
        break;
    }

    return React.createElement(Comp, props);
  }
};

export default InputText;
