import React from "react";
import { GraphandModel, GraphandHistoryModel } from "graphand-js";
import {
  InfiniteList,
  InfiniteListContainerProps,
  InfiniteListItemProps,
} from "graphand-react";
import { getProjectClient } from "../utils/graphand";
import { ViewComponentContext } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HistoryListItemComponent: React.FunctionComponent<
  InfiniteListItemProps<{
    item: GraphandHistoryModel;
  }>
> = ({ item, watcherRef }) => {
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  return (
    <div ref={watcherRef} className="flex items-center py-3 space-x-4">
      {item.metas?.account?.suspense((account) => {
        return (
          <div>
            {account.renderFieldView("_preview", {
              context: ViewComponentContext.TABLE_CELL_MULTIPLE,
            })}
          </div>
        );
      })}
      <div>
        <div>{item.kind}</div>
        <div>{JSON.stringify(item.diffs)}</div>
      </div>
    </div>
  );
};

const HistoryListContainerComponent: React.FunctionComponent<
  InfiniteListContainerProps
> = ({ children }) => {
  return (
    <div className="flex flex-col divide-y divide-gray-300">{children}</div>
  );
};

const HistoryListEmptyComponent: React.FunctionComponent<any> = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-40 text-xl space-y-4 text-gray-400">
      <FontAwesomeIcon icon={["fad", "history"]} className="text-3xl" />
      <div>L&apos;historique est vide</div>
    </div>
  );
};

const HistoryList: React.FunctionComponent<{
  historyModel: typeof GraphandModel;
  opts?: any;
}> = ({ historyModel, opts }) => {
  return (
    <div>
      <InfiniteList
        model={historyModel}
        ItemComponent={HistoryListItemComponent}
        ContainerComponent={HistoryListContainerComponent}
        EmptyComponent={HistoryListEmptyComponent}
        opts={opts}
      />
    </div>
  );
};

export default HistoryList;
