import { GraphandModelData, GraphandModelDataModel } from "graphand-js";
import CreateItem from "../components/CreateItem";
import UpdateItem from "../components/UpdateItem";
import PreviewItem from "../components/PreviewItem";
import PageComponentMultiple from "../components/PageComponent/Multiple";
import PageComponentSingle from "../components/PageComponent/Single";
import { GraphandModelConstructor } from "../utils/types";
import ConfigureModelWizard from "../wizards/ConfigureModel";
import CreateFiltersWizard from "../wizards/CreateFilters";

class Data extends GraphandModelData {
  static previewField: string;
  static imageField?: string;
  static titleField?: string;
  static subtitleField?: string;
  static pageComponent: GraphandModelConstructor["pageComponent"];
  static createItemComponent = CreateItem;
  static updateItemComponent = UpdateItem;
  static previewItemComponent = PreviewItem;
  static __dataModel?: GraphandModelDataModel;
  static wizards = {};

  static async _reloadFields(dataModel: GraphandModelDataModel) {
    if (dataModel.multiple) {
      this.pageComponent = PageComponentMultiple;
    } else {
      this.pageComponent = PageComponentSingle;
    }

    const conf = await dataModel.getConfiguration();

    this.imageField = conf.imageField;
    this.titleField = conf.titleField;
    this.subtitleField = conf.subtitleField;

    this.previewField = this.previewField || this.titleField || "_id";
  }

  static async initialize() {
    const _this = this;
    if (_this.name.startsWith("Data:")) {
      const slug = _this.name.replace("Data:", "");
      if (slug) {
        const dataModel = await _this._client
          .getModel("DataModel")
          .get({ query: { slug } });

        if (dataModel) {
          this.__dataModel = dataModel;
          this.wizards = {
            [`configureModel:${dataModel._scope}`]: ConfigureModelWizard,
            [`createFilters:${dataModel._scope}`]: CreateFiltersWizard,
          };
          dataModel.subscribe(() => this._reloadFields(dataModel));
          await this._reloadFields(dataModel);
        }
      }
    }
  }
}

export default Data;
