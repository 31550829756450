import React from "react";
import Modal, {
  ModalCloseType,
  ModalProps,
  ModalTransitionType,
} from "../components/Modal";
import Button, { ButtonTheme } from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum UpdateErrorType {
  unauthorized = "unauthorized",
}

const ErrorUpdateModal: React.FunctionComponent<
  ModalProps & {
    error?: UpdateErrorType;
  }
> = ({ error, ...modalProps }) => {
  return (
    <Modal
      title="Impossible de modifier cet élément"
      transitionType={ModalTransitionType.SCALE}
      closeType={ModalCloseType.BACK}
      onConfirm={() => {
        modalProps.onClose(false);
      }}
      className="max-w-xl"
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2 space-y-6 flex flex-col items-center text-center">
        <div className="h-20 w-20 bg-red-100 flex items-center justify-center rounded-full text-4xl text-red-700">
          <FontAwesomeIcon icon={["fal", "triangle-exclamation"]} />
        </div>
        <p>
          Il semble que vous n'ayez pas les droits nécessaires pour modifier cet
          élément. Les modifications effectuées{" "}
          <strong>n'ont pas été prises en compte</strong>. Seul un
          administrateur peut modifier ces droits.
        </p>
        <div className="flex items-center justify-center space-x-2 w-full">
          <Button
            theme={ButtonTheme.light}
            onClick={() => modalProps.onClose(false)}
          >
            Fermer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorUpdateModal;
