import React from "react";
import { QueryBuilderFilterTileProps } from "./QueryBuilderFilterTile";
import { useQueryBuilderFilterTile } from "../../utils/hooks";

const QueryBuilderFilterTileFieldBoolean: React.FunctionComponent<
  QueryBuilderFilterTileProps
> = (props) => {
  const {
    state,
    setState,
    inputRef,
    renderDropdown,
    renderCloseButton,
    renderLabel,
    disabled,
  } = useQueryBuilderFilterTile(props, {
    operators: ["$eq", "$ne"],
    defaultState: {
      operator: "$eq",
      value: true,
    },
  });

  return (
    <div className="flex items-center bg-gray-200 rounded-lg py-2 px-3 select-none text-gray-600 space-x-2">
      <div className="flex items-center space-x-3">
        {renderLabel}
        {renderDropdown}
        <input
          ref={inputRef}
          type="checkbox"
          disabled={disabled}
          className="h-5 w-5 text-button border-gray-300 rounded-md"
          checked={state.value}
          onChange={({ currentTarget: { checked } }) =>
            setState((s) => ({ ...s, value: checked }))
          }
        />
      </div>
      {renderCloseButton}
    </div>
  );
};

export default QueryBuilderFilterTileFieldBoolean;
