import React from "react";
import { ViewComponentProps } from "graphand-react";

const EnvironmentName: React.FunctionComponent<ViewComponentProps> = ({
  item,
  options,
}) => {
  const { status } = item;

  return (
    <div className="flex items-center justify-start space-x-1.5">
      <div
        className={`h-3 w-3 rounded-full flex-shrink-0 ${
          status === "error"
            ? "bg-red-500"
            : status === "ready"
            ? "bg-green-500"
            : "bg-orange-500"
        }`}
      />
      {item.renderFieldView("name", options)}
    </div>
  );
};

export default EnvironmentName;
