import React, { useState } from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import { getProjectClient } from "../utils/graphand";
import DataModel from "../models/DataModel";
import CreateItemModal from "../modals/CreateItemModal";
import ModelListModal from "../modals/ModelListModal";
import Button, { ButtonTheme } from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import FieldViewContainer from "../components/FieldViewContainer";

const DataModelField: React.FunctionComponent<ViewComponentProps> = (props) => {
  const { options, item, field, slug } = props;
  const { t } = useTranslation();
  const client = getProjectClient();
  const [modalListOpen, setModalListOpen] = useState(false);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);

  if (!client) {
    return null;
  }

  const DataField = client.getModel("DataField");

  switch (options.context) {
    case ViewComponentContext.TABLE_CELL:
      const query = { scope: item._scope };
      return (
        <div>
          {DataField.getList({
            query,
            count: true,
          }).suspense(
            (list) => {
              return (
                <div className="flex items-center space-x-2">
                  <Button
                    theme={ButtonTheme.inline}
                    className="text-blue-500 w-auto"
                    onClick={() => setModalListOpen(true)}
                  >
                    {list.count} champ(s)
                  </Button>
                  <Button
                    theme={ButtonTheme.inline}
                    className="text-blue-500 w-auto"
                    onClick={() => setModalCreateOpen(true)}
                  >
                    <FontAwesomeIcon icon={["far", "plus"]} />
                  </Button>
                </div>
              );
            },
            { subscribe: true }
          )}

          <ModelListModal
            isOpen={modalListOpen}
            onClose={setModalListOpen}
            className="max-w-screen-sm"
            props={{
              model: DataField,
              filter: { query },
              handleItemClick: "update",
              createProps: {
                dataModel: item as DataModel,
              },
            }}
          />

          <CreateItemModal
            model={DataField}
            isOpen={modalCreateOpen}
            onClose={setModalCreateOpen}
            props={{
              dataModel: item as DataModel,
            }}
          />
        </div>
      );
    case ViewComponentContext.PREVIEW_LINE:
      let label = "label" in options ? options.label : field?.__dataField?.name;
      if (label === undefined) {
        label = t(`labels.fields.${slug}.default`);
      }

      return (
        <FieldViewContainer {...props} label={label}>
          <DataModelField
            {...props}
            options={{
              ...props.options,
              context: ViewComponentContext.TABLE_CELL,
            }}
          />
        </FieldViewContainer>
      );
    default:
      break;
  }

  return null;
};

export default DataModelField;
