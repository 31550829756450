import { DataFieldFieldType } from "./enums";
import {
  GraphandField,
  GraphandFieldBoolean,
  GraphandFieldNumber,
  GraphandFieldText,
  GraphandModel,
} from "graphand-js";
import InputScope from "../fields/inputs/Scope";
import InputQuery from "../fields/inputs/Query";
import DataField from "../models/DataField";
import { IconName } from "@fortawesome/fontawesome-svg-core";

class UIDataField {
  constructor(conf: {
    icon?: IconName;
    assignValues?: (values: any) => void;
    assignConf?: (values: any) => void;
    fields?: typeof GraphandModel["schema"];
  }) {
    this.icon = conf.icon ?? "question-circle";
    this.assignValues = conf.assignValues ?? this.assignValues;
    this.assignConf = conf.assignConf ?? this.assignConf;
    this.fields = conf.fields ?? {};
  }

  icon: IconName;
  assignValues(values: any) {}
  assignConf(configuration: any) {}
  fields: typeof GraphandModel["schema"];
}

export const dataFieldTypes: Map<DataFieldFieldType, UIDataField> = new Map([
  // text

  [
    DataFieldFieldType.text_default,
    new UIDataField({
      icon: "input-text",
      assignValues: (values) => {
        values.type = "Text";
      },
      fields: {
        defaultValue: new GraphandFieldText(),
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        sparse: new GraphandFieldBoolean(),
        minLength: new GraphandFieldNumber(),
        maxLength: new GraphandFieldNumber(),
        regex: new GraphandFieldText(),
        regexOptions: new GraphandFieldText(),
        unit: new GraphandFieldText(),
      },
    }),
  ],
  [
    DataFieldFieldType.text_textarea,
    new UIDataField({
      icon: "text",
      assignValues: (values) => {
        values.type = "Text";
      },
      assignConf: (configuration) => {
        configuration.theme = "textarea";
      },
      fields: {
        defaultValue: new GraphandFieldText({ theme: "textarea" }),
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        sparse: new GraphandFieldBoolean(),
        minLength: new GraphandFieldNumber(),
        maxLength: new GraphandFieldNumber(),
        regex: new GraphandFieldText(),
        regexOptions: new GraphandFieldText(),
        unit: new GraphandFieldText(),
      },
    }),
  ],
  [
    DataFieldFieldType.text_rte,
    new UIDataField({
      icon: "code",
      assignValues: (values) => {
        values.type = "Text";
      },
      assignConf: (configuration) => {
        configuration.theme = "rte";
      },
      fields: {
        defaultValue: new GraphandFieldText(),
        required: new GraphandFieldBoolean(),
      },
    }),
  ],
  [
    DataFieldFieldType.text_options_select,
    new UIDataField({
      icon: "ballot-check",
      assignValues: (values) => {
        values.type = "Text";
      },
      assignConf: (configuration) => {
        configuration.multiple = true;
        configuration.creatable = true;
        configuration.duplicates = true;
        configuration.theme = "select";
      },
      fields: {
        options: new GraphandFieldText({ multiple: true, creatable: true }),
        required: new GraphandFieldBoolean(),
        creatable: new GraphandFieldBoolean(),
        duplicates: new GraphandFieldBoolean(),
      },
    }),
  ],
  [
    DataFieldFieldType.text_options_list,
    new UIDataField({
      icon: "list-radio",
      assignValues: (values) => {
        values.type = "Text";
      },
      assignConf: (configuration) => {
        configuration.multiple = true;
        configuration.creatable = true;
        configuration.duplicates = true;
        configuration.theme = "list";
      },
      fields: {
        options: new GraphandFieldText({ multiple: true, creatable: true }),
        required: new GraphandFieldBoolean(),
        creatable: new GraphandFieldBoolean(),
        duplicates: new GraphandFieldBoolean(),
      },
    }),
  ],
  [
    DataFieldFieldType.text_color,
    new UIDataField({
      icon: "palette",
      assignValues: (values) => {
        values.type = "Text";
      },
      assignConf: (configuration) => {
        configuration.theme = "color";
      },
      fields: {
        defaultValue: new GraphandFieldText({ theme: "color" }),
        required: new GraphandFieldBoolean(),
      },
    }),
  ],

  // number

  [
    DataFieldFieldType.number_default,
    new UIDataField({
      icon: "input-numeric",
      assignValues: (values) => {
        values.type = "Number";
      },
      fields: {
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        sparse: new GraphandFieldBoolean(),
        minValue: new GraphandFieldNumber(),
        maxValue: new GraphandFieldNumber(),
        unit: new GraphandFieldText(),
      },
    }),
  ],

  // relation

  [
    DataFieldFieldType.relation_default_single,
    new UIDataField({
      icon: "link-simple",
      assignValues: (values) => {
        values.type = "Relation";
      },
      assignConf: (values) => {
        values.multiple = false;
      },
      fields: {
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        ref: new GraphandField({ InputComponent: InputScope }),
        initialQuery: new GraphandField({ InputComponent: InputQuery }),
      },
    }),
  ],
  [
    DataFieldFieldType.relation_default_multiple,
    new UIDataField({
      icon: "link",
      assignValues: (values) => {
        values.type = "Relation";
      },
      assignConf: (values) => {
        values.multiple = true;
        values.duplicates = true;
      },
      fields: {
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        duplicates: new GraphandFieldBoolean(),
        ref: new GraphandField({ InputComponent: InputScope }),
        initialQuery: new GraphandField({ InputComponent: InputQuery }),
      },
    }),
  ],
  [
    DataFieldFieldType.relation_media_single,
    new UIDataField({
      icon: "photo-film",
      assignValues: (values) => {
        values.type = "Relation";
      },
      assignConf: (values) => {
        values.multiple = false;
        values.ref = "Media";
      },
      fields: {
        required: new GraphandFieldBoolean(),
        unique: new GraphandFieldBoolean(),
        initialQuery: new GraphandField({ InputComponent: InputQuery }),
      },
    }),
  ],
  [
    DataFieldFieldType.relation_media_multiple,
    new UIDataField({
      icon: "photo-film",
      assignValues: (values) => {
        values.type = "Relation";
      },
      assignConf: (values) => {
        values.multiple = true;
        values.ref = "Media";
      },
      fields: {
        required: new GraphandFieldBoolean(),
        duplicates: new GraphandFieldBoolean(),
        initialQuery: new GraphandField({ InputComponent: InputQuery }),
      },
    }),
  ],

  // date

  [
    DataFieldFieldType.date_default,
    new UIDataField({
      icon: "calendar",
      assignValues: (values) => {
        values.type = "Date";
      },
    }),
  ],
  [
    DataFieldFieldType.date_time,
    new UIDataField({
      icon: "calendar-week",
      assignValues: (values) => {
        values.type = "Date";
      },
      assignConf: (values) => {
        values.time = true;
      },
    }),
  ],

  // boolean

  [
    DataFieldFieldType.boolean_checkbox,
    new UIDataField({
      icon: "check-circle",
      assignValues: (values) => {
        values.type = "Boolean";
      },
    }),
  ],
  [
    DataFieldFieldType.boolean_switch,
    new UIDataField({
      icon: "toggle-on",
      assignValues: (values) => {
        values.type = "Boolean";
      },
    }),
  ],
  [
    DataFieldFieldType.boolean_icon,
    new UIDataField({
      icon: "icons",
      assignValues: (values) => {
        values.type = "Boolean";
      },
    }),
  ],

  // json

  [
    DataFieldFieldType.json,
    new UIDataField({
      icon: "brackets-curly",
      assignValues: (values) => {
        values.type = "JSON";
      },
    }),
  ],
]);

export const assignDataField = (fieldType: DataFieldFieldType, values: any) => {
  const uiDataField = dataFieldTypes.get(fieldType);

  if (uiDataField) {
    uiDataField.assignValues(values);
  }

  return values;
};

export const assignDataFieldConfiguration = (
  fieldType: DataFieldFieldType,
  configuration: any
) => {
  const uiDataField = dataFieldTypes.get(fieldType);

  if (uiDataField) {
    uiDataField.assignConf(configuration);
  }

  return configuration;
};

export const getFieldConfigurationFields = (fieldType: DataFieldFieldType) => {
  const uiDataField = dataFieldTypes.get(fieldType);

  return uiDataField?.fields || {};
};

export const getFieldTypeFromDataField = (field: DataField) => {
  const { type, configuration } = field;

  switch (type) {
    case "Text":
      if (configuration?.options?.length || configuration?.multiple) {
        switch (configuration?.theme) {
          case "list":
            return DataFieldFieldType.text_options_list;
          case "select":
          default:
            return DataFieldFieldType.text_options_select;
        }
      }

      switch (configuration?.theme) {
        case "rte":
          return DataFieldFieldType.text_rte;
        case "color":
          return DataFieldFieldType.text_color;
        case "textarea":
          return DataFieldFieldType.text_textarea;
        case "default":
        default:
          return DataFieldFieldType.text_default;
      }
    case "Number":
      return DataFieldFieldType.number_default;
    case "JSON":
      return DataFieldFieldType.json;
    case "Relation":
      if (configuration?.ref === "Media") {
        return !configuration?.multiple
          ? DataFieldFieldType.relation_media_single
          : DataFieldFieldType.relation_media_multiple;
      }

      return !configuration?.multiple
        ? DataFieldFieldType.relation_default_single
        : DataFieldFieldType.relation_default_multiple;
    case "Boolean":
      switch (configuration?.theme) {
        case "switch":
          return DataFieldFieldType.boolean_switch;
        case "icon":
          return DataFieldFieldType.boolean_icon;
        case "checkbox":
        default:
          return DataFieldFieldType.boolean_checkbox;
      }
    case "Date":
      return configuration?.time
        ? DataFieldFieldType.date_time
        : DataFieldFieldType.date_default;
  }

  return null;
};
