import React, { useEffect, useRef, useState } from "react";
import { ViewComponentProps } from "graphand-react";

const SockethookHostTitle: React.FunctionComponent<ViewComponentProps> = ({
  item,
  options,
}) => {
  const [online, setOnline] = useState<any>(false);
  const indicatorRef = useRef<any>();

  const _ping = async () => {
    if (item.blocked) {
      return;
    }

    try {
      const res = await item.ping();
      setOnline(res);
    } catch (e) {
      setOnline(false);
    }

    setTimeout(() => {
      if (indicatorRef.current) {
        _ping();
      }
    }, 1000);
  };

  useEffect(() => {
    _ping();
  }, []);

  options.fallback = "-";

  const ms = online?.[item.name];

  return (
    <div className="flex items-center justify-start space-x-1.5">
      <div
        ref={indicatorRef}
        className={`h-3 w-3 rounded-full flex-shrink-0 ${
          item.blocked
            ? "bg-red-500"
            : online
            ? "bg-green-500 animate-pulse"
            : "bg-orange-500"
        }`}
      />
      {item.renderFieldView("name", options)}
      {ms ? <div className="text-sm text-gray-500">({ms}ms)</div> : null}
    </div>
  );
};

export default SockethookHostTitle;
