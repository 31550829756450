import React from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";

const InputTextTextarea: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { id, slug, value, field, onChange, options, errors, inputRef, model } =
    props;
  const isLoading = props.form?.isLoading;
  const { t } = useTranslation();
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  return (
    <FieldInputContainer {...props} label={label}>
      {(open: boolean) => (
        <>
          {options.helper ? (
            <div className="px-1 text-sm text-gray-500">{options.helper}</div>
          ) : null}

          <div className="mt-1">
            <textarea
              ref={inputRef}
              name={id}
              id={id}
              value={value || ""}
              onChange={({ currentTarget: { value } }) =>
                onChange?.(String(value))
              }
              placeholder={options.placeholder || ""}
              disabled={isLoading || open || options.disabled}
              className={`resize-none ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white ${
                errors?.length ? "border-red-700" : "border-gray-200"
              }`}
              style={{ height: "200px" }}
            />
          </div>

          <FieldInputErrors
            errors={errors}
            label={label}
            slug={slug}
            model={model}
          />
        </>
      )}
    </FieldInputContainer>
  );
};

export default InputTextTextarea;
