import React from "react";
import { FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import Select, { SelectOptionComponentProps } from "../../../components/Select";
import { getProjectClient } from "../../../utils/graphand";
import { useTranslation } from "react-i18next";
import { GraphandFieldText } from "graphand-js";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";

const InputScopeOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option, handleClick, selected }) => {
  const { t } = useTranslation();
  const client = getProjectClient();
  let slug;

  if (!client) {
    return <div onClick={handleClick}>{option}</div>;
  }

  const match = option.match("^Data:(.+)$");
  if (match) {
    slug = match[1];
  }

  if (!slug) {
    return <div onClick={handleClick}>{t(`scopes.${option}`)}</div>;
  }

  const DataModel = client.getModel("DataModel");
  return (
    <div onClick={handleClick}>
      {DataModel.get({ query: { slug } }).suspense((d) => d.name, option)}
    </div>
  );
};

const InputScope: FunctionComponent<Partial<InputComponentProps>> = (props) => {
  const { value, onChange, errors, inputRef, options, field, slug, model } =
    props;
  const client = getProjectClient();
  const { t } = useTranslation();

  if (!client) {
    return null;
  }

  const DataModel = client.getModel("DataModel");
  const required = (field as GraphandFieldText)?.required;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  if (label && required) {
    label += "*";
  }

  return (
    <FieldInputContainer {...props} label={label}>
      {(open: boolean) => (
        <div>
          {DataModel.getList({}).suspense(
            (list) => {
              return (
                <Select
                  value={value}
                  inlineValue
                  closable={false}
                  hasError={Boolean(errors?.length)}
                  inputRef={inputRef}
                  onChange={(v) => onChange?.(v)}
                  OptionComponent={InputScopeOptionComponent}
                  options={list
                    .map((m) => `Data:${m.slug}`)
                    .concat(["Account", "Media"])}
                  beforeHelper={options.beforeHelper}
                  afterHelper={options.afterHelper}
                />
              );
            },
            {
              fallback: (
                <Select
                  value={value}
                  inlineValue
                  closable={false}
                  hasError={Boolean(errors?.length)}
                  inputRef={inputRef}
                  onChange={(v) => onChange?.(v)}
                  OptionComponent={InputScopeOptionComponent}
                  options={[]}
                  beforeHelper={options.beforeHelper}
                  afterHelper={options.afterHelper}
                />
              ),
            }
          )}

          <FieldInputErrors
            errors={errors}
            label={label}
            slug={slug}
            model={model}
          />
        </div>
      )}
    </FieldInputContainer>
  );
};

export default InputScope;
