import React, { useRef } from "react";
import { GraphandFieldText } from "graphand-js";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";

const InputTextSolid: React.FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  const { id, slug, value, field, onChange, options, errors, inputRef, model } =
    props;
  const isLoading = props.form?.isLoading;
  const InputComponent = useRef(
    options.InputComponent ??
      ((props: any) => <input ref={props.inputRef} {...props} />)
  );
  const { t } = useTranslation();
  const required = (field as GraphandFieldText)?.required;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  if (label && required) {
    label += " *";
  }

  const afterHelper = options.afterHelper ?? field?.unit;

  return (
    <FieldInputContainer {...props} label={label}>
      {(open: boolean) => (
        <div>
          <div
            className={`flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white ${
              errors?.length ? "border-red-700" : "border-gray-200"
            }`}
          >
            {options.beforeHelper ? (
              <div className="pl-4 text-base flex items-center text-gray-400 flex-shrink-0">
                {options.beforeHelper}
              </div>
            ) : null}

            <InputComponent.current
              key={id}
              tabIndex={1}
              className={`w-full h-input text-left overflow-hidden border-0 px-4 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0 ${
                options.disabled ? "opacity-70" : ""
              } ${options.inputClassName || ""}`}
              inputRef={inputRef}
              type={options.type || "text"}
              name={id}
              id={id}
              pattern={options.pattern}
              value={value || ""}
              onChange={({ currentTarget: { value } }: any) =>
                onChange?.(String(value))
              }
              placeholder={options.placeholder || ""}
              disabled={isLoading || open || options.disabled}
            />

            {afterHelper ? (
              <div className="pr-4 text-base flex items-center text-gray-400 flex-shrink-0">
                {afterHelper}
              </div>
            ) : null}
          </div>

          <FieldInputErrors
            errors={errors}
            label={label}
            slug={slug}
            model={model}
          />
        </div>
      )}
    </FieldInputContainer>
  );
};

export default InputTextSolid;
