import React from "react";
import { FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import InputRelationMultiple from "./_multiple";
import InputRelationSingle from "./_single";
import { GraphandFieldRelation } from "graphand-js";
import InputRelationSingleSelect from "./_singleSelect";
import InputRelationSingleImage from "./_singleImage";
import InputRelationSingleMedia from "./_singleMedia";

const InputRelation: FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  let Comp;
  const field = props.field as GraphandFieldRelation;

  switch (props.options?.theme) {
    case "single-image":
      Comp = InputRelationSingleImage;
      break;
    case "select":
      Comp = field?.multiple
        ? InputRelationMultiple
        : InputRelationSingleSelect;
      break;
    default:
      break;
  }

  if (!Comp) {
    if (field?.multiple) {
      Comp = InputRelationMultiple;
    } else {
      // if (field?.ref === "Media") {
      //   Comp = InputRelationSingleMedia;
      // } else {
      Comp = InputRelationSingle;
      // }
    }
  }

  return React.createElement(Comp, props);
};

export default InputRelation;
