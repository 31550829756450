import React from "react";
import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon: React.FunctionComponent<{
  name: IconName;
  theme?: "fad" | "far" | "fal";
  fallbackName?: IconName;
  className?: string;
  size?: SizeProp;
}> = ({
  size,
  className,
  name,
  theme = "fad",
  fallbackName = "question-circle",
}) => {
  return (
    <FontAwesomeIcon
      size={size}
      className={className || ""}
      icon={[theme, name ?? fallbackName]}
    />
  );
};

export default Icon;
