import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { UpdateItemComponentProps } from "../utils/types";
import MediaPlayer from "./MediaPlayer";
import Media from "../models/Media";
import UpdateItemWrapper from "./UpdateItemWrapper";

const UpdateMediaFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
  instance,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <MediaPlayer media={instance as Media} />
      <div className="space-y-4">
        {fields.render("private", { theme: "switch" })}
        {fields.render("file")}
        {fields.render("name")}
        {fields.render("description", { theme: "textarea" })}
        {Object.keys(fields)
          .filter(
            (f) =>
              ![
                "private",
                "file",
                "name",
                "description",
                "url",
                "mimetype",
                "size",
                "height",
                "width",
                "originalname",
                "duration",
              ].includes(f)
          )
          .map((f) => fields.render(f))}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.updateLoading" : "actions.update")}
      </Button>
    </form>
  );
};

const UpdateMedia: React.FunctionComponent<UpdateItemComponentProps> = (
  props
) => {
  return <UpdateItemWrapper {...props} template={UpdateMediaFormTemplate} />;
};

export default UpdateMedia;
