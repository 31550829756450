import React from "react";
import { useAccountSettings, useWizard } from "../utils/hooks";
import Container from "./Container";

export type WizardProps = {
  condition?: boolean;
  children?: any;
  wizardKey: string;
  className?: string;
  animate?: boolean;
  ContainerComponent?: React.FunctionComponent<any>;
};

const Wizard: React.FunctionComponent<WizardProps> = ({
  condition,
  children,
  wizardKey,
  className,
  animate = true,
  ...props
}) => {
  const [hideWizards] = useAccountSettings<boolean>("hideWizards", false);
  const [display, ignore] = useWizard(wizardKey);

  if (condition !== undefined && !condition) {
    return null;
  }

  const ContainerComponent: React.FunctionComponent<any> =
    props.ContainerComponent ?? (({ children }) => children);

  return (
    <ContainerComponent>
      <Container
        wizardKey={wizardKey}
        display={Boolean(display)}
        animate={animate}
        onClose={!hideWizards ? ignore : undefined}
        className={className}
      >
        {children}
      </Container>
    </ContainerComponent>
  );
};

export default Wizard;
