import { GraphandField, GraphandModelMedia } from "graphand-js";
import MediaPreviewView from "../fieldsViews/MediaPreview";
import PreviewMedia from "../components/PreviewMedia";
import CreateMedia from "../components/CreateMedia";
import UpdateMedia from "../components/UpdateMedia";
import MediaInfoView from "../fieldsViews/MediaInfo";
import QuickCreateMedia from "../components/QuickCreateMedia";
import { FilterModalType } from "../modals/AddFilterModal";
import PageComponentMultiple from "../components/PageComponent/Multiple";
import React from "react";
import { PageComponentProps } from "../utils/types";
import { DisplayMode } from "../utils/enums";
import CreateFiltersWizard from "../wizards/CreateFilters";
import { getProjectClient } from "../utils/graphand";
import { Translation } from "react-i18next";

const MediaPageComponent: React.FunctionComponent<PageComponentProps> = (
  props
) => {
  return (
    <PageComponentMultiple
      {...props}
      listActions={(item) => [
        {
          label: <Translation>{(t) => t("actions.download")}</Translation>,
          onClick: () => item.download(),
        },
      ]}
      defaultMode={DisplayMode.GRID}
    />
  );
};

class Media extends GraphandModelMedia {
  static previewField = "_preview";
  static listFields = ["_label", "mimetype"];
  static imageField = "_preview";
  static titleField = "name";
  static subtitleField = "size";
  static infoField = "_info";
  static pageComponent = MediaPageComponent;
  static previewItemComponent = PreviewMedia;
  static createItemComponent = CreateMedia;
  static updateItemComponent = UpdateMedia;
  static quickCreateItemComponent = QuickCreateMedia;
  static filtersOptions = [FilterModalType.MEDIA_TYPE];
  static wizards = {
    "createFilters:Media": CreateFiltersWizard,
  };

  static get __dataModel() {
    const client = getProjectClient();
    return client?.getModel("DataModel").get("_dataModelMedia");
  }

  async download() {
    let url: any;

    // if (this.size < 10000000) {
    const file = await fetch(this.getUrl());
    const fileBlob = await file.blob();
    url = URL.createObjectURL(fileBlob);
    // } else {
    //   url = this.getUrl({ name: true });
    // }

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.setAttribute("download", this.name);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

Media.customFields({
  _preview: new GraphandField({
    ViewComponent: MediaPreviewView,
  }),
  _info: new GraphandField({
    ViewComponent: MediaInfoView,
  }),
});

Media.hook("formChange", (values: any, { isModified }: any) => {
  if (values.file && !isModified("name")) {
    values.name = values.file.name;
  }
});

Media.hook(
  "formSubmit",
  (values: any, { instance, values: _values, isModified }: any) => {
    if (!instance?._id && !isModified("name")) {
      values.name = _values.name;
    }
  }
);

Media.fields.size.viewOptions = { type: "filesize" };
Media.fields.width.unit = "px";
Media.fields.height.unit = "px";
Media.fields.duration.unit = "s";

export default Media;
