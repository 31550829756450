import React, { FunctionComponent, useEffect } from "react";
import { controlSubject, readySubject } from "../../components/LayoutGlobal";
import { GraphandModelOrgInvitation } from "graphand-js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faUserPlus,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { useOrganization } from "../../utils/hooks";
import ProjectsList from "./ProjectsList";
import Button from "../../components/Button";
import Dropdown, { DropdownPosition } from "../../components/Dropdown";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import { InfiniteList } from "graphand-react";
import { getGlobalClient } from "../../utils/graphand";
import { useTranslation } from "react-i18next";
import Organization from "../../models/Organization";

const InvitationsListTile: FunctionComponent<{
  item: GraphandModelOrgInvitation;
}> = ({ item: invitation }) => {
  return (
    <div className="w-full flex items-center py-3">
      <div className="bg-global-inset h-14 w-14 flex items-center justify-center rounded-full text-white select-none flex-shrink-0">
        <FontAwesomeIcon icon={faUserCircle} size="2x" />
      </div>
      <div className="ml-3 w-full">
        <div className="text-lg font-bold truncate">{invitation.email}</div>
        <div className="text-sm text-gray-500 truncate">
          Invité{" "}
          {formatDistance(new Date(invitation.createdAt), new Date(), {
            addSuffix: true,
            locale: fr,
          })}
        </div>
      </div>
      <div className="flex items-center justify-end space-x-4 cursor-pointer">
        <Dropdown
          position={DropdownPosition.RIGHT}
          button={
            <button
              type="button"
              className="text h-10 w-10 rounded-md flex items-center justify-center focus:outline-none select-none text-xl text-red-700 hover:bg-red-100"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          }
          className="w-72"
        >
          {invitation.organization.suspense((org: Organization) => {
            return (
              <div className="p-4 space-y-4">
                <div>
                  Voulez-vous vraiment supprimer l'invitation à "{org.name}" ?
                </div>
                <button
                  type="button"
                  className="bg-red-500 h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button-red rounded-xl border border-white text-white"
                  onClick={() => invitation.delete()}
                >
                  Confirmer
                </button>
              </div>
            );
          })}
        </Dropdown>
      </div>
    </div>
  );
};

const InvitationsList = () => {
  const { organization } = useOrganization();
  const client = getGlobalClient();
  const OrgInvitation = client.getModel("OrgInvitation");
  const { t } = useTranslation();

  useEffect(() => {
    if (organization) {
      controlSubject.next(
        <div>
          <Link to="/invitations/new" className="inline-block">
            <Button>
              <FontAwesomeIcon icon={faUserPlus} className="w-6 h-6 mr-2" />
              Inviter des membres
            </Button>
          </Link>
        </div>
      );
    }

    readySubject.next(true);

    return () => {
      controlSubject.next(null);
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return <ProjectsList />;
  }

  return (
    <div className="flex flex-col divide-y divide-gray-200">
      <InfiniteList
        model={OrgInvitation}
        ItemComponent={InvitationsListTile}
        EmptyComponent={
          <div className="p-4 text-lg">
            <h1 className="text-3xl font-bold mb-2">
              ✉️ Invitez vos collaborateurs
            </h1>
            <p className="mb-2">
              Depuis cette page vous pouvez inviter vos collaborateurs à
              rejoindre l'organisation. Les membres invités ne sont pas invités
              à rejoindre les projets de l'organisation. Pour ce faire, vous
              devrez les inviter directement depuis le backoffice du projet.
            </p>
            <p>
              Vous pouvez dès maintenant{" "}
              <Link
                to="/invitations/new"
                className="hover:underline text-button"
              >
                inviter vos collaborateurs
              </Link>
              .
            </p>
          </div>
        }
        opts={{ query: { organization: organization?._id } }}
      />
    </div>
  );
};

export default InvitationsList;
