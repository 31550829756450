import React, { useEffect, useState } from "react";
import NavbarProject from "./NavbarProject";
import SidebarProject from "./SidebarProject";
import { Transition } from "@headlessui/react";
import { Helmet } from "react-helmet";
import { useCurrentProject } from "../utils/hooks";
import { useDropzone } from "react-dropzone";
import { getProjectClient } from "../utils/graphand";
import MediaUploadSplash from "./MediaUploadSplash";
import MediaUploadMenu from "./MediaUploadMenu";
import { BehaviorSubject } from "rxjs";

export const uploadingFilesSubject = new BehaviorSubject<File[]>([]);
export const handleLayoutDropzone = new BehaviorSubject<boolean>(true);

const LayoutProject: React.FunctionComponent<{
  children: any;
  ready: boolean;
}> = ({ children, ready }) => {
  const client = getProjectClient();
  const { project } = useCurrentProject();
  // const [fileDragging, setFileDragging] = useState(false);
  const [enabled, setEnabled] = useState(handleLayoutDropzone.getValue());

  useEffect(() => {
    const sub = handleLayoutDropzone.subscribe(setEnabled);

    return () => sub.unsubscribe();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (files: File[]) => {
      uploadingFilesSubject.next(
        uploadingFilesSubject.getValue().concat(files)
      );

      if (!client) {
        return;
      }

      const Media = client.getModel("Media");

      await Promise.all(
        files.map((file) => Media.create({ file }).catch((e) => null))
      );
    },
    // onDragEnter: () =>
    //   enabled && !fileDragging && setFileDragging((p) => (p ? p : true)),
    // onDragLeave: () =>
    //   enabled && fileDragging && setFileDragging((p) => (p ? false : p)),
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    noDrag: !enabled,
  });

  return (
    <div className="flex h-screen relative" {...getRootProps()}>
      <input {...getInputProps()} />
      <Helmet>
        <title>{project ? `${project.name} | Graphand` : "Graphand"}</title>
      </Helmet>
      <MediaUploadSplash fileDragging={isDragActive} onTerminate={() => null} />
      <MediaUploadMenu />
      <Transition
        show={ready}
        enter="transition transform ease-out duration-500 delay-100"
        enterFrom="opacity-0 -translate-x-10"
        enterTo="opacity-100 translate-x-0"
        leave="transition transform ease-in duration-500 delay-100"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 -translate-x-10"
      >
        <SidebarProject />
      </Transition>
      <div className="w-full h-screen overflow-hidden flex flex-col">
        <Transition
          as="div"
          className="relative z-30"
          show={ready}
          enter="transition transform ease-out duration-500 delay-100"
          enterFrom="opacity-0 -translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition transform ease-in duration-500 delay-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-4"
        >
          <NavbarProject />
        </Transition>
        <Transition
          show={ready}
          id="projectContent"
          className="flex-grow overflow-auto"
          enter="transition transform ease-out duration-500 delay-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          {children}
        </Transition>
      </div>
    </div>
  );
};

export default LayoutProject;
