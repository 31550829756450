import React, { useEffect } from "react";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import { useOrganization } from "../../utils/hooks";
import ProjectsList from "./ProjectsList";
import { GraphandForm } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader, faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { GraphandFieldText } from "graphand-js";
import { useNavigate } from "react-router-dom";
import { getGlobalClient } from "../../utils/graphand";

const InviteMembers = () => {
  const navigate = useNavigate();
  const { organization } = useOrganization();

  useEffect(() => {
    if (organization) {
      titleSubject.next("Inviter de nouveaux membres");
    }

    readySubject.next(true);

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return <ProjectsList />;
  }

  const _handleSubmit = async ({ emailsList }: any) => {
    const list = emailsList?.split(/,|;/).map((e: string) => e.trim());
    if (list?.length) {
      const client = getGlobalClient();
      const OrgInvitation = client.getModel("OrgInvitation");
      await OrgInvitation.create(
        list.map((email: string) => ({ email, organization }))
      );
      navigate(-1);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-2">
        🤝 Invitez votre équipe à collaborer avec vous
      </h1>
      <p className="mb-8">
        Vous pouvez inviter votre équipe à collaborer avec vous au sein de
        l'organisation <strong>{organization.name}</strong> en ajoutant leurs
        addresses email dans le champ ci-dessous.
      </p>
      <div className="max-w-lg mx-auto">
        <GraphandForm
          fields={{ emailsList: new GraphandFieldText() }}
          onSubmit={_handleSubmit}
        >
          {({ formRef, handleSubmit, fields, isLoading }) => {
            return (
              <form
                ref={formRef}
                onSubmit={isLoading ? null : handleSubmit}
                className={isLoading ? "cursor-progress" : ""}
              >
                <div
                  className={`space-y-6 mb-8 transition ${
                    isLoading ? "opacity-50" : "opacity-100"
                  }`}
                >
                  {fields.render("emailsList", {
                    theme: "textarea",
                    helper: "Entrez la liste séparée d'une virugule",
                    disabled: isLoading,
                  })}
                </div>
                <button
                  type="submit"
                  className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
                >
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="lg"
                    className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                      isLoading ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  Envoyer
                  <FontAwesomeIcon
                    icon={faPaperPlaneTop}
                    size="lg"
                    className="ml-3"
                  />
                </button>
              </form>
            );
          }}
        </GraphandForm>
      </div>
    </div>
  );
};

export default InviteMembers;
