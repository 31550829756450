import React from "react";
import { useTranslation } from "react-i18next";
import { getProjectClient } from "../../../utils/graphand";

const SettingsSecrets = () => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  return (
    <div className="sm:p-4 lg:p-6">
      <div className="w-full max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          🔐 {t("labels.settings.secrets")}
        </h1>
      </div>
    </div>
  );
};

export default SettingsSecrets;
