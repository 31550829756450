import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import ModelList, { ModelListWrapperRaw } from "../../../components/ModelList";
import { getProjectClient } from "../../../utils/graphand";
import ExecuteAggregationModal from "../../../modals/ExecuteAggregationModal";
import Aggregation from "../../../models/Aggregation";

const SettingsAggregations = () => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const [executing, setExecuting] = useState<Aggregation>();

  if (!client) {
    return null;
  }

  return (
    <>
      <div className="sm:p-4 lg:p-6">
        <div className="w-full max-w-screen-md mx-auto">
          <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
            🔣 {t("labels.settings.aggregations")}
          </h1>

          <Container contentClassName="p-2 sm:p-4 lg:p-6 rounded-none sm:rounded-xl lg:rounded-lg">
            <ModelList
              WrapperComponent={ModelListWrapperRaw}
              model={client.getModel("Aggregation")}
              defaultListFields={["_label"]}
              labelField={{
                title: "name",
                subtitle: "scope",
              }}
              inlineActions={(item) => [
                {
                  label: t("actions.execute"),
                  onClick: () => setExecuting(item as Aggregation),
                  icon: "play",
                },
              ]}
              listActions={(item) => [
                {
                  label: t("actions.execute"),
                  onClick: () => setExecuting(item as Aggregation),
                },
              ]}
            />
          </Container>
        </div>
      </div>

      <ExecuteAggregationModal
        isOpen={Boolean(executing)}
        onClose={() => setExecuting(undefined)}
        aggregation={executing}
      />
    </>
  );
};

export default SettingsAggregations;
