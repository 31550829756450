import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectClient } from "../../utils/graphand";
import ProjectTransition from "../../components/ProjectTransition";
import { GraphandModelConstructor } from "../../utils/types";
import { useAccountSettings } from "../../utils/hooks";

export type ModelPageWizardProps = {
  moduleFilters?: any;
  handleCreateFilter?: () => void;
  wizardKey: string;
  model?: GraphandModelConstructor;
  ContainerComponent?: React.FunctionComponent<any>;
};

const ModelPage: React.FunctionComponent<any> = () => {
  const { scope } = useParams();
  const client = getProjectClient();
  const [recentApps, setRecentApps] = useAccountSettings<string[]>(
    "recentApps",
    []
  );
  const [isReady, setIsReady] = useState(true);

  const model = client?.getModel(scope as string) as GraphandModelConstructor;

  const _refreshRecentApps = () => {
    const dataModel = model.__dataModel;

    if (!dataModel) {
      return;
    }

    const recentAppsSet = new Set(recentApps);

    if (recentAppsSet.has(dataModel._id)) {
      recentAppsSet.delete(dataModel._id);
    }

    setRecentApps([dataModel._id].concat(Array.from(recentAppsSet)));
  };

  useEffect(() => {
    if (!model) {
      return;
    }

    if (model._initialized) {
      _refreshRecentApps();
      return;
    }

    setIsReady((p) => (p ? false : p));
    model._init().finally(() => {
      _refreshRecentApps();
      setIsReady((p) => p || true);
    });
  }, [scope, client?._uid]);

  if (!model?.pageComponent || !isReady) {
    return null;
  }

  return (
    <ProjectTransition>
      <model.pageComponent model={model} />
    </ProjectTransition>
  );
};

export default ModelPage;
