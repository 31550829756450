import colors from "tailwindcss/colors";

const getColorForMimetype = (mimetype: string): string => {
  let color;

  if (mimetype.includes("image")) {
    color = "#006bd2";
  } else if (mimetype.includes("video")) {
    color = colors.green["600"];
  } else if (mimetype.includes("pdf")) {
    color = colors.red["700"];
  } else if (mimetype.includes("zip")) {
    color = colors.yellow["500"];
  }

  return color || colors.gray["500"];
};

export default getColorForMimetype;
