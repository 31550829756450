import Modal, { ModalProps } from "../components/Modal";
import React from "react";
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

const JSONEditorModal: React.FunctionComponent<
  ModalProps & {
    codeMirrorProps?: Partial<ReactCodeMirrorProps>;
    children?: any;
  }
> = ({ codeMirrorProps, children, ...modalProps }) => {
  return (
    <Modal
      defaultFullscreen
      {...modalProps}
      fullscreenClassName="absolute inset-0 flex flex-col json-editor-modal"
    >
      <div className="flex flex-col ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm bg-white">
        <div className="flex items-center p-1 w-full border-gray-200 border-b">
          {children}
        </div>
      </div>

      <CodeMirror
        className="h-full"
        height="auto"
        extensions={[json()]}
        {...codeMirrorProps}
      />
    </Modal>
  );
};

export default JSONEditorModal;
