import React from "react";
import { useTranslation } from "react-i18next";

const SettingsSecurity = () => {
  const { t } = useTranslation();

  return (
    <div className="py-6 px-4 lg:p-10 w-full">
      <h1 className="text-2xl font-bold mb-6">
        🛡 {t("labels.settings.security")}
      </h1>
    </div>
  );
};

export default SettingsSecurity;
