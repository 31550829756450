import { useTranslation } from "react-i18next";
import ModelList, { ModelListWrapperRaw } from "../../../components/ModelList";
import { getProjectClient } from "../../../utils/graphand";
import Container from "../../../components/Container";

const SettingsSearch = () => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  return (
    <div className="sm:p-4 lg:p-6">
      <div className="w-full max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          🔍 {t("labels.settings.search")}
        </h1>

        <Container contentClassName="p-2 sm:p-4 lg:p-6 rounded-none sm:rounded-xl lg:rounded-lg">
          <ModelList
            WrapperComponent={ModelListWrapperRaw}
            model={client.getModel("EsMapping")}
            defaultListFields={["_label"]}
            labelField={{
              title: "name",
              subtitle: "_count",
            }}
            listActions={(item) => [
              {
                label: t(`actions.sync_esMapping`),
                onClick: () => item.sync(),
              },
              {
                label: t(`actions.sync_esMapping_resetIndex`),
                onClick: () => item.sync({ resetIndex: true }),
              },
            ]}
          />
        </Container>
      </div>
    </div>
  );
};

export default SettingsSearch;
