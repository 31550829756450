import React from "react";
import { FunctionComponent } from "react";
import { GraphandForm, InputComponentProps } from "graphand-react";
import { GraphandFieldJSON } from "graphand-js";
import InputJSONDefault from "./_default";

const InputJSON: FunctionComponent<Partial<InputComponentProps>> = (props) => {
  const { onChange, field, item, options } = props;

  if (!options.template) {
    return <InputJSONDefault {...props} />;
  }

  // @ts-ignore
  const _field = field as GraphandFieldJSON;

  const _fields = Object.assign({}, _field?.fields, options.fields);

  const { template, fields, ...formProps } = options;

  return (
    <GraphandForm
      instance={item}
      values={props.value || {}}
      onChange={onChange}
      fields={_fields}
      template={options.template}
    />
  );
};

export default InputJSON;
