import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { getProjectClient } from "../utils/graphand";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MediaUploadSplash: React.FunctionComponent<{
  fileDragging: boolean;
  onTerminate: () => void;
}> = ({ fileDragging, onTerminate }) => {
  const client = getProjectClient();
  const progressBarRef = useRef<SVGCircleElement>();
  const [mediasQueue, setMediaQueue] = useState(
    client?._mediasQueueSubject.getValue()
  );

  useEffect(() => {
    if (!client) {
      return;
    }

    const sub = client._mediasQueueSubject.subscribe(setMediaQueue);

    return () => sub?.unsubscribe();
  }, [client]);

  const pending = mediasQueue?.filter((i) => i.status !== "end") || [];
  const received = pending.reduce((total, i) => total + i.bytesReceived, 1);
  const expected = pending.reduce((total, i) => total + i.bytesExpected, 1);
  const progress = received / expected || 0;

  useEffect(() => {
    if (!progressBarRef.current) {
      return;
    }

    progressBarRef.current.style.strokeDashoffset = String(
      100 - progress * 100
    );

    if (progress === 1) {
      onTerminate();
    }
  }, [progress]);

  return (
    <Transition
      show={fileDragging}
      as={Fragment}
      enter="transition transform ease-out duration-100"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition transform ease-in duration-100 delay-100"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 p-4 z-50">
        <div className="h-full w-full bg-primary bg-opacity-25 rounded-3xl border-dashed border-4 border-primary backdrop-filter backdrop-blur-sm flex items-center justify-center">
          <div className="relative">
            <svg
              className="progress-circle"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                ref={progressBarRef as MutableRefObject<SVGCircleElement>}
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__progress
                 js-progress-bar"
              />
            </svg>

            <Transition
              show={progress !== 1}
              as={Fragment}
              enter="transition transform ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition transform ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-0 flex items-center justify-center text-primary text-xl font-bold truncate">
                {Math.ceil(progress * 1000) / 10 || 0} %
              </div>
            </Transition>

            <Transition
              show={progress === 1}
              as={Fragment}
              enter="transition transform ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition transform ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-0 flex items-center justify-center text-primary text-3xl font-bold truncate">
                <FontAwesomeIcon icon={["fad", "upload"]} />
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default MediaUploadSplash;
