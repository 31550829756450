import React, { FunctionComponent, useState } from "react";
import { GraphandModelOrgInvitation } from "graphand-js";
import { getGlobalClient } from "../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheck,
  faLoader,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import Organization from "../models/Organization";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import Dropdown, { DropdownPosition } from "./Dropdown";
import { useNavigate } from "react-router-dom";

const PersonalInvitationsTile: FunctionComponent<{
  item: GraphandModelOrgInvitation;
}> = ({ item: invitation }) => {
  const navigate = useNavigate();
  const User = getGlobalClient().getModel("User");
  const [loading, setLoading] = useState(false);

  const _handleJoin = async () => {
    setLoading(true);
    try {
      await invitation.accept();
      navigate("/");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex items-center py-3">
      <div className="bg-global-inset h-14 w-14 flex items-center justify-center rounded-md text-white select-none flex-shrink-0">
        <FontAwesomeIcon icon={faBuilding} size="2x" />
      </div>
      <div className="ml-3 w-full">
        <div className="text-lg font-bold truncate">
          {invitation.organization.suspense((o: Organization) => o.name)}
        </div>
        <div className="text-sm text-gray-500 truncate">
          Invité{" "}
          {User.get(invitation.createdBy).suspense((u) =>
            u ? <strong>par {u.fullname}</strong> : null
          )}{" "}
          {formatDistance(new Date(invitation.createdAt), new Date(), {
            addSuffix: true,
            locale: fr,
          })}
        </div>
      </div>
      <div className="flex items-center justify-end space-x-4 cursor-pointer">
        <button
          type="button"
          onClick={_handleJoin}
          className={`text h-10 w-10 rounded-md flex items-center justify-center focus:outline-none select-none text-xl hover:bg-green-100 ${
            loading ? "text-primary" : "text-green-700"
          }`}
        >
          {loading ? (
            <FontAwesomeIcon icon={faLoader} className="animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
        <Dropdown
          position={DropdownPosition.RIGHT}
          button={
            <button
              type="button"
              className="text h-10 w-10 rounded-md flex items-center justify-center focus:outline-none select-none text-xl text-red-700 hover:bg-red-100"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          }
          className="w-72"
        >
          {invitation.organization.suspense((org: Organization) => {
            return (
              <div className="p-4 space-y-4">
                <div>
                  Voulez-vous vraiment refuser l'invitation à "{org.name}" ?
                </div>
                <button
                  type="button"
                  className="bg-red-500 h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button-red rounded-xl border border-white text-white"
                  onClick={() => invitation.delete()}
                >
                  Confirmer
                </button>
              </div>
            );
          })}
        </Dropdown>
      </div>
    </div>
  );
};

export default PersonalInvitationsTile;
