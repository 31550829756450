import React from "react";
import { FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import { getProjectClient } from "../../../utils/graphand";
import { useTranslation } from "react-i18next";
import { GraphandFieldText, GraphandModelList } from "graphand-js";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryBuilder from "../../../components/QueryBuilder";

const InputQuery: FunctionComponent<Partial<InputComponentProps>> = (props) => {
  const { value, onChange, errors, options, field, slug } = props;
  const values = props.form?.values || {};
  const client = getProjectClient();
  const { t } = useTranslation();

  if (!client) {
    return null;
  }

  const model = values.ref && client.getModel(values.ref);

  if (!model) {
    return null;
  }

  const required = (field as GraphandFieldText)?.required;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  if (label && required) {
    label += "*";
  }

  return (
    <FieldInputContainer {...props} label={label}>
      {(open: boolean) => (
        <div>
          <QueryBuilder
            InputContainerComponent={({ children }) => (
              <div className="flex space-x-3 relative z-10">
                <div className="w-full">{children}</div>
                <Combobox.Button as={React.Fragment}>
                  {({ open }) => (
                    <button
                      type="button"
                      className="border border-gray-200 flex-grow rounded-xl flex-shrink-0 border bg-white px-4 flex items-center truncate hover:bg-gray-100"
                    >
                      <span className="mr-1.5">Filtrer</span>
                      <FontAwesomeIcon
                        icon={["far", "angle-down"]}
                        className={`transition ${open ? "rotate-180" : ""}`}
                      />
                    </button>
                  )}
                </Combobox.Button>
              </div>
            )}
            model={model}
            onChange={(query) => onChange?.(query())}
          />

          {model
            .getList({ query: value, count: true })
            .suspense((list: GraphandModelList<any>) => {
              return <div>{list.count} éléments sélectionnés</div>;
            })}

          <FieldInputErrors
            errors={errors}
            label={label}
            slug={slug}
            model={model}
          />
        </div>
      )}
    </FieldInputContainer>
  );
};

export default InputQuery;
