import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useCurrentProject } from "../../../utils/hooks";
import Project from "../../../models/Project";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getGlobalClient, projectClientSubject } from "../../../utils/graphand";

const SettingsGeneralConfigurationTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ fields }) => {
  return <>{fields.render("accountPictureField", { theme: "select" })}</>;
};

const SettingsGeneral = () => {
  const navigate = useNavigate();
  const { project } = useCurrentProject();
  const { t } = useTranslation();

  if (!project) {
    return null;
  }

  const _handleSubmit = async (values: Partial<Project>) => {
    if (values.owner) {
      alert("Modification du propriétaire impossible");
      return;
    }

    try {
      await project.update({ set: values });
    } catch (e) {
      console.log(e);
    }
  };

  const _handleDeleteProject = async () => {
    const confirmDelete = window.confirm(
      "Do you really want to delete project ?"
    );

    if (confirmDelete && (await project.delete())) {
      getGlobalClient()?.getModel("Project").clearCache();
      navigate("/");
      projectClientSubject.next(null);
    }
  };

  return (
    <div className="p-2 sm:p-4 lg:p-6">
      <div className="w-full max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          ⚙️ {t("labels.settings.general")}
        </h1>

        <div className="divide-y divide-gray-300 space-y-4">
          <div>
            Identifiant du projet :{" "}
            <strong className="select-all">{project._id}</strong>
          </div>

          <GraphandForm
            instance={project}
            onSubmit={_handleSubmit}
            resetAfterSubmit={false}
          >
            {({ formRef, handleSubmit, fields, isLoading }) => {
              return (
                <form
                  ref={formRef}
                  onSubmit={isLoading ? null : handleSubmit}
                  className={`-mt-6 ${
                    isLoading ? "cursor-progress w-full" : "w-full"
                  }`}
                >
                  <div
                    className={`divide-y divide-gray-300 transition ${
                      isLoading ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    <div className="space-y-4 py-6">
                      {fields.render("name", {
                        disabled: isLoading,
                        reinitValue: project.name,
                      })}
                    </div>
                    <div className="space-y-4 py-6">
                      {fields.render("accessTokenLifetime", {
                        disabled: isLoading,
                        reinitValue: 86400,
                        afterHelper: "s",
                      })}
                      {fields.render("refreshTokenLifetime", {
                        disabled: isLoading,
                        reinitValue: 2592000,
                        afterHelper: "s",
                      })}
                    </div>
                    <div className="space-y-4 py-6">
                      {fields.render("owner", {
                        disabled: isLoading,
                      })}
                      {fields.render("configuration", {
                        template: SettingsGeneralConfigurationTemplate,
                      })}
                      {fields.render("configuration")}
                    </div>
                  </div>
                  <div className="mt-2 space-y-2">
                    <button
                      type="submit"
                      className="px-20 w-full bg-button h-input flex items-center justify-center cursor-pointer text-center shadow-button rounded-xl border border-white text-white"
                    >
                      <FontAwesomeIcon
                        icon={faLoader}
                        size="lg"
                        className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                          isLoading ? "opacity-100" : "opacity-0"
                        }`}
                      />
                      Valider
                    </button>

                    <button
                      type="button"
                      className="px-20 bg-red-500 w-full h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button-red rounded-xl border border-white text-white"
                      onClick={_handleDeleteProject}
                    >
                      Supprimer le projet
                    </button>
                  </div>
                </form>
              );
            }}
          </GraphandForm>
        </div>
      </div>
    </div>
  );
};

export default SettingsGeneral;
