import React, { useEffect } from "react";
import { InputComponentProps } from "graphand-react";
import { GraphandFieldRelation } from "graphand-js";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileSize from "filesize";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { getProjectClient } from "../../../utils/graphand";
import { handleLayoutDropzone } from "../../../components/LayoutProject";

const InputFile: React.FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  const { options, value, onChange, errors, slug } = props;
  const isLoading = props.form?.isLoading;
  const field = props.field as GraphandFieldRelation;

  useEffect(() => {
    handleLayoutDropzone.next(false);

    return () => handleLayoutDropzone.next(true);
  }, []);

  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noDragEventsBubbling: true,
    multiple: field.multiple,
    onDrop: (files) => {
      const v = field.multiple ? files : files[0];
      onChange?.(v);
      options.onChange?.(v);
    },
  });

  const client = getProjectClient();

  if (!client) {
    return null;
  }

  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  return (
    <FieldInputContainer {...props} label={label}>
      <div
        {...getRootProps()}
        {...options}
        className={`focus:outline-none ${
          options.disabled ? "opacity-50" : ""
        } ${options.className || ""}`}
      >
        <input tabIndex={1} {...getInputProps()} />
        {options.description ? (
          <p className="text-sm text-gray-500 mb-1">{options.description}</p>
        ) : null}
        {value?.name ? (
          <button
            className="relative block w-full border-2 border-primary border-dashed rounded-xl p-12 text-center focus:outline-none"
            type="button"
          >
            {isLoading ? (
              <div className="inline-flex items-center justify-center h-12 w-12 animate-spin">
                <FontAwesomeIcon icon={["far", "loader"]} size="2x" />
              </div>
            ) : (
              <div className="inline-flex items-center justify-center h-12 w-12">
                <FontAwesomeIcon icon={["fad", "photo-film"]} size="2x" />
              </div>
            )}
            <span className="mt-2 block text-sm font-medium text-gray-900 font-bold">
              {value.name} ({fileSize(value.size)})
            </span>
          </button>
        ) : (
          <button
            className={`group relative block w-full border-2 border-gray-200 border-dashed rounded-xl p-12 text-center focus:outline-none ${
              isDragActive ? "border-primary" : "hover:border-primary"
            }`}
            type="button"
          >
            <div className="inline-flex items-center justify-center h-12 w-12">
              <FontAwesomeIcon icon={["fad", "photo-film"]} size="2x" />
            </div>
            <span className="mt-2 block text-sm font-medium text-gray-900">
              <span className="text-blue-500 hover:text-blue-700">
                Cliquez ici
              </span>{" "}
              ou déposez un fichier
            </span>
          </button>
        )}

        <FieldInputErrors
          errors={errors}
          label={label}
          slug={slug}
          model={client.getModel("Media")}
        />
      </div>
    </FieldInputContainer>
  );
};

export default InputFile;
