import React, { useEffect, useState } from "react";
import { getProjectClient } from "../../utils/graphand";
import QueryBuilderFilterTileFieldText from "./QueryBuilderFilterTileFieldText";
import QueryBuilderFilterTileFieldId from "./QueryBuilderFilterTileFieldId";
import QueryBuilderFilterTileFieldNumber from "./QueryBuilderFilterTileFieldNumber";
import QueryBuilderFilterTileFieldBoolean from "./QueryBuilderFilterTileFieldBoolean";
import QueryBuilderFilterTileEsMapping from "./QueryBuilderFilterTileEsMapping";
import QueryBuilderFilterTileFieldRelation from "./QueryBuilderFilterTileFieldRelation";
import { GraphandModelConstructor } from "../../utils/types";

export enum QueryBuilderFilterType {
  field = 1,
  esMapping = 2,
}

export type QueryBuilderFilterState = { operator?: string; value: any };

export type QueryBuilderFilterTileProps = {
  filter: QueryBuilderFilter<any>;
  model: GraphandModelConstructor;
  onClose?: (assignFn: (query: any) => any) => void;
  onChange?: (assignFn: (query: any) => any) => void;
};

export type QueryBuilderFilter<T extends QueryBuilderFilterType> = {
  type: T;
  init?: QueryBuilderFilterState;
  payload: T extends QueryBuilderFilterType.field
    ? {
        scope: string;
        field: string;
      }
    : T extends QueryBuilderFilterType.esMapping
    ? {
        _id: string;
      }
    : any;
};

const QueryBuilderFilterTile: React.FunctionComponent<
  QueryBuilderFilterTileProps
> = (props) => {
  const [ready, setReady] = useState(false);
  const { filter, onClose } = props;
  const client = getProjectClient();

  useEffect(() => {
    setReady(true);
  }, []);

  const _onClose = async (assignFn: any) => {
    if (!onClose) {
      return;
    }

    setReady(false);
    setTimeout(() => onClose(assignFn), 300);
  };

  if (!client) {
    return null;
  }

  if (filter.type === QueryBuilderFilterType.field) {
    const { scope, field } = filter.payload;
    const model = client.getModel(scope);

    if (!model) {
      return null;
    }

    const modelField = model.fields[field];

    if (!modelField) {
      return null;
    }

    const type = modelField.constructor.__fieldType;
    let render;

    const tileProps = {
      ...props,
      model,
      field: modelField,
      onClose: onClose && _onClose,
    };

    switch (type) {
      case "Id":
        render = <QueryBuilderFilterTileFieldId {...tileProps} />;
        break;
      case "Text":
        render = <QueryBuilderFilterTileFieldText {...tileProps} />;
        break;
      case "Number":
        render = <QueryBuilderFilterTileFieldNumber {...tileProps} />;
        break;
      case "Boolean":
        render = <QueryBuilderFilterTileFieldBoolean {...tileProps} />;
        break;
      case "Relation":
        render = <QueryBuilderFilterTileFieldRelation {...tileProps} />;
        break;
      default:
        render = null;
        break;
    }

    return (
      <div
        className={`relative z-30 transition-all duration-300 ${
          ready ? "opacity-100 scale-100" : "opacity-0 scale-90"
        }`}
      >
        {render}
      </div>
    );
  } else if (filter.type === QueryBuilderFilterType.esMapping) {
    const { _id } = filter.payload;

    return (
      <div
        className={`relative z-30 transition-all duration-300 ${
          ready ? "opacity-100 scale-100" : "opacity-0 scale-90"
        }`}
      >
        <QueryBuilderFilterTileEsMapping
          {...props}
          esMapping={_id}
          onClose={onClose && _onClose}
        />
      </div>
    );
  }

  return null;
};

export default QueryBuilderFilterTile;
