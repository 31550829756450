import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { UpdateItemComponentProps } from "../utils/types";
import { getProjectClient } from "../utils/graphand";
import { useCurrentProject } from "../utils/hooks";
import { GraphandModelDataField } from "graphand-js";
import UpdateItemWrapper from "./UpdateItemWrapper";

const UpdateAccountFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ formRef, isLoading, handleSubmit, fields }) => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const { project } = useCurrentProject();
  const DataField = client?.getModel("DataField");

  const _render = (accountField?: GraphandModelDataField) => {
    return (
      <form
        ref={formRef}
        onSubmit={isLoading ? null : handleSubmit}
        className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
      >
        <div className="space-y-4">
          {accountField ? (
            <div className="w-full max-w-xs mx-auto">
              {fields.render(accountField.slug, { theme: "single-image" })}
            </div>
          ) : null}
          <div className="flex items-center w-full space-x-4">
            <div className="w-full">{fields.render("firstname")}</div>
            <div className="w-full">{fields.render("lastname")}</div>
          </div>
          <div className="flex items-center w-full space-x-4">
            <div className="w-full">{fields.render("email")}</div>
            <div className="w-full">
              {fields.render("password", { theme: "password" })}
            </div>
          </div>
          {fields.render("role", { theme: "select" })}
          {Object.keys(fields)
            .filter(
              (f) =>
                ![
                  accountField?.slug,
                  "firstname",
                  "lastname",
                  "password",
                  "_fullname",
                  "role",
                  "email",
                  "invite",
                ].includes(f)
            )
            .map((f) => fields.render(f))}
        </div>
        <Button type="submit">
          {t(isLoading ? "actions.updateLoading" : "actions.update")}
        </Button>
      </form>
    );
  };

  if (!project?.configuration.accountPictureField) {
    return _render();
  }

  return (
    <>
      {DataField?.get(
        project.configuration.accountPictureField.toString()
      )?.suspense(
        (field) => {
          return _render(field as GraphandModelDataField);
        },
        { subscribe: true }
      )}
    </>
  );
};

const UpdateAccount: React.FunctionComponent<UpdateItemComponentProps> = (
  props
) => {
  return (
    <UpdateItemWrapper
      {...props}
      template={UpdateAccountFormTemplate}
      initialValues={{ ...props.item.toJSON(), password: "" }}
    />
  );
};

export default UpdateAccount;
