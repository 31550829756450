import React from "react";
import {
  GraphandModelDataModel,
  GraphandFieldText,
  GraphandField,
  GraphandFieldRelation,
} from "graphand-js";
import CreateDataModel from "../components/CreateDataModel";
import { camelCase } from "lodash";
import UpdateDataModel from "../components/UpdateDataModel";
import { ViewComponentProps } from "graphand-react";
import FieldIconView from "../fieldsViews/FieldIcon";
import DataModelField from "../fieldsViews/DataModelFields";
import ViewText from "../fields/views/Text";
import { useTranslation } from "react-i18next";
import PreviewDataModel from "../components/PreviewDataModel";
import { deepEqual } from "fast-equals";
import { GraphandModelConstructor } from "../utils/types";

class DataModel extends GraphandModelDataModel {
  static previewField = "name";
  static previewItemComponent = PreviewDataModel;
  static createItemComponent = CreateDataModel;
  static updateItemComponent = UpdateDataModel;

  get isConfigured() {
    return Boolean(
      this.isTemporary() ||
        (this.configuration?.icon && !this?.multiple) ||
        this.configuration?.titleField ||
        this.configuration?.subtitleField ||
        this.configuration?.imageField
    );
  }

  getLink() {
    return "/apps/" + this._scope;
  }

  async getConfiguration(key?: string): Promise<any> {
    if (key) {
      const conf = await this.getConfiguration();
      return conf[key];
    }

    const baseConf = this.configuration;
    // @ts-ignore
    const client = this.constructor._client;
    if (!client || !this._scope) {
      return {};
    }

    const [imageField, titleField, subtitleField] = await Promise.all([
      baseConf?.imageField,
      baseConf?.titleField,
      baseConf?.subtitleField,
    ]);

    const model = client.getModel(this._scope) as GraphandModelConstructor;
    return {
      icon: baseConf.icon ?? "question-circle",
      imageField: model.imageField ?? imageField?.slug,
      titleField: model.titleField ?? titleField?.slug,
      subtitleField: model.subtitleField ?? subtitleField?.slug,
    };
  }

  updateConfiguration(payload: any) {
    const { constructor } = Object.getPrototypeOf(this);
    const defaultConfiguration =
      constructor.fields.configuration.getter.call(this, {}) || {};
    const configuration = Object.keys(payload).reduce(
      (final: any, key: string) => {
        if (
          payload[key] === undefined ||
          deepEqual(payload[key], defaultConfiguration[key])
        ) {
          delete payload[key];
        } else {
          final[key] = payload[key];
        }

        return final;
      },
      this.raw.configuration
    );

    return this.update({ set: { configuration } }, { preStore: true });
  }
}

DataModel.hook("formChange", (values: any, { instance, isModified }: any) => {
  if ((!instance || instance.isTemporary()) && !isModified("slug")) {
    values.slug = values.name && camelCase(values.name);
  }
});

DataModel.hook("formSubmit", (values: any, { instance, isModified }: any) => {
  if ((!instance || instance.isTemporary()) && !isModified("slug")) {
    values.slug = values.name && camelCase(values.name);
  }
});

DataModel.customFields({
  name: ({ name }) => {
    name.ViewComponent = ((props: ViewComponentProps) => {
      const { t } = useTranslation();
      let value = props.value;

      if (props.item._scope === "Account") {
        value = t("labels.apps.Account");
      } else if (props.item._scope === "Media") {
        value = t("labels.apps.Media");
      }

      return <ViewText {...props} value={value} />;
    }) as React.FunctionComponent;

    return name;
  },
  _scope: new GraphandFieldText({
    getter(value: string | undefined, instance: DataModel) {
      return value ?? `Data:${instance.slug}`;
    },
  }),
  _icon: new GraphandField({
    ViewComponent: (props: ViewComponentProps) => {
      let value = props.value;

      return (
        <FieldIconView
          {...props}
          value={value ?? props.item?.configuration?.icon}
        />
      );
    },
  }),
  _fields: new GraphandField({
    ViewComponent: DataModelField,
  }),
  configuration: ({ configuration }) => {
    configuration.fields = {
      icon: new GraphandFieldText({
        options: [
          "house",
          "circle-exclamation",
          "star",
          "globe",
          "earth-europe",
          "xmark",
          "link",
          "user",
          "photo-film",
          "tag",
          "plus",
          "empty-set",
          "bell",
          "cog",
          "building",
          "search",
          "eye",
          "eye-slash",
          "image",
          "wand-magic-sparkles",
          "play",
          "grid",
          "newspaper",
          "message",
          "messages",
          "address-book",
          "question-circle",
          "star-of-life",
          "scale-balanced",
          "palette",
          "paintbrush-pencil",
          "key",
          "book",
          "layer-group",
          "envelope",
          "user-group",
          "folder",
          "file",
          "file-contract",
          "location-pin",
          "ranking-star",
          "screwdriver-wrench",
          "bowling-ball",
          "tennis-ball",
          "share",
          "pot-food",
          "ship",
          "anchor",
        ],
      }),
      titleField: new GraphandFieldRelation({ ref: "DataField" }),
      subtitleField: new GraphandFieldRelation({ ref: "DataField" }),
      imageField: new GraphandFieldRelation({ ref: "DataField" }),
    };

    return configuration;
  },
});

export default DataModel;
