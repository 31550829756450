import React from "react";
import { InputComponentProps } from "graphand-react";
import InputTextSolid from "./_solid";

const InputTextNumber: React.FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  props.options.type = "number";

  return <InputTextSolid {...props} />;
};

export default InputTextNumber;
