import React, { useEffect, useState } from "react";
// @ts-ignore
import { Resizable } from "react-resizable";
import SelectItemsModal from "../../modals/SelectItemsModal";
import { getProjectClient } from "../../utils/graphand";
import Dropdown, { DropdownPosition } from "../Dropdown";
import InputRteControl from "./Control";
import { EditorState, RichUtils, EntityInstance } from "draft-js";
import { GraphandModelMedia } from "graphand-js";
import InputTextSolid from "../../fields/inputs/Text/_solid";

const InputRteToolsbar: React.FunctionComponent<{
  className?: string;
  currentBlock?: any;
  updateCurrentBlock: () => void;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onInsertMedia: (media: GraphandModelMedia) => void;
  onChangeLink: (url: string) => void;
  activeLink?: EntityInstance;
}> = ({
  currentBlock,
  updateCurrentBlock,
  editorState,
  setEditorState,
  modalOpen,
  setModalOpen,
  onInsertMedia,
  onChangeLink,
  activeLink,
}) => {
  const client = getProjectClient();
  const [selectMediaModalOpen, setSelectMediaModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");

  const _refreshLinkUrl = () => {
    if (activeLink) {
      const data = activeLink.getData();
      if (data?.url) {
        setLinkUrl(data?.url || "");
      }
    } else if (linkUrl?.length) {
      setLinkUrl("");
    }
  };

  useEffect(() => {
    _refreshLinkUrl();
  }, [activeLink]);

  useEffect(() => {
    onChangeLink(linkUrl);
  }, [linkUrl]);

  const Media = client?.getModel("Media");

  if (!Media) {
    return null;
  }

  return (
    <>
      <InputRteControl
        icon="bold"
        editorState={editorState}
        setEditorState={setEditorState}
        inlineStyle="BOLD"
      />
      <InputRteControl
        icon="underline"
        editorState={editorState}
        setEditorState={setEditorState}
        inlineStyle="UNDERLINE"
      />
      <InputRteControl
        icon="italic"
        editorState={editorState}
        setEditorState={setEditorState}
        inlineStyle="ITALIC"
      />
      <Dropdown
        position={DropdownPosition.CENTER}
        onOpen={updateCurrentBlock}
        button={
          <InputRteControl
            icon="block-quote"
            editorState={editorState}
            setEditorState={setEditorState}
            // isActive={[
            //   "header-one",
            //   "header-two",
            //   "header-three",
            //   "header-four",
            //   "header-five",
            //   "header-six",
            // ].includes(currentBlock?.getType())}
          />
        }
        links={[
          {
            label: "Titre 1",
            isActive: currentBlock?.getType() === "header-one",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-one")
              );
            },
          },
          {
            label: "Titre 2",
            isActive: currentBlock?.getType() === "header-two",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-two")
              );
            },
          },
          {
            label: "Titre 3",
            isActive: currentBlock?.getType() === "header-three",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-three")
              );
            },
          },
          {
            label: "Titre 4",
            isActive: currentBlock?.getType() === "header-four",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-four")
              );
            },
          },
          {
            label: "Titre 5",
            isActive: currentBlock?.getType() === "header-five",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-five")
              );
            },
          },
          {
            label: "Titre 6",
            isActive: currentBlock?.getType() === "header-six",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "header-six")
              );
            },
          },
          {
            label: "Base",
            isActive: currentBlock?.getType() === "unstyled",
            onClick: () => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, currentBlock?.getType())
              );
            },
          },
        ]}
      />
      <InputRteControl
        icon="photo-film"
        editorState={editorState}
        setEditorState={setEditorState}
        onClick={() => setSelectMediaModalOpen(true)}
      />
      <Dropdown
        position={DropdownPosition.CENTER}
        onOpen={_refreshLinkUrl}
        disabled={editorState.getSelection().isCollapsed()}
        button={
          <InputRteControl
            icon="link"
            disabled={editorState.getSelection().isCollapsed()}
            editorState={editorState}
            setEditorState={setEditorState}
            isActive={Boolean(activeLink)}
          />
        }
      >
        <div className="p-2">
          <InputTextSolid
            id={`linkUrl`}
            value={linkUrl}
            onChange={setLinkUrl}
            options={{
              label: null,
              placeholder: "http:// ...",
            }}
          />
        </div>
      </Dropdown>
      <InputRteControl
        icon="arrow-rotate-left"
        editorState={editorState}
        setEditorState={setEditorState}
        onClick={() => setEditorState((s) => EditorState.undo(s))}
      />
      <InputRteControl
        icon="arrow-rotate-right"
        editorState={editorState}
        setEditorState={setEditorState}
        onClick={() => setEditorState((s) => EditorState.redo(s))}
      />
      <InputRteControl
        icon={modalOpen ? "compress" : "expand"}
        editorState={editorState}
        setEditorState={setEditorState}
        onClick={() => setModalOpen((o) => !o)}
      />

      <SelectItemsModal
        isOpen={selectMediaModalOpen}
        onClose={setSelectMediaModalOpen}
        value={null}
        multiple={false}
        autoSave
        query={{ mimetype: { $regex: "image" } }}
        onChange={async (v) => {
          const media = await Media.get(v);

          onInsertMedia(media);
          setSelectMediaModalOpen(false);
        }}
        model={Media}
      />
    </>
  );
};

export default InputRteToolsbar;
