import { GraphandField, GraphandModelEnvironment } from "graphand-js";
import EnvironmentName from "../fieldsViews/EnvironmentName";
import CreateEnvironment from "../components/CreateEnvironment";

class Environment extends GraphandModelEnvironment {
  static previewField = "name";
  static createItemComponent = CreateEnvironment;
}

Environment.customFields({
  _name: new GraphandField({
    ViewComponent: EnvironmentName,
  }),
});

export default Environment;
