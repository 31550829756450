import { GraphandModelSockethook, GraphandField } from "graphand-js";
import SockethookTitle from "../fieldsViews/SockethookTitle";
import PreviewSockethook from "../components/PreviewSockethook";

class Sockethook extends GraphandModelSockethook {
  static previewField = "identifier";
  static previewItemComponent = PreviewSockethook;
}

Sockethook.customFields({
  _title: new GraphandField({
    ViewComponent: SockethookTitle,
  }),
});

export default Sockethook;
