import React, { useState } from "react";
import {
  GraphandFieldBoolean,
  GraphandFieldJSON,
  GraphandModelAccount,
  GraphandFieldText,
  GraphandField,
} from "graphand-js";
import { deepEqual } from "fast-equals";
import { capitalizeFirstLetter } from "../utils/formatter";
import AccountPictureView from "../fieldsViews/AccountPicture";
import PreviewAccount from "../components/PreviewAccount";
import CreateAccount from "../components/CreateAccount";
import UpdateAccount from "../components/UpdateAccount";
import AccountPreviewView from "../fieldsViews/AccountPreview";
import PageComponentMultiple from "../components/PageComponent/Multiple";
import SelectAccountPictureFieldWizard from "../wizards/SelectAccountPictureField";
import CreateFiltersWizard from "../wizards/CreateFilters";
import { getProjectClient } from "../utils/graphand";
import { PageComponentProps } from "../utils/types";
import { Translation } from "react-i18next";
import { copyToClipboard } from "../utils/tools";
import InviteAccountModal from "../modals/InviteAccountModal";

const AccountPageComponent: React.FunctionComponent<PageComponentProps> = (
  props
) => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <PageComponentMultiple
      {...props}
      listActions={(item) => [
        {
          label: (
            <Translation>{(t) => t("actions.generate_token")}</Translation>
          ),
          onClick: async () => {
            const { accessToken } = await item.generateToken();
            copyToClipboard(accessToken);
          },
        },
      ]}
      globalActions={[
        {
          label: (
            <Translation>{(t) => t("actions.invite_account")}</Translation>
          ),
          onClick: () => setInviteModalOpen(true),
        },
      ]}
    >
      <InviteAccountModal
        isOpen={inviteModalOpen}
        onClose={setInviteModalOpen}
      />
    </PageComponentMultiple>
  );
};

class Account extends GraphandModelAccount {
  static previewField = "_preview";
  static listFields = ["_label", "email"];
  static imageField = "_picture";
  static titleField = "_fullname";
  static subtitleField = "role";
  static pageComponent = AccountPageComponent;
  static createItemComponent = CreateAccount;
  static updateItemComponent = UpdateAccount;
  static previewItemComponent = PreviewAccount;
  static wizards = {
    selectAccountPictureField: SelectAccountPictureFieldWizard,
    "createFilters:Account": CreateFiltersWizard,
  };

  static get __dataModel() {
    const client = getProjectClient();
    return client?.getModel("DataModel").get("_dataModelAccount");
  }

  isAdmin() {
    return Boolean(this.role?.admin);
  }

  get currentSettings(): any {
    const { constructor } = Object.getPrototypeOf(this);
    const roleSettings = this.role?.raw?.accountsSettings || {};
    const thisSettings = { ...this.raw.settings };
    Object.keys(thisSettings)
      .filter((key) => thisSettings[key] === undefined)
      .forEach((key) => delete thisSettings[key]);

    return constructor.fields.settings.getter(
      { ...roleSettings, ...thisSettings },
      this
    );
  }

  updateSettings(payload: any) {
    this.settings = { ...this.raw.settings, ...payload };
    const accountSettings = this.role.accountsSettings || {};
    const set = Object.keys(payload).reduce((final: any, key: string) => {
      if (
        payload[key] === undefined ||
        deepEqual(payload[key], accountSettings[key])
      ) {
        final.$unset = final.$unset || {};
        final.$unset[`settings.${key}`] = true;
        return final;
      }

      final[`settings.${key}`] = payload[key];
      return final;
    }, {});

    return this.update({ set }, { preStore: false, upsert: false });
  }

  toggleSetting(key: string, item: any) {
    const settingList = new Set(this.currentSettings[key]?.ids || []);
    if (settingList.has(item)) {
      settingList.delete(item);
    } else {
      settingList.add(item);
    }

    this.updateSettings({ [key]: Array.from(settingList) });
  }
}

Account.customFields({
  _preview: new GraphandField({
    ViewComponent: AccountPreviewView,
  }),
  _picture: new GraphandField({
    ViewComponent: AccountPictureView,
  }),
  _fullname: new GraphandFieldText({
    getter(value: unknown, account: Account) {
      return `${capitalizeFirstLetter(
        account.firstname
      )} ${capitalizeFirstLetter(account.lastname)}`;
    },
  }),
  invite: new GraphandFieldBoolean({ defaultValue: false }),
  settings: new GraphandFieldJSON({}),
});

export default Account;
