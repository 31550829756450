import React from "react";
import Media from "../../models/Media";
import MediaPlayerImage from "./Image";
import MediaPlayerVideo from "./Video";
import MediaPlayerPdf from "./Pdf";

export type MediaPlayerProps = {
  media: Media;
};

const MediaPlayer: React.FunctionComponent<MediaPlayerProps> = (props) => {
  let Component;

  if (props.media.mimetype.includes("image")) {
    Component = MediaPlayerImage;
  } else if (props.media.mimetype.includes("video")) {
    Component = MediaPlayerVideo;
  } else if (props.media.mimetype.includes("pdf")) {
    Component = MediaPlayerPdf;
  }

  if (Component) {
    return React.createElement(Component, props);
  }

  return null;
};

export default MediaPlayer;
