import { GraphandModelAggregation } from "graphand-js";
import CreateAggregation from "../components/CreateAggregation";
import UpdateAggregation from "../components/UpdateAggregation";
import PreviewAggregation from "../components/PreviewAggregation";

class Aggregation extends GraphandModelAggregation {
  static previewField = "name";
  static createItemComponent = CreateAggregation;
  static updateItemComponent = UpdateAggregation;
  static previewItemComponent = PreviewAggregation;
}

export default Aggregation;
