import React, { useEffect, useState } from "react";
import { ViewComponentProps } from "graphand-react";
import ViewText from "../Text";
import { GraphandFieldRelation } from "graphand-js";
import { useTranslation } from "react-i18next";
import PreviewItemModal from "../../../modals/PreviewItemModal";
import { GraphandModelConstructor } from "../../../utils/types";
import { getProjectClient } from "../../../utils/graphand";
import { ViewComponentContext } from "../../../utils/enums";

const ViewRelationSingle: React.FunctionComponent<ViewComponentProps> = (
  props
) => {
  const { slug, item, options } = props;
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const client = getProjectClient();
  const { t } = useTranslation();

  useEffect(() => {
    if (previewModalOpen) {
      options.setModalProps?.({
        className: "max-w-screen-sm scale-95",
      });
    } else {
      options.setModalProps?.({ className: "max-w-screen-sm" });
    }
  }, [previewModalOpen]);

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;
  const model = client.getModel(field.ref) as GraphandModelConstructor;

  props.options.label = field?.__dataField?.name;
  if (props.options.label === undefined) {
    props.options.label = t(`labels.fields.${slug}.default`);
  }

  const instance = options.instance ?? item[slug];
  return (
    instance?.suspense(
      (res: any) => {
        const _options = {
          ...options,
          onClick: [
            ViewComponentContext.TABLE_CELL,
            ViewComponentContext.TABLE_CELL_MULTIPLE,
            ViewComponentContext.PREVIEW_LINE,
            ViewComponentContext.LIST_ROW,
          ].includes(options.context)
            ? () => setPreviewModalOpen(true)
            : undefined,
        };
        let _render;

        if (model.previewField) {
          _render = res.renderFieldView(model.previewField, _options);
        } else {
          _render = <ViewText {...props} value={res._id} options={_options} />;
        }

        return (
          <>
            {_render}

            <PreviewItemModal
              isOpen={previewModalOpen}
              onClose={setPreviewModalOpen}
              model={model}
              item={res}
            />
          </>
        );
      },
      {
        updateKey: instance?._id,
        subscribe: true,
        fallback: options.fallback || null,
      }
    ) ||
    options.fallback ||
    null
  );
};

export default ViewRelationSingle;
