import React from "react";
import { MediaPlayerProps } from "./index";

const MediaPlayerVideo: React.FunctionComponent<MediaPlayerProps> = ({
  media,
}) => {
  return (
    <video controls className="w-full">
      <source src={media.getUrl({ stream: "video/mp4" })} type="video/mp4" />
      <source
        src={media.getUrl({ stream: media.mimetype })}
        type={media.mimetype}
      />
    </video>
  );
};

export default MediaPlayerVideo;
