import React from "react";
import { GraphandFieldText } from "graphand-js";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Select";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";

const InputTextOptionsSelect: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { slug, value, field, onChange, options, errors, model, inputRef } =
    props;
  const { t } = useTranslation();
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  return (
    <FieldInputContainer
      {...props}
      label={label}
      links={[
        {
          label: t("actions.select_none"),
          onClick: () => onChange?.(undefined),
        },
      ]}
    >
      <Select
        value={value}
        multiple={(field as GraphandFieldText).multiple}
        hasError={Boolean(errors?.length)}
        inputRef={inputRef}
        onChange={onChange || (() => {})}
        options={(field as GraphandFieldText)?.options || []}
        creatable={(field as GraphandFieldText).creatable}
        beforeHelper={options.beforeHelper}
        afterHelper={options.afterHelper}
      />

      <FieldInputErrors
        errors={errors}
        label={label}
        slug={slug}
        model={model}
      />
    </FieldInputContainer>
  );
};

export default InputTextOptionsSelect;
