import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGlobalClient } from "../../utils/graphand";
import { sendConfirmationLink } from "../../utils/tools";
import { useCurrentUser } from "../../utils/hooks";

const CONFIRM_STAGES = {
  INITIALIZE: 1,
  LOADING: 2,
  CONFIRMED: 3,
  ERROR: 4,
  EXPIRED: 4,
};

let updater: any;

const ConfirmUser = () => {
  const client = getGlobalClient();
  const [params] = useSearchParams();
  const { user } = useCurrentUser();
  const [confirmStage, setConfirmStage] = useState(CONFIRM_STAGES.INITIALIZE);

  const token = params.get("token");

  const _confirm = async () => {
    if (confirmStage !== CONFIRM_STAGES.INITIALIZE) {
      return;
    }

    setConfirmStage(CONFIRM_STAGES.LOADING);

    try {
      updater =
        updater ||
        client._axios.post(
          "/auth/confirm",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      await updater;
      setConfirmStage(CONFIRM_STAGES.CONFIRMED);
    } catch (e) {
      // @ts-ignore
      if (e.graphandErrors?.find(({ code }) => code === "expired_token")) {
        setConfirmStage(CONFIRM_STAGES.EXPIRED);
      } else {
        setConfirmStage(CONFIRM_STAGES.ERROR);
      }
    }
  };

  useEffect(() => {
    titleSubject.next("Confirmation du compte");
    readySubject.next(true);

    if (!token) {
      setConfirmStage(CONFIRM_STAGES.ERROR);

      return () => {
        titleSubject.next(null);
        readySubject.next(false);
      };
    }

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  }, []);

  useEffect(() => {
    if (user && token) {
      if (user.status === "new") {
        _confirm();
      } else {
        setConfirmStage(CONFIRM_STAGES.CONFIRMED);
      }
    }
  }, [user?._id]);

  const _renderTitle = () => {
    switch (confirmStage) {
      case CONFIRM_STAGES.INITIALIZE:
      case CONFIRM_STAGES.LOADING:
        return (
          <>
            <div className="flex items-center w-8 h-8 mr-3">
              <FontAwesomeIcon icon={faLoader} className="animate-spin" />
            </div>
            Un instant, nous confirmons votre compte ...
          </>
        );
      case CONFIRM_STAGES.CONFIRMED:
        return (
          <>
            <div className="flex items-center w-8 h-8 mr-3">✅</div>
            Votre compte a été confirmé
          </>
        );
      case CONFIRM_STAGES.EXPIRED:
        return (
          <>
            <div className="flex items-center w-8 h-8 mr-3">⏱</div>
            Le lien a expiré
          </>
        );
      case CONFIRM_STAGES.ERROR:
        return (
          <>
            <div className="flex items-center w-8 h-8 mr-3">⛔️</div>
            Une erreur est survenue
          </>
        );
    }
  };

  const _renderContent = () => {
    switch (confirmStage) {
      case CONFIRM_STAGES.EXPIRED:
        return (
          <p className="mt-2">
            Le lien sur lequel vous avez cliqué a expiré. Vous pouvez{" "}
            <button
              type="button"
              className="text-button hover:underline"
              onClick={sendConfirmationLink}
            >
              en recevoir un nouveau en cliquant ici
            </button>
          </p>
        );
      case CONFIRM_STAGES.INITIALIZE:
      case CONFIRM_STAGES.LOADING:
      case CONFIRM_STAGES.ERROR:
        return null;
      case CONFIRM_STAGES.CONFIRMED:
        return <>Votre compte a été bien été confirmé.</>;
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold flex items-center">{_renderTitle()}</h1>
      {_renderContent()}
    </div>
  );
};

export default ConfirmUser;
