import React from "react";
import { GraphandValidationError } from "graphand-js";
import { capitalizeFirstLetter } from "../utils/formatter";
import { useTranslation } from "react-i18next";
import { InputComponentProps } from "graphand-react";

const FieldInputErrors: React.FunctionComponent<{
  errors?: InputComponentProps["errors"];
  slug?: InputComponentProps["slug"];
  label: any;
  model?: InputComponentProps["model"];
}> = ({ errors, slug, label, model }) => {
  const { t } = useTranslation();

  if (!errors?.length) {
    return null;
  }

  return (
    <div className="mt-1 text-sm text-red-600 space-y-1">
      {errors.map((e: GraphandValidationError) => (
        <p>
          {t(`errors.fields.${e.code}`, {
            single: t(`labels.fields.${slug}.single`),
            of: t(`labels.fields.${slug}.of`),
            label,
            model: capitalizeFirstLetter(
              t(`labels.models.${model?.scope}.single`)
            ),
            interpolation: { escapeValue: false },
          })}
        </p>
      ))}
    </div>
  );
};

export default FieldInputErrors;
