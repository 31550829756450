import React from "react";
import { EditorState, RichUtils } from "draft-js";
import Button, { ButtonTheme } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const InputRteControl: React.FunctionComponent<{
  icon: IconName;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  isActive?: boolean;
  inlineStyle?: string;
  blockStyle?: string;
  onClick?: () => void;
  disabled?: boolean;
}> = ({
  icon,
  editorState,
  setEditorState,
  inlineStyle,
  blockStyle,
  onClick,
  isActive: _isActive,
  disabled,
}) => {
  let isActive = _isActive;

  if (inlineStyle) {
    const _inlineStyle = editorState.getCurrentInlineStyle();
    isActive = _inlineStyle.has(inlineStyle);
  }

  if (blockStyle) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockStartKey = selection.getStartKey();
    const block = contentState.getBlockMap().get(blockStartKey);
    isActive = block.getType() === blockStyle;
  }

  if (!onClick) {
    if (inlineStyle) {
      onClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
      };
    } else if (blockStyle) {
      onClick = () => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockStyle));
      };
    }
  }

  return (
    <Button
      theme={isActive ? ButtonTheme.default : ButtonTheme.inline}
      className="w-9 h-9"
      disabled={disabled}
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={["far", icon]} />
    </Button>
  );
};

export default InputRteControl;
