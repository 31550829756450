import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MediaInfoView: React.FunctionComponent<ViewComponentProps> = ({
  item,
  options,
}) => {
  switch (options.context) {
    case ViewComponentContext.GRID_LABEL_INFO:
      let content = null;

      if (item.width || item.height) {
        content = `${item.width}x${item.height}`;
      }

      if (item.duration) {
        content = (
          <>
            <FontAwesomeIcon icon={["far", "timer"]} className="mr-1" />
            {Math.round(item.duration)}s
          </>
        );
      }

      if (!content) {
        return null;
      }

      return (
        <div
          className={`bg-gray-200 bg-opacity-60 rounded px-1 py-0.5 text-xs text-gray-600 select-none`}
        >
          {content}
        </div>
      );
    default:
      break;
  }

  return null;
};

export default MediaInfoView;
