import update from "immutability-helper";
import React, {
  Fragment,
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { handleLayoutDropzone } from "./LayoutProject";

export type SelectOptionComponentProps = {
  option: string;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  display: "list" | "input";
  handleClick?: () => void;
};

export type SelectContainerComponentProps = {
  children: any;
  hasError?: boolean;
  beforeHelper: any;
  afterHelper: any;
};

export type SelectProps = {
  value: any;
  onChange: (value: any) => void;
  filter?: (option: string, input: string) => boolean;
  options: string[];
  multiple?: boolean;
  hasError?: boolean;
  inputClassName?: string;
  beforeHelper?: any;
  afterHelper?: any;
  inputProps?: object;
  OptionComponent?: React.FunctionComponent<SelectOptionComponentProps>;
  ContainerComponent?: React.FunctionComponent<SelectContainerComponentProps>;
  creatable?: boolean;
  inputRef?: React.Ref<any>;
  closable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  inlineValue?: boolean;
};

const SelectInputOption: React.FunctionComponent<{
  OptionComponent: any;
  option: string;
  onClose?: () => void;
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  setIsDragging?: (isDragging: boolean) => void;
}> = ({ OptionComponent, option, index, onClose, move, setIsDragging }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag<any, any, any>({
    type: "selectOption",
    item: { option, index },
    collect: (monitor: any) => {
      return { isDragging: monitor.isDragging() };
    },
  });

  useEffect(() => {
    setIsDragging?.(isDragging);

    if (isDragging) {
      handleLayoutDropzone.next(false);
    } else {
      handleLayoutDropzone.next(true);
    }

    return () => {
      handleLayoutDropzone.next(true);
    };
  }, [isDragging]);

  const [{ handlerId }, drop] = useDrop<any, any, any>({
    accept: "selectOption",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const { bottom, top } = hoverBoundingRect;

      const hoverMiddleY = (bottom - top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      move(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      key={`${option}-label-container`}
      ref={drag(drop(ref)) as LegacyRef<HTMLDivElement>}
      data-handler-id={handlerId}
      className={`flex items-center bg-gray-200 h-input-2 rounded-lg px-2 select-none text-gray-600 m-1 ${
        isDragging ? "opacity-0" : "opacity-100"
      }`}
    >
      <OptionComponent
        key={`${option}-label`}
        option={option}
        display="input"
      />

      {onClose ? (
        <button
          role="button"
          type="button"
          className="h-6 w-6 ml-1 flex items-center justify-center cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={["far", "xmark"]} />
        </button>
      ) : null}
    </div>
  );
};

const DefaultOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option }) => <span>{option}</span>;

const DefaultContainerComponent: React.FunctionComponent<
  SelectContainerComponentProps
> = ({ children, hasError, beforeHelper, afterHelper }) => (
  <button
    type="button"
    className={`flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white ${
      hasError ? "border-red-700" : "border-gray-200"
    }`}
  >
    {beforeHelper ? (
      <div className="pl-4 text-base flex items-center text-gray-400 flex-shrink-0">
        {beforeHelper}
      </div>
    ) : null}

    {children}

    {afterHelper ? (
      <div className="pr-4 text-base flex items-center text-gray-400 flex-shrink-0">
        {afterHelper}
      </div>
    ) : null}
  </button>
);

const Select: React.FunctionComponent<SelectProps> = (props) => {
  const {
    onChange,
    onClick,
    hasError,
    options,
    multiple,
    inputClassName = "",
    beforeHelper,
    afterHelper,
    inputProps = {},
    filter,
    creatable,
    inputRef,
    closable = true,
    disabled = false,
    inlineValue = false,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const _handleChange = (v: any) => {
    onChange(v);
  };

  const value = multiple
    ? Array.isArray(props.value)
      ? props.value
      : [props.value].filter(Boolean)
    : Array.isArray(props.value)
    ? props.value[0]
    : props.value;

  const OptionComponent = props.OptionComponent ?? DefaultOptionComponent;
  const ContainerComponent =
    props.ContainerComponent ?? DefaultContainerComponent;

  const _filter = filter ?? ((option, input) => option.includes(input));

  const move = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const v = update(value, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, value[dragIndex]],
        ],
      });

      onChange(v);
    },
    [value]
  );

  const displayOptions = options.filter((option) =>
    _filter(option, inputValue)
  );

  return (
    <div className="relative" onClick={onClick}>
      <Combobox
        value={value}
        disabled={Boolean(disabled || onClick)}
        onChange={_handleChange}
        // @ts-ignore
        multiple={multiple}
      >
        <Combobox.Button as="div">
          <ContainerComponent
            hasError={hasError}
            beforeHelper={beforeHelper}
            afterHelper={afterHelper}
          >
            {multiple || inlineValue ? (
              <div className="flex p-1 flex-wrap w-full">
                {(multiple ? value : [value].filter(Boolean))?.map(
                  (option: string, index: number) => (
                    <SelectInputOption
                      move={move}
                      key={option}
                      index={index}
                      OptionComponent={OptionComponent}
                      option={option}
                      onClose={
                        closable
                          ? () => {
                              _handleChange(
                                value.filter((v: string) => v !== option)
                              );
                            }
                          : undefined
                      }
                    />
                  )
                )}

                <Combobox.Input
                  {...inputProps}
                  displayValue={
                    inlineValue ? () => "" : () => String(inputValue || "")
                  }
                  ref={inputRef}
                  className={`min-w-32 h-input-1 w-0 flex-grow flex-shrink overflow-hidden border-0 px-2 bg-transparent rounded-xl focus:outline-none focus:border-none focus:ring-0 ${inputClassName} ${
                    disabled ? "opacity-50" : ""
                  } ${onClick ? "cursor-pointer" : ""}`}
                  placeholder="Rechercher ..."
                  type="text"
                  onChange={({ currentTarget: { value } }: any) => {
                    setInputValue(value);
                  }}
                />
              </div>
            ) : (
              <Combobox.Input
                {...inputProps}
                className={`h-input overflow-hidden border-0 px-4 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0 ${inputClassName} ${
                  disabled ? "opacity-50" : ""
                }`}
                type="text"
                onChange={({ currentTarget: { value } }: any) => {
                  setInputValue(value);
                }}
              />
            )}
          </ContainerComponent>
        </Combobox.Button>

        {!onClick ? (
          <Combobox.Options as={Fragment}>
            <div className="absolute z-50 bg-white top-full mt-2 w-full rounded-2xl p-4 shadow-lg space-y-4 max-h-64 overflow-auto">
              {displayOptions.map((option) => (
                <Combobox.Option as={Fragment} key={option} value={option}>
                  {({ active, selected, disabled }) => (
                    <li
                      className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                        active ? "bg-gray-200" : ""
                      }`}
                      role="option"
                    >
                      <input
                        type="checkbox"
                        className="h-5 w-5 text-button border-gray-300 rounded-md mr-2"
                        checked={selected}
                        onChange={() => null}
                      />
                      <OptionComponent
                        option={option}
                        active={active}
                        selected={selected}
                        disabled={disabled}
                        display="list"
                      />
                    </li>
                  )}
                </Combobox.Option>
              ))}

              {creatable &&
              inputValue &&
              !options.find((o) => o === inputValue) ? (
                <Combobox.Option
                  as={Fragment}
                  key={inputValue}
                  value={inputValue}
                >
                  {({ active, selected, disabled }) => (
                    <li
                      className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                        active ? "bg-gray-200" : ""
                      }`}
                      role="option"
                    >
                      <input
                        type="checkbox"
                        className="h-5 w-5 text-button border-gray-300 rounded-md mr-2"
                        checked={selected}
                        onChange={() => null}
                      />
                      <OptionComponent
                        option={inputValue}
                        active={active}
                        selected={selected}
                        disabled={disabled}
                        display="list"
                      />
                    </li>
                  )}
                </Combobox.Option>
              ) : !displayOptions?.length ? (
                <div className="flex flex-col items-center justify-center w-full py-4 text-xl space-y-4 text-gray-400">
                  <FontAwesomeIcon
                    icon={["fad", "empty-set"]}
                    className="text-2xl"
                  />
                  <div>La liste est vide</div>
                </div>
              ) : null}
            </div>
          </Combobox.Options>
        ) : null}
      </Combobox>
    </div>
  );
};

export default Select;
