import Wizard from "../components/Wizard";
import React from "react";

const SettingsInterfaceWizard = (props: any) => {
  return (
    <Wizard wizardKey="settings-interface" {...props}>
      <div className="font-bold mb-2">
        Configurez l'interface de votre projet
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
        beatae corporis deserunt eveniet fuga illum ipsam libero, magnam nemo
        nesciunt odio placeat quaerat quia quisquam, quo sunt tenetur, vero
        voluptatibus!
      </p>
    </Wizard>
  );
};

export default SettingsInterfaceWizard;
