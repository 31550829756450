import Modal, { ModalProps } from "../components/Modal";
import React, { useState } from "react";
import { GraphandModel } from "graphand-js";
import { GraphandModelConstructor } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";
import { deepEqual } from "fast-equals";
import UpdateItemModal from "./UpdateItemModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown, { DropdownPosition } from "../components/Dropdown";
import Button, { ButtonTheme } from "../components/Button";
import { useTranslation } from "react-i18next";

const PreviewItemModal: React.FunctionComponent<
  ModalProps & {
    model: GraphandModelConstructor;
    item: GraphandModel;
  }
> = (props) => {
  const { t } = useTranslation();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [_modalProps, _setModalProps] = useState<Partial<ModalProps>>({});
  const { model, item, ...__modalProps } = props;

  if (!model.previewItemComponent) {
    return null;
  }

  const PreviewItemComponent = model.previewItemComponent;

  const setModalProps: React.Dispatch<
    React.SetStateAction<Partial<ModalProps>>
  > = (data) => {
    if (typeof data === "function") {
      data = data(_modalProps);
    }

    if (!deepEqual(data, _modalProps)) {
      _setModalProps(data);
    }
  };

  const _handleDelete = async () => {
    setDeleteLoading(true);
    __modalProps.onClose(false);
    await new Promise((resolve) => setTimeout(resolve, 400));
    await item.delete();
    setDeleteLoading(false);
  };

  const modalProps = { ...__modalProps, ..._modalProps };

  modalProps.topActions = modalProps.topActions || [];

  if (!item?.isTemporary()) {
    modalProps.topActions = modalProps.topActions.concat({
      helper: t("actions.delete"),
      label: deleteLoading ? (
        <div className="h-6 w-6 flex items-center justify-center">
          <FontAwesomeIcon icon={["far", "loader"]} className="animate-spin" />
        </div>
      ) : (
        <Dropdown
          position={DropdownPosition.RIGHT}
          buttonClassName="h-6 w-6 flex items-center justify-center"
          button={<FontAwesomeIcon icon={["far", "trash"]} />}
          className="w-72 mt-3 text-base"
        >
          <div className="p-4 space-y-4">
            <div className="overflow-hidden">
              Voulez-vous vraiment supprimer{" "}
              <strong>{item.renderDefaultFieldView()}</strong> ?
            </div>
            <Button theme={ButtonTheme.default_red} onClick={_handleDelete}>
              Confirmer
            </Button>
          </div>
        </Dropdown>
      ),
      onClick: () => null,
    });
  }

  if (model.updateItemComponent && !item?.isTemporary()) {
    modalProps.topActions = modalProps.topActions.concat({
      helper: t("actions.update"),
      label: (
        <div className="h-6 w-6 flex items-center justify-center">
          <FontAwesomeIcon icon={["far", "edit"]} />
        </div>
      ),
      onClick: () => setUpdateModalOpen(true),
    });
  }

  return (
    <>
      <Modal
        title={item.renderDefaultFieldView?.({
          context: ViewComponentContext.MODAL_TITLE,
        })}
        className="max-w-screen-sm"
        {...modalProps}
      >
        <div className="p-4 lg:p-6 pt-0 lg:pt-2">
          <PreviewItemComponent
            model={model}
            item={item}
            setModalProps={setModalProps}
          />
        </div>
      </Modal>

      {model.updateItemComponent ? (
        <UpdateItemModal
          isOpen={updateModalOpen}
          onClose={setUpdateModalOpen}
          model={model}
          item={item}
        />
      ) : null}
    </>
  );
};

export default PreviewItemModal;
