import React, { Fragment, useEffect, useState } from "react";
import {
  getProjectClient,
  projectClientSubject,
  switchEnvironment,
} from "../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { getProjectMatch } from "../utils/getProjectMatch";
import { useCurrentAccount, useCurrentProject } from "../utils/hooks";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownPosition } from "./Dropdown";
import { SidebarProjectMinifiedSubject } from "./SidebarProject";
import AccountPictureView from "../fieldsViews/AccountPicture";
import { GraphandModel, GraphandModelAccount } from "graphand-js";
import PreviewItemModal from "../modals/PreviewItemModal";
import { Listbox, Transition } from "@headlessui/react";
import Tippy from "@tippyjs/react";

const NavbarProject = () => {
  const navigate = useNavigate();
  const projectMatch = getProjectMatch();
  const { project } = useCurrentProject();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [minified, setMinified] = useState(
    SidebarProjectMinifiedSubject.getValue()
  );
  const client = getProjectClient();
  const [environment, setEnvironment] = useState(
    client?._options.env || "master"
  );
  const [previewAccountOpen, setPreviewAccountOpen] = useState(false);
  const { account } = useCurrentAccount();

  useEffect(() => {
    const sub = SidebarProjectMinifiedSubject.subscribe(setMinified);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (environment !== client?._options.env) {
      switchEnvironment(environment);
    }
  }, [environment]);

  if (!client) {
    return null;
  }

  const _toggleSidebar = () => {
    SidebarProjectMinifiedSubject.next(!minified);
  };

  const Environment = client.getModel("Environment");

  return (
    <>
      <div className="h-14 sm:h-16 px-2 sm:px-4 flex items-center justify-between border-b border-gray-300 bg-background w-full">
        <div className="flex w-full">
          <Tippy
            content={t(
              minified ? "actions.expand_sidebar" : "actions.compress_sidebar"
            )}
          >
            <button
              type="button"
              onClick={_toggleSidebar}
              className={`relative h-h-14 sm:h-16 w-14 sm:w-16 origin-center -ml-2 hidden md:inline-flex items-center justify-center text-lg text-gray-500 hover:text-blue-500 transition ${
                minified ? "-rotate-180 -left-1" : ""
              }`}
            >
              <FontAwesomeIcon icon={["fad", "left-long-to-line"]} />
            </button>
          </Tippy>
          <Link
            to="/"
            className="h-14 sm:h-16 px-4 -ml-4 flex items-center justify-center flex-shrink-0 sm:hidden"
          >
            <img alt="logo" src="/images/icon.png" className="h-9" />
          </Link>
          {/*<div className="h-14 sm:h-16 flex items-center w-full">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="h-full w-full bg-transparent border-0 focus:outline-none focus:ring-0 outline-none px-4"*/}
          {/*    placeholder="Rechercher partout ..."*/}
          {/*    value={search}*/}
          {/*    onChange={({ currentTarget: { value } }) => setSearch(value)}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="flex items-center justify-center divide-x divide-gray-300">
          <div className="flex items-center space-x-6 pr-4">
            <div className="text-lg font-bold text-gray-400 hidden md:block truncate">
              {project?.name}
            </div>
            <Tippy content={t("labels.links.apps")}>
              <NavLink
                to="/apps"
                className={({ isActive }) =>
                  `h-14 sm:h-16 inline-flex items-center justify-center text-lg ${
                    isActive ? "text-primary" : ""
                  }`
                }
              >
                <FontAwesomeIcon icon={["fad", "grid-2"]} />
              </NavLink>
            </Tippy>
            {account?.isAdmin() ? (
              <Tippy content={t("labels.links.settings")}>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    `h-14 sm:h-16 inline-flex items-center justify-center text-lg ${
                      isActive ? "text-primary" : ""
                    }`
                  }
                >
                  <FontAwesomeIcon icon={["fad", "cog"]} />
                </NavLink>
              </Tippy>
            ) : null}
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="h-14 sm:h-16 inline-flex items-center justify-center text-lg"*/}
            {/*>*/}
            {/*  <FontAwesomeIcon icon={["fad", "bell"]} />*/}
            {/*</button>*/}
            {Environment.getList({}).suspense(
              (list) => {
                if (list.length < 2) {
                  return null;
                }

                return (
                  <Listbox
                    as={Fragment}
                    value={environment}
                    onChange={setEnvironment}
                  >
                    <div className="relative">
                      <Listbox.Button as={Fragment}>
                        <button type="button">
                          <div className="h-14 inline-flex flex items-center space-x-2 font-medium">
                            <FontAwesomeIcon
                              icon={["fad", "code-merge"]}
                              className="text-primary text-lg"
                            />
                            <div>{environment}</div>
                          </div>
                        </button>
                      </Listbox.Button>
                      <Transition
                        className="mt-3 z-30 w-40 origin-top-right absolute right-0 transform rounded-lg bg-white shadow-lg focus:outline-none border border-gray-200 overflow-auto"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Listbox.Options as={Fragment}>
                          <div className="p-1">
                            {list.map((e) => (
                              <Listbox.Option
                                key={e._id}
                                value={e.name}
                                as={Fragment}
                              >
                                {({ active }) => (
                                  <div
                                    className={`group w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer text-left ${
                                      active
                                        ? "bg-button text-white"
                                        : "text-gray-900"
                                    }`}
                                  >
                                    {e.name}
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </div>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                );
              },
              { subscribe: true }
            )}
          </div>
          <Dropdown
            position={DropdownPosition.RIGHT}
            button={
              <button
                type="button"
                className="h-14 sm:h-16 inline-flex items-center justify-center text-2xl pl-2 sm:pl-4"
              >
                <div className="h-10 w-10 rounded-full overflow-hidden flex items-center justify-center text-sm">
                  <AccountPictureView
                    item={account as GraphandModel}
                    options={{ size: 2 }}
                  />
                </div>
              </button>
            }
            links={[
              [
                {
                  label: t("actions.preview_account"),
                  onClick: () => setPreviewAccountOpen(true),
                },
                { label: t("actions.account_settings"), link: "/account" },
                !projectMatch
                  ? {
                      label: t("actions.back_to_organization"),
                      onClick: () => {
                        navigate("/");
                        projectClientSubject.next(null);
                      },
                    }
                  : null,
                project && !getProjectMatch()
                  ? {
                      label: (
                        <div className="inline-flex items-center space-x-2 w-full overflow-hidden">
                          <div className="inline-flex items-center flex-wrap overflow-hidden">
                            <div className="">https://</div>
                            <div className="truncate">{project.slug}</div>
                            <div className="">.graphand.com</div>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className="ml-1"
                          />
                        </div>
                      ),
                      link: `https://${project.slug}.graphand.com`,
                    }
                  : null,
              ].filter(Boolean),
              {
                label: "Déconnexion",
                onClick: () => {
                  getProjectClient()?.logout();
                },
              },
            ].filter(Boolean)}
          />
        </div>
      </div>

      {account ? (
        <PreviewItemModal
          isOpen={previewAccountOpen}
          onClose={setPreviewAccountOpen}
          model={account.constructor as typeof GraphandModelAccount}
          item={account}
        />
      ) : null}
    </>
  );
};

export default NavbarProject;
