import React from "react";
import { QueryBuilderFilterTileProps } from "./QueryBuilderFilterTile";
import { useQueryBuilderFilterTile } from "../../utils/hooks";
import { getProjectClient } from "../../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QueryBuilderFilterTileEsMapping: React.FunctionComponent<
  QueryBuilderFilterTileProps & {
    esMapping: string;
  }
> = ({ esMapping, ...props }) => {
  const EsMapping = getProjectClient()?.getModel("EsMapping");

  const { state, setState, inputRef, renderCloseButton, disabled } =
    useQueryBuilderFilterTile(props, {
      defaultState: {
        value: "",
      },
      validateChange: (state) => state.value?.length,
      proxyAssign: async (state) => {
        if (!EsMapping) {
          return (query) => query;
        }

        const m = await EsMapping.get(esMapping);
        const { hits } = await m.search({ q: state.value, size: 100 });
        const ids = hits.hits.map((r: any) => r._id);
        return (query) => {
          query._id = { $in: ids };
          return query;
        };
      },
      proxyUnassign: () => {
        return (query) => {
          delete query._id;
          return query;
        };
      },
    });

  if (!EsMapping) {
    return null;
  }

  return (
    <div className="flex items-center bg-gray-200 rounded-lg py-2 px-3 select-none text-gray-600">
      <div className="flex items-center space-x-3">
        {EsMapping.get(esMapping).suspense(
          (m) => (
            <span>{m.name}</span>
          ),
          {
            fallback: esMapping,
          }
        )}

        <div className="overflow-visible bg-white z-0 min-w-7 rounded-lg shadow-lg px-2 text-blue-500 h-7 flex items-center justify-center text-sm">
          <FontAwesomeIcon icon={["far", "search"]} />
        </div>
        <div className="relative flex items-center h-7">
          <div className="h-full min-w-[50px] w-full opacity-0 select-none px-1">
            {state.value || ""}
          </div>
          <input
            disabled={disabled}
            ref={inputRef}
            type="text"
            value={state.value}
            className="absolute inset-0 p-0 bg-transparent border-none focus:outline-none focus:shadow-none focus:ring-0"
            onChange={({ currentTarget: { value } }) =>
              setState((s) => ({ ...s, value }))
            }
          />
        </div>
      </div>
      {renderCloseButton}
    </div>
  );
};

export default QueryBuilderFilterTileEsMapping;
