import { getGlobalClient } from "./graphand";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { cloneDeep } from "lodash";
import { capitalizeFirstLetter } from "./formatter";
import { ViewComponentContext } from "./enums";
import React from "react";
import { GraphandModel, GraphandModelProject } from "graphand-js";
import {
  FilterConf,
  FilterConfType,
  FilterItem,
  SelectionStackItem,
} from "./types";
import { Translation } from "react-i18next";
import getColorForMimetype from "./getColorForMimetype";

export const sendConfirmationLink = async () => {
  const client = getGlobalClient();
  await client._axios.post("/auth/send-confirmation-link");
  alert("Un nouveau lien vous a été envoyé par email");
};

export const generateBorderDashed = ({
  dasharray,
  color,
  width,
}: {
  dasharray?: number;
  color?: string;
  width?: number;
}) => {
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${
    color ? encodeURIComponent(color) : "black"
  }' stroke-width='${width ?? 2}' stroke-dasharray='${
    dasharray ?? 10
  }' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;
};

export const copyToClipboard = (text: string) => {
  let textarea;
  try {
    // @ts-ignore
    const { clipboardData } = window;
    if (clipboardData?.setData) {
      return clipboardData.setData("Text", text);
    } else if (document.queryCommandSupported?.("copy")) {
      textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      const copied = document.execCommand?.("copy");
      if (!copied) {
        throw new Error();
      }
    } else {
      throw new Error();
    }
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex);
    return prompt("", text);
  } finally {
    if (textarea) {
      document.body.removeChild(textarea);
    }
  }
};

export const isScrolledIntoView = (el: any) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const isVisible = elemTop >= 0 && elemBottom <= el.offsetParent?.innerHeight;
  return isVisible;
};

export const isId = (input: string): boolean => {
  return (
    typeof input === "string" &&
    /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(input)
  );
};

export const getOperatorIcon = (operator: string): IconName => {
  switch (operator) {
    case "$in":
      return "diagram-venn";
    case "$nin":
      return "empty-set";
    case "$gt":
      return "greater-than";
    case "$gte":
      return "greater-than-equal";
    case "$lt":
      return "less-than";
    case "$lte":
      return "less-than-equal";
    case "$ne":
      return "not-equal";
    case "$regex":
      return "pipe";
    case "$eq":
    default:
      return "equals";
  }
};

export const getQuerySelection = (selectionStack: SelectionStackItem[]) => {
  const querySelection: any = { $or: [], $nor: undefined };

  let _selectionStack = [...selectionStack];
  _selectionStack.forEach((stackItem) => {
    let selectionStackTrue = _selectionStack.filter((s) => !s.inverse);
    let selectionStackFalse = _selectionStack.filter((s) => s.inverse);

    if (stackItem.inverse) {
      querySelection.$nor = querySelection.$nor || [];
      querySelection.$nor.push(
        cloneDeep({
          ...stackItem.query,
          $nor: selectionStackTrue.length
            ? selectionStackTrue.map((s) => s.query)
            : undefined,
        })
      );
    } else {
      querySelection.$or.push(
        cloneDeep({
          ...stackItem.query,
          $nor: selectionStackFalse.length
            ? selectionStackFalse.map((s) => s.query)
            : undefined,
        })
      );
    }

    _selectionStack = _selectionStack.filter((s) => s !== stackItem);
  });

  querySelection.$or = querySelection.$or.length
    ? querySelection.$or
    : [{ _id: { $exists: false } }];

  return querySelection;
};

export const getFiltersFromFilterConf = async (
  conf: FilterConf<any>,
  model: typeof GraphandModel,
  t: any
): Promise<FilterItem[]> => {
  if (conf.type === FilterConfType.INTELLIGENT_RELATION) {
    const _filter = conf as FilterConf<FilterConfType.INTELLIGENT_RELATION>;
    if (!_filter.field) {
      return [];
    }

    const field = model.fields[_filter.field];
    if (!field?.ref) {
      return [];
    }

    const _model = model._client.getModel(field.ref);
    const list = await _model.getList({
      query: _filter.query || {},
      pageSize: 10,
    });

    const listFilters: FilterItem[] = list.map((i) => ({
      label: (
        <div className="flex items-center justify-start w-full overflow-hidden space-x-1">
          <div>
            {capitalizeFirstLetter(
              model.fields[_filter.field]?.__dataField?.name ||
                t(`labels.fields.${_filter.field}.default`)
            )}
            &nbsp;:
          </div>
          <div className="inline-block font-bold">
            {i.renderDefaultFieldView({
              context: ViewComponentContext.LIST_ROW,
            })}
          </div>
        </div>
      ),
      query: {
        [String(_filter.field)]: field.multiple
          ? { $in: [i._id] }
          : { $eq: i._id },
      },
      icon: "wand-magic-sparkles",
    }));

    return listFilters;
  } else if (conf.type === FilterConfType.INTELLIGENT_OPTIONS) {
    const _filter = conf as FilterConf<FilterConfType.INTELLIGENT_OPTIONS>;
    if (!_filter.field) {
      return [];
    }

    const field = model.fields[_filter.field];
    if (!field?.options) {
      return [];
    }

    const listFilters: FilterItem[] = field.options.map((option: string) => ({
      label: (
        <div className="flex items-center justify-start w-full overflow-hidden space-x-1">
          <div>
            {capitalizeFirstLetter(
              model.fields[_filter.field]?.__dataField?.name ||
                t(`labels.fields.${_filter.field}.default`)
            )}
            &nbsp;:
          </div>
          <div className="inline-block font-bold">{option}</div>
        </div>
      ),
      query: {
        [String(_filter.field)]: { $eq: option },
      },
      icon: "wand-magic-sparkles",
    }));

    return listFilters;
  } else if (conf.type === FilterConfType.MEDIA_TYPE) {
    const _filter = conf as FilterConf<FilterConfType.MEDIA_TYPE>;

    return _filter.types.map((type) => {
      return {
        label: (
          <span className="font-bold">
            <Translation>
              {(t) => t(`enums.FilterMediaTypeOptions.${type}.label`)}
            </Translation>
          </span>
        ),
        color: getColorForMimetype(type),
        query: { mimetype: { $regex: type } },
        icon: "folder",
      };
    });
  } else {
    const _filter = conf as FilterConf<FilterConfType.DEFAULT>;
    return [
      {
        label: <span className="font-bold">{_filter.label}</span>,
        query: _filter.query || {},
      },
    ];
  }
};

export const oauthGlobal = () => {
  let loginWindow: any;

  const height = window.outerHeight / 1.3;
  const width = window.outerWidth / 1.7;
  const top = (window.outerHeight - height) / 2;
  const left = (window.outerWidth - width) / 2;

  return new Promise((resolve, reject) => {
    const callback = ({ data }: any) => {
      window.removeEventListener("message", callback, false);
      timer && clearInterval(timer);
      loginWindow && loginWindow.close();
      if (!data) {
        reject();
      } else {
        resolve(data);
      }
    };

    window.addEventListener("message", callback);

    loginWindow = window.open(
      `https://app.graphand.com/oauth`,
      "_blank",
      `fullscreen=no,height=${height},width=${width},top=${top},left=${left}`
    );

    const timer = setInterval(function () {
      if (loginWindow.closed) {
        reject();
        clearInterval(timer);
      }
    }, 100);
  });
};

export const oauthProject = (project: GraphandModelProject) => {
  let loginWindow: any;

  const height = window.outerHeight / 1.3;
  const width = window.outerWidth / 1.7;
  const top = (window.outerHeight - height) / 2;
  const left = (window.outerWidth - width) / 2;

  return new Promise((resolve, reject) => {
    const callback = ({ data }: any) => {
      window.removeEventListener("message", callback, false);
      timer && clearInterval(timer);
      loginWindow && loginWindow.close();
      if (!data) {
        reject();
      } else {
        resolve(data);
      }
    };

    window.addEventListener("message", callback);

    loginWindow = window.open(
      `https://${project.slug}.graphand.com/oauth`,
      "_blank",
      `fullscreen=no,height=${height},width=${width},top=${top},left=${left}`
    );

    const timer = setInterval(function () {
      if (loginWindow.closed) {
        reject();
        clearInterval(timer);
      }
    }, 100);
  });
};

const windowUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  return (e.returnValue = "Voulez-vous vraiment quitter ?");
};

export const preventWindowUnload = () => {
  window.addEventListener("beforeunload", windowUnload);
};

export const stopPreventWindowUnload = () => {
  window.removeEventListener("beforeunload", windowUnload);
};
