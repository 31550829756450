import React, { useRef } from "react";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { getProjectClient } from "../utils/graphand";
import ModelListLabelComponent from "./ModelList/LabelComponent";
import { UpdateItemComponentProps } from "../utils/types";

const ConfigureDataModelLabelConfigurationFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ fields, instance }) => {
  return (
    <>
      {fields.render("imageField", {
        query: {
          type: "Relation",
          scope: `Data:${instance.slug}`,
          "configuration.ref": "Media",
          "configuration.multiple": false,
        },
        theme: "select",
      })}
      {fields.render("titleField", {
        query: { scope: `Data:${instance.slug}` },
        theme: "select",
      })}
      {fields.render("subtitleField", {
        query: { scope: `Data:${instance.slug}` },
        theme: "select",
      })}
    </>
  );
};

const ConfigureDataModelLabelFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams & {
    autoSubmit?: boolean;
  }
> = ({ formRef, handleSubmit, fields, isLoading, instance, values }) => {
  const { t } = useTranslation();

  const { slug } = instance || {};
  const client = getProjectClient();

  if (!slug || !client) {
    return null;
  }

  const DataField = client.getModel("DataField");
  const Model = client.getModelByIdentifier(slug);
  const lastInstanceList = Model.getList({ pageSize: 1, sort: "-createdAt" });

  const fallback = ({ image, title, subtitle }: any = {}) => (
    <div className="max-w-xs mx-auto pb-4 mb-4">
      <ModelListLabelComponent
        placeholders
        image={image}
        title={title}
        subtitle={subtitle}
      />
    </div>
  );

  return (
    <>
      {DataField.getList({
        ids: [
          values.configuration?.imageField,
          values.configuration?.titleField,
          values.configuration?.subtitleField,
        ].filter(Boolean),
      }).suspense(
        (dataFields) => {
          const image = dataFields.find(
            (f) => f._id === values.configuration.imageField
          )?.slug;
          const title = dataFields.find(
            (f) => f._id === values.configuration.titleField
          )?.slug;
          const subtitle = dataFields.find(
            (f) => f._id === values.configuration.subtitleField
          )?.slug;

          return lastInstanceList.suspense((list) => {
            if (!list.length) {
              return fallback({ image, title, subtitle });
            }

            const last = list[0];

            if (!last) {
              return fallback({ image, title, subtitle });
            }

            return (
              <div className="max-w-xs mx-auto pb-4 mb-4">
                <ModelListLabelComponent
                  item={last}
                  image={image}
                  title={title}
                  subtitle={subtitle}
                  placeholders
                />
              </div>
            );
          });
        },
        { fallback: fallback() }
      )}

      <form
        ref={formRef}
        onSubmit={isLoading ? null : handleSubmit}
        className={`space-y-4 ${isLoading ? "cursor-progress" : ""}`}
      >
        {fields.render("configuration", {
          template: ConfigureDataModelLabelConfigurationFormTemplate,
        })}

        <Button type="submit">{t("actions.validate")}</Button>
      </form>
    </>
  );
};

const ConfigureDataModelLabelLabel: React.FunctionComponent<
  Omit<UpdateItemComponentProps, "setModalProps"> & {
    autoSubmit?: boolean;
  }
> = ({ model, item, onUpdate, autoSubmit }) => {
  const { t } = useTranslation();

  const _handleSubmit = async (values: any) => {
    await item.update({ set: values });
    onUpdate(item);
  };

  const { current: template } = useRef((params: GraphandFormTemplateParams) => (
    <ConfigureDataModelLabelFormTemplate
      key="ConfigureDataModelLabelFormTemplate"
      {...params}
      autoSubmit={autoSubmit}
    />
  ));

  return (
    <GraphandForm
      instance={item}
      model={model}
      onSubmit={_handleSubmit}
      template={template}
    />
  );
};

export default ConfigureDataModelLabelLabel;
