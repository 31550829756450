import React from "react";
import { ViewComponentProps } from "graphand-react";
import fileSize from "filesize";
import ViewText from "./Text";

const ViewNumber: React.FunctionComponent<ViewComponentProps> = (props) => {
  const { value, field, options } = props;
  let _display = value !== undefined ? String(value) : undefined;

  if (field.viewOptions?.type === "filesize") {
    _display = fileSize(value);
    options.title = value;
  }

  return <ViewText {...props} value={_display} rawValue={value} />;
};

export default ViewNumber;
