import { Menu, Transition } from "@headlessui/react";
import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

enum DropdownPosition {
  LEFT = "left",
  RIGHT = "right",
  RIGHT_BOTTOM = "right_bottom",
  CENTER = "center",
}

export type DropdownLink = {
  label: any;
  link?: string;
  onClick?: () => void;
  theme?: "default" | "danger";
  isActive?: boolean;
  disabled?: boolean;
  helper?: string;
};

export enum DropdownAdjustType {
  DEFAULT = "default",
  RESIZE = "resize",
}

export type DropdownLinkGroup = (DropdownLink | null)[];

const Dropdown: React.FunctionComponent<{
  position?: DropdownPosition;
  button: any;
  links?: (DropdownLink | DropdownLinkGroup | undefined | null)[];
  children?: any;
  content?: any;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  onOpen?: (isOpen: boolean) => void;
  onClose?: (isOpen: boolean) => void;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  adjustType?: DropdownAdjustType;
}> = ({
  button,
  links,
  position,
  children = null,
  className = "mt-2",
  buttonClassName = "",
  disabled,
  onOpen,
  onClose,
  content = null,
  isOpen,
  setOpen,
  adjustType = DropdownAdjustType.DEFAULT,
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const [adjustPosition, setAdjustPosition] = useState<{
    x: number;
    y: number;
    height: number | "auto";
  }>({ x: 0, y: 0, height: "auto" });

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", _refreshDropdownPosition);
    };
  }, []);

  let _className =
    "absolute z-30 w-56 transform rounded-lg bg-white shadow-lg focus:outline-none border border-gray-200 overflow-auto";

  switch (position) {
    case DropdownPosition.LEFT:
      _className += " left-0";
      break;
    case DropdownPosition.RIGHT:
    case DropdownPosition.RIGHT_BOTTOM:
      _className += " right-0";
      break;
    default:
    case DropdownPosition.CENTER:
      _className += " left-2/4 -translate-x-2/4";
      break;
  }

  switch (position) {
    case DropdownPosition.RIGHT_BOTTOM:
      _className += " bottom-14";
      break;
    default:
      _className += " top-0";
      break;
  }

  switch (position) {
    case DropdownPosition.LEFT:
      _className += " origin-top-left";
      break;
    case DropdownPosition.RIGHT:
      _className += " origin-top-right";
      break;
    case DropdownPosition.RIGHT_BOTTOM:
      _className += " origin-bottom-right";
      break;
    default:
    case DropdownPosition.CENTER:
      _className += " origin-center";
      break;
  }

  const _renderMenuItem = (item: DropdownLink) => {
    let _itemClassName = (active: boolean) => "";

    switch (item.theme) {
      case "danger":
        _itemClassName = (active: boolean) => {
          return `${
            active || item.isActive ? "bg-red-500 text-white" : "text-red-500"
          } ${
            item.disabled ? "opacity-50" : ""
          } group flex w-full items-center rounded-md px-2 py-2 text-sm text-left`;
        };
        break;
      case "default":
      default:
        _itemClassName = (active: boolean) => {
          return `${
            active || item.isActive ? "bg-button text-white" : "text-gray-900"
          } ${
            item.disabled ? "opacity-50" : ""
          } group flex w-full items-center rounded-md px-2 py-2 text-sm text-left`;
        };
        break;
    }

    return item.link ? (
      item.link.startsWith("http") ? (
        <Menu.Item disabled={item.disabled}>
          {({ active }) => (
            <a
              href={item.link}
              target="_blank"
              className={_itemClassName(active)}
              onClick={item.onClick}
              rel="noreferrer"
            >
              {item.label}
            </a>
          )}
        </Menu.Item>
      ) : (
        <Menu.Item disabled={item.disabled}>
          {({ active }) => (
            <Link
              to={item.link || "#"}
              className={_itemClassName(active)}
              onClick={item.onClick}
            >
              {item.label}
            </Link>
          )}
        </Menu.Item>
      )
    ) : (
      <Menu.Item disabled={item.disabled}>
        {({ active }) => (
          <button
            type="button"
            className={_itemClassName(active)}
            onClick={item.onClick}
          >
            {item.label}
          </button>
        )}
      </Menu.Item>
    );
  };

  const _refreshDropdownPosition = () => {
    if (!containerRef.current) {
      return;
    }

    const containerBoundingClientRect =
      containerRef.current.getBoundingClientRect();

    setAdjustPosition((prevAdjust) => {
      const adjust: any = {};

      if (adjustType === DropdownAdjustType.DEFAULT) {
        const left = containerBoundingClientRect.left - prevAdjust.x;

        const right = left + containerBoundingClientRect.width;

        const top = containerBoundingClientRect.top - prevAdjust.y;

        const bottom = top + containerBoundingClientRect.height;

        if (left < 20) {
          adjust.x = -left + 20;
        } else if (right > window.innerWidth - 20) {
          adjust.x = window.innerWidth - right - 20;
        } else if (prevAdjust.x) {
          adjust.x = 0;
        }

        if (top < 20) {
          adjust.y = -top + 20;
        } else if (bottom > window.innerHeight - 20) {
          adjust.y = window.innerHeight - bottom - 20;
        } else if (prevAdjust.y) {
          adjust.y = 0;
        }
      } else if (adjustType === DropdownAdjustType.RESIZE) {
        const top = containerBoundingClientRect.top - prevAdjust.y;

        const height = containerBoundingClientRect.height;
        const bottom = top + height;

        if (bottom > window.innerHeight - 20) {
          adjust.height = window.innerHeight - top - 20;
        } else if (adjust.height !== "auto") {
          adjust.height = "auto";
        }
      }

      if (Object.keys(adjust).length) {
        return { ...prevAdjust, ...adjust };
      }

      return prevAdjust;
    });
  };

  return (
    <Menu as={Fragment}>
      {({ open }) => {
        setOpen?.(open);
        return (
          <div
            className="relative text-left overflow-visible"
            onClick={(e) => e.stopPropagation()}
          >
            {button ? (
              <Menu.Button
                as="button"
                className={`focus:outline-none h-full overflow-visible ${buttonClassName}`}
                disabled={disabled}
              >
                {typeof button === "function" ? button(open) : button}
              </Menu.Button>
            ) : null}
            <div
              className="relative"
              style={{
                left: adjustPosition.x,
                top: adjustPosition.y,
              }}
            >
              <Transition
                show={open || isOpen}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                beforeEnter={() => {
                  _refreshDropdownPosition();
                  window.addEventListener("resize", _refreshDropdownPosition);
                  onOpen?.(true);
                }}
                afterEnter={() => {
                  _refreshDropdownPosition();
                }}
                beforeLeave={() => {
                  window.removeEventListener(
                    "resize",
                    _refreshDropdownPosition
                  );
                }}
                afterLeave={() => {
                  onClose?.(false);
                }}
              >
                <div
                  className={`${_className} ${className}`}
                  style={{ height: adjustPosition.height }}
                >
                  <Menu.Items as={Fragment}>
                    <div
                      ref={containerRef as MutableRefObject<HTMLDivElement>}
                      className="focus:outline-none"
                    >
                      {typeof children === "function"
                        ? children(open)
                        : children}

                      {links && Array.isArray(links) ? (
                        <div className="divide-y divide-gray-100">
                          {links.find((i: any) => Array.isArray(i)) ? (
                            links.map((list: any) => (
                              <div className="p-1">
                                {Array.isArray(list)
                                  ? list.map(_renderMenuItem)
                                  : _renderMenuItem(list)}
                              </div>
                            ))
                          ) : (
                            <div className="p-1">
                              {links.map(
                                (link) =>
                                  link && _renderMenuItem(link as DropdownLink)
                              )}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </div>

            {typeof content === "function" ? content(open) : content}
          </div>
        );
      }}
    </Menu>
  );
};

export { DropdownPosition };
export default Dropdown;
