import React, { useEffect, useState } from "react";
import { useAccountSettings, useCurrentAccount } from "../../../utils/hooks";
import SettingsInterfaceWizard from "../../../wizards/SettingsInterface";
import { useTranslation } from "react-i18next";
import InputBoolean from "../../../fields/inputs/Boolean";

const SettingsInterface = () => {
  const [ignored, setIgnored] = useAccountSettings("ignoredWizards", undefined);
  const { account } = useCurrentAccount();
  const [hideWizards, setHideWizards] = useAccountSettings(
    "hideWizards",
    false
  );
  const [reload, setReload] = useState(0);
  const { t } = useTranslation();
  const accountSettingsKeys = Object.keys(account?.raw.settings || {}).filter(
    (key) => account?.raw.settings[key] !== undefined
  );

  useEffect(() => {
    const sub = account?.subscribe(() => setReload((r) => r + 1));

    return () => sub?.unsubscribe();
  }, []);

  return (
    <div className="py-6 px-4 lg:p-10 w-full">
      <h1 className="text-2xl font-bold mb-6">
        🖥 {t("labels.settings.interface")}
      </h1>
      <SettingsInterfaceWizard className="mb-6" />
      <div className="space-y-4 flex flex-col items-start">
        <InputBoolean
          id="hideWizardsSwitch"
          slug="hideWizardsSwitch"
          value={hideWizards}
          onChange={setHideWizards}
          options={{ theme: "switch" }}
        />
        <button
          type="button"
          className={`text-blue-500 ${
            ignored === undefined || hideWizards
              ? "opacity-50"
              : "hover:underline"
          }`}
          onClick={() => setIgnored(undefined)}
          disabled={ignored === undefined || hideWizards}
        >
          Réinitialiser les wizards
        </button>
        <button
          type="button"
          className={`text-blue-500 ${
            !accountSettingsKeys.length ? "opacity-50" : "hover:underline"
          }`}
          onClick={() => {
            account?.update({ set: { settings: {} } }, { preStore: true });
          }}
          disabled={!accountSettingsKeys.length}
        >
          Réinitialiser les paramètres de l'interface
        </button>
      </div>
    </div>
  );
};

export default SettingsInterface;
