import React from "react";
import Modal, { ModalCloseType, ModalProps } from "../components/Modal";
import { GraphandModelConstructor } from "../utils/types";

const PageWizardsModal: React.FunctionComponent<
  ModalProps & {
    model: GraphandModelConstructor;
  }
> = ({ model, ...modalProps }) => {
  const wizards = model.wizards || {};

  return (
    <Modal
      closeType={ModalCloseType.BACK}
      title="Centre d'aide"
      className="max-w-screen-md"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <div className="space-y-2 md:space-y-6">
          {Object.keys(wizards).map((wizardKey) => {
            return React.createElement(wizards[wizardKey], {
              wizardKey,
              model,
            });
          })}
        </div>
      </div>
    </Modal>
  );
};

export default PageWizardsModal;
