import InputText from "../fields/inputs/Text";
import InputTextNumber from "../fields/inputs/Text/_number";
import InputBoolean from "../fields/inputs/Boolean";
import InputRelation from "../fields/inputs/Relation";
import { GraphandPluginReact, InputComponentProps } from "graphand-react";
import AuthClient, {
  AuthTokenStorage,
  GraphandPlugin as RxjsAuthGraphandPlugin,
} from "rxjs-auth";
import { GraphandClient, GraphandPlugin } from "graphand-js";
import { getCookie, removeCookie, setCookie } from "./cookies";
import { getProjectMatch } from "./getProjectMatch";
import {
  getGlobalClient,
  getProjectClient,
  keepConnectedSubject,
  projectClientSubject,
} from "./graphand";
import ViewText from "../fields/views/Text";
import ViewNumber from "../fields/views/Number";
import ViewBoolean from "../fields/views/Boolean";
import ViewRelation from "../fields/views/Relation";
import ViewId from "../fields/views/Id";
import { isId } from "./tools";
import ViewDate from "../fields/views/Date";
import InputJSON from "../fields/inputs/JSON";
import ViewJSON from "../fields/views/JSON";
import InputDate from "../fields/inputs/Date";
import Account from "../models/Account";
import React from "react";

const plugins: any = [];

plugins.push([
  new GraphandPluginReact({
    fields: {
      Id: {
        view: ViewId,
      },
      Text: {
        input: InputText as React.FunctionComponent<InputComponentProps>,
        view: ViewText,
      },
      Number: {
        input: InputTextNumber as React.FunctionComponent<InputComponentProps>,
        view: ViewNumber,
      },
      Boolean: {
        input: InputBoolean as React.FunctionComponent<InputComponentProps>,
        view: ViewBoolean,
      },
      Relation: {
        input: InputRelation as React.FunctionComponent<InputComponentProps>,
        view: ViewRelation,
      },
      Date: {
        input: InputDate as React.FunctionComponent<InputComponentProps>,
        view: ViewDate,
      },
      JSON: {
        input: InputJSON as React.FunctionComponent<InputComponentProps>,
        view: ViewJSON,
      },
    },
  }),
]);

plugins.push([
  new RxjsAuthGraphandPlugin({
    authClient: (client: GraphandClient) => {
      const authClient = new AuthClient<Account>("graphand", {
        fetchUser: async () => {
          const token = authClient.getAccessToken();

          try {
            if (!token) {
              throw new Error();
            }

            const User = client.getModel("User");

            if (!client._options.project) {
              return await User.getCurrent();
            }

            try {
              const Account = client.getModel("Account");
              return await Account.getCurrent(true, {
                populate: "role",
              });
            } catch (e) {
              removeCookie(`graphand:${client._options.project}:env`);
              removeCookie("graphand:project");

              console.error(
                "Une erreur est survenue, vous ne semblez pas avoir de compte sur ce projet"
              );

              if (getProjectMatch()) {
                authClient.logout();
              } else {
                projectClientSubject.next(null);
              }

              throw e;
            }
          } catch (e) {
            console.log(e);
            return null;
          }
        },
        login: async (credentials: any) => {
          const client = getProjectMatch()
            ? getProjectClient() || getGlobalClient()
            : getGlobalClient();

          try {
            await client.login(credentials);
          } catch (e) {
            console.log(e);
            throw e;
          }
        },
        refreshToken: () => client.refreshToken(),
        getAccessToken: () => client.getAccessToken(),
        getRefreshToken: () => client.getRefreshToken(),
        tokenStorage: new AuthTokenStorage({
          get: getCookie,
          set: (token: string, name: string) => {
            const keep = keepConnectedSubject.getValue();
            setCookie(name, token, keep);
          },
          del: removeCookie,
        }),
      });

      return authClient;
    },
  }),
]);

const HomePlugin = class extends GraphandPlugin<{}> {
  onInstall(): void {
    // this.client._axios.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     if (this.client._options.project) {
    //       if (error.response.status === 403) {
    //         console.log(403, error.message);
    //       } else if (error.response.status === 401) {
    //         console.log(401, error.message);
    //       }
    //     }
    //   }
    // );

    // @ts-ignore
    const lib = this.client.constructor.lib;

    const { GraphandField, GraphandModel } = lib;

    GraphandField.prototype.handleQueryBuilderInput = function (
      inputValue: any
    ) {
      switch (this.constructor.__fieldType) {
        case "Text":
          if (!isId(inputValue)) return { value: inputValue };
          break;
        case "Number":
          let val = parseFloat(inputValue);
          if (!isNaN(val) && !isId(inputValue)) return { value: inputValue };
          break;
        case "Relation":
          if (isId(inputValue)) return { value: inputValue };
          break;
      }

      return null;
    };
    GraphandModel.createItemComponent = null;

    GraphandModel.prototype.renderDefaultFieldView = function (options: any) {
      const { constructor } = Object.getPrototypeOf(this);
      return this.renderFieldView(constructor.previewField, options);
    };

    return;
  }
};

plugins.push([HomePlugin]);

export default plugins;
