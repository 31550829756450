import Modal, { ModalProps } from "../components/Modal";
import React, { useRef } from "react";
import ModelList, { ModelListWrapperRaw } from "../components/ModelList";
import { ModelListProps } from "../utils/types";

const ModelListModal: React.FunctionComponent<
  ModalProps & {
    props: ModelListProps;
  }
> = ({ props, ...modalProps }) => {
  const { current: list } = useRef<any>(
    <ModelList {...props} WrapperComponent={ModelListWrapperRaw} />
  );

  return (
    <Modal
      title="Liste des éléments"
      className="max-w-screen-lg"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">{list}</div>
    </Modal>
  );
};

export default ModelListModal;
