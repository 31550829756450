import Modal, { ModalProps } from "../components/Modal";
import React, { useState } from "react";
import { GraphandModel } from "graphand-js";
import {
  GraphandModelConstructor,
  UpdateItemComponentProps,
} from "../utils/types";
import { deepEqual } from "fast-equals";

const UpdateItemModal: React.FunctionComponent<
  ModalProps & {
    model: GraphandModelConstructor;
    item: GraphandModel;
    handleUpdate?: UpdateItemComponentProps["handleUpdate"];
  }
> = (props) => {
  const [_modalProps, _setModalProps] = useState<Partial<ModalProps>>({});
  const { model, item, handleUpdate, ...__modalProps } = props;

  if (!model.updateItemComponent) {
    return null;
  }

  const UpdateItemComponent = model.updateItemComponent;

  const setModalProps: React.Dispatch<
    React.SetStateAction<Partial<ModalProps>>
  > = (data) => {
    if (typeof data === "function") {
      data = data(_modalProps);
    }

    if (!deepEqual(data, _modalProps)) {
      _setModalProps(data);
    }
  };

  const modalProps = { ...__modalProps, ..._modalProps };

  return (
    <Modal
      title="Modifier l'élément"
      className="max-w-screen-lg"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <UpdateItemComponent
          model={model}
          item={item}
          setModalProps={setModalProps}
          handleUpdate={handleUpdate}
          onUpdate={() => modalProps.onClose(false)}
        />
      </div>
    </Modal>
  );
};

export default UpdateItemModal;
