import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../../utils/enums";
import ViewText from "./Text";
import { useTranslation } from "react-i18next";

const ViewBoolean: React.FunctionComponent<ViewComponentProps> = (props) => {
  const { t } = useTranslation();
  const { value, options, field, slug } = props;

  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  const displayValue = value ? t("labels.other.yes") : t("labels.other.no");

  switch (options?.context) {
    case ViewComponentContext.LIST_LABEL_TITLE:
    case ViewComponentContext.LIST_LABEL_SUBTITLE:
    case ViewComponentContext.GRID_LABEL_SUBTITLE:
    case ViewComponentContext.GRID_LABEL_SUBTITLE:
      return <ViewText {...props} value={`${label}: ${displayValue}`} />;
  }

  return <ViewText {...props} value={displayValue} rawValue={value} />;
};

export default ViewBoolean;
