import { IconName } from "@fortawesome/fontawesome-svg-core";

const getIconForMimetype = (mimetype: string): IconName => {
  if (mimetype.includes("image")) {
    return "file-image";
  } else if (mimetype.includes("video")) {
    return "file-video";
  } else if (mimetype.includes("pdf")) {
    return "file-pdf";
  } else if (mimetype.includes("zip")) {
    return "file-zipper";
  }

  return "file";
};

export default getIconForMimetype;
