import React, { useEffect, useState } from "react";
// @ts-ignore
import { Resizable } from "react-resizable";
import Button, { ButtonTheme } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deepEqual } from "fast-equals";
import SelectItemsModal from "../../modals/SelectItemsModal";
import { getProjectClient } from "../../utils/graphand";
import Tippy from "@tippyjs/react";

const InputRteBlockImage: React.FunctionComponent<{
  entity: any;
  contentState: any;
  entityKey: string;
  elementProps: any;
}> = ({ entity, entityKey, contentState, elementProps }) => {
  const { data } = entity;
  const client = getProjectClient();
  const Media = client?.getModel("Media");
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const [mediaId, setMediaId] = useState(data.media?._id);
  const [fit, setFit] = useState<"cover" | "contain">(data.fit ?? "cover");
  const [dimensions, setDimensions] = useState({
    width: data.dimensions?.width ?? 150,
    height: data.dimensions?.height ?? 150,
  });

  const _getUrl = () => {
    const _dimensions =
      dimensions.width > dimensions.height
        ? { w: dimensions.width }
        : { h: dimensions.height };

    return Media?.get(mediaId).getUrl({
      ..._dimensions,
    });
  };

  const [url, setUrl] = useState(_getUrl());

  useEffect(() => {
    if (data.dimensions && !deepEqual(data.dimensions, dimensions)) {
      setDimensions({ ...data.dimensions });
    }
  }, [JSON.stringify(data.dimensions)]);

  useEffect(() => {
    if (data.media?._id !== mediaId) {
      setMediaId(data.media?._id);
    }
  }, [data.media?._id]);

  useEffect(() => {
    if (data.fit !== fit) {
      setFit(data.fit);
    }
  }, [data.fit]);

  useEffect(() => {
    if (dimensions && !deepEqual(data.dimensions, dimensions)) {
      contentState.mergeEntityData(entityKey, { dimensions });
    }
  }, [JSON.stringify(dimensions)]);

  useEffect(() => {
    if (fit && !deepEqual(data.fit, fit)) {
      contentState.mergeEntityData(entityKey, { fit });
    }
  }, [fit]);

  useEffect(() => {
    if (mediaId && data.media?._id !== mediaId && Media) {
      (async () => {
        const media = await Media.get(mediaId);
        if (media?._id === mediaId) {
          contentState.mergeEntityData(entityKey, { media });
          _refreshUrl();
        }
      })();
    }
  }, [mediaId]);

  const _refreshUrl = () => {
    setUrl(_getUrl());
  };

  const _onResize = (e: any, { size }: any) => {
    setDimensions(size);
  };

  const _toggleFit = () => {
    setFit((f) => (f === "contain" ? "cover" : "contain"));
  };

  const isFocused = elementProps?.className === "f1vn2c6d";

  return (
    <>
      <div className="group inline-block relative">
        <div
          className={`absolute z-30 top-0 left-0 max-w-full overflow-hidden bg-gray-300 bg-opacity-50 py-0.5 px-1 text-xs opacity-0 flex items-center space-x-1 select-none ${
            isFocused ? "opacity-100" : "group-hover:opacity-100"
          }`}
        >
          <Tippy content="Toggle fit / contain">
            <div
              onClick={_toggleFit}
              className="cursor-pointer text-gray-500 hover:text-primary"
            >
              <FontAwesomeIcon
                icon={["far", fit === "contain" ? "maximize" : "minimize"]}
              />
            </div>
          </Tippy>
          <Tippy content="Modifier l'image">
            <div
              onClick={() => setSwitchModalOpen(true)}
              className="cursor-pointer text-gray-500 hover:text-primary"
            >
              <FontAwesomeIcon icon={["far", "rotate"]} />
            </div>
          </Tippy>
          <div>
            {dimensions.height}x{dimensions.width}
          </div>
        </div>

        <Resizable
          height={dimensions.height}
          width={dimensions.width}
          minConstraints={[50, 50]}
          onResize={_onResize}
          resizeHandles={["s", "e", "se"]}
          handle={(handleAxis: any, ref: any) => {
            return (
              <div
                ref={ref}
                onClick={() => {
                  setTimeout(_refreshUrl);
                }}
                className={`absolute z-30 ${
                  handleAxis === "s"
                    ? "bottom-0 left-1/2 -translate-x-1/2"
                    : handleAxis === "e"
                    ? "top-1/2 -translate-y-1/2 right-0"
                    : "bottom-0 right-0"
                } cursor-pointer select-none flex items-end justify-end transform opacity-0 ${
                  isFocused ? "opacity-100" : "group-hover:opacity-100"
                }`}
              >
                <Button
                  theme={ButtonTheme.light}
                  className="w-5 h-5 px-0 select-none rounded-none flex-shrink-0 overflow-hidden"
                >
                  <FontAwesomeIcon
                    icon={[
                      "far",
                      handleAxis === "s"
                        ? "grip-lines"
                        : handleAxis === "e"
                        ? "grip-lines-vertical"
                        : "grip-dots",
                    ]}
                    className="text-sm text-gray-500"
                  />
                </Button>
              </div>
            );
          }}
        >
          <div
            className={`relative ring-2 ring-transparent ${
              isFocused ? "ring-blue-500" : "group-hover:ring-blue-300"
            }`}
            style={{ width: dimensions.width, height: dimensions.height }}
          >
            <div
              className={`absolute inset-0 bg-grid-texture z-10 opacity-0 transition ${
                isFocused ? "opacity-30" : "group-hover:opacity-30"
              }`}
            />
            <img
              src={url}
              width={dimensions.width}
              height={dimensions.height}
              className={`w-full h-full m-0 relative z-20 ${
                fit === "contain" ? "object-contain" : "object-cover"
              }`}
            />
          </div>
        </Resizable>
      </div>

      {Media ? (
        <SelectItemsModal
          isOpen={switchModalOpen}
          onClose={setSwitchModalOpen}
          value={null}
          multiple={false}
          autoSave
          query={{ mimetype: { $regex: "image" } }}
          onChange={(id) => {
            setMediaId(id);
            setSwitchModalOpen(false);
          }}
          model={Media}
        />
      ) : null}
    </>
  );
};

export default InputRteBlockImage;
