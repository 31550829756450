import React, { useState } from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { json } from "@codemirror/lang-json";
import CodeMirror from "@uiw/react-codemirror";
import Button, { ButtonTheme } from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSONEditorModal from "../../modals/JSONEditorModal";
import FieldViewContainer from "../../components/FieldViewContainer";

const ViewJSON: React.FunctionComponent<ViewComponentProps> = (props) => {
  const { value, options, slug, field } = props;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const _modal = (
    <JSONEditorModal
      isOpen={modalOpen}
      onClose={setModalOpen}
      codeMirrorProps={{
        value: JSON.stringify(value, null, 2),
        readOnly: true,
      }}
    >
      <Button
        theme={ButtonTheme.inline}
        className="w-9 h-9"
        onClick={() => setModalOpen((o) => !o)}
      >
        <FontAwesomeIcon icon={["far", modalOpen ? "compress" : "expand"]} />
      </Button>
    </JSONEditorModal>
  );

  switch (options.context) {
    case ViewComponentContext.PREVIEW_LINE:
      if (!value || !Object.keys(value).length) {
        return null;
      }

      let label = "label" in options ? options.label : field?.__dataField?.name;
      if (label === undefined) {
        label = t(`labels.fields.${slug}.default`);
      }

      let content = (
        <div
          title={options.title}
          className={`w-full relative`}
          onClick={options.onClick}
        >
          <Button
            theme={ButtonTheme.light}
            className="w-9 h-9 absolute right-1.5 top-1.5 z-10"
            onClick={() => setModalOpen((o) => !o)}
          >
            <FontAwesomeIcon
              icon={["far", modalOpen ? "compress" : "expand"]}
            />
          </Button>

          <CodeMirror
            readOnly
            className="w-full max-h-56 overflow-hidden select-none"
            value={JSON.stringify(value, null, 2)}
            height="auto"
            extensions={[json()]}
          />

          {_modal}
        </div>
      );

      return (
        <FieldViewContainer
          {...props}
          label={label}
          rawValue={JSON.stringify(value)}
        >
          {content}
        </FieldViewContainer>
      );
    case ViewComponentContext.LIST_LABEL_SUBTITLE:
      return (
        <>
          <Button
            theme={ButtonTheme.inline}
            className="w-8 h-8 px-0"
            onClick={() => setModalOpen((o) => !o)}
          >
            <FontAwesomeIcon
              icon={["far", "brackets-curly"]}
              className="text-gray-500 text-sm"
            />
          </Button>

          {_modal}
        </>
      );
    default:
      break;
  }

  return (
    <>
      <Button
        theme={ButtonTheme.light}
        className="w-11"
        onClick={() => setModalOpen((o) => !o)}
      >
        <FontAwesomeIcon icon={["far", "brackets-curly"]} />
      </Button>

      {_modal}
    </>
  );
};

export default ViewJSON;
