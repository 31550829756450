import Wizard from "../components/Wizard";
import React from "react";
import { ModelPageWizardProps } from "../pages/project/ModelPage";
import { useTranslation } from "react-i18next";
import { useAccountSettings, useCurrentProject } from "../utils/hooks";
import { getProjectClient } from "../utils/graphand";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";

const SettingsGeneralConfigurationTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ fields }) => {
  return <>{fields.render("accountPictureField", { theme: "select" })}</>;
};

const SelectAccountPictureFieldWizard = (props: ModelPageWizardProps) => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const { project } = useCurrentProject();
  const [ignored, setIgnored] = useAccountSettings<string[]>(
    "ignoredWizards",
    []
  );

  if (!project || !client) {
    return null;
  }

  const _handleChange = async (values: any) => {
    project.update({
      set: {
        "configuration.accountPictureField":
          values.configuration?.accountPictureField,
      },
    });
  };

  return (
    <Wizard
      wizardKey={props.wizardKey ?? "selectAccountPictureField"}
      condition={!project.configuration.accountPictureField}
      ContainerComponent={props.ContainerComponent}
    >
      <div className="font-bold mb-2">
        Sélectionnez un champ d'image pour les comptes
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
        beatae corporis deserunt eveniet fuga illum ipsam libero, magnam nemo
        nesciunt odio placeat quaerat quia quisquam, quo sunt tenetur, vero
        voluptatibus!
      </p>

      <GraphandForm
        instance={project}
        resetAfterSubmit={false}
        values={{}}
        onChange={_handleChange}
      >
        {({ formRef, handleSubmit, fields, isLoading }) => {
          return (
            <form
              ref={formRef}
              onSubmit={isLoading ? null : handleSubmit}
              className={`px-4 max-w-sm mx-auto mt-4 ${
                isLoading ? "cursor-progress" : ""
              }`}
            >
              {fields.render("configuration", {
                template: SettingsGeneralConfigurationTemplate,
                autofocus: false,
              })}
            </form>
          );
        }}
      </GraphandForm>
    </Wizard>
  );
};

export default SelectAccountPictureFieldWizard;
