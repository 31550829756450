import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

const ModelGridEmptyComponent: React.FunctionComponent<{
  onMount?: any;
}> = ({ onMount }) => {
  useEffect(() => {
    onMount?.();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-80 text-2xl space-y-4 text-gray-400">
      <FontAwesomeIcon icon={["fad", "empty-set"]} className="text-5xl" />
      <div>La liste est vide</div>
    </div>
  );
};

export default ModelGridEmptyComponent;
