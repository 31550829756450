import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown, { DropdownAdjustType, DropdownPosition } from "../Dropdown";
import Button, { ButtonTheme } from "../Button";
import QueryBuilder from "../QueryBuilder";
import { useTranslation } from "react-i18next";
import CreateItemModal from "../../modals/CreateItemModal";
import { capitalizeFirstLetter } from "../../utils/formatter";
import { ModelListProps } from "../../utils/types";

const PageComponentQueryBuilder: React.FunctionComponent<{
  actions: ModelListProps["globalActions"];
  model: ModelListProps["model"];
  createProps?: ModelListProps["createProps"];
  onCreate?: ModelListProps["onCreate"];
  reloadQueryBuilder?: ModelListProps["reloadQueryBuilder"];
  setQuery: (query: any) => void;
  controls?: any;
  sort?: string;
  setSort?: (sort: string) => void;
}> = ({
  actions,
  model,
  reloadQueryBuilder,
  setQuery,
  createProps,
  controls = null,
  onCreate,
  sort,
  setSort,
}) => {
  const [displayCreateItemModal, setDisplayCreateItemModal] = useState(false);
  const { t } = useTranslation();

  const InputContainerComponent = ({ children }: any) => {
    const [dropdownSortOpen, setDropdownSortOpen] = useState(false);

    return (
      <div className="flex space-x-1 sm:space-x-1.5 lg:space-x-2 relative z-10 mt-2 sm:mt-0">
        <div className="w-full">{children}</div>
        <Combobox.Button as={React.Fragment}>
          {({ open }) => (
            <button
              type="button"
              className="border border-gray-200 flex-grow rounded-xl flex-shrink-0 border bg-white px-4 flex items-center truncate hover:bg-gray-100"
            >
              <FontAwesomeIcon
                icon={["far", "filter"]}
                className={`sm:hidden ${open ? "text-primary" : ""}`}
              />
              <span className="mr-1.5 hidden sm:inline-block">
                {t("actions.filter")}
              </span>
              <FontAwesomeIcon
                icon={["far", "angle-down"]}
                className={`transition hidden sm:inline-block ${
                  open ? "rotate-180" : ""
                }`}
              />
            </button>
          )}
        </Combobox.Button>

        {sort && setSort ? (
          <Dropdown
            adjustType={DropdownAdjustType.RESIZE}
            buttonClassName="flex flex-col"
            isOpen={dropdownSortOpen}
            setOpen={setDropdownSortOpen}
            position={DropdownPosition.RIGHT}
            button={
              <Button
                tooltip="Trier par"
                theme={ButtonTheme.light}
                className="h-full flex-grow w-12 flex-shrink-0"
              >
                <FontAwesomeIcon icon={["far", "sort"]} />
              </Button>
            }
          >
            <div className="px-1 py-1">
              <h2 className="text-sm p-2 text-gray-500" role="none">
                Trier par
              </h2>
              {["_id"]
                .concat(
                  Object.keys(model.fields).filter((f) => !f.startsWith("_"))
                )
                .map((f) => {
                  let sortSlug = f;

                  const match = sort.match(new RegExp(`(\-)?${sortSlug}`));

                  let isSorted: boolean = false;
                  let inverted: boolean = false;

                  if (match) {
                    const { 1: _inverted } = match;
                    isSorted = true;
                    inverted = !_inverted;
                  }

                  const _handleClick = (e: any) => {
                    e?.preventDefault();
                    e?.stopPropagation();

                    if (!isSorted) {
                      setSort("-" + sortSlug);
                    } else if (!inverted) {
                      setSort(sortSlug);
                    } else {
                      setSort("-_id");
                    }
                  };

                  return (
                    <div
                      className={`cursor-pointer text-gray-900 group flex w-full items-center rounded-md px-2 py-1 text-sm text-left hover:bg-button hover:text-white`}
                      onClick={_handleClick}
                    >
                      <div className="w-full truncate text-sm">
                        {capitalizeFirstLetter(
                          model.fields[f]?.__dataField?.name ||
                            t(`labels.fields.${f}.default`)
                        )}
                      </div>
                      <div
                        className={`h-5 w-5 flex items-center justify-center text-sm transition-transform ml-1 ${
                          inverted ? "rotate-90" : "-rotate-90"
                        } ${isSorted ? "opacity-100" : "opacity-0"}`}
                      >
                        <FontAwesomeIcon icon={["far", "arrow-right"]} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Dropdown>
        ) : null}

        <Dropdown
          position={DropdownPosition.RIGHT}
          buttonClassName="flex flex-col"
          button={
            <Button
              tooltip="Options"
              theme={ButtonTheme.light}
              className="h-full flex-grow w-12 flex-shrink-0"
            >
              <FontAwesomeIcon icon={["far", "colon"]} className="rotate-90" />
            </Button>
          }
          links={actions?.filter(Boolean)}
        />
        {model.createItemComponent ? (
          <Button
            tooltip="Nouveau"
            onClick={() => setDisplayCreateItemModal(true)}
            className="h-auto w-12 flex-shrink-0"
          >
            <FontAwesomeIcon icon={["far", "plus"]} />
          </Button>
        ) : null}
        {controls}
      </div>
    );
  };

  return (
    <>
      <QueryBuilder
        key="QueryBuilder"
        InputContainerComponent={InputContainerComponent}
        model={model}
        reload={reloadQueryBuilder}
        onChange={setQuery}
      />

      {model.createItemComponent ? (
        <CreateItemModal
          isOpen={displayCreateItemModal}
          onClose={setDisplayCreateItemModal}
          props={createProps}
          onCreate={onCreate}
          model={model}
        />
      ) : null}
    </>
  );
};

export default PageComponentQueryBuilder;
