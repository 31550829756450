import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddFilterModal, { FilterModalType } from "../../modals/AddFilterModal";
import {
  useAccountSettings,
  useCurrentAccount,
  useModelConfiguration,
  useModelFilters,
  useSelectionStack,
} from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { parseInt } from "lodash";
import { Transition } from "@headlessui/react";
import Button, { ButtonTheme } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwitchList from "../SwitchList";
import { faGrid, faList } from "@fortawesome/pro-duotone-svg-icons";
import MenuList from "../MenuList";
import ModelList from "../ModelList";
import ModelGrid from "../ModelGrid";
import PageWizardsModal from "../../modals/PageWizardsModal";
import ManageFiltersModal from "../../modals/ManageFiltersModal";
import SelectionControls from "../SelectionControls";
import {
  FilterConf,
  FilterItem,
  ModelListProps,
  PageComponentProps,
} from "../../utils/types";
import Container from "../Container";
import PageComponentMultipleQueryBuilder from "./QueryBuilder";
import ModelListModal from "../../modals/ModelListModal";
import { getProjectClient } from "../../utils/graphand";
import { DisplayMode } from "../../utils/enums";
import { GraphandModelDataModel } from "graphand-js";
import ConfigureDataModelModal from "../../modals/ConfigureDataModelModal";
import PreviewItemModal from "../../modals/PreviewItemModal";
import Tippy from "@tippyjs/react";
import ModelHistoryModal from "../../modals/ModelHistoryModal";

const WrapperComponent = ({ children }: any) => children;

const usePageComponentMultiple = function (props: PageComponentProps) {
  const { model, defaultMode = DisplayMode.LIST } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const scope = String(model.scope);
  const location = useLocation();
  const { hash } = location;
  const { account } = useCurrentAccount();
  const [displayMode, setDisplayMode] = useAccountSettings<string>(
    `displayMode:${scope}`,
    defaultMode
  );
  const [wizardsOpen, setWizardsOpen] = useState(false);
  const [configureModelOpen, setConfigureModelOpen] = useState(false);
  const [dataFieldsOpen, setDataFieldsOpen] = useState(false);
  const [historyModelOpen, setHistoryModelOpen] = useState(false);
  const [previewModelOpen, setPreviewModelOpen] = useState(false);
  const [selectFieldsModal, setSelectFieldsModal] = useState(false);
  const [displayAddFilterModal, setDisplayAddFilterModal] = useState<
    boolean | FilterModalType
  >(false);
  const [ignoredWizards] = useAccountSettings<string[] | undefined>(
    "ignoredWizards",
    undefined
  );
  const [sort, setSort] = useAccountSettings<string>(`sort:${scope}`, "-_id");
  const [displayManageFiltersModal, setDisplayManageFiltersModal] =
    useState<boolean>(false);
  const [query, setQuery] = useState({});
  const [reloadQueryBuilder, setReloadQueryBuilder] = useState(0);
  const [pinnedModels, setPinnedModels] = useAccountSettings<string[]>(
    "pinnedModels",
    ["Account", "Media"]
  );

  const client = getProjectClient();

  let dataModel: GraphandModelDataModel | undefined;

  if (scope === "Account") {
    dataModel = client?.getModel("DataModel").get("_dataModelAccount");
  } else if (scope === "Media") {
    dataModel = client?.getModel("DataModel").get("_dataModelMedia");
  } else {
    dataModel = model.__dataModel;
  }

  const configuration = useModelConfiguration(dataModel);
  const {
    filters,
    loading: filtersLoading,
    addFilter,
    setFilters,
  } = useModelFilters(dataModel);

  const [displayFilters, setDisplayFilters] = useAccountSettings<boolean>(
    `displayFilters:${scope}`,
    Boolean(filters.length)
  );

  const filterActiveIndex = hash ? parseInt(hash.replace("#f", "")) : -1;
  let filter: FilterItem | undefined;

  if (!isNaN(filterActiveIndex) && filterActiveIndex > -1) {
    filter = filters[filterActiveIndex];
  }

  const { selectionStack, setSelectionStack, querySelection } =
    useSelectionStack(model, filter);

  const _handleFilterConfAdd = async (conf: FilterConf<any>) => {
    await addFilter(conf);
    setDisplayAddFilterModal(false);
  };

  const _handleTogglePinned = async () => {
    const pinned = new Set(pinnedModels);
    if (pinned.has(scope)) {
      pinned.delete(scope);
    } else {
      pinned.add(scope);
    }

    setPinnedModels(Array.from(pinned));
  };

  return {
    t,
    navigate,
    scope,
    location,
    account,
    displayMode,
    setDisplayMode,
    wizardsOpen,
    setWizardsOpen,
    dataFieldsOpen,
    setDataFieldsOpen,
    selectFieldsModal,
    setSelectFieldsModal,
    displayAddFilterModal,
    setDisplayAddFilterModal,
    ignoredWizards,
    sort,
    setSort,
    displayManageFiltersModal,
    setDisplayManageFiltersModal,
    query,
    setQuery,
    reloadQueryBuilder,
    setReloadQueryBuilder,
    pinnedModels,
    setPinnedModels,
    client,
    configuration,
    filters,
    filtersLoading,
    displayFilters,
    setDisplayFilters,
    selectionStack,
    setSelectionStack,
    querySelection,
    _handleFilterConfAdd,
    _handleTogglePinned,
    dataModel,
    filter,
    hash,
    addFilter,
    setFilters,
    configureModelOpen,
    setConfigureModelOpen,
    previewModelOpen,
    setPreviewModelOpen,
    historyModelOpen,
    setHistoryModelOpen,
  };
};

const PageComponentMultiple: React.FunctionComponent<PageComponentProps> = (
  props
) => {
  const {
    t,
    navigate,
    scope,
    account,
    displayMode,
    setDisplayMode,
    wizardsOpen,
    setWizardsOpen,
    dataFieldsOpen,
    setDataFieldsOpen,
    selectFieldsModal,
    setSelectFieldsModal,
    displayAddFilterModal,
    setDisplayAddFilterModal,
    ignoredWizards,
    sort,
    setSort,
    displayManageFiltersModal,
    setDisplayManageFiltersModal,
    query,
    setQuery,
    reloadQueryBuilder,
    pinnedModels,
    client,
    configuration,
    filters,
    filtersLoading,
    displayFilters,
    setDisplayFilters,
    selectionStack,
    setSelectionStack,
    querySelection,
    _handleFilterConfAdd,
    _handleTogglePinned,
    dataModel,
    filter,
    hash,
    configureModelOpen,
    setConfigureModelOpen,
    previewModelOpen,
    setPreviewModelOpen,
    historyModelOpen,
    setHistoryModelOpen,
  } = usePageComponentMultiple(props);

  const {
    model,
    children = null,
    globalActions = [],
    listActions,
    inlineActions,
  } = props;

  if (!client || !dataModel) {
    return null;
  }

  const [DataField, DataModel] = client.getModels(["DataField", "DataModel"]);

  // const wizardsKeys = Object.keys(constructor.wizards).filter(
  //   (key) => !ignoredWizards?.includes(key)
  // );

  const ListComponent: React.FunctionComponent<ModelListProps> =
    displayMode === DisplayMode.LIST ? ModelList : ModelGrid;

  return (
    <section className="py-4 sm:py-6 px-0 sm:px-2 lg:px-4 max-w-screen-2xl mx-auto space-y-0 sm:space-y-3 lg:space-y-4">
      <h1 className="flex items-center justify-between px-3 sm:px-0">
        <Transition.Child
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-100 -translate-y-4"
        >
          <div className="text-2xl sm:text-3xl font-bold w-full flex items-center truncate space-x-3">
            <div>{dataModel?.renderFieldView?.("_icon", { theme: "fad" })}</div>
            <div className="w-full truncate flex items-center">
              {dataModel?.renderFieldView?.("name")}
            </div>
            {filter && !displayFilters ? (
              <div className="flex items-center text-base text-gray-500">
                ({filter.label})
              </div>
            ) : null}
          </div>
        </Transition.Child>

        <div className="flex items-center divide-x divide-gray-300 -mx-2 lg:-mx-4">
          <div className="flex items-center px-2 sm:px-3 lg:px-4 space-x-1 sm:space-x-2">
            <Button
              tooltip={t(
                pinnedModels.includes(scope)
                  ? "actions.unpin_model"
                  : "actions.pin_model"
              )}
              theme={ButtonTheme.inline}
              onClick={() => _handleTogglePinned()}
              className={`${
                pinnedModels.includes(scope) ? "text-primary" : ""
              } hidden sm:flex`}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "thumbtack"]} />
              </div>
            </Button>
            {dataModel?.isTemporary() ? null : (
              <Button
                labelCount={dataModel?.isConfigured ? 0 : 1}
                tooltip={t("actions.configure_model")}
                theme={ButtonTheme.inline}
                onClick={() => setConfigureModelOpen(true)}
                className={`${configureModelOpen ? "text-primary" : ""}`}
              >
                <div className="h-4 w-4 flex items-center justify-center">
                  <FontAwesomeIcon icon={["fad", "screwdriver-wrench"]} />
                </div>
              </Button>
            )}
            <Button
              tooltip={t("actions.history_model")}
              theme={ButtonTheme.inline}
              onClick={() => setHistoryModelOpen(true)}
              className={`${historyModelOpen ? "text-primary" : ""}`}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "history"]} />
              </div>
            </Button>
            <Button
              tooltip="Centre d'aide"
              theme={ButtonTheme.inline}
              onClick={() => setWizardsOpen(true)}
              className={wizardsOpen ? "text-primary" : ""}
            >
              <div className="h-4 w-4 flex items-center justify-center">
                <FontAwesomeIcon icon={["fad", "info-circle"]} />
              </div>
            </Button>
          </div>
          <div className="px-2 sm:px-3 lg:px-4">
            <SwitchList
              activeKey={displayMode}
              list={[
                {
                  key: DisplayMode.GRID as string,
                  label: ({ isActive }: { isActive: boolean }) => (
                    <Tippy content="Vue grille" placement="bottom">
                      <div className="h-9 w-11 text-lg flex items-center justify-center">
                        <FontAwesomeIcon
                          className={`transition-colors ${
                            isActive ? "text-primary" : ""
                          }`}
                          icon={faGrid}
                        />
                      </div>
                    </Tippy>
                  ),
                },
                {
                  key: DisplayMode.LIST as string,
                  label: ({ isActive }: { isActive: boolean }) => (
                    <Tippy content="Vue liste" placement="bottom">
                      <div className="h-9 w-11 text-lg flex items-center justify-center">
                        <FontAwesomeIcon
                          className={`transition-colors ${
                            isActive ? "text-primary" : ""
                          }`}
                          icon={faList}
                        />
                      </div>
                    </Tippy>
                  ),
                },
              ]}
              onClick={(item) => {
                if (item.key && typeof item.key === "string") {
                  setDisplayMode(item.key);
                }
              }}
            />
          </div>
        </div>
      </h1>

      <Transition.Child
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-100 -translate-y-6"
      >
        {filtersLoading || displayFilters ? (
          <div className="relative z-20 w-full overflow-auto no-scrollbar border-b border-gray-200 sm:-mt-3 lg:-mt-4 px-3 sm:px-0">
            <MenuList
              activeKey={hash ? hash.replace("#", "") : "-1"}
              ContainerComponent={({ children }) => (
                <ul className="relative flex items-center space-x-4">
                  {children}
                </ul>
              )}
              onClick={(item) =>
                item.key === "-1" ? navigate("") : navigate(`#${item.key}`)
              }
              list={[
                {
                  key: "-1",
                  label: (
                    <span className="inline-flex flex items-center font-bold">
                      <FontAwesomeIcon
                        icon={["fad", "list"]}
                        className="mr-2 text-sm"
                      />
                      Liste
                    </span>
                  ),
                },
              ].concat(
                filters.map((filter: FilterItem, index: number) => ({
                  key: `f${String(index)}`,
                  label: (
                    <li className="inline-flex flex items-center">
                      <FontAwesomeIcon
                        style={{ color: filter.color || "currentColor" }}
                        icon={["fad", filter.icon || "filters"]}
                        className="mr-2 text-sm"
                      />
                      {filter.label}
                    </li>
                  ),
                }))
              )}
            />
          </div>
        ) : (
          <div className="mb-2 sm:mb-0" />
        )}
      </Transition.Child>

      <Transition.Child
        className="pb-6"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-8"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-100 -translate-y-8"
      >
        <Container contentClassName="p-2 sm:p-4 lg:p-6 rounded-none sm:rounded-xl lg:rounded-lg">
          <PageComponentMultipleQueryBuilder
            key="PageComponentMultipleQueryBuilder"
            actions={globalActions
              .concat([
                ...(displayMode === DisplayMode.LIST
                  ? [
                      {
                        label: t("actions.select_fields"),
                        onClick: () => setSelectFieldsModal(true),
                      },
                    ]
                  : [
                      {
                        label: t("actions.select_all"),
                        onClick: () => {
                          setSelectionStack((s) =>
                            s.concat({ query, inverse: false })
                          );
                        },
                      },
                      {
                        label: t("actions.select_none"),
                        onClick: () => {
                          setSelectionStack((s) =>
                            s.concat({ query, inverse: true })
                          );
                        },
                      },
                    ]),
                account?.isAdmin()
                  ? {
                      label: t("actions.preview_dataModel"),
                      onClick: () => setPreviewModelOpen(true),
                    }
                  : null,
                {
                  label: t("actions.manage_filters"),
                  onClick: () => setDisplayManageFiltersModal?.(true),
                  disabled: !filters?.length,
                },
                {
                  label: t("actions.add_filter"),
                  onClick: () => setDisplayAddFilterModal?.(true),
                },
                {
                  label: displayFilters
                    ? t("actions.hide_filters")
                    : t("actions.display_filters"),
                  onClick: () => setDisplayFilters(!displayFilters),
                },
              ])
              .filter(Boolean)}
            model={model}
            reloadQueryBuilder={reloadQueryBuilder}
            setQuery={setQuery}
            sort={sort}
            setSort={setSort}
          />

          <ListComponent
            model={model}
            filter={filter}
            subquery={query}
            reloadQueryBuilder={reloadQueryBuilder}
            defaultListFields={model.listFields}
            selectionStack={selectionStack}
            listActions={listActions}
            inlineActions={inlineActions}
            setSelectionStack={setSelectionStack}
            WrapperComponent={WrapperComponent}
            selectFieldsModal={selectFieldsModal}
            setSelectFieldsModal={setSelectFieldsModal}
            sort={sort}
            setSort={setSort}
            labelField={{
              image: configuration.imageField,
              title: configuration.titleField,
              subtitle: configuration.subtitleField,
            }}
          />
        </Container>
      </Transition.Child>

      {children}

      <ModelListModal
        isOpen={dataFieldsOpen}
        onClose={setDataFieldsOpen}
        props={{
          model: DataField,
          filter: { query: { scope: model.scope } },
          handleItemClick: "update",
          createProps: {
            query: { scope: model.scope },
            dataModel,
          },
        }}
      />

      <PreviewItemModal
        isOpen={previewModelOpen}
        onClose={setPreviewModelOpen}
        model={DataModel}
        item={dataModel}
      />

      <ConfigureDataModelModal
        dataModel={dataModel}
        isOpen={configureModelOpen}
        onClose={setConfigureModelOpen}
      />

      <PageWizardsModal
        isOpen={wizardsOpen}
        onClose={setWizardsOpen}
        model={model}
      />

      <AddFilterModal
        model={model}
        isOpen={displayAddFilterModal}
        onClose={setDisplayAddFilterModal}
        filter={filter}
        onSave={_handleFilterConfAdd}
        selectionStack={selectionStack}
      />

      <ManageFiltersModal
        isOpen={displayManageFiltersModal}
        onClose={setDisplayManageFiltersModal}
        dataModel={dataModel}
        model={model}
        handleAdd={() => setDisplayAddFilterModal(true)}
      />

      <ModelHistoryModal
        isOpen={historyModelOpen}
        onClose={setHistoryModelOpen}
        model={model.HistoryModel}
      />

      <SelectionControls
        model={model}
        query={querySelection}
        selectionStack={selectionStack}
        show={!displayAddFilterModal}
        setSelectionStack={setSelectionStack}
        onAddFilter={() => setDisplayAddFilterModal(FilterModalType.SELECTION)}
        onDeleteList={async () => {
          await model.delete({ query: querySelection });
          setSelectionStack([]);
        }}
        onUpdateList={async (values: any) => {
          await model.update({ query: querySelection, set: values });
          // setSelectionStack([]);
        }}
      />
    </section>
  );
};

export default PageComponentMultiple;
