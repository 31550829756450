import React, { useEffect } from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CreateItemComponentProps } from "../utils/types";
import CreateItemWrapper from "./CreateItemWrapper";
import { getProjectClient } from "../utils/graphand";

const CreateEnvironmentFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  const Environment = client.getModel("Environment");

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("name")}
        {fields.render("description", { theme: "textarea" })}
        {Environment.get({ query: { name: "master" } }).suspense(
          (env) =>
            fields.render("cloneFrom", {
              theme: "select",
              placeholderValue: env._id,
            }),
          {
            fallback: fields.render("cloneFrom", {
              theme: "select",
            }),
          }
        )}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.createLoading" : "actions.create")}
      </Button>
    </form>
  );
};

const CreateEnvironment: React.FunctionComponent<CreateItemComponentProps> = (
  props
) => {
  const client = getProjectClient();

  useEffect(() => {
    props.setModalProps?.({
      title: "Créer un environnement",
    });
  }, []);

  if (!client) {
    return null;
  }

  return (
    <CreateItemWrapper
      {...props}
      beforeCreate={async (values: any) => {
        if (!values.cloneFrom) {
          const Environment = client.getModel("Environment");
          const env = await Environment.get({ query: { name: "master" } });
          values.cloneFrom = env._id;
        }
      }}
      template={CreateEnvironmentFormTemplate}
    />
  );
};

export default CreateEnvironment;
