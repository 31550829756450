import React from "react";
import { getGlobalClient, getProjectClient } from "../utils/graphand";
import Client, {
  GraphandFieldBoolean,
  GraphandModelAccount,
} from "graphand-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { GraphandForm } from "graphand-react";
import { useTranslation } from "react-i18next";
import { getProjectMatch } from "../utils/getProjectMatch";
import { useCurrentAccount, useCurrentUser } from "../utils/hooks";
import PreviewAccount from "../components/PreviewAccount";
import Button, { ButtonTheme } from "../components/Button";
import Container from "../components/Container";
import UserPicture from "../components/UserPicture";

type OAuthLoggedProps = {
  onValidate: () => void;
  onCancel: () => void;
  client: Client;
};

const OAuthLoggedUser: React.FunctionComponent<OAuthLoggedProps> = ({
  onValidate,
  client,
}) => {
  const { user } = useCurrentUser();

  if (!user) {
    return (
      <Button theme={ButtonTheme.light} onClick={() => client.logout()}>
        Changer de compte
      </Button>
    );
  }

  return (
    <>
      <div className="space-y-4 mb-8">
        <Button onClick={() => onValidate()}>
          Continuer en tant que {user.fullname}
        </Button>
        <Button theme={ButtonTheme.light} onClick={() => client.logout()}>
          Changer de compte
        </Button>
      </div>
      <div className="space-y-3 flex flex-col items-center mb-6">
        <div className="w-full flex items-center justify-center">
          <div className="h-32 w-32 overflow-hidden rounded-full flex items-center justify-center flex-shrink-0">
            {user.picture ? (
              <UserPicture
                src={`${
                  user.picture
                }?u=${user.updatedAt.toString()}&h=250&w=250`}
                alt={user.fullname}
              />
            ) : (
              <FontAwesomeIcon
                size="2x"
                icon={["fal", "user-circle"]}
                className="text-gray-400"
              />
            )}
          </div>
        </div>
        <div className="inline truncate text-lg font-bold ">
          {user.fullname}
        </div>
      </div>
    </>
  );
};

const OAuthLoggedAccount: React.FunctionComponent<OAuthLoggedProps> = ({
  onValidate,
  client,
}) => {
  const { account } = useCurrentAccount();

  if (!account) {
    return (
      <Button theme={ButtonTheme.light} onClick={() => client.logout()}>
        Changer de compte
      </Button>
    );
  }

  return (
    <>
      <div className="space-y-4 mb-8">
        <Button onClick={() => onValidate()}>
          Continuer en tant que {account.fullname}
        </Button>
        <Button theme={ButtonTheme.light} onClick={() => client.logout()}>
          Changer de compte
        </Button>
      </div>
      <PreviewAccount
        model={account.constructor as typeof GraphandModelAccount}
        item={account}
      />
    </>
  );
};

const OAuth: React.FunctionComponent<{ logged: boolean }> = ({ logged }) => {
  const projectMatch = getProjectMatch();
  const globalClient = getGlobalClient();
  const projectClient = getProjectClient();
  const { t } = useTranslation();
  const client = projectMatch ? projectClient : globalClient;

  const _validate = () => {
    const accessToken = client?.getAccessToken();
    const refreshToken = client?.getRefreshToken();
    window.opener?.postMessage({ accessToken, refreshToken }, "*");
  };

  const _cancel = () => {
    window.opener && window.opener.postMessage(null, "*");
    window.close();
  };

  const _handleSubmit = async (credentials: any) => {
    await client?.login(credentials);
    await client?.authmanager.sync();
  };

  if (!window.opener || !client) {
    return null;
  }

  const User = globalClient.getModel("User");
  const { email, password } = User.fields;
  return (
    <div className="p-4 max-w-screen-sm mx-auto">
      <Container>
        {logged ? (
          projectMatch ? (
            <OAuthLoggedAccount
              client={client}
              onValidate={_validate}
              onCancel={_cancel}
            />
          ) : (
            <OAuthLoggedUser
              client={client}
              onValidate={_validate}
              onCancel={_cancel}
            />
          )
        ) : (
          <GraphandForm
            fields={{
              email,
              password,
              keepConnected: new GraphandFieldBoolean(),
            }}
            model={User}
            onSubmit={_handleSubmit}
          >
            {({ formRef, handleSubmit, fields, isLoading }) => {
              return (
                <form
                  ref={formRef}
                  onSubmit={isLoading ? null : handleSubmit}
                  className={isLoading ? "cursor-progress w-full" : "w-full"}
                >
                  <h1 className="text-center text-3xl font-bold mb-4">
                    🛡 Une application demande à accéder à votre compte
                  </h1>
                  <div
                    className={`space-y-6 mb-8 transition ${
                      isLoading ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    {fields.render("email", {
                      type: "email",
                      placeholder: "Votre adresse email",
                      disabled: isLoading,
                    })}
                    {fields.render("password", {
                      theme: "password",
                      placeholder: "Votre mot de passe",
                      disabled: isLoading,
                    })}
                  </div>
                  <button
                    type="submit"
                    className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-xl border border-white text-white"
                  >
                    <FontAwesomeIcon
                      icon={faLoader}
                      size="lg"
                      className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                        isLoading ? "opacity-100" : "opacity-0"
                      }`}
                    />
                    {isLoading ? t("actions.loginLoading") : t("actions.login")}
                  </button>
                </form>
              );
            }}
          </GraphandForm>
        )}
      </Container>
    </div>
  );
};

export default OAuth;
