import Logo from "./Logo";
import React, { useEffect, useState } from "react";
import { getProjectClient, projectClientSubject } from "../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { getProjectMatch } from "../utils/getProjectMatch";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { useAccountSettings, useModelFilters } from "../utils/hooks";
import { InfiniteList } from "graphand-react";
import SidebarLink from "./SidebarLink";
import DataModel from "../models/DataModel";

export const SidebarProjectMinifiedSubject = new BehaviorSubject(false);

const SidebarProjectItemComponent: React.FunctionComponent<{
  item: DataModel;
  isActive: boolean;
}> = ({ item, isActive }) => {
  const { filters, loading } = useModelFilters(item);
  const [minified, setMinified] = useState(
    SidebarProjectMinifiedSubject.getValue()
  );

  useEffect(() => {
    const sub = SidebarProjectMinifiedSubject.subscribe(setMinified);

    return () => sub.unsubscribe();
  }, []);

  if (!item) {
    return null;
  }

  return (
    <SidebarLink
      minified={minified}
      className={`transition duration-500 ${
        isActive ? "translate-x-0 opacity-100" : "translate-x-3 opacity-0"
      }`}
      loading={loading}
      to={item.getLink()}
      IconComponent={() => item.renderFieldView("_icon", { theme: "fad" })}
      sublinks={filters.map((filter, index) => ({
        label: filter.label,
        anchor: `f${index}`,
      }))}
    >
      {item.renderFieldView("name")}
    </SidebarLink>
  );
};

const SidebarProject = () => {
  const navigate = useNavigate();
  const client = getProjectClient();
  const projectMatch = getProjectMatch();
  const { t } = useTranslation();
  const [pinnedModels] = useAccountSettings<string[]>("pinnedModels", [
    "Account",
    "Media",
  ]);
  const [minified, setMinified] = useState(
    SidebarProjectMinifiedSubject.getValue()
  );

  useEffect(() => {
    const sub = SidebarProjectMinifiedSubject.subscribe(setMinified);

    return () => sub.unsubscribe();
  }, []);

  const DataModel = client?.getModel("DataModel");

  if (!DataModel) {
    return null;
  }

  const slugs = pinnedModels
    .filter((scope) => String(scope).startsWith("Data:"))
    .map((scope) => String(scope).replace("Data:", ""));

  const staticItems = [
    pinnedModels.includes("Account")
      ? DataModel.get("_dataModelAccount")
      : null,
    pinnedModels.includes("Media") ? DataModel.get("_dataModelMedia") : null,
  ].filter(Boolean) as DataModel[];

  return (
    <div
      className={`h-full overflow-auto w-20 flex-shrink-0 border-r border-gray-300 bg-background hidden sm:flex flex-col duration-300 transition-all ${
        minified ? "" : "md:w-80"
      }`}
    >
      <div
        className={`h-16 px-4 items-center flex-shrink-0 hidden ${
          minified ? "" : "md:flex"
        }`}
      >
        <Logo />
      </div>
      <Link
        to="/"
        className={`h-16 px-4 flex items-center justify-center flex-shrink-0 ${
          minified ? "" : "md:hidden"
        }`}
      >
        <img alt="logo" src="/images/icon.png" className="h-9" />
      </Link>
      <div className="flex px-4 flex-col flex-grow justify-between mt-4">
        <InfiniteList
          key={client?._uid}
          model={DataModel}
          activeFn={(index) => index * 100 + 300}
          opts={{ query: { slug: { $in: slugs } }, pageSize: 50 }}
          staticItems={staticItems}
          ContainerComponent={({ children }: any) => (
            <div className="space-y-1">{children}</div>
          )}
          EmptyComponent={<div className="space-y-1" />}
          ItemComponent={SidebarProjectItemComponent}
        />
        {/*<div>*/}
        {/*  {projectMatch ? null : (*/}
        {/*    <button*/}
        {/*      type="button"*/}
        {/*      onClick={() => {*/}
        {/*        navigate("/");*/}
        {/*        projectClientSubject.next(null);*/}
        {/*      }}*/}
        {/*      className={`hover:underline font-bold flex items-center py-4 transition truncate ${*/}
        {/*        minified ? "hidden" : ""*/}
        {/*      }`}*/}
        {/*    >*/}
        {/*      <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />*/}
        {/*      {t("actions.back_to_organization")}*/}
        {/*    </button>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default SidebarProject;
