import React, { useEffect } from "react";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import { InfiniteList } from "graphand-react";
import { getGlobalClient } from "../../utils/graphand";
import { useTranslation } from "react-i18next";
import PersonalInvitationsTile from "../../components/PersonalInvitationsTile";

const PersonalInvitations = () => {
  const client = getGlobalClient();
  const OrgInvitation = client.getModel("OrgInvitation");
  const { t } = useTranslation();

  useEffect(() => {
    titleSubject.next("Mes invitations");
    readySubject.next(true);

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  }, []);

  return (
    <div className="flex flex-col divide-y divide-gray-200">
      <InfiniteList
        model={OrgInvitation}
        ItemComponent={PersonalInvitationsTile}
        opts={{}}
      />
    </div>
  );
};

export default PersonalInvitations;
