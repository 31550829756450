import { deepEqual } from "fast-equals";
import { Routes, Route, useLocation, Location } from "react-router-dom";
import Dashboard from "./pages/project/Dashboard";
import AppsList from "./pages/project/AppsList";
import Settings from "./pages/project/Settings";
import React, { FunctionComponent, useEffect, useState } from "react";
import ModelPage from "./pages/project/ModelPage";
import { BehaviorSubject } from "rxjs";
import AccountSettings from "./pages/project/AccountSettings";
import LayoutProject from "./components/LayoutProject";
import { Transition } from "@headlessui/react";
import LayoutNotLogged from "./components/LayoutNotLogged";
import Login from "./pages/project/notLogged/Login";
import Join from "./pages/project/notLogged/Join";
import { projectClientSubject } from "./utils/graphand";

export const projectRouterNextLocationSubject = new BehaviorSubject<
  Location | undefined
>(undefined);

export const projectRouterLocationSubject = new BehaviorSubject<
  Location | undefined
>(undefined);

const NotLoggedProject: FunctionComponent<{
  loading: boolean;
  animationStage: number;
  onRouteChange: () => any;
}> = ({ loading, animationStage, onRouteChange }) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState<any>(location);
  const [loaded, setLoaded] = useState<boolean>(false);

  const ready = loaded && animationStage > -1;

  useEffect(() => {
    if (location !== displayLocation && ready) {
      onRouteChange();
      setTimeout(() => {
        setDisplayLocation(location);
      }, 300);
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => setLoaded(true));
  }, []);

  // const ready = loaded && animationStage === 0;

  return (
    <LayoutNotLogged ready={ready}>
      <Routes key={displayLocation.pathname} location={displayLocation}>
        <Route
          path="/join"
          element={<Join loading={loading} ready={ready} />}
        />
        <Route path="*" element={<Login loading={loading} ready={ready} />} />
      </Routes>
    </LayoutNotLogged>
  );
};

const ProjectRouter: React.FunctionComponent<{
  ready: boolean;
  logged: boolean;
  animationStage: number;
  setAnimationStage: React.Dispatch<React.SetStateAction<number>>;
}> = ({ ready, logged, animationStage, setAnimationStage }) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState<any>(location);

  useEffect(() => {
    projectRouterLocationSubject.next(displayLocation);

    const sub = projectRouterLocationSubject.subscribe(setDisplayLocation);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (
      displayLocation &&
      projectRouterNextLocationSubject.getValue()?.pathname !==
        displayLocation.pathname
    ) {
      projectRouterNextLocationSubject?.next(displayLocation);
    }
  }, [displayLocation]);

  useEffect(() => {
    if (location.pathname !== displayLocation?.pathname) {
      projectRouterNextLocationSubject.next(location);
    } else if (!deepEqual(location, displayLocation)) {
      projectRouterNextLocationSubject.next(location);
      setDisplayLocation(location);
    }
  }, [location]);

  return logged ? (
    <Routes key={displayLocation.pathname} location={displayLocation}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/apps" element={<AppsList />} />
      <Route path="/account" element={<AccountSettings />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/apps/:scope" element={<ModelPage />} />
    </Routes>
  ) : null;
};

const Project: FunctionComponent<{
  ready: boolean;
  logged: boolean;
  animationStage: number;
  setAnimationStage: React.Dispatch<React.SetStateAction<number>>;
}> = ({ ready, logged, animationStage, setAnimationStage }) => {
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const sub = projectClientSubject.subscribe(() => {
      setReload((r) => r + 1);
    });

    return () => sub?.unsubscribe();
  }, []);

  return (
    <>
      <Transition
        show={!logged || !ready}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100 z-50"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 z-50"
        leaveTo="opacity-0"
      >
        <div className="inset-0 fixed z-50 h-screen">
          <NotLoggedProject
            loading={logged}
            animationStage={logged || animationStage < 1 ? animationStage : 0}
            onRouteChange={() => setAnimationStage(-1)}
          />
        </div>
      </Transition>

      <LayoutProject ready={ready}>
        <ProjectRouter
          ready={ready}
          logged={logged}
          animationStage={animationStage}
          setAnimationStage={setAnimationStage}
        />
      </LayoutProject>
    </>
  );
};

export default Project;
