import Modal, { ModalProps } from "../components/Modal";
import React, { useState } from "react";
import { GraphandModelConstructor } from "../utils/types";
import HistoryList from "../components/HistoryList";
import SwitchList from "../components/SwitchList";

const ModelHistoryModal: React.FunctionComponent<
  ModalProps & {
    model: GraphandModelConstructor;
  }
> = ({ model, ...modalProps }) => {
  const [kind, setKind] = useState<string>("all");

  const query: any = {};

  if (kind !== "all") {
    query.kind = kind;
  }

  return (
    <Modal
      title="Historique des modifications"
      className="max-w-screen-lg"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <SwitchList
          activeKey={kind}
          list={[
            {
              key: "all",
              label: ({ isActive }: { isActive: boolean }) => <div>Tous</div>,
            },
            {
              key: "create",
              label: ({ isActive }: { isActive: boolean }) => <div>create</div>,
            },
            {
              key: "update",
              label: ({ isActive }: { isActive: boolean }) => <div>update</div>,
            },
            {
              key: "delete",
              label: ({ isActive }: { isActive: boolean }) => <div>delete</div>,
            },
          ]}
          onClick={(item) => {
            if (item.key && typeof item.key === "string") {
              setKind(item.key);
            }
          }}
        />

        <HistoryList historyModel={model} opts={{ query }} />
      </div>
    </Modal>
  );
};

export default ModelHistoryModal;
