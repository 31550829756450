import React from "react";
import { QueryBuilderFilterTileProps } from "./QueryBuilderFilterTile";
import { useQueryBuilderFilterTile } from "../../utils/hooks";

const QueryBuilderFilterTileFieldText: React.FunctionComponent<
  QueryBuilderFilterTileProps
> = (props) => {
  const {
    state,
    setState,
    inputRef,
    renderDropdown,
    renderCloseButton,
    renderLabel,
    disabled,
  } = useQueryBuilderFilterTile(props, {
    operators: ["$eq", "$ne", "$regex"],
    defaultState: {
      operator: "$regex",
      value: "",
    },
    validateChange: (state) => state.value?.length,
    proxyAssign: (state, props) => {
      const { operator, value } = state;
      const { field } = props.filter.payload;

      if (!field || !operator) {
        return (q) => q;
      }

      const res = {
        [operator]: value,
      };

      if (operator === "$regex") {
        res[operator] = res[operator].replace(" ", "\\ ");
        res.$options = "ixs";
      }

      return (query) => {
        query[field] = res;
        return query;
      };
    },
  });

  return (
    <div className="flex items-center bg-gray-200 rounded-lg py-2 px-3 select-none text-gray-600">
      <div className="flex items-center space-x-3">
        {renderLabel}
        {renderDropdown}
        <div className="relative flex items-center h-7">
          <div className="h-full min-w-[50px] w-full opacity-0 select-none px-1">
            {state.value || ""}
          </div>
          <input
            disabled={disabled}
            ref={inputRef}
            type="text"
            value={state.value}
            className="absolute inset-0 p-0 bg-transparent border-none focus:outline-none focus:shadow-none focus:ring-0"
            onChange={({ currentTarget: { value } }) =>
              setState((s) => ({ ...s, value }))
            }
          />
        </div>
      </div>
      {renderCloseButton}
    </div>
  );
};

export default QueryBuilderFilterTileFieldText;
