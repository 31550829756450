import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { UpdateItemComponentProps } from "../utils/types";
import UpdateItemWrapper from "./UpdateItemWrapper";

const UpdateItemFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">{fields.renderDefault()}</div>
      <Button type="submit">
        {t(isLoading ? "actions.updateLoading" : "actions.update")}
      </Button>
    </form>
  );
};

const UpdateItem: React.FunctionComponent<UpdateItemComponentProps> = (
  props
) => {
  return <UpdateItemWrapper {...props} template={UpdateItemFormTemplate} />;
};

export default UpdateItem;
