import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { GraphandModel } from "graphand-js";
import { Combobox } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import QueryBuilderFilterTile, {
  QueryBuilderFilter,
  QueryBuilderFilterType,
} from "./QueryBuilderFilterTile";
import GraphandFieldIcon from "../GraphandFieldIcon";
import { capitalizeFirstLetter } from "../../utils/formatter";
import { getProjectClient } from "../../utils/graphand";
import InputTextSolid from "../../fields/inputs/Text/_solid";
import { cloneDeep, isEqual } from "lodash";
import { isId } from "../../utils/tools";

export const QueryBuilderOperators = ["$eq", "$ne", "$regex"];

export type QueryBuilderProps = {
  model: typeof GraphandModel;
  onChange: (query: any) => void;
  initialQuery?: any;
  InputContainerComponent?: React.FunctionComponent<{ children: any }>;
  disabled?: boolean;
  reload?: any;
  inputRef?: any;
  filters?: QueryBuilderFilter<any>[];
  setFilters?: React.Dispatch<React.SetStateAction<QueryBuilderFilter<any>[]>>;
  placeholder?: string;
};

const QueryBuilder: React.FunctionComponent<QueryBuilderProps> = React.memo(
  ({
    model,
    onChange,
    initialQuery = {},
    disabled = false,
    reload,
    inputRef,
    placeholder = "Rechercher ...",
    ...props
  }) => {
    const client = getProjectClient();
    const [inputValue, setInputValue] = useState("");
    const [_filters, _setFilters] = useState<QueryBuilderFilter<any>[]>([]);
    const { t } = useTranslation();

    const filters = props.filters ?? _filters;
    const setFilters = props.setFilters ?? _setFilters;

    const DefaultInputContainerComponent = useRef(
      ({ children }: any) => children
    ).current;
    const InputContainerComponent =
      props.InputContainerComponent ?? DefaultInputContainerComponent;

    useEffect(() => {
      if (initialQuery) {
        _initFilters(initialQuery);
      }

      return () => onChange(() => ({}));
    }, [JSON.stringify(initialQuery), reload]);

    if (!client) {
      return null;
    }

    const _initFilters = (q: any) => {
      const _filters: QueryBuilderFilter<any>[] = [];

      Object.keys(q).forEach((key) => {
        if (model.fields[key] && model.scope) {
          const _filter: QueryBuilderFilter<QueryBuilderFilterType.field> = {
            type: QueryBuilderFilterType.field,
            payload: {
              scope: model.scope,
              field: key,
            },
          };

          const operator = QueryBuilderOperators.find((op) => op in q[key]);
          const value = operator && q[key][operator];

          if (operator) {
            _filter.init = { value, operator };
          }

          _filters.push(_filter);
        }
      });

      if (!isEqual(filters, _filters)) {
        setFilters(_filters);
      }
    };

    const _addFilter = (f: QueryBuilderFilter<any>) => {
      setFilters((filters) => {
        return [...filters].filter((filter) => {
          if (filter.type === QueryBuilderFilterType.field) {
            return f.payload.field !== filter.payload.field;
          }

          if (filter.type === QueryBuilderFilterType.esMapping) {
            return f.type !== QueryBuilderFilterType.esMapping;
          }

          return true;
        });
      });
      setTimeout(() => setFilters((filters) => filters.concat(f)));
      setInputValue("");
    };

    const _removeFilter = (
      assignFn: (query: any) => any,
      filter: QueryBuilderFilter<any>
    ) => {
      setFilters((filters) => filters.filter((f) => f !== filter));
      onChange((q: any) => {
        const _query: any = q ? cloneDeep(q) : {};
        return assignFn(_query);
      });
    };

    const _changeFilter = (assignFn: (query: any) => any) => {
      onChange((q: any) => {
        const _query: any = q ? cloneDeep(q) : {};
        return assignFn(_query);
      });
    };

    return (
      <div className="space-y-2">
        {!disabled ? (
          <div className="relative">
            <Combobox value={null} onChange={_addFilter}>
              <InputContainerComponent>
                <InputTextSolid
                  id={`QueryBuilder-${model.scope}`}
                  inputRef={inputRef}
                  onChange={setInputValue}
                  options={{
                    label: null,
                    placeholder,
                    beforeHelper: <FontAwesomeIcon icon={faSearch} />,
                    InputComponent: Combobox.Input,
                  }}
                />
              </InputContainerComponent>

              <Combobox.Options as={Fragment}>
                <div className="absolute z-50 bg-white top-full mt-2 w-full rounded-2xl p-4 shadow-lg space-y-4 max-h-96 overflow-auto">
                  {isId(inputValue) ? (
                    <ul className="space-y-1">
                      <Combobox.Option
                        as={Fragment}
                        key={inputValue}
                        value={
                          {
                            type: QueryBuilderFilterType.field,
                            init: { operator: "$eq", value: inputValue },
                            payload: { scope: model.scope, field: "_id" },
                          } as QueryBuilderFilter<QueryBuilderFilterType.field>
                        }
                      >
                        {({ active, selected, disabled }) => (
                          <li
                            className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                              active ? "bg-gray-200" : ""
                            }`}
                            role="option"
                          >
                            <div className="h-6 w-6 flex items-center justify-center">
                              <GraphandFieldIcon
                                field={model.fields._id}
                                slug="_id"
                                theme="fad"
                                className="text-xl"
                              />
                            </div>
                            <span className="ml-3 flex-auto truncate">
                              {inputValue}
                            </span>
                          </li>
                        )}
                      </Combobox.Option>
                    </ul>
                  ) : null}

                  {client
                    .getModel("EsMapping")
                    .getList({ query: { scope: model.scope }, count: true })
                    .suspense((list) => {
                      if (!inputValue.length || !list.count) {
                        return null;
                      }

                      return (
                        <ul className="space-y-1">
                          <li>
                            <h2
                              className="text-sm p-2 text-gray-500"
                              role="none"
                            >
                              Rechercher "{inputValue}"
                            </h2>
                          </li>
                          {list.map((mapping) => (
                            <Combobox.Option
                              as={Fragment}
                              key={mapping._id}
                              value={
                                {
                                  type: QueryBuilderFilterType.esMapping,
                                  init: { value: inputValue },
                                  payload: { _id: mapping._id },
                                } as QueryBuilderFilter<QueryBuilderFilterType.esMapping>
                              }
                            >
                              {({ active, selected, disabled }) => (
                                <li
                                  className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                                    active ? "bg-gray-200" : ""
                                  }`}
                                  role="option"
                                >
                                  <div className="h-6 w-6 flex items-center justify-center">
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      className="text-xl"
                                    />
                                  </div>
                                  <span className="ml-3 flex-auto truncate">
                                    {mapping.renderFieldView("name")}
                                  </span>
                                </li>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      );
                    })}

                  <ul className="space-y-1">
                    <li>
                      <h2 className="text-sm p-2 text-gray-500" role="none">
                        Filtrer par
                      </h2>
                    </li>
                    {Object.keys(model.fields).map((slug) => {
                      const field = model.fields[slug];
                      const init = inputValue
                        ? field.handleQueryBuilderInput(inputValue)
                        : {};

                      if (!init) {
                        return null;
                      }

                      return (
                        <Combobox.Option
                          as={Fragment}
                          key={slug}
                          value={
                            {
                              type: QueryBuilderFilterType.field,
                              init,
                              payload: { scope: model.scope, field: slug },
                            } as QueryBuilderFilter<QueryBuilderFilterType.field>
                          }
                        >
                          {({ active, selected, disabled }) => (
                            <li
                              className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                                active ? "bg-gray-200" : ""
                              }`}
                              role="option"
                            >
                              <div className="h-6 w-6 flex items-center justify-center">
                                <GraphandFieldIcon
                                  field={model.fields[slug]}
                                  slug={slug}
                                  theme="fad"
                                  className="text-xl"
                                />
                              </div>
                              <span className="ml-3 flex-auto truncate">
                                {capitalizeFirstLetter(
                                  model.fields[slug]?.__dataField?.name ||
                                    t(`labels.fields.${slug}.default`)
                                )}
                              </span>
                            </li>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </ul>

                  {filters.length ? (
                    <ul className="space-y-1">
                      <li>
                        <h2 className="text-sm p-2 text-gray-500" role="none">
                          Remplacer
                        </h2>
                      </li>
                      {filters
                        .map(
                          (f) =>
                            f.type === QueryBuilderFilterType.field &&
                            f.payload.field
                        )
                        .map((slug) => (
                          <Combobox.Option
                            as={Fragment}
                            key={slug}
                            value={
                              {
                                type: QueryBuilderFilterType.field,
                                payload: { scope: model.scope, field: slug },
                              } as QueryBuilderFilter<QueryBuilderFilterType.field>
                            }
                          >
                            {({ active, selected, disabled }) => (
                              <li
                                className={`flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                                  active ? "bg-gray-200" : ""
                                }`}
                                role="option"
                              >
                                <div className="h-6 w-6 flex items-center justify-center">
                                  <GraphandFieldIcon
                                    field={model.fields[slug]}
                                    slug={slug}
                                    theme="fad"
                                    className="text-xl"
                                  />
                                </div>
                                <span className="ml-3 flex-auto truncate">
                                  {capitalizeFirstLetter(
                                    model.fields[slug]?.__dataField?.name ||
                                      t(`labels.fields.${slug}.default`)
                                  )}
                                </span>
                              </li>
                            )}
                          </Combobox.Option>
                        ))}
                    </ul>
                  ) : null}
                </div>
              </Combobox.Options>
            </Combobox>
          </div>
        ) : null}

        <div className="flex items-center flex-wrap -m-1">
          {filters.map((filter) => (
            <div key={JSON.stringify(filter)} className="m-1">
              <QueryBuilderFilterTile
                filter={filter}
                model={model}
                onClose={
                  disabled
                    ? undefined
                    : (assignFn) => _removeFilter(assignFn, filter)
                }
                onChange={disabled ? undefined : _changeFilter}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default QueryBuilder;
