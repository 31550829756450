import React, { useEffect, useState } from "react";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import { GraphandForm } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { useCurrentUser } from "../../utils/hooks";
import ImagePicker from "../../components/ImagePicker";
import { GraphandError } from "graphand-js";
import Container from "../../components/Container";
import { faHexagonExclamation } from "@fortawesome/pro-duotone-svg-icons";

const UserSettings = () => {
  const [errors, setErrors] = useState<GraphandError[] | undefined>([]);
  const [picture, setPicture] = useState<File | null>();
  const { user } = useCurrentUser();

  const _handleSubmit = async (values: any) => {
    setErrors([]);
    // @ts-ignore
    await user.update({ set: values, picture });
  };

  useEffect(() => {
    titleSubject.next("Paramètres du compte");
    readySubject.next(true);

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  }, []);

  return (
    <>
      <div className="space-y-6 mb-6">
        {errors?.map((e) => (
          <Container animate>
            <p className="text-red-600 flex items-center">
              <FontAwesomeIcon
                icon={faHexagonExclamation}
                size="lg"
                className="mr-2"
              />
              {e.message}
            </p>
          </Container>
        ))}
      </div>
      <div className="w-full flex flex-grow items-center p-4">
        <GraphandForm
          instance={user}
          values={{ ...user.toJSON(), password: "" }}
          onSubmit={_handleSubmit}
          onError={(e, unhandled) => setErrors(unhandled)}
        >
          {({ formRef, handleSubmit, fields, isLoading }) => {
            return (
              <form
                ref={formRef}
                onSubmit={isLoading ? null : handleSubmit}
                className={isLoading ? "cursor-progress w-full" : "w-full"}
              >
                <div
                  className={`space-y-4 mb-8 transition ${
                    isLoading ? "opacity-50" : "opacity-100"
                  }`}
                >
                  <div className="text-center mb-10">
                    <ImagePicker
                      value={picture}
                      onChange={setPicture}
                      defaultPreview={
                        user?.picture
                          ? `${user.picture}?u=${user.updatedAt.toString()}`
                          : undefined
                      }
                      loading={isLoading}
                    />
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="w-full">
                      {fields.render("firstname", {
                        placeholder: "Nouveau prénom",
                        disabled: isLoading,
                      })}
                    </div>
                    <div className="w-full">
                      {fields.render("lastname", {
                        placeholder: "Nouveau nom",
                        disabled: isLoading,
                      })}
                    </div>
                  </div>
                  {fields.render("email", {
                    type: "email",
                    placeholder: "Nouvelle adresse email",
                    disabled: isLoading,
                  })}
                  {fields.render("password", {
                    theme: "password",
                    placeholder: "Nouveau mot de passe",
                    disabled: isLoading,
                  })}
                </div>
                <button
                  type="submit"
                  className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-xl border border-white text-white"
                >
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="lg"
                    className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                      isLoading ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  Modifier mon compte
                </button>
              </form>
            );
          }}
        </GraphandForm>
      </div>
    </>
  );
};

export default UserSettings;
