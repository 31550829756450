import React, { useEffect, useState } from "react";
import Dropdown, { DropdownLinkGroup, DropdownPosition } from "./Dropdown";
import { copyToClipboard } from "../utils/tools";
import { capitalizeFirstLetter } from "../utils/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import UpdateItemModal from "../modals/UpdateItemModal";
import { GraphandModelConstructor } from "../utils/types";
import { ViewComponentProps } from "graphand-react";

const FieldViewContainer: React.FunctionComponent<
  ViewComponentProps & {
    rawValue?: any;
    children: any;
    label: any;
    controls?: any;
    links?: DropdownLinkGroup;
  }
> = ({
  children,
  label,
  field,
  slug,
  value,
  controls,
  links = [],
  rawValue,
}) => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    let sub: any;

    if (field?.__dataField) {
      sub = field.__dataField.subscribe(() => setReload((r) => r + 1));
    }

    return () => sub?.unsubscribe();
  }, [field]);

  const _render = () => {
    const button = label
      ? (open: boolean) => (
          <div className="flex group items-center mb-1 space-x-2">
            <div className="h-7 relative cursor-pointer text-left font-medium text-gray-700 flex items-center focus:outline-none w-full overflow-visible flex-grow">
              <div className="truncate text-sm text-gray-500">
                {capitalizeFirstLetter(label)}
              </div>
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`ml-2 transition transform opacity-0 ${
                  open
                    ? "opacity-100 -rotate-180"
                    : "group-hover:opacity-100 rotate-0"
                }`}
              />
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={(e) => e.stopPropagation()}
            >
              {controls}
            </div>
          </div>
        )
      : null;

    return (
      <Dropdown
        position={DropdownPosition.LEFT}
        button={button}
        className="mt-0"
        buttonClassName="w-full overflow-hidden"
        links={[
          {
            label: "Copier le slug",
            onClick: () => copyToClipboard(slug),
          },
          {
            label: "Copier la valeur",
            onClick: () => copyToClipboard(rawValue ?? value),
          },
          field?.__dataField
            ? {
                label: "Modifier le champ",
                onClick: () => setUpdateModalOpen(true),
              }
            : null,
          ...links,
        ].filter(Boolean)}
        content={(open: boolean) => {
          const _content =
            typeof children === "function" ? children(open) : children;

          return (
            <>
              {_content}

              {field?.__dataField ? (
                <UpdateItemModal
                  isOpen={updateModalOpen}
                  onClose={(v) => {
                    setUpdateModalOpen(v);
                  }}
                  model={
                    field.__dataField.constructor as GraphandModelConstructor
                  }
                  item={field.__dataField}
                />
              ) : null}
            </>
          );
        }}
      />
    );
  };

  return (
    <>
      {field?.__dataField?.suspense(_render, { subscribe: true }) || _render()}
    </>
  );
};

export default FieldViewContainer;
