import React from "react";
import { useTranslation } from "react-i18next";
import { getProjectClient } from "../../../utils/graphand";
import ModelList from "../../../components/ModelList";
import { DropdownLinkGroup } from "../../../components/Dropdown";

const SettingsSockethooks = () => {
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  return (
    <div className="p-2 sm:p-4 lg:p-6 h-full">
      <div className="w-full max-w-screen-lg mx-auto h-full lg:overflow-hidden lg:flex lg:flex-col">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          🔃 {t("labels.settings.sockethooks")}
        </h1>
        <div className="flex flex-col xl:flex-row lg:h-0 lg:flex-grow">
          <div className="xl:w-7/12 flex-shrink-0 flex-grow p-1 max-h-full overflow-auto xl:pb-8">
            <ModelList
              model={client.getModel("Sockethook")}
              defaultListFields={["_label"]}
              listActions={(item): DropdownLinkGroup => {
                return [
                  {
                    label: "Ping",
                    onClick: async () => {
                      const res = await item.ping();
                      alert(JSON.stringify(res));
                    },
                  },
                ];
              }}
              labelField={{
                title: "_title",
              }}
            />
          </div>
          <div className="xl:w-5/12 min-w-80 flex-shrink-0 flex-grow p-1 max-h-full overflow-auto xl:pb-8">
            <ModelList
              disableFilters
              model={client.getModel("SockethookHost")}
              defaultListFields={["_label"]}
              defaultSort="-connectedAt"
              listActions={(item): DropdownLinkGroup => {
                return [
                  {
                    label: "Ping",
                    onClick: async () => {
                      const res = await item.ping();
                      if (!res?.[item.name]) {
                        alert("Host not responding");
                        return;
                      }

                      const delay = res?.[item.name];
                      alert(`${delay}ms`);
                    },
                  },
                  item.blocked
                    ? {
                        label: "Débloquer",
                        onClick: () => {
                          item.unblock();
                        },
                      }
                    : {
                        label: "Bloquer",
                        onClick: () => {
                          item.block();
                        },
                      },
                ];
              }}
              labelField={{
                title: "_title",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSockethooks;
