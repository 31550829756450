import Modal, { ModalProps } from "../components/Modal";
import React, { useEffect, useState } from "react";
import DataModel from "../models/DataModel";
import { GraphandModelConstructor } from "../utils/types";
import { useTranslation } from "react-i18next";
import ConfigureDataModelLabel from "../components/ConfigureDataModelLabel";

const DataModelLabelModal: React.FunctionComponent<
  ModalProps & {
    dataModel: DataModel;
  }
> = (props) => {
  const [dataModel, setDataModel] = useState(props.dataModel);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.dataModel) {
      setDataModel(props.dataModel);
    }
  }, [props.dataModel]);

  return (
    <Modal
      {...props}
      title={t("actions.configure_model_label")}
      className="max-w-screen-sm"
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        {dataModel ? (
          <ConfigureDataModelLabel
            model={dataModel.constructor as GraphandModelConstructor}
            item={dataModel}
            onUpdate={() => props.onClose(false)}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default DataModelLabelModal;
