import React from "react";
import { getQuerySelection } from "../../utils/tools";
import { GraphandFieldText, GraphandValidationError } from "graphand-js";
import { GraphandForm } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryBuilder from "../../components/QueryBuilder";
import Button from "../../components/Button";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { AddFilterModalProps } from "./index";

const AddFilterSelection: React.FunctionComponent<AddFilterModalProps> = ({
  selectionStack,
  filter,
  model,
  onSave,
}) => {
  let query = selectionStack
    ? getQuerySelection(selectionStack)
    : { _id: { $exists: false } };

  if (filter) {
    query = { $and: [query, filter.query] };
  }

  const _handleSubmit = (values: any) => {
    const filter = { ...values, query };
    if (!filter.label) {
      throw new GraphandValidationError(
        "Field label is required",
        "label",
        "required"
      );
    }

    onSave(filter);
  };

  return (
    <GraphandForm
      fields={{
        label: new GraphandFieldText(),
      }}
      onSubmit={_handleSubmit}
    >
      {({ formRef, handleSubmit, fields, isLoading }) => {
        return (
          <form
            ref={formRef}
            onSubmit={isLoading ? null : handleSubmit}
            className={`${isLoading ? "cursor-progress" : ""}`}
          >
            <div
              className={`space-y-6 transition mb-8 ${
                isLoading ? "opacity-50" : "opacity-100"
              }`}
            >
              {fields.render("label", {
                disabled: isLoading,
                placeholder: "Nouveau filtre",
              })}

              {selectionStack ? (
                <ul className="space-y-4">
                  {filter ? (
                    <li className="flex items-center space-x-4">
                      <div
                        className={`h-6 w-6 flex items-center justify-center text-green-500`}
                      >
                        <FontAwesomeIcon icon={["far", "plus"]} />
                      </div>
                      <div className="font-bold">{filter.label}</div>
                      {model
                        .getList({
                          query: filter.query,
                          count: true,
                          pageSize: 1,
                        })
                        .suspense((list) => (
                          <div>({list.count})</div>
                        ))}
                    </li>
                  ) : null}
                  {selectionStack.map((i, index) => {
                    const _query = i.displayQuery || i.query;
                    if (_query && !Object.keys(_query).length && filter) {
                      return null;
                    }

                    return (
                      <li className="flex items-center space-x-4">
                        <div
                          className={`h-6 w-6 flex items-center justify-center ${
                            i.inverse ? "text-red-500" : "text-green-500"
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={
                              i.inverse ? ["far", "subtract"] : ["far", "plus"]
                            }
                          />
                        </div>
                        <div>
                          {_query && !Object.keys(_query).length ? (
                            <div>
                              <FontAwesomeIcon
                                icon={["far", "asterisk"]}
                                className="text-sm"
                              />
                            </div>
                          ) : (
                            <QueryBuilder
                              model={model}
                              onChange={() => null}
                              disabled
                              initialQuery={_query}
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}

              {model.getList({ query, count: true }).suspense((list) => {
                return <div>{list.count} éléments sélectionnés</div>;
              })}
            </div>
            <Button
              type="submit"
              className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
            >
              <FontAwesomeIcon
                icon={faLoader}
                size="lg"
                className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                  isLoading ? "opacity-100" : "opacity-0"
                }`}
              />
              Enregistrer
            </Button>
          </form>
        );
      }}
    </GraphandForm>
  );
};

export default AddFilterSelection;
