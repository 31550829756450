import Wizard from "../components/Wizard";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { ModelPageWizardProps } from "../pages/project/ModelPage";
import ConfigureDataModelModal from "../modals/ConfigureDataModelModal";
import { getProjectClient } from "../utils/graphand";
import DataModel from "../models/DataModel";

const ConfigureModelWizard = (props: ModelPageWizardProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const client = getProjectClient();
  const { t } = useTranslation();

  const dataModel = props.model?.__dataModel;

  if (!client || !dataModel) {
    return null;
  }

  return (
    <>
      <Wizard
        condition={!dataModel.isTemporary() && !dataModel.isConfigured}
        wizardKey={props.wizardKey}
        ContainerComponent={props.ContainerComponent}
      >
        <div className="font-bold mb-2">
          Terminez la configuration du modèle
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
          beatae corporis deserunt eveniet fuga illum ipsam libero, magnam nemo
          nesciunt odio placeat quaerat quia quisquam, quo sunt tenetur, vero
          voluptatibus!
        </p>
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="flex items-center w-full text-right justify-end mt-6 text-button hover:underline"
            onClick={() => setModalOpen(true)}
          >
            {t("actions.configure_model")}
            <FontAwesomeIcon icon={["far", "arrow-right"]} className="ml-2" />
          </button>
        </div>
      </Wizard>

      <ConfigureDataModelModal
        dataModel={dataModel as DataModel}
        isOpen={modalOpen}
        onClose={setModalOpen}
      />
    </>
  );
};

export default ConfigureModelWizard;
