import React from "react";
import { ViewComponentProps } from "graphand-react";
import { GraphandFieldRelation } from "graphand-js";
import { getProjectClient } from "../../../utils/graphand";
import ViewRelationSingle from "./_single";
import ViewRelationMultiple from "./_multiple";

const ViewRelation: React.FunctionComponent<ViewComponentProps> = (props) => {
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;

  // switch (options.context) {
  //   case ViewComponentContext.LIST_LABEL_TITLE:
  //   case ViewComponentContext.LIST_LABEL_SUBTITLE:
  //     if (field.multiple) {
  //       return null;
  //     }
  //
  //     return (
  //       item[slug]?.suspense(
  //         (item: any) => {
  //           const model = item.constructor;
  //           if (model.previewField) {
  //             return item.renderFieldView(model.previewField, options);
  //           }
  //
  //           return <ViewText {...props} value={item._id} />;
  //         },
  //         { subscribe: true }
  //       ) || <ViewText {...props} value={t("labels.other.empty")} />
  //     );
  //   default:
  //     break;
  // }

  if (field.multiple) {
    return <ViewRelationMultiple {...props} />;
  }

  return <ViewRelationSingle {...props} />;
};

export default ViewRelation;
