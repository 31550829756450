import React from "react";
import { QueryBuilderFilterTileProps } from "./QueryBuilderFilterTile";
import { isId } from "../../utils/tools";
import { useQueryBuilderFilterTile } from "../../utils/hooks";
import Select from "../Select";

const QueryBuilderFilterTileFieldId: React.FunctionComponent<
  QueryBuilderFilterTileProps
> = (props) => {
  const {
    state,
    setState,
    inputRef,
    renderDropdown,
    renderCloseButton,
    renderLabel,
    disabled,
  } = useQueryBuilderFilterTile(props, {
    operators: ["$eq", "$ne", "$in", "$nin"],
    defaultState: {
      operator: "$eq",
      value: "",
    },
    validateChange: (state) => {
      const isMultiple = ["$in", "$nin"].includes(String(state.operator));

      if (isMultiple) {
        return Array.isArray(state.value) && state.value.every(isId);
      }

      return typeof state.value === "string" && isId(state.value);
    },
  });

  const isMultiple = ["$in", "$nin"].includes(String(state.operator));

  return (
    <div className="flex items-center bg-gray-200 rounded-lg py-2 px-3 select-none text-gray-600">
      <div className="flex items-center space-x-3 mr-2">
        {renderLabel}
        {renderDropdown}
        <Select
          ContainerComponent={({ children }) => (
            <button
              type="button"
              className={`-my-3 flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white`}
            >
              {children}
            </button>
          )}
          inputClassName="text-sm"
          multiple={isMultiple}
          creatable
          inputRef={inputRef}
          disabled={disabled}
          value={state.value}
          onChange={(value) => setState((s) => ({ ...s, value }))}
          options={[]}
        />
      </div>
      {renderCloseButton}
    </div>
  );
};

export default QueryBuilderFilterTileFieldId;
