import {
  createProjectClient,
  getGlobalClient,
  projectClientSubject,
} from "../../utils/graphand";
import GraphandClient, {
  GraphandModelAccount,
  GraphandModelProject,
  GraphandModelRole,
} from "graphand-js";
import Container from "../../components/Container";
import React, { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { controlSubject, readySubject } from "../../components/LayoutGlobal";
import { Link } from "react-router-dom";
import { useCurrentUser, useOrganization } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { InfiniteList } from "graphand-react";
import PersonalInvitationsTile from "../../components/PersonalInvitationsTile";
import UserPicture from "../../components/UserPicture";
import { oauthProject } from "../../utils/tools";
import { setCookie } from "../../utils/cookies";

const clients: any = {};

const ProjectListTile: FunctionComponent<{
  project: GraphandModelProject;
  onClick: (client: GraphandClient) => any;
}> = ({ project, onClick }) => {
  const { t } = useTranslation();
  const [client, setClient] = useState<GraphandClient>();
  const [{ account, loading }, setState] = useState<{
    account: GraphandModelAccount | null;
    loading: boolean;
  }>({
    account: null,
    loading: true,
  });

  const _init = async () => {
    if (!clients[project._id]) {
      clients[project._id] = createProjectClient({
        id: project._id,
        assign: {
          initModels: false,
          subscribeFields: false,
        },
      });
    }

    const client = await clients[project._id];
    setClient((c) => (client._uid !== c ? client : c));
    if (!client) {
      setState({ account: null, loading: false });
      return;
    }

    try {
      await client.authmanager.sync();
      if (client.authmanager.user?._id) {
        setState({ account: client.authmanager.user, loading: false });
      } else {
        throw new Error();
      }
    } catch (e) {
      setState({ account: null, loading: false });
    }
  };

  useEffect(() => {
    _init();
  }, []);

  const _associate = async () => {
    const data: any = await oauthProject(project);
    if (!data?.accessToken) {
      return;
    }

    const client = await clients[project._id];
    if (!client) {
      return;
    }

    client.setAccessToken(data.accessToken);
    client.setRefreshToken(data.refreshToken);

    const globalClient = getGlobalClient();
    const accessToken = globalClient.getAccessToken();
    const refreshToken = globalClient.getRefreshToken();

    await client.associate(accessToken);

    client.setAccessToken(accessToken);
    client.setRefreshToken(refreshToken);

    await client.authmanager.sync();
    if (client.authmanager.user?._id) {
      setState({ account: client.authmanager.user, loading: false });
      onClick(client);
    }
  };

  return (
    <>
      <Container className={loading ? "opacity-50" : ""}>
        <div className="flex items-start justify-between w-full">
          <div className="flex items-center w-full overflow-hidden">
            <div className="font-poppins font-black text-3xl bg-global-inset h-14 w-14 flex items-center justify-center rounded-md text-white text-shadow-md select-none flex-shrink-0">
              {project?.name[0]}
            </div>
            <div className="ml-3 w-full overflow-hidden">
              <div className="text-lg font-bold truncate">{project.name}</div>
              <div className="text-sm text-gray-500 truncate flex space-x-3">
                {loading
                  ? "Recherche du compte ..."
                  : account?.role?.suspense(
                      (role: GraphandModelRole) =>
                        role ? (
                          <div className="flex items-center justify-start">
                            <div className="h-5 w-5 mr-1 flex items-center justify-center">
                              <UserPicture />
                            </div>
                            {account.role.name || "-"}
                          </div>
                        ) : (
                          <div>Compte introuvable</div>
                        ),
                      { fallback: <div>Compte introuvable</div> }
                    ) || <div>Compte introuvable</div>}
                <div className="flex items-center justify-start">
                  <div className="h-5 w-5 mr-1 flex items-center justify-center">
                    <FontAwesomeIcon icon={["far", "code-merge"]} />
                  </div>
                  {client?._options.env}
                </div>
              </div>
            </div>
          </div>
        </div>
        {account && client ? (
          <button
            type="button"
            className="flex items-center w-full text-right justify-end mt-6 text-button hover:underline"
            onClick={() => onClick(client)}
          >
            {t("actions.open_project")}
            <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
          </button>
        ) : loading ? null : (
          <button
            type="button"
            className="flex items-center w-full text-right justify-end mt-6 text-button hover:underline"
            onClick={_associate}
          >
            {t("actions.join_project")}
            <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
          </button>
        )}
      </Container>
    </>
  );
};

const ProjectsList = () => {
  const { user } = useCurrentUser();
  const Project = getGlobalClient().getModel("Project");
  const OrgInvitation = getGlobalClient().getModel("OrgInvitation");
  const { t } = useTranslation();
  const { organization } = useOrganization();

  useEffect(() => {
    if (organization) {
      controlSubject.next(
        <div>
          <Link to="/create-project" className="inline-block">
            <Button>
              <FontAwesomeIcon icon={faPlus} className="w-6 h-6 mr-2" />
              {t("actions.create_project")}
            </Button>
          </Link>
        </div>
      );
    } else {
      readySubject.next(true);
    }

    return () => {
      controlSubject.next(null);
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return (
      <div className="p-4 text-lg">
        <h1 className="text-3xl font-bold mb-2">
          👋 Bienvenue sur Graphand, {user.firstname}
        </h1>
        <p className="mb-2">
          Bienvenue sur le backoffice de Graphand. Si vous voyez ce message
          c'est que vous ne faites partie d'aucune organisation.
          <br />
          Sur graphand, les projets et factures sont regroupées au sein
          d'organisations. C'est donc ici que vous pourrez créer des groupes de
          collaboration afin d'organiser vos projets communs et de regrouper vos
          factures.
        </p>
        <p>
          Avant de commencer, vous pouvez{" "}
          <Link to="/new" className="hover:underline text-button">
            créer une nouvelle organisation
          </Link>
          . Si vous avez reçu un mail d'invitation pour rejoindre une
          organisation déjà existante, merci de cliquer sur le lien que vous
          trouverez dans le mail. Nous vous attendons !
        </p>

        <InfiniteList
          model={OrgInvitation}
          ContainerComponent={({ children }) => (
            <div>
              <h2 className="mt-6 text-xl font-medium mb-2">
                Vous avez recu des invitations
              </h2>
              <div className="flex flex-col divide-y divide-gray-200">
                {children}
              </div>
            </div>
          )}
          ItemComponent={PersonalInvitationsTile}
          opts={{ query: { email: "$currentUser.email" } }}
        />
      </div>
    );
  }

  const _handleProjectClick = async (client: GraphandClient) => {
    if (!client._options.project) {
      return;
    }

    delete clients[client._options.project];

    setCookie("graphand:project", client._options.project);

    projectClientSubject.next(client);
  };

  return (
    <div>
      {Project.getList({ query: { organization: organization?._id } }).suspense(
        (list) => {
          readySubject.next(true);

          if (!list.length) {
            return (
              <div className="p-4 text-lg">
                <h1 className="text-3xl font-bold mb-2">
                  🌎 Votre nouvelle organisation est prête !
                </h1>
                <p className="mb-2">
                  Bienvenue sur votre nouvelle organisation. L'organisation{" "}
                  <strong>{organization.name}</strong> est maintenant prête à
                  être configurée.
                  <br />
                  Pour commencer, que diriez-vous de{" "}
                  <Link
                    to="/create-project"
                    className="hover:underline text-button"
                  >
                    créer un nouveau projet
                  </Link>{" "}
                  ?
                </p>
              </div>
            );
          }

          return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {list.map((project) => (
                <ProjectListTile
                  key={project._id}
                  project={project}
                  onClick={_handleProjectClick}
                />
              ))}
            </div>
          );
        },
        { subscribe: true }
      )}
    </div>
  );
};

export default ProjectsList;
