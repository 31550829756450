import React, { useEffect, useRef, useState } from "react";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import {
  InputTextOptionsListContainerComponentProps,
  InputTextOptionsListOptionComponentProps,
} from "../fields/inputs/Text/_optionsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { UpdateItemComponentProps } from "../utils/types";

const DataModelIconListComponent: React.FunctionComponent<
  InputTextOptionsListOptionComponentProps
> = ({ option, handleClick, selected }) => {
  return (
    <div
      className={`"h-0 pb-full rounded-lg cursor-pointer bg-gray-100 select-none relative ${
        selected ? "bg-blue-100 text-blue-500" : "hover:bg-gray-200"
      }`}
      onClick={handleClick}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="h-6 w-6 flex items-center justify-center text-xl">
          <FontAwesomeIcon icon={["far", option as IconName]} />
        </div>
      </div>
    </div>
  );
};

const ConfigureDataModelIconConfigurationFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ fields }) => {
  return (
    <>
      {fields.render("icon", {
        theme: "list",
        OptionComponent: DataModelIconListComponent,
        ContainerComponent: ({
          children,
        }: InputTextOptionsListContainerComponentProps) => (
          <div className="grid grid-cols-xs gap-2">{children}</div>
        ),
      })}
    </>
  );
};

const ConfigureDataModelIconFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams & {
    autoSubmit?: boolean;
  }
> = ({
  formRef,
  handleSubmit,
  fields,
  isLoading,
  autoSubmit,
  values,
  isModified,
}) => {
  const { t } = useTranslation();
  const submittedRef = useRef<any>(false);

  useEffect(() => {
    if (isModified("configuration") && autoSubmit && !submittedRef.current) {
      submittedRef.current = true;
      handleSubmit();
    }
  }, [values.configuration?.icon]);

  return (
    <>
      <form
        ref={formRef}
        onSubmit={isLoading ? null : handleSubmit}
        className={`space-y-4 ${isLoading ? "cursor-progress" : ""}`}
      >
        {fields.render("configuration", {
          template: ConfigureDataModelIconConfigurationFormTemplate,
        })}

        {!autoSubmit ? (
          <Button type="submit">{t("actions.validate")}</Button>
        ) : null}
      </form>
    </>
  );
};

const ConfigureDataModelIcon: React.FunctionComponent<
  Omit<UpdateItemComponentProps, "setModalProps"> & {
    autoSubmit?: boolean;
  }
> = ({ model, item, onUpdate, autoSubmit }) => {
  const _handleSubmit = async (values: any) => {
    console.log(values);
    await item.update({ set: values });
    onUpdate(item);
  };

  const { current: template } = useRef((params: GraphandFormTemplateParams) => (
    <ConfigureDataModelIconFormTemplate
      key="ConfigureDataModelIconFormTemplate"
      {...params}
      autoSubmit={autoSubmit}
    />
  ));

  return (
    <GraphandForm
      instance={item}
      model={model}
      onSubmit={_handleSubmit}
      template={template}
    />
  );
};

export default ConfigureDataModelIcon;
