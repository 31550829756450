import React, { useCallback, useEffect, useState } from "react";
import { GraphandModel } from "graphand-js";
import Container from "../Container";
import { InfiniteList } from "graphand-react";
import { useAccountSettings } from "../../utils/hooks";
import SelectFieldsModal from "../../modals/SelectFieldsModal";
import { deepEqual } from "fast-equals";
import ModelListEmptyComponent from "./EmptyComponent";
import ModelListItemComponent from "./ItemComponent";
import ModelListPageComponent from "./PageComponent";
import { getQuerySelection } from "../../utils/tools";
import ModelListHeaderField from "./HeaderField";
import { ModelListProps } from "../../utils/types";
import ModelListLabelComponent from "./LabelComponent";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageComponentQueryBuilder from "../PageComponent/QueryBuilder";
import { getProjectClient } from "../../utils/graphand";

export const ModelListWrapperRaw: ModelListProps["WrapperComponent"] = ({
  children,
  toolsbar,
}: any) => (
  <>
    {toolsbar}
    {children}
  </>
);

const ModelList: React.FunctionComponent<ModelListProps> = ({
  staticItems,
  model,
  globalActions,
  listActions,
  inlineActions,
  defaultSort = "-_id",
  filter,
  selectionStack,
  setSelectionStack,
  reloadQueryBuilder,
  disableFilters = false,
  disableHeader = false,
  disableHeaderSelection,
  handleItemClick,
  EmptyComponent,
  createProps,
  onCreate,
  headerControls,
  ...props
}) => {
  let labelField: ModelListProps["labelField"] = props.labelField ?? {
    image: model.imageField,
    title: model.titleField,
    subtitle: model.subtitleField,
  };

  if (!Object.values(labelField).filter(Boolean).length) {
    labelField = undefined;
  }

  const defaultDisplay =
    props.defaultListFields ||
    model.listFields ||
    (labelField ? ["_label"] : ["_id"]);

  const [_query, setQuery] = useState({});
  const [_displayFields, setDisplayFields] = useAccountSettings<string[]>(
    `displayFields:${model.scope}`,
    defaultDisplay
  );
  const [_sort, _setSort] = useAccountSettings<string>(
    `sort:${model.scope}`,
    defaultSort
  );
  const [_selectFieldsModal, _setSelectFieldsModal] = useState(false);
  const subquery = props.subquery ?? _query;
  const query = filter ? { $and: [filter.query, subquery] } : subquery;
  const { t } = useTranslation();

  useEffect(() => {
    if (!_displayFields?.length) {
      setDisplayFields(defaultDisplay);
    }
  }, [_displayFields]);

  const selectFieldsModal = props.selectFieldsModal ?? _selectFieldsModal;
  const setSelectFieldsModal =
    props.setSelectFieldsModal ?? _setSelectFieldsModal;

  const sort = props.sort ?? _sort;
  const setSort = props.setSort ?? _setSort;

  // useEffect(() => {
  //   if (selectionStack?.length) {
  //     setSelectionStack?.([]);
  //   }
  // }, [filter]);

  let displayFields = _displayFields;
  let LabelComponent: React.FunctionComponent<{
    item: GraphandModel;
    onClick?: () => void;
  }> = () => null;

  if (labelField) {
    const { image, title, subtitle } = labelField;

    LabelComponent = ({ item, onClick }) => (
      <ModelListLabelComponent
        item={item}
        image={image}
        title={title}
        subtitle={subtitle}
        onClick={onClick}
      />
    );
  } else {
    displayFields = displayFields.filter((f) => f !== "_label");
  }

  const displaySelection = selectionStack && setSelectionStack;
  const querySelection = displaySelection && getQuerySelection(selectionStack);

  const ContainerComponent = ({ count, children }: any) => (
    <div className="relative w-full">
      <table
        className={`w-full bg-white table-fixed model-list-table relative z-0 ${
          disableHeader ? "header-disabled" : ""
        }`}
      >
        <thead>
          <tr>
            {displaySelection ? (
              <th className="w-10 sm:w-12 lg:w-14">
                {disableHeaderSelection
                  ? null
                  : model
                      .getList({
                        query: {
                          $and: [query, querySelection].filter(Boolean),
                        },
                        count: true,
                      })
                      .suspense(
                        (list) => {
                          const checked = list.count === count;

                          return (
                            <input
                              type="checkbox"
                              className="h-5 w-5 text-button border-gray-300 rounded-md"
                              checked={checked}
                              onChange={() => {
                                setSelectionStack((s) =>
                                  s.concat({
                                    query,
                                    displayQuery: subquery,
                                    inverse: checked,
                                  })
                                );
                              }}
                            />
                          );
                        },
                        {
                          fallback: (
                            <input
                              type="checkbox"
                              className="h-5 w-5 text-button border-gray-300 rounded-md"
                              checked={
                                selectionStack.slice(-1)?.[0] &&
                                deepEqual(
                                  selectionStack.slice(-1)[0].query,
                                  query
                                ) &&
                                !selectionStack.slice(-1)[0].inverse
                              }
                            />
                          ),
                        }
                      )}
              </th>
            ) : null}
            {displayFields.map((slug, index) => (
              <ModelListHeaderField
                key={`${slug}-header-field`}
                sort={sort}
                setSort={setSort}
                slug={slug}
                field={model.fields[slug]}
                index={index}
                labelField={labelField?.title}
                disableHeader={disableHeader}
              />
            ))}
            <th className="w-16 sm:w-18 lg:w-20 text-xs truncate" />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );

  const PageComponent = ({ list, getItemProps }: any) => (
    <ModelListPageComponent
      list={list}
      getItemProps={getItemProps}
      model={model}
      querySelection={querySelection}
    />
  );

  const DefaultWrapperComponent = useCallback(
    ({ children, toolsbar }: any) => (
      <Container>
        {!disableFilters ? toolsbar : null}
        {children}
      </Container>
    ),
    []
  );

  const WrapperComponent = props.WrapperComponent ?? DefaultWrapperComponent;

  const LoadingComponent = () => (
    <>
      <div className="absolute w-full bottom-0 flex items-center justify-center h-24">
        <div className="inline-flex items-center justify-center h-12 w-12 animate-spin text-primary">
          <FontAwesomeIcon icon={["far", "loader"]} size="2x" />
        </div>
      </div>
      <div className="h-24" />
    </>
  );

  const _toolsbar = (
    <PageComponentQueryBuilder
      actions={[
        {
          label: t("actions.select_fields"),
          onClick: () => setSelectFieldsModal(true),
        },
        ...(globalActions || []),
      ]}
      model={model}
      reloadQueryBuilder={reloadQueryBuilder}
      createProps={createProps}
      onCreate={onCreate}
      setQuery={setQuery}
      controls={headerControls}
    />
  );

  return (
    <>
      <WrapperComponent toolsbar={_toolsbar}>
        <InfiniteList
          key={getProjectClient()?._uid}
          model={model}
          activeFn={(index) => {
            const t = Math.pow(index, 0.7) * 200 + 150;
            const b = index * 75;
            return t < b ? b : t;
          }}
          staticItems={staticItems}
          watchItemIndex={-5}
          opts={{
            query,
            sort,
            pageSize: 20,
          }}
          ContainerComponent={ContainerComponent}
          PageComponent={PageComponent}
          EmptyComponent={EmptyComponent ?? ModelListEmptyComponent}
          ItemComponent={ModelListItemComponent}
          LoadingComponent={LoadingComponent}
          itemProps={({ item }) => ({
            setSelectionStack,
            displaySelection,
            displayFields,
            onClick: handleItemClick,
            LabelComponent,
            inlineActions:
              typeof inlineActions === "function"
                ? inlineActions(item)
                : inlineActions,
            listActions:
              typeof listActions === "function"
                ? listActions(item)
                : listActions,
          })}
        />
      </WrapperComponent>

      <SelectFieldsModal
        isOpen={selectFieldsModal}
        onClose={setSelectFieldsModal}
        model={model}
        value={displayFields}
        onChange={setDisplayFields}
      />
    </>
  );
};

export default ModelList;
