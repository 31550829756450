import Graphand, { GraphandModel, GraphandField } from "graphand-js";
import { BehaviorSubject } from "rxjs";
import Project from "../models/Project";
import Organization from "../models/Organization";
import Account from "../models/Account";
import DataModel from "../models/DataModel";
import plugins from "./graphand-plugins";
import Media from "../models/Media";
import Role from "../models/Role";
import Data from "../models/DataItem";
import SockethookHost from "../models/SockethookHost";
import Sockethook from "../models/Sockethook";
import DataField from "../models/DataField";
import Token from "../models/Token";
import Environment from "../models/Environment";
import { getCookie, removeCookie, setCookie } from "./cookies";
import Webhook from "../models/Webhook";
import Aggregation from "../models/Aggregation";
import EsMapping from "../models/EsMapping";

export const projectClientSubject = new BehaviorSubject<
  Graphand | null | undefined
>(undefined);

export const keepConnectedSubject = new BehaviorSubject<boolean>(true);

GraphandModel.customFields({
  _label: new GraphandField(),
});

export const graphandClient = new Graphand({
  // cdn: "cdn.graphand.io.local:1337",
  // host: "api-dev.graphand.io",
  // host: "api.graphand.io.local:1337",
  // ssl: false,
  autoSync: true,
  realtime: true,
  initProject: true,
  initModels: false,
  subscribeFields: false,
  models: [
    Aggregation,
    Organization,
    Project,
    Account,
    DataModel,
    DataField,
    Media,
    Role,
    Data,
    Sockethook,
    SockethookHost,
    Token,
    Environment,
    Webhook,
    EsMapping,
  ],
  plugins,
});

export const createProjectClient = async ({
  id,
  slug,
  env,
  assign = {},
}: {
  id?: string;
  slug?: string;
  env?: string;
  assign?: any;
}) => {
  let project;
  const Project = graphandClient.getModel("Project");

  if (id) {
    project = await Project.get(id, {
      axiosOpts: { headers: { Authorization: false } },
    });
  } else if (slug) {
    project = await Project.get(
      { query: { slug } },
      { axiosOpts: { headers: { Authorization: false } } }
    );
  }

  if (!project) {
    return null;
  }

  env = String(env || getCookie(`graphand:${project._id}:env`) || "master");

  const client = graphandClient.clone({
    project: project._id,
    initModels: true,
    subscribeFields: true,
    env,
    ...assign,
  });

  const logout = client.logout;
  client.logout = function (...args) {
    graphandClient.logout();

    return logout.apply(this, args);
  };

  await client._init();

  const DataModel = client.getModel("DataModel");
  DataModel.upsertStore([
    new DataModel({
      _id: "_dataModelAccount",
      multiple: true,
      _scope: "Account",
      _icon: "user-group",
    }),
    new DataModel({
      _id: "_dataModelMedia",
      multiple: true,
      _scope: "Media",
      _icon: "photo-film",
    }),
  ]);

  return client;
};

export const getGlobalClient = () => graphandClient;

export const getProjectClient = () => projectClientSubject.getValue();

export const switchEnvironment = (env: string = "master") => {
  const client = getProjectClient();

  if (!client) {
    return;
  }

  createProjectClient({
    id: client._options.project,
    env,
  }).then((c) => {
    if (!c) {
      return;
    }

    if (!env || env === "master") {
      removeCookie(`graphand:${c._options.project}:env`);
    } else {
      setCookie(`graphand:${c._options.project}:env`, env);
    }

    projectClientSubject.next(c);
  });
};
