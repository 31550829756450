import React, { useEffect, useState } from "react";
import { ViewComponentProps } from "graphand-react";
import ViewNumber from "../fields/views/Number";

const EsMappingCountView: React.FunctionComponent<ViewComponentProps> = (
  props
) => {
  const [count, setCount] = useState<number>();
  const { item } = props;

  const _refresh = async () => {
    try {
      const _count = await item?.count();
      setCount(_count?.count);
    } catch (e) {
      setCount(undefined);
    }
  };

  useEffect(() => {
    _refresh();
  }, [item]);

  return <ViewNumber {...props} value={count} />;
};

export default EsMappingCountView;
