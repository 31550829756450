import Modal, { ModalProps } from "../components/Modal";
import React, { useEffect, useRef, useState } from "react";
import ModelList from "../components/ModelList";
import Button, { ButtonTheme } from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { DropdownLinkGroup } from "../components/Dropdown";
import { GraphandModelConstructor } from "../utils/types";

const SelectItemsModal: React.FunctionComponent<
  ModalProps & {
    value: any;
    onChange: (v: any) => void;
    model: GraphandModelConstructor;
    globalActions?: DropdownLinkGroup;
    autoSave?: boolean;
    query: any;
    multiple?: boolean;
    displaySelection?: boolean;
  }
> = ({
  value,
  onChange,
  model,
  autoSave,
  multiple = true,
  query = {},
  globalActions = [],
  displaySelection = false,
  ...modalProps
}) => {
  const [_currentValue, setValue] = useState<string | string[]>(value);
  const currentValueRef = useRef<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (modalProps.isOpen) {
      setValue(value);
    }
  }, [modalProps.isOpen]);

  const _handleChange = async (v: any) => {
    if (Object.keys(query).length) {
      const found = await model.count({
        query: { $and: [query, { _id: v }] },
      });

      if (!found) {
        alert("OVERFLOW_QUERY");
        return;
      }
    }

    setValue((prev) => {
      if (multiple) {
        const currentValue = new Set(
          Array.isArray(prev) ? prev : [prev].filter(Boolean)
        );

        if (currentValue.has(v)) {
          currentValue.delete(v);
        } else {
          currentValue.add(v);
        }

        return Array.from(currentValue);
      }

      return v;
    });

    if (autoSave) {
      onChange(v);
    }
  };

  const _handleSetSelectionStack = async (getStack: any) => {
    if (!getStack?.length) {
      return;
    }

    // const stack = getStack([]);
    //
    // const querySelection = getQuerySelection(stack);
    // const list = await model.getList({ query: querySelection });
    // if (list.length) {
    //   const picked = list[0]._id;
    //   _handleChange(picked);
    // }
  };

  if (multiple) {
    currentValueRef.current = (
      Array.isArray(_currentValue)
        ? _currentValue
        : [_currentValue].filter(Boolean)
    ) as string[];

    return (
      <Modal
        {...modalProps}
        title="Sélectionner des éléments"
        className="max-w-screen-lg flex-grow"
      >
        <div className="px-4 lg:px-6">
          {model.quickCreateItemComponent
            ? React.createElement(model.quickCreateItemComponent, {
                model,
                onCreate: (item) => _handleChange(item._id),
              })
            : null}
        </div>
        <ModelList
          filter={{ query, label: "" }}
          model={model}
          disableHeaderSelection
          selectionStack={currentValueRef.current
            .filter(Boolean)
            .map((_id: string) => ({
              query: { _id: { $eq: _id } },
              inverse: false,
            }))}
          handleItemClick={(item) => _handleChange(item._id)}
          createProps={{ query }}
          onCreate={(item) => _handleChange(item._id)}
          setSelectionStack={_handleSetSelectionStack}
          globalActions={[
            ...globalActions,
            {
              label: t("actions.select_none"),
              onClick: () => onChange(undefined),
            },
          ]}
          headerControls={
            autoSave ? null : (
              <Button
                theme={ButtonTheme.default_green}
                onClick={() => onChange(currentValueRef.current)}
                className="h-auto w-12 flex-shrink-0"
              >
                <FontAwesomeIcon icon={["far", "check"]} />
              </Button>
            )
          }
        />
      </Modal>
    );
  }

  currentValueRef.current = (
    Array.isArray(_currentValue) ? _currentValue[0] : _currentValue
  ) as string;

  return (
    <Modal
      title="Sélectionner un élément"
      className="max-w-screen-lg flex-grow"
      expandable
      {...modalProps}
    >
      <div className="px-4 lg:px-6">
        {model.quickCreateItemComponent
          ? React.createElement(model.quickCreateItemComponent, {
              model,
              onCreate: (item) => _handleChange(item._id),
            })
          : null}
      </div>

      <ModelList
        filter={{ query, label: "" }}
        model={model}
        disableHeaderSelection
        selectionStack={[currentValueRef.current]
          .filter(Boolean)
          .map((_id) => ({ query: { _id: { $eq: _id } }, inverse: false }))}
        handleItemClick={(item) => _handleChange(item._id)}
        createProps={{ query }}
        onCreate={(item) => _handleChange(item._id)}
        setSelectionStack={_handleSetSelectionStack}
        globalActions={[
          ...globalActions,
          {
            label: t("actions.select_none"),
            onClick: () => onChange(undefined),
          },
        ]}
        headerControls={
          autoSave ? null : (
            <Button
              theme={ButtonTheme.default_green}
              onClick={() => onChange(currentValueRef.current)}
              className="h-auto w-12 flex-shrink-0"
            >
              <FontAwesomeIcon icon={["far", "check"]} />
            </Button>
          )
        }
      />
    </Modal>
  );
};

export default SelectItemsModal;
