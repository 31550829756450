import React, { useEffect } from "react";
import { GraphandForm } from "graphand-react";
import { getGlobalClient } from "../../utils/graphand";
import { useOrganization } from "../../utils/hooks";
import { readySubject, titleSubject } from "../../components/LayoutGlobal";
import ProjectsList from "./ProjectsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const CreateProject = () => {
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const client = getGlobalClient();
  const Project = client.getModel("Project");

  useEffect(() => {
    if (organization) {
      titleSubject.next("Créer un projet");
    }

    readySubject.next(true);

    return () => {
      titleSubject.next(null);
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return <ProjectsList />;
  }

  const _handleSubmit = async (
    values: Partial<InstanceType<typeof Project>>
  ) => {
    const project = await Project.create({
      ...values,
      locales: [values.defaultLocale].filter(Boolean),
      organization,
    });
    navigate("/");
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-2">🚀 Un nouveau commencement !</h1>
      <p className="mb-8">
        Vous êtes sur le point de créer un nouveau projet au sein de
        l'organisation <strong>{organization.name}</strong>. Si vous souhaitez
        créer un projet dans une organisation différente, vous pouvez retourner{" "}
        <Link to="/" className="text-button hover:underline">
          à la liste des projets
        </Link>{" "}
        pour changer d'organisation.
      </p>
      <div className="max-w-lg mx-auto text-base">
        <GraphandForm model={Project} onSubmit={_handleSubmit}>
          {({ formRef, handleSubmit, fields, isLoading }) => {
            return (
              <form
                ref={formRef}
                onSubmit={isLoading ? null : handleSubmit}
                className={isLoading ? "cursor-progress" : ""}
              >
                <div
                  className={`space-y-6 mb-8 transition ${
                    isLoading ? "opacity-50" : "opacity-100"
                  }`}
                >
                  {fields.render("name", {
                    disabled: isLoading,
                    placeholder: "Nouveau projet",
                  })}
                  {fields.render("slug", {
                    disabled: isLoading,
                    placeholder: "nouveau-projet",
                    beforeHelper: "https://",
                    afterHelper: ".graphand.com",
                  })}
                  {fields.render("defaultLocale", {
                    disabled: isLoading,
                    placeholder: "Langue par défaut",
                  })}
                </div>
                <button
                  type="submit"
                  className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
                >
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="lg"
                    className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
                      isLoading ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  Valider
                </button>
              </form>
            );
          }}
        </GraphandForm>
      </div>
    </div>
  );
};

export default CreateProject;
