import {
  GraphandModel,
  GraphandModelDataModel,
  GraphandModelList,
} from "graphand-js";
import React from "react";
import { ModalProps } from "../components/Modal";
import { FilterModalType } from "../modals/AddFilterModal";
import { DisplayMode } from "./enums";
import { DropdownLinkGroup } from "../components/Dropdown";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { ModelPageWizardProps } from "../pages/project/ModelPage";

export type GraphandModelConstructor = typeof GraphandModel & {
  pageComponent?: React.FunctionComponent<PageComponentProps>;
  createItemComponent?: React.FunctionComponent<CreateItemComponentProps>;
  quickCreateItemComponent?: React.FunctionComponent<QuickCreateItemComponentProps>;
  updateItemComponent?: React.FunctionComponent<UpdateItemComponentProps>;
  previewItemComponent?: React.FunctionComponent<PreviewItemComponentProps>;
  listFields?: string[];
  titleField?: string;
  subtitleField?: string;
  imageField?: string;
  previewField?: string;
  infoField?: string;
  filtersOptions?: FilterModalType[];
  __dataModel?: GraphandModelDataModel;
  wizards?: {
    [key: string]: React.FunctionComponent<ModelPageWizardProps>;
  };
};

export type ModelListProps = {
  model: GraphandModelConstructor;
  defaultListFields?: string[];
  defaultSort?: string;
  labelField?: { image?: string; title?: string; subtitle?: string };
  infoField?: string;
  filters?: any;
  setFilters?: (filters: any) => void;
  filter?: FilterItem;
  sort?: string;
  setSort?: (sort: string) => void;
  staticItems?: GraphandModel[] | GraphandModelList<GraphandModel>;
  selectionStack?: SelectionStackItem[];
  setSelectionStack?: React.Dispatch<
    React.SetStateAction<SelectionStackItem[]>
  >;
  globalActions?: DropdownLinkGroup;
  inlineActions?:
    | DropdownLinkGroup
    | ((item: GraphandModel) => DropdownLinkGroup);
  listActions?:
    | DropdownLinkGroup
    | ((item: GraphandModel) => DropdownLinkGroup);
  reloadQueryBuilder?: any;
  disableFilters?: boolean;
  disableHeader?: boolean;
  disableHeaderSelection?: boolean;
  handleItemClick?: "update" | "preview" | ((item: GraphandModel) => void);
  WrapperComponent?: React.FunctionComponent<{ children: any; toolsbar: any }>;
  EmptyComponent?: any;
  createProps?: any;
  onCreate?: (item: GraphandModel) => void;
  subquery?: any;
  selectFieldsModal?: boolean;
  setSelectFieldsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  headerControls?: any;
};

export type PageComponentProps<
  T extends
    | typeof GraphandModel
    | GraphandModelConstructor = GraphandModelConstructor
> = {
  model: T;
  defaultMode?: DisplayMode;
  children?: JSX.Element;
  globalActions?: DropdownLinkGroup;
  listActions?:
    | DropdownLinkGroup
    | ((item: InstanceType<T>) => DropdownLinkGroup);
  inlineActions?:
    | DropdownLinkGroup
    | ((item: InstanceType<T>) => DropdownLinkGroup);
};

export type UpdateItemComponentProps = {
  model: GraphandModelConstructor;
  item: GraphandModel;
  setModalProps?: React.Dispatch<React.SetStateAction<Partial<ModalProps>>>;
  handleUpdate?: (values: any) => Promise<any> | any;
  onUpdate: (item?: GraphandModel) => void;
  [prop: string]: any;
};

export type PreviewItemComponentProps = {
  model: GraphandModelConstructor;
  item: GraphandModel;
  setModalProps?: React.Dispatch<React.SetStateAction<Partial<ModalProps>>>;
  [prop: string]: any;
};

export type CreateItemComponentProps = {
  model: GraphandModelConstructor;
  setModalProps?: React.Dispatch<React.SetStateAction<Partial<ModalProps>>>;
  onCreate: (item?: GraphandModel) => void;
  initialValues?: any;
  [prop: string]: any;
};

export type QuickCreateItemComponentProps = {
  model: GraphandModelConstructor;
  onCreate: (item: GraphandModel) => void;
  [prop: string]: any;
};

export type SelectionStackItem = {
  query: any;
  displayQuery?: any;
  inverse: boolean;
};

export enum FilterConfType {
  DEFAULT,
  INTELLIGENT_RELATION,
  INTELLIGENT_OPTIONS,
  MEDIA_TYPE,
}

export type FilterConf<T extends FilterConfType> =
  T extends FilterConfType.INTELLIGENT_RELATION
    ? {
        type: T;
        query: any;
        field: string;
        icon?: IconName;
      }
    : T extends FilterConfType.INTELLIGENT_OPTIONS
    ? {
        type: T;
        field: string;
        icon?: IconName;
      }
    : T extends FilterConfType.MEDIA_TYPE
    ? {
        type: T;
        icon?: IconName;
        types: string[];
      }
    : {
        type: T;
        label: string;
        query: any;
        icon?: IconName;
      };

export type FilterItem = {
  label?: string | JSX.Element;
  query?: any;
  ids?: string[];
  icon?: IconName;
  color?: string;
};
