import React, { Fragment, useEffect, useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownLink } from "./Dropdown";
import Tippy from "@tippyjs/react";

export enum ModalCloseType {
  DEFAULT = 1,
  BACK = 2,
}

export enum ModalTransitionType {
  DEFAULT = 1,
  SCALE = 2,
}

export type ModalProps = {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  onConfirm?: (open: boolean) => void;
  title?: string;
  closeType?: ModalCloseType;
  transitionType?: ModalTransitionType;
  children?: any;
  className?: string;
  fullscreenClassName?: string;
  topActions?: (DropdownLink | null)[];
  beforeClose?: () => Promise<boolean> | boolean;
  expandable?: boolean;
  defaultFullscreen?: boolean;
};

const Modal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  title,
  closeType,
  transitionType,
  className = "",
  fullscreenClassName = "max-w-screen-xl",
  topActions,
  beforeClose,
  expandable = false,
  defaultFullscreen = false,
}) => {
  const displayTitle = title || closeType;
  closeType = closeType ?? ModalCloseType.DEFAULT;
  let closeButtonRef = useRef(null);
  const [fullscreen, setFullscreen] = useState(defaultFullscreen);
  const transitionContainerRef = useRef<React.FunctionComponent<any>>();

  const _handleKeyress = (e: KeyboardEvent) => {
    if (e.which === 13) {
      onConfirm?.(true);
    }
  };

  useEffect(() => {
    if (onConfirm) {
      if (isOpen) {
        window.addEventListener("keydown", _handleKeyress);
      } else {
        window.removeEventListener("keydown", _handleKeyress);
      }

      return () => window.removeEventListener("keydown", _handleKeyress);
    }
  }, [isOpen]);

  useEffect(() => {
    transitionContainerRef.current = _getTransitionContainer();
  }, [transitionType]);

  const _getTransitionContainer = () => {
    let TransitionContainer = ({ children }: any) => (
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300 delay-100"
        enterFrom="opacity-0 -translate-y-8"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-8"
      >
        {children}
      </Transition.Child>
    );

    switch (transitionType) {
      case ModalTransitionType.SCALE:
        TransitionContainer = ({ children }: any) => (
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-200 delay-75"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            {children}
          </Transition.Child>
        );
        break;
      case ModalTransitionType.DEFAULT:
      default:
        break;
    }

    return TransitionContainer;
  };

  const _handleClose = async (value: any) => {
    const res = await beforeClose?.();
    if (beforeClose && !res) {
      return;
    }

    onClose(false);
  };

  const TransitionContainer =
    transitionContainerRef.current as React.FunctionComponent<any>;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={_handleClose}
        initialFocus={closeButtonRef}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200 delay-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            aria-hidden="true"
            className={`fixed inset-0 backdrop-blur-sm transition-all ${
              fullscreen
                ? "bg-white"
                : "bg-white sm:bg-gray-700 sm:bg-opacity-25"
            }`}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex flex-col min-h-full items-center justify-center text-center ${
              fullscreen ? "" : "p-0 sm:p-4"
            }`}
          >
            <TransitionContainer>
              <Dialog.Panel as={Fragment}>
                <div
                  className={`modal w-full transform bg-white text-left align-middle transition-all ${
                    fullscreen
                      ? `fullscreen ${fullscreenClassName}`
                      : `sm:rounded-lg sm:shadow-xl ${className}`
                  }`}
                >
                  {displayTitle ? (
                    <Dialog.Title as={Fragment}>
                      <div className="space-x-4 my-1 lg:my-2 z-30 bg-white sticky top-0 flex items-center justify-between w-full px-4 lg:px-6 py-2">
                        <div className="text-xl sm:text-2xl font-medium flex items-center w-full flex-shrink overflow-hidden">
                          {closeType === ModalCloseType.BACK ? (
                            <button
                              ref={closeButtonRef}
                              type="button"
                              onClick={_handleClose}
                              className="h-6 w-6 -mr-6 flex items-center justify-center text-xl mr-2 flex-shrink-0 relative z-20"
                            >
                              <FontAwesomeIcon icon={["far", "arrow-left"]} />
                            </button>
                          ) : null}

                          {closeType === ModalCloseType.BACK ? (
                            <div className="w-full truncate text-center">
                              {title}
                            </div>
                          ) : (
                            <div className="w-full truncate">{title}</div>
                          )}
                        </div>

                        <div
                          className="flex items-center justify-center space-x-4 focus:outline-none"
                          tabIndex={0}
                        >
                          {topActions?.map((action) => {
                            if (!action) {
                              return null;
                            }

                            const _content = action.link ? (
                              <a
                                href={action.link}
                                download
                                target="_blank"
                                className="h-4 w-4 flex items-center justify-center flex-shrink-0 text-base focus:outline-none"
                                rel="noreferrer"
                              >
                                {action.label}
                              </a>
                            ) : (
                              <button
                                type="button"
                                onClick={action.onClick}
                                className="h-4 w-4 flex items-center justify-center flex-shrink-0 text-base focus:outline-none"
                              >
                                {action.label}
                              </button>
                            );

                            return action.helper ? (
                              <Tippy content={action.helper} placement="bottom">
                                {_content}
                              </Tippy>
                            ) : (
                              _content
                            );
                          })}

                          {expandable ? (
                            <Tippy
                              content={fullscreen ? "Diminuer" : "Agrandir"}
                              placement="bottom"
                            >
                              <button
                                type="button"
                                onClick={() => setFullscreen((f) => !f)}
                                className="h-4 w-4 items-center justify-center flex-shrink-0 hidden sm:flex text-base focus:outline-none"
                              >
                                <FontAwesomeIcon
                                  icon={[
                                    "far",
                                    fullscreen ? "compress" : "expand",
                                  ]}
                                />
                              </button>
                            </Tippy>
                          ) : null}

                          {closeType === ModalCloseType.DEFAULT ? (
                            <button
                              ref={closeButtonRef}
                              type="button"
                              onClick={_handleClose}
                              className="h-4 w-4 flex items-center justify-center text-xl flex-shrink-0"
                            >
                              <FontAwesomeIcon icon={["far", "xmark"]} />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </Dialog.Title>
                  ) : null}

                  {children}
                </div>
              </Dialog.Panel>
            </TransitionContainer>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
