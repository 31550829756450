import React from "react";
import { PreviewItemComponentProps } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";

const PreviewAggregation: React.FunctionComponent<
  PreviewItemComponentProps
> = ({ model, item }) => {
  // useEffect(() => {
  //   setModalProps?.({
  //     topActions: [
  //       {
  //         label: <DownloadButton item={item as GraphandModelMedia} />,
  //         onClick: () => null,
  //       },
  //     ],
  //   });
  // }, []);

  return (
    <div className="space-y-3">
      {item.renderFieldView("name", {
        context: ViewComponentContext.PREVIEW_LINE,
      })}
      {item.renderFieldView("description", {
        theme: "textarea",
        context: ViewComponentContext.PREVIEW_LINE,
      })}
      {item.renderFieldView("scope", {
        context: ViewComponentContext.PREVIEW_LINE,
      })}
      {item.renderFieldView("pipeline", {
        context: ViewComponentContext.PREVIEW_LINE,
      })}
      {item.renderFieldView("defaultVars", {
        context: ViewComponentContext.PREVIEW_LINE,
      })}
      {item.cache ? (
        <>
          {item.renderFieldView("cacheExpiredToleration", {
            context: ViewComponentContext.PREVIEW_LINE,
          })}
          {item.renderFieldView("cacheMaxAge", {
            context: ViewComponentContext.PREVIEW_LINE,
          })}
          {item.renderFieldView("cacheKey", {
            context: ViewComponentContext.PREVIEW_LINE,
          })}
        </>
      ) : null}
    </div>
  );
};

export default PreviewAggregation;
