import React from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CreateItemComponentProps } from "../utils/types";
import CreateItemWrapper from "./CreateItemWrapper";

const CreateAggregationFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
  values,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("name")}
        {fields.render("description", { theme: "textarea" })}
        {fields.render("scope")}
        {fields.render("pipeline", { defaultValue: "[]" })}
        {fields.render("defaultVars")}
        {fields.render("cache")}
        {values.cache ? (
          <>
            {fields.render("cacheExpiredToleration")}
            {fields.render("cacheMaxAge")}
            {fields.render("cacheKey")}
          </>
        ) : null}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.createLoading" : "actions.create")}
      </Button>
    </form>
  );
};

const CreateAggregation: React.FunctionComponent<CreateItemComponentProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <CreateItemWrapper
      {...props}
      template={CreateAggregationFormTemplate}
      title={t("title.createAggregation")}
    />
  );
};

export default CreateAggregation;
