import React, { useEffect, useState } from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import { GraphandFieldRelation } from "graphand-js";
import SelectItemsModal from "../../../modals/SelectItemsModal";
import { getProjectClient } from "../../../utils/graphand";
import { ModalCloseType } from "../../../components/Modal";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { GraphandModelConstructor } from "../../../utils/types";
import { generateBorderDashed } from "../../../utils/tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { faCamera, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { useDropzone } from "react-dropzone";
import { ViewComponentContext } from "../../../utils/enums";
import { handleLayoutDropzone } from "../../../components/LayoutProject";

const InputRelationSingleImage: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { slug, value, onChange, options, errors, item } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const client = getProjectClient();

  useEffect(() => {
    handleLayoutDropzone.next(false);

    return () => handleLayoutDropzone.next(true);
  }, []);

  const onDrop = async (files: any) => {
    const [file] = files;
    if (!file || !client) {
      return;
    }

    setLoading(true);
    const Media = client.getModel("Media");

    let item;

    try {
      item = await Media.create({ file });
    } catch (e) {}

    setLoading(false);

    if (item) {
      if (Object.keys(query).length) {
        const found = await model.count({
          query: { $and: [query, { _id: item._id }] },
        });

        if (found) {
          onChange?.(item._id);
        }
      } else {
        onChange?.(item._id);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;

  const model = client.getModel(field.ref) as GraphandModelConstructor;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  const itemValue = item?.get(slug, false);
  const reinitValue = options.reinitValue ?? itemValue;

  let query = options.query ?? field.query ?? {};
  query = { $and: [query, { mimetype: { $regex: "image" } }] };

  const Media = client.getModel("Media");
  const loading = isLoading || props.form?.isLoading;

  return (
    <>
      <FieldInputContainer {...props} label={null}>
        <div
          className="flex items-center justify-center"
          onClick={() => setModalOpen(true)}
        >
          <div
            className={`relative h-36 w-36 p-3 rounded-full inline-flex justify-center group cursor-pointer select-none overflow-hidden ${
              loading ? "items-end" : "items-center"
            }`}
            style={{
              backgroundImage: generateBorderDashed({ color: "#D1D5DB" }),
              backgroundSize: "cover",
            }}
            {...getRootProps()}
          >
            {value
              ? Media.get(value).suspense(
                  (media: any) => (
                    <div className="absolute inset-0 -z-10 rounded-full overflow-hidden">
                      {media.renderFieldView("_preview", {
                        context: ViewComponentContext.GRID_LABEL_IMAGE,
                      })}
                    </div>
                  ),
                  { subscribe: true, updateKey: value }
                )
              : null}
            <input tabIndex={1} {...getInputProps()} />
            <div
              className={`w-8 h-8 flex items-center justify-center rounded-full ${
                loading
                  ? "bg-button text-white"
                  : "bg-gray-500 text-gray-300 group-hover:bg-button group-hover:text-white group-hover:opacity-100 opacity-80"
              }`}
            >
              {loading ? (
                <FontAwesomeIcon icon={faLoader} className="animate-spin" />
              ) : (
                <FontAwesomeIcon icon={value ? faPencil : faCamera} />
              )}
            </div>
          </div>
        </div>

        <FieldInputErrors
          errors={errors}
          label={label}
          slug={slug}
          model={model}
        />
      </FieldInputContainer>

      <SelectItemsModal
        isOpen={modalOpen}
        closeType={ModalCloseType.BACK}
        onClose={setModalOpen}
        value={value}
        query={query}
        multiple={false}
        autoSave
        onChange={(v) => {
          onChange?.(v);
          setModalOpen(false);
        }}
        model={model}
      />
    </>
  );
};

export default InputRelationSingleImage;
