import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "swiper/css";
import "swiper/css/effect-cards";
import App from "./App";
import "./utils/i18n";
import { getProjectMatch } from "./utils/getProjectMatch";
import { createProjectClient, projectClientSubject } from "./utils/graphand";
import { getCookie } from "./utils/cookies";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

// @ts-ignore
library.add(far, fad, fal);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const projectMatch = getProjectMatch();
if (projectMatch) {
  createProjectClient({ slug: projectMatch }).then((c) => {
    if (!c) {
      return;
    }

    projectClientSubject.next(c);
  });
} else {
  const project = getCookie("graphand:project");

  if (project) {
    createProjectClient({ id: project }).then((c) => {
      projectClientSubject.next(c);
    });
  }
}

root.render(<App />);
