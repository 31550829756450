import React from "react";
import { SelectOptionComponentProps } from "../../components/Select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const AddFilterSelectTypeOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option, handleClick, selected }) => {
  const { t } = useTranslation();

  return (
    <li
      onClick={handleClick}
      className="w-full bg-white border border-gray-200 rounded-2xl p-6 cursor-pointer hover:bg-gray-100"
    >
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={option}
            name={option}
            type="radio"
            checked={selected}
            className="focus:ring-indigo-500 h-4 w-4 text-button border-gray-200"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={option} className="flex items-center">
            <FontAwesomeIcon
              className="mr-2"
              icon={[
                "fad",
                t(`enums.FilterModalType.${option}.icon`) as IconName,
              ]}
            />
            <div className="font-medium text-gray-700">
              {t(`enums.FilterModalType.${option}.title`)}
            </div>
          </label>
          <p className="text-gray-500">
            {t(`enums.FilterModalType.${option}.description`)}
          </p>
        </div>
      </div>
    </li>
  );
};

export default AddFilterSelectTypeOptionComponent;
