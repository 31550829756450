import React, { useEffect, useState } from "react";
import { PreviewItemComponentProps } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";
import MediaPlayer from "./MediaPlayer";
import Media from "../models/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GraphandModelMedia } from "graphand-js";
import { useTranslation } from "react-i18next";

const DownloadButton: React.FunctionComponent<{ item: GraphandModelMedia }> = ({
  item,
}) => {
  const [loading, setLoading] = useState(false);

  const _download = async () => {
    setLoading(true);
    try {
      await item.download();
    } catch (e) {}

    setLoading(false);
  };

  return (
    <div className="h-6 w-6 flex items-center justify-center">
      {loading ? (
        <div>
          <FontAwesomeIcon icon={["far", "loader"]} className="animate-spin" />
        </div>
      ) : (
        <div onClick={_download}>
          <FontAwesomeIcon icon={["far", "download"]} />
        </div>
      )}
    </div>
  );
};

const PreviewMedia: React.FunctionComponent<PreviewItemComponentProps> = ({
  model,
  item,
  setModalProps,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setModalProps?.({
      topActions: [
        {
          label: <DownloadButton item={item as GraphandModelMedia} />,
          onClick: () => null,
          helper: t("actions.download"),
        },
      ],
    });
  }, []);

  const fields = Object.keys(model.fields).filter((f) => {
    return (
      !f.startsWith("_") &&
      !["createdAt", "createdBy", "updatedAt", "updatedBy"].includes(f)
    );
  });

  return (
    <div className="space-y-3">
      <MediaPlayer media={item as Media} />

      {fields.map((f) => {
        return item.renderFieldView(f, {
          context: ViewComponentContext.PREVIEW_LINE,
        });
      })}
    </div>
  );
};

export default PreviewMedia;
