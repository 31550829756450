import React from "react";
import { GraphandFieldText } from "graphand-js";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";

export type InputTextOptionsListContainerComponentProps = { children: any };

export type InputTextOptionsListOptionComponentProps = {
  option: string;
  handleClick: () => void;
  selected: boolean;
};

export type InputTextOptionsListOption = {
  ContainerComponent?: React.FunctionComponent<InputTextOptionsListContainerComponentProps>;
  OptionComponent?: React.FunctionComponent<InputTextOptionsListOptionComponentProps>;
};

const InputTextOptionsList: React.FunctionComponent<
  Partial<
    InputComponentProps & {
      options?: InputTextOptionsListOption;
    }
  >
> = (props) => {
  const { slug, value, field, onChange, options, errors, model } = props;
  const { t } = useTranslation();
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  const ContainerComponent =
    options.ContainerComponent ?? (({ children }: any) => <ul>{children}</ul>);

  const OptionComponent =
    options.OptionComponent ??
    (({
      option,
      handleClick,
      selected,
    }: {
      option: string;
      handleClick: () => void;
      selected: boolean;
    }) => (
      <li className={selected ? "font-bold" : ""} onClick={handleClick}>
        {option}
      </li>
    ));

  const _handleClick = (option: string) => {
    let _value;

    if (field?.multiple) {
      _value = Array.isArray(value) ? value : [value].filter(Boolean);
      if (_value.includes(option)) {
        _value = _value.filter((v: any) => v !== option);
      } else {
        _value = _value.concat(option);
      }
    } else {
      _value = option;
    }

    onChange?.(_value);
  };

  return (
    <FieldInputContainer {...props} label={label}>
      <ContainerComponent>
        {(field as GraphandFieldText)?.options?.map((option: string) => {
          const selected = Array.isArray(value)
            ? value.includes(option)
            : option === value;

          return (
            <OptionComponent
              key={option}
              selected={selected}
              option={option}
              handleClick={() => _handleClick(option)}
            />
          );
        })}
      </ContainerComponent>

      <FieldInputErrors
        errors={errors}
        label={label}
        slug={slug}
        model={model}
      />
    </FieldInputContainer>
  );
};

export default InputTextOptionsList;
