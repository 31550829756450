import React, { useState } from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import Select, { SelectOptionComponentProps } from "../../../components/Select";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { getProjectClient } from "../../../utils/graphand";
import { GraphandFieldRelation, GraphandModel } from "graphand-js";
import { GraphandModelConstructor } from "../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateItemModal from "../../../modals/CreateItemModal";

const InputRelationSingleSelectOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps & {
    model: GraphandModelConstructor;
  }
> = (props) => {
  const { option, handleClick, model } = props;
  // @ts-ignore
  const { previewField } = model;
  return (
    <div onClick={handleClick}>
      {previewField
        ? model
            .get(option)
            .suspense((item) => item.renderFieldView(previewField), option)
        : option}
    </div>
  );
};

const InputRelationSingleSelect: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { slug, value, onChange, options, errors, inputRef } = props;
  const [createItemModal, setCreateItemModal] = useState(false);
  const { t } = useTranslation();
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;

  const model = client.getModel(field.ref) as GraphandModelConstructor;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  let query = options.query ?? field.query ?? {};

  const _handleCreate = async (item: GraphandModel) => {
    if (Object.keys(query).length) {
      const found = await model.count({
        query: { $and: [query, { _id: item._id }] },
      });

      if (found) {
        onChange?.(item._id);
      }
    } else {
      onChange?.(item._id);
    }
  };

  return (
    <>
      <FieldInputContainer
        {...props}
        label={label}
        links={[
          {
            label: t("actions.select_none"),
            disabled: !value?.length,
            onClick: () => onChange?.(undefined),
          },
        ]}
        controls={[
          <button
            type="button"
            className="text-button hover:text-button-hover flex items-center"
            onClick={() => setCreateItemModal(true)}
          >
            <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
            {t("actions.create")}
          </button>,
        ]}
      >
        {model.getList({ query, count: true }).suspense(
          (list) => {
            return (
              <Select
                value={value ?? options.placeholderValue}
                inlineValue
                closable={false}
                disabled={!list.count}
                hasError={Boolean(errors?.length)}
                inputRef={inputRef}
                onChange={(v) => onChange?.(v)}
                OptionComponent={(_props) => (
                  <InputRelationSingleSelectOptionComponent
                    {..._props}
                    model={model}
                  />
                )}
                options={list.ids}
                beforeHelper={options.beforeHelper}
                afterHelper={options.afterHelper}
              />
            );
          },
          {
            fallback: (
              <Select
                value={value}
                inlineValue
                closable={false}
                hasError={Boolean(errors?.length)}
                inputRef={inputRef}
                onChange={(v) => onChange?.(v)}
                OptionComponent={(_props) => (
                  <InputRelationSingleSelectOptionComponent
                    {..._props}
                    model={model}
                  />
                )}
                options={[]}
                beforeHelper={options.beforeHelper}
                afterHelper={options.afterHelper}
              />
            ),
          }
        )}

        <FieldInputErrors
          errors={errors}
          slug={slug}
          label={label}
          model={model}
        />
      </FieldInputContainer>

      <CreateItemModal
        isOpen={createItemModal}
        onClose={setCreateItemModal}
        model={model}
        props={{ query }}
        onCreate={_handleCreate}
      />
    </>
  );
};

export default InputRelationSingleSelect;
