import Modal, { ModalProps } from "../components/Modal";
import Select from "../components/Select";
import React, { useEffect, useState } from "react";
import { GraphandModel } from "graphand-js";
import Button from "../components/Button";
import GraphandFieldIcon from "../components/GraphandFieldIcon";
import { capitalizeFirstLetter } from "../utils/formatter";
import { useTranslation } from "react-i18next";
import { snakeCase } from "lodash";

const SelectFieldsModal: React.FunctionComponent<
  ModalProps & {
    value: any;
    onChange: (v: any) => void;
    model: typeof GraphandModel;
  }
> = ({ value, onChange, model, ...modalProps }) => {
  const [currentValue, setValue] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    if (modalProps.isOpen) {
      setValue(value);
    }
  }, [modalProps.isOpen]);

  const _handleConfirm = () => {
    modalProps.onClose(false);
    onChange(currentValue);
  };

  return (
    <Modal
      {...modalProps}
      title="Modifier les champs"
      className="max-w-screen-sm"
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <Select
          multiple
          value={currentValue}
          onChange={setValue}
          options={Object.keys(model.fields)}
          filter={(option, input) => {
            return (
              snakeCase(
                model.fields[option]?.__dataField?.name ||
                  t(`labels.fields.${option}.default`)
              ).includes(snakeCase(input)) ||
              snakeCase(option).includes(snakeCase(input))
            );
          }}
          OptionComponent={(props) => {
            const { option, active, display } = props;
            if (!option) {
              return null;
            }

            return (
              <div
                className={`flex cursor-pointer select-none items-center rounded-md py-1 ${
                  active ? "bg-gray-200" : ""
                }`}
              >
                {display !== "input" ? (
                  <div className="h-6 w-6 flex items-center justify-center mr-3">
                    <GraphandFieldIcon
                      field={model.fields[option]}
                      slug={option}
                      theme="fad"
                      className="text-xl"
                    />
                  </div>
                ) : null}
                <span className="flex-auto truncate">
                  {capitalizeFirstLetter(
                    model.fields[option]?.__dataField?.name ||
                      t(`labels.fields.${option}.default`)
                  )}
                </span>
              </div>
            );
          }}
        />

        <Button className="mt-4" onClick={_handleConfirm}>
          Valider
        </Button>
      </div>
    </Modal>
  );
};

export default SelectFieldsModal;
