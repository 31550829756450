import Modal, { ModalCloseType, ModalProps } from "../components/Modal";
import React, { useEffect, useState } from "react";
import Dropdown, { DropdownPosition } from "../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GraphandModel, GraphandModelDataModel } from "graphand-js";
import { useTranslation } from "react-i18next";
import { getFiltersFromFilterConf } from "../utils/tools";
import Button, { ButtonTheme } from "../components/Button";
import { FilterConf, FilterItem } from "../utils/types";
import { useModelFilters } from "../utils/hooks";

const ManageFiltersModalItem: React.FunctionComponent<{
  filterConf: FilterConf<any>;
  model: typeof GraphandModel;
  onDelete: () => void;
  onOpen: () => void;
  onClose: () => void;
}> = ({ filterConf, model, onDelete, onClose, onOpen }) => {
  const [modelFilters, setModelFilters] = useState<FilterItem[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getFiltersFromFilterConf(filterConf, model, t).then(setModelFilters);
  }, [filterConf]);

  return (
    <div className="w-full flex items-center justify-between space-x-4 py-2">
      <ul className="relative flex items-center space-x-4 flex-shrink overflow-hidden text-gray-500">
        {modelFilters.map((filter) => (
          <li className="inline-flex flex items-center flex-shrink-0">
            <FontAwesomeIcon
              icon={["fad", filter.icon || "filters"]}
              className="mr-2 text-sm"
            />
            {filter.label}
          </li>
        ))}
      </ul>
      <Dropdown
        onOpen={onOpen}
        onClose={onClose}
        position={DropdownPosition.RIGHT}
        button={
          <button
            type="button"
            className="text h-10 w-10 rounded-md flex items-center justify-center focus:outline-none select-none text-xl text-gray-700 hover:bg-gray-200"
          >
            <FontAwesomeIcon icon={["far", "xmark"]} />
          </button>
        }
        className="w-72"
      >
        <div className="p-4 space-y-4">
          <div>Voulez-vous vraiment supprimer ce filtre ?</div>
          <button
            type="button"
            className="bg-red-500 h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button-red rounded-xl border border-white text-white"
            onClick={onDelete}
          >
            Confirmer
          </button>
        </div>
      </Dropdown>
    </div>
  );
};

const ManageFiltersModal: React.FunctionComponent<
  ModalProps & {
    dataModel: GraphandModelDataModel;
    model: typeof GraphandModel;
    handleAdd: () => void;
  }
> = (props) => {
  const [itemModalOpen, setItemModalOpen] = useState<FilterConf<any> | null>(
    null
  );
  const { dataModel, model, handleAdd, ...modalProps } = props;
  const { filterConfs, setFilters } = useModelFilters(dataModel);

  const _handleDeleteFilter = (filter: FilterConf<any>) => {
    setFilters(filterConfs.filter((f) => f !== filter));
    setItemModalOpen(null);
    if (filterConfs?.length === 1) {
      modalProps.onClose?.(false);
    }
  };

  useEffect(() => {
    if (modalProps.isOpen) {
      setItemModalOpen(null);
    }
  }, [modalProps.isOpen]);

  return (
    <Modal
      {...modalProps}
      closeType={ModalCloseType.BACK}
      title="Gérer les filtres"
      className="max-w-screen-sm"
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        {filterConfs?.length ? (
          <div>
            {filterConfs.map((conf) => (
              <div
                className={`transition-opacity ${
                  itemModalOpen && itemModalOpen !== conf ? "opacity-25" : ""
                }`}
              >
                <ManageFiltersModalItem
                  key={JSON.stringify(conf)}
                  onDelete={() => _handleDeleteFilter(conf)}
                  model={model}
                  filterConf={conf}
                  onOpen={() => setItemModalOpen(conf)}
                  onClose={() => setItemModalOpen(null)}
                />
              </div>
            ))}
          </div>
        ) : null}
        {handleAdd ? (
          <Button
            theme={ButtonTheme.inline}
            onClick={handleAdd}
            className={`text-button hover:text-button-hover transition-opacity ${
              itemModalOpen ? "opacity-25" : ""
            }`}
          >
            <FontAwesomeIcon icon={["fad", "plus"]} className="mr-2 text-sm" />
            Ajouter un filtre
          </Button>
        ) : null}
      </div>
    </Modal>
  );
};

export default ManageFiltersModal;
