import React from "react";
import { SelectProps } from "./Select";

type ListPickerProps = SelectProps & {
  ContainerComponent?: React.FunctionComponent<{ children: any }>;
};

const ListPicker: React.FunctionComponent<ListPickerProps> = ({
  options,
  OptionComponent,
  ContainerComponent,
  value,
  onChange,
}) => {
  const _OptionComponent =
    OptionComponent ??
    (({ option, handleClick }) => <li onClick={handleClick}>{option}</li>);
  const _ContainerComponent =
    ContainerComponent ?? (({ children }) => <ul>{children}</ul>);

  return (
    <_ContainerComponent>
      {options.map((option) => {
        return (
          <_OptionComponent
            key={option}
            option={option}
            selected={value === option}
            display="list"
            handleClick={() => onChange(option)}
          />
        );
      })}
    </_ContainerComponent>
  );
};

export default ListPicker;
