import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import NavbarGlobal from "./NavbarGlobal";
import { useOrganization, setOrganization } from "../utils/hooks";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowLeft,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const titleSubject = new BehaviorSubject<any>(undefined);
export const controlSubject = new BehaviorSubject<any>(undefined);
export const readySubject = new BehaviorSubject<boolean>(false);

const LayoutGlobalOrganizationPicker: FunctionComponent = () => {
  const { organization, list } = useOrganization();

  if (!organization || !list?.length) {
    return (
      <div className="relative mt-1 w-full">
        <Link
          to="/new"
          className="cursor-pointer w-full text-3xl md:text-4xl h-12 md:h-18 flex items-center text-white font-poppins font-bold text-shadow-md flex items-center"
        >
          <span className="text-3xl mr-4">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="block truncate py-2">
            Créer une nouvelle organisation
          </span>
        </Link>
      </div>
    );
  }

  return (
    <Listbox value={organization} onChange={setOrganization}>
      <div className="relative mt-1 w-full">
        <Listbox.Button className="cursor-pointer w-full text-3xl md:text-4xl lg:text-6xl h-12 md:h-18 lg:h-24 flex items-center text-white font-poppins font-bold text-shadow-md flex items-center">
          <span className="block truncate py-2">
            {organization?.name || "-"}
          </span>
          <span className="text-3xl ml-4">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-in duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute -mt-24 z-20 w-full overflow-visible rounded-md focus:outline-none">
            <Listbox.Button className="fixed bg-black inset-0 bg-opacity-25 backdrop-blur-md" />
            <Transition.Child
              as="div"
              enter="transform transition ease-in duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transform transition ease-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {organization ? (
                <Listbox.Option
                  key={organization._id}
                  className={({ active }) =>
                    `relative cursor-pointer text-3xl md:text-4xl lg:text-6xl h-12 md:h-18 lg:h-24 flex items-center text-white font-poppins font-bold text-shadow-md flex items-center hover:text-primary ${
                      active ? "" : ""
                    }`
                  }
                  value={organization}
                >
                  <span className="block truncate py-2">
                    {organization?.name || "-"}
                  </span>
                  <span className="text-3xl ml-4 text-white">
                    <Transition.Child
                      as="div"
                      enter="transform transition ease-in duration-200"
                      enterFrom="rotate-0"
                      enterTo="-rotate-180"
                      leave="transform transition ease-in duration-200"
                      leaveFrom="-rotate-180"
                      leaveTo="rotate-0"
                    >
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Transition.Child>
                  </span>
                </Listbox.Option>
              ) : null}
              {list?.length
                ? list
                    .filter((o) => o !== organization)
                    .map((o) => (
                      <Listbox.Option
                        key={o._id}
                        className={({ active }) =>
                          `relative cursor-pointer text-3xl md:text-4xl lg:text-6xl h-12 md:h-18 lg:h-24 flex items-center text-white font-poppins font-bold text-shadow-md flex items-center hover:text-primary ${
                            active ? "" : ""
                          }`
                        }
                        value={o}
                      >
                        <span className="block truncate py-2">
                          {o?.name || "-"}
                        </span>
                      </Listbox.Option>
                    ))
                : null}
              <Link to="/new">
                <Listbox.Button
                  className={
                    "relative cursor-pointer text-3xl h-12 flex items-center text-white font-poppins font-bold text-shadow-md flex items-center hover:text-primary"
                  }
                >
                  <span className="text-3xl mr-4">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span className="block truncate py-2">
                    Créer une nouvelle organisation
                  </span>
                </Listbox.Button>
              </Link>
            </Transition.Child>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

const LayoutGlobalMenu: FunctionComponent = () => {
  const { organization } = useOrganization();

  if (!organization) {
    return null;
  }

  return (
    <nav className="inline-flex h-11 space-x-1 rounded-xl bg-gray-100 p-1.5">
      <NavLink
        to="/"
        className={({ isActive }) =>
          `flex items-center justify-center cursor-pointer rounded-lg px-3 text-sm font-medium leading-5 focus:outline-none ${
            isActive
              ? "text-black bg-white shadow"
              : "text-gray-700 hover:bg-gray-200"
          }`
        }
      >
        Projets
      </NavLink>
      <NavLink
        to="/members"
        className={({ isActive }) =>
          `flex items-center justify-center cursor-pointer rounded-lg px-3 text-sm font-medium leading-5 focus:outline-none ${
            isActive
              ? "text-black bg-white shadow"
              : "text-gray-700 hover:bg-gray-200"
          }`
        }
      >
        Membres
      </NavLink>
      <NavLink
        to="/invitations"
        className={({ isActive }) =>
          `flex items-center justify-center cursor-pointer rounded-lg px-3 text-sm font-medium leading-5 focus:outline-none ${
            isActive
              ? "text-black bg-white shadow"
              : "text-gray-700 hover:bg-gray-200"
          }`
        }
      >
        Invitations
      </NavLink>
      <NavLink
        to="/payment"
        className={({ isActive }) =>
          `flex items-center justify-center cursor-pointer rounded-lg px-3 text-sm font-medium leading-5 focus:outline-none ${
            isActive
              ? "text-black bg-white shadow"
              : "text-gray-700 hover:bg-gray-200"
          }`
        }
      >
        Paiements
      </NavLink>
      <NavLink
        to="/settings"
        className={({ isActive }) =>
          `flex items-center justify-center cursor-pointer rounded-lg px-3 text-sm font-medium leading-5 focus:outline-none ${
            isActive
              ? "text-black bg-white shadow"
              : "text-gray-700 hover:bg-gray-200"
          }`
        }
      >
        Paramètres
      </NavLink>
    </nav>
  );
};

const LayoutGlobal: FunctionComponent<any> = ({
  children,
  transitioning,
  ready,
  ...props
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(titleSubject.getValue());
  const [control, setControl] = useState(controlSubject.getValue());
  const { organization } = useOrganization();
  const [_ready, setReady] = useState(readySubject.getValue());
  const { t } = useTranslation();

  ready = ready && _ready;

  useEffect(() => {
    const subs = [
      titleSubject.subscribe(setTitle),
      controlSubject.subscribe(setControl),
      readySubject.subscribe(setReady),
    ];

    return () => {
      subs.map((s) => s.unsubscribe());
    };
  }, []);

  return (
    <div className="bg-global min-h-screen flex flex-col">
      <Helmet>
        <title>
          {organization ? `${organization.name} | Graphand` : "Graphand"}
        </title>
      </Helmet>
      <NavbarGlobal />
      <div className="w-full px-6 h-72 flex items-center flex-shrink-0">
        <div className="w-full max-w-screen-lg mx-auto">
          <div className="h-12 md:h-18 lg:h-24 flex items-center">
            {title ? (
              <div className="cursor-pointer text-3xl md:text-4xl lg:text-6xl flex items-center text-white font-poppins font-bold text-shadow-md flex items-center">
                <span className="block truncate py-2">{title}</span>
              </div>
            ) : (
              <LayoutGlobalOrganizationPicker />
            )}
          </div>
          <div className="flex items-center justify-between space-x-6 overflow-hidden">
            <div>
              {title ? (
                <button
                  type="button"
                  onClick={() => (organization ? navigate(-1) : navigate("/"))}
                  className="flex h-11 items-center text-white font-bold cursor-pointer hover:underline"
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
                  {t(
                    organization
                      ? "actions.back_to_organization"
                      : "actions.back_to_home"
                  )}
                </button>
              ) : (
                <LayoutGlobalMenu />
              )}
            </div>
            {control || null}
          </div>
        </div>
      </div>
      <div className="bg-background flex-grow">
        <div
          {...props}
          className={`relative w-full max-w-screen-lg mx-auto lg:rounded-xl lg:shadow-2xl p-6 bg-background -top-10 transition transform ease-in duration-300 ${
            !ready || transitioning
              ? "opacity-0 -translate-y-1"
              : "opacity-100 translate-y-0"
          } ${props.className || ""}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutGlobal;
