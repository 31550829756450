export enum ViewComponentContext {
  LIST_LABEL_IMAGE,
  LIST_LABEL_TITLE,
  LIST_LABEL_SUBTITLE,
  TABLE_CELL,
  TABLE_CELL_MULTIPLE,
  GRID_LABEL_IMAGE,
  GRID_LABEL_TITLE,
  GRID_LABEL_SUBTITLE,
  GRID_LABEL_INFO,
  LIST_ROW,
  MODAL_TITLE,
  PREVIEW_LINE,
  INPUT_INLINE,
  INPUT_INLINE_MULTIPLE,
  INPUT_QUERY_BUILDER_TILE,
}

export enum DisplayMode {
  LIST = "list",
  GRID = "grid",
}

export enum InputComponentContext {
  FORM_CELL,
  QUERY_BUILDER_TILE,
}

export enum DataFieldFieldType {
  "text_default" = "text_default",
  "text_textarea" = "text_textarea",
  "text_rte" = "text_rte",
  "text_options_select" = "text_options_select",
  "text_options_list" = "text_options_list",
  "text_color" = "text_color",
  "number_default" = "number_default",
  "relation_default_single" = "relation_default_single",
  "relation_default_multiple" = "relation_default_multiple",
  "relation_media_single" = "relation_media_single",
  "relation_media_multiple" = "relation_media_multiple",
  "boolean_checkbox" = "boolean_checkbox",
  "boolean_switch" = "boolean_switch",
  "boolean_icon" = "boolean_icon",
  "date_default" = "date_default",
  "date_time" = "date_time",
  "json" = "json",
}

export enum FilterMediaTypeOptions {
  "image",
  "video",
  "pdf",
  "zip",
}
