import React, { FunctionComponent } from "react";
import Tippy from "@tippyjs/react";

export enum ButtonTheme {
  default = "default",
  default_green = "defaultGreen",
  default_red = "defaultRed",
  light = "light",
  inline = "inline",
}

const Button: FunctionComponent<{
  labelCount?: number;
  children: any;
  className?: string;
  theme?: ButtonTheme | string;
  onClick?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  onMouseUp?: (e: any) => void;
  type?: "submit" | "reset" | "button" | undefined;
  style?: any;
  disabled?: boolean;
  tooltip?: string;
}> = ({
  labelCount,
  children,
  className = "",
  theme = ButtonTheme.default,
  onClick,
  onMouseDown,
  onMouseUp,
  type = "button",
  style = {},
  disabled,
  tooltip,
}) => {
  let themeClassName;

  switch (theme) {
    case ButtonTheme.inline:
      themeClassName =
        "bg-transparent hover:bg-opacity-50 bg-gray-300 bg-opacity-0 rounded-xl border border-transparent ";
      break;
    case ButtonTheme.light:
      themeClassName =
        "bg-white hover:bg-gray-100 rounded-xl border border-gray-200 ";
      break;
    case ButtonTheme.default_green:
      themeClassName =
        "bg-green-500 hover:bg-green-700 shadow rounded-xl border border-white text-white";
      break;
    case ButtonTheme.default_red:
      themeClassName =
        "bg-red-500 hover:bg-red-700 shadow rounded-xl border border-white text-white";
      break;
    case ButtonTheme.default:
    default:
      themeClassName =
        "bg-button hover:bg-button-hover shadow-button rounded-xl border border-white text-white";
      break;
  }

  const _button = (
    <button
      type={type}
      onClick={
        disabled || !onClick
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick(e);
            }
      }
      onMouseDown={
        disabled || !onMouseDown
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onMouseDown(e);
            }
      }
      onMouseUp={
        disabled || !onMouseUp
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onMouseUp(e);
            }
      }
      className={`h-11 px-3 flex items-center justify-center text-center w-full ${
        labelCount ? "relative" : "truncate"
      } ${
        disabled ? "opacity-50" : "cursor-pointer"
      } ${themeClassName} ${className}`}
      style={style}
    >
      {labelCount ? (
        <div className="h-5 w-5 border border-red-600 absolute -top-1.5 -right-1.5 bg-red-500 rounded-full text-xs flex items-center justify-center font-medium text-white">
          {labelCount}
        </div>
      ) : null}
      {children}
    </button>
  );

  if (tooltip) {
    return (
      <Tippy content={tooltip} placement="bottom">
        {_button}
      </Tippy>
    );
  }

  return _button;
};

export default Button;
