import React from "react";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import {
  GraphandField,
  GraphandFieldBoolean,
  GraphandFieldNumber,
  GraphandFieldText,
  GraphandModel,
} from "graphand-js";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { DataFieldFieldType } from "../utils/enums";
import InputScope from "../fields/inputs/Scope";
import InputQuery from "../fields/inputs/Query";
import {
  assignDataFieldConfiguration,
  getFieldConfigurationFields,
} from "../utils/fields";

const FieldTypeForm: React.FunctionComponent<{
  fieldType?: DataFieldFieldType;
  onSubmit: (values: any) => void;
  FormComponent?: React.FunctionComponent<{
    values: any;
    onSubmit: (values: any) => void;
    fields: typeof GraphandModel.schema;
  }>;
  values?: any;
}> = ({ fieldType, onSubmit, ...props }) => {
  const _values = props.values ?? {};

  if (fieldType) {
    assignDataFieldConfiguration(fieldType, _values);
  }

  const _getFields = () => {
    if (!fieldType) {
      return {};
    }

    return getFieldConfigurationFields(fieldType);
  };

  const _getTemplate = () => {
    let Template: React.FunctionComponent<GraphandFormTemplateParams>;
    Template = (props: GraphandFormTemplateParams) => {
      const { formRef, isLoading, handleSubmit, fields } = props;
      const { t } = useTranslation();

      return (
        <form
          ref={formRef}
          onSubmit={isLoading ? null : handleSubmit}
          className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
        >
          <div className="space-y-4">{fields.renderDefault()}</div>
          <Button type="submit">{t("actions.validate")}</Button>
        </form>
      );
    };

    return Template;
  };

  const FormComponent =
    props.FormComponent ??
    (({ fields, values, onSubmit }) => (
      <GraphandForm
        key={fieldType}
        values={values}
        onSubmit={onSubmit}
        fields={fields}
        template={_getTemplate()}
      />
    ));

  return (
    <FormComponent
      key={fieldType}
      values={_values}
      onSubmit={(data: any) => onSubmit(Object.assign(_values, data))}
      fields={_getFields()}
    />
  );
};

export default FieldTypeForm;
