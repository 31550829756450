import { getProjectClient } from "../../utils/graphand";
import {
  ContentBlock,
  ContentState,
  DraftBlockType,
  EditorState,
} from "draft-js";
import InputRteBlockAtomic from "./BlockAtomic";
import { convertFromHTML, convertToHTML } from "draft-convert";
import React from "react";

export const _convertFromHTML = (html: string) => {
  return convertFromHTML({
    htmlToBlock: (nodeName) => {
      if (nodeName === "wbr") {
        return "unstyled";
      }

      if (nodeName === "img") {
        return "atomic";
      }

      return undefined;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      return convertHTMLToEntity(nodeName, node, createEntity) || undefined;
    },
  })(html);
};

export const _convertToHTML = (state: EditorState) => {
  const contentState = state.getCurrentContent();

  return convertToHTML({
    blockToHTML: (block) => {
      const nextBlock = contentState.getBlockAfter(block.key);

      if (block.type === "unstyled" && block.text === "") {
        if (nextBlock?.getType() === "atomic") {
          return <wbr />;
        }

        return <br />;
      }

      return undefined;
    },
    entityToHTML: (entity, originalText) => {
      if (entity) {
        return convertEntityToHTML(entity, originalText);
      }

      return originalText;
    },
  })(contentState);
};

export const convertEntityToHTML = (entity: any, originalText: string) => {
  if (entity.type === "LINK") {
    const { url } = entity.data;

    return `<a href="${url}">${originalText}</a>`;
  }

  if (entity.type === "media") {
    const { media, dimensions, fit } = entity.data;

    if (!dimensions) {
      return `<img src="${media.getUrl()}" alt="${media.name}" />`;
    }

    const src = media.getUrl({
      w: dimensions.width * 2,
      h: dimensions.height * 2,
      fit,
    });

    return `<img src="${src}" width="${dimensions.width}" height="${dimensions.height}" alt="${media.name}" />`;
  }

  return null;
};

export const convertHTMLToEntity = (
  nodeName: any,
  node: any,
  createEntity: any
) => {
  const client = getProjectClient();

  if (nodeName === "a") {
    const href = node.getAttribute("href") || "#";

    return createEntity("LINK", "MUTABLE", {
      url: href,
    });
  }

  if (nodeName === "img") {
    const Media = client?.getModel("Media");
    const src = node.getAttribute("src") || "";
    const decodedMedia = Media?.decodeUrl(src);
    if (!decodedMedia) {
      return undefined;
    }

    const [media, opts] = decodedMedia;

    const dimensions = {
      width: parseInt(node.getAttribute("width") || (opts.w || 300) / 2),
      height: parseInt(node.getAttribute("height") || (opts.h || 300) / 2),
    };
    const fit = opts.fit;

    return createEntity("media", "IMMUTABLE", {
      media,
      dimensions,
      fit,
    });
  }
};

export const createRootPlugin = (config: any = {}) => {
  const atomicComponent = config.focusDecorator(InputRteBlockAtomic);

  return {
    blockStyleFn: () => {
      return "";
    },
    blockRendererFn: (block: any) => {
      const type: DraftBlockType = block.getType();

      if (type === "atomic") {
        return {
          component: atomicComponent,
          editable: false,
        };
      }

      return null;
    },
  };
};

export const _findLinkEntities = (
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  block.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const _customStyleMap = {};
