import React, { useEffect } from "react";
import { PreviewItemComponentProps } from "../utils/types";
import { ViewComponentContext } from "../utils/enums";
import PreviewItem from "./PreviewItem";
import { getProjectClient } from "../utils/graphand";
import { useCurrentProject } from "../utils/hooks";
import { GraphandModelDataField } from "graphand-js";

const PreviewAccount: React.FunctionComponent<
  PreviewItemComponentProps & {
    disablePreview?: boolean;
  }
> = (props) => {
  const { item, setModalProps } = props;
  const client = getProjectClient();
  const { project } = useCurrentProject();
  const DataField = client?.getModel("DataField");

  useEffect(() => {
    setModalProps?.({
      // topActions: [
      //   {
      //     label: <FontAwesomeIcon icon={["far", "key"]} />,
      //     link: item.getUrl({ name: true }),
      //   },
      // ],
    });
  }, []);

  const _render = (accountField?: GraphandModelDataField) => {
    return (
      <div className="pt-4">
        <div className="space-y-3 flex flex-col items-center mb-6">
          <div className="w-full flex items-center justify-center">
            {item.renderFieldView("_picture", {
              context: ViewComponentContext.PREVIEW_LINE,
            })}
          </div>
          {item.renderFieldView("_fullname", {
            className: "text-lg font-bold",
          })}
        </div>

        {!props.disablePreview ? (
          <PreviewItem
            {...props}
            ignoreFields={[
              accountField?.slug,
              "password",
              "invite",
              "settings",
            ]}
          />
        ) : null}
      </div>
    );
  };

  if (!project?.configuration.accountPictureField) {
    return _render();
  }

  return (
    <>
      {DataField?.get(
        project.configuration.accountPictureField.toString()
      )?.suspense(
        (field) => {
          return _render(field as GraphandModelDataField);
        },
        { subscribe: true }
      )}
    </>
  );
};

export default PreviewAccount;
