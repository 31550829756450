import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCurrentProject } from "../utils/hooks";
import { getProjectClient } from "../utils/graphand";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

const AccountPictureView: React.FunctionComponent<
  Partial<ViewComponentProps>
> = ({ item, options }) => {
  const client = getProjectClient();
  const { project } = useCurrentProject();

  const accountPictureField = project?.raw?.configuration?.accountPictureField;

  const _renderContent = ({ size = 3 } = {}) => {
    const fallback = (
      <FontAwesomeIcon
        size={`${size}x` as SizeProp}
        icon={["fal", "user-circle"]}
        className="text-gray-400"
      />
    );

    if (!accountPictureField) {
      return fallback;
    }

    const DataField = client?.getModel("DataField");
    return DataField?.get(accountPictureField).suspense(
      (field) => {
        if (!field || !item?.get(field.slug)) {
          return fallback;
        }

        return (
          <img
            className="w-full h-full"
            src={item
              ?.get(field.slug)
              ?.getUrl?.({ fit: "cover", w: size * 32, h: size * 32 })}
            alt={item._fullname}
          />
        );
      },
      { subscribe: true, fallback, updateKey: accountPictureField }
    );
  };

  switch (options.context) {
    case ViewComponentContext.LIST_LABEL_IMAGE:
      return (
        <div className="h-12 w-12 overflow-hidden rounded-full flex items-center justify-center flex-shrink-0">
          {_renderContent()}
        </div>
      );
    case ViewComponentContext.GRID_LABEL_IMAGE:
      return (
        <div className="h-full w-full overflow-hidden flex items-center justify-center flex-shrink-0">
          {_renderContent({ size: 5 })}
        </div>
      );
    case ViewComponentContext.PREVIEW_LINE:
      return (
        <div className="h-32 w-32 overflow-hidden rounded-full flex items-center justify-center flex-shrink-0">
          {_renderContent({ size: 5 })}
        </div>
      );
    default:
      break;
  }

  return (
    <div className="h-full w-full overflow-hidden flex items-center justify-center flex-shrink-0">
      {_renderContent({ size: options.size })}
    </div>
  );
};

export default AccountPictureView;
