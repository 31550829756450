import React from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../utils/formatter";

const InputBooleanSwitch: React.FunctionComponent<
  Partial<InputComponentProps>
> = ({ id, slug, options, field, value, onChange }) => {
  const { t } = useTranslation();
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  const checked = !!value;

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => onChange?.(!checked)}
    >
      <button
        type="button"
        className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
          checked ? "bg-blue-600" : "bg-gray-200"
        }`}
        role="switch"
        aria-checked={checked}
        aria-labelledby={id}
      >
        <span
          aria-hidden="true"
          className={`pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${
            checked ? "translate-x-5" : "translate-x-0"
          }`}
        />
      </button>
      {label ? (
        <span className="ml-3" id={id}>
          <span className="text-sm font-medium text-gray-900">
            {capitalizeFirstLetter(label)}
          </span>
        </span>
      ) : null}
    </div>
  );
};

export default InputBooleanSwitch;
