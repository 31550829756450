import React, { FunctionComponent } from "react";

const RecoverPassword: FunctionComponent<{
  loading?: boolean;
  ready: boolean;
}> = () => {
  return <div>RecoverPassword</div>;
};

export default RecoverPassword;
