import React, { Fragment, FunctionComponent } from "react";
import { useCurrentProject } from "../utils/hooks";
import { Transition } from "@headlessui/react";
import { Helmet } from "react-helmet";
import { getProjectMatch } from "../utils/getProjectMatch";

const LayoutNotLogged: FunctionComponent<{
  ready?: any;
  children: any;
}> = ({ ready, children }) => {
  const { project: _project } = useCurrentProject();
  const project = getProjectMatch() ? _project : null;

  return (
    <div className="bg-global flex h-screen items-stretch">
      <Helmet>
        <title>Graphand</title>
      </Helmet>
      <div className="w-full hidden lg:flex flex-col h-full justify-end">
        <Transition
          show={ready}
          as={Fragment}
          enter="transition transform ease-out duration-500 delay-100"
          enterFrom="opacity-0 -translate-y-10"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-10"
        >
          <div className="p-6 w-full max-w-screen-lg mx-auto">
            <img alt="icon" src="/images/icon.png" className="h-16 mb-6" />
            <div className="text-3xl md:text-4xl lg:text-6xl text-white font-poppins font-bold text-shadow-md mb-6">
              Bienvenue sur{" "}
              <span className="text-primary">
                {project?.name || "Graphand"}
              </span>
            </div>
            {project ? (
              <div className="text-white">
                Ce backoffice est propulsé grâce à Graphand.
              </div>
            ) : null}
            <div className="text-white">
              En vous connectant sur cette interface, vous acceptez{" "}
              <a href="/" target="_blank" className="underline">
                les conditions d'utilisation
              </a>{" "}
              ainsi que{" "}
              <a href="/" target="_blank" className="underline">
                la politique de confidentialité
              </a>{" "}
              de Graphand.
            </div>
          </div>
        </Transition>
      </div>
      <div className="w-full bg-white p-8 flex flex-col overflow-auto">
        {children}
        <div className="text-sm text-center mt-2 lg:hidden">
          {project ? (
            <div className="text-gray-500">
              Ce backoffice est propulsé grâce à Graphand.
            </div>
          ) : null}
          <div className="text-gray-500">
            En vous connectant sur cette interface, vous acceptez{" "}
            <a href="/" target="_blank" className="underline">
              les conditions d'utilisation
            </a>{" "}
            ainsi que{" "}
            <a href="/" target="_blank" className="underline">
              la politique de confidentialité
            </a>{" "}
            de Graphand.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutNotLogged;
