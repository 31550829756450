import React, { useEffect } from "react";
import { readySubject } from "../../components/LayoutGlobal";
import { useOrganization } from "../../utils/hooks";
import ProjectsList from "./ProjectsList";

const Settings = () => {
  const { organization } = useOrganization();

  useEffect(() => {
    readySubject.next(true);

    return () => {
      readySubject.next(false);
    };
  }, [organization]);

  if (!organization) {
    return <ProjectsList />;
  }

  return <div>Paramètres</div>;
};

export default Settings;
