import React from "react";

const InputRteDecoratorLink: React.FunctionComponent<any> = ({
  contentState,
  entityKey,
  children,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return <a href={url}>{children}</a>;
};

export default InputRteDecoratorLink;
