import React from "react";
import { MediaPlayerProps } from "./index";

const MediaPlayerImage: React.FunctionComponent<MediaPlayerProps> = ({
  media,
}) => {
  const h = media.height ? Math.min(media.height, 768) : 768;

  return (
    <img
      alt={media.title}
      src={media.getUrl({ h })}
      className="max-h-96 mx-auto"
    />
  );
};

export default MediaPlayerImage;
