import React from "react";
import { ViewComponentProps } from "graphand-react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import ViewText from "./Text";
import { GraphandFieldDate } from "graphand-js";

const ViewDate: React.FunctionComponent<ViewComponentProps> = (props) => {
  const { value, field } = props;

  let _display;
  const { t } = useTranslation();

  if (value) {
    const _field = field as GraphandFieldDate;
    const formatStr = _field.time ? "Pp" : "P";
    _display = format(value, formatStr, { locale: fr });
  } else {
    _display = t(`labels.other.date_none`);
  }

  return <ViewText {...props} value={_display} rawValue={value} />;
};

export default ViewDate;
