import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import FieldViewContainer from "../components/FieldViewContainer";
import Tippy from "@tippyjs/react";

const AccountPreviewView: React.FunctionComponent<ViewComponentProps> = (
  props
) => {
  const { item, options } = props;
  switch (options.context) {
    case ViewComponentContext.PREVIEW_LINE:
      return (
        <FieldViewContainer {...options}>
          <AccountPreviewView
            {...props}
            options={{ ...options, context: ViewComponentContext.TABLE_CELL }}
          />
        </FieldViewContainer>
      );
    case ViewComponentContext.TABLE_CELL:
      return (
        <div
          className={`flex items-center space-x-2 ${
            options.onClick ? "cursor-pointer" : ""
          }`}
          onClick={options.onClick}
        >
          <div className="h-8 w-8 flex items-center justify-center rounded-full overflow-hidden">
            {item.renderFieldView("_picture", { size: 2 })}
          </div>
          {item.renderFieldView("_fullname", options)}
        </div>
      );
    case ViewComponentContext.TABLE_CELL_MULTIPLE:
      return (
        <Tippy content={item._fullname}>
          <div
            className={`h-12 w-12 flex items-center justify-center rounded-full overflow-hidden ${
              options.onClick ? "cursor-pointer" : ""
            }`}
            onClick={options.onClick}
          >
            {item.renderFieldView("_picture", { size: 3 })}
          </div>
        </Tippy>
      );
    case ViewComponentContext.INPUT_INLINE:
      return (
        <div
          className={`flex items-center space-x-1.5 ${
            options.onClick ? "cursor-pointer" : ""
          }`}
          onClick={options.onClick}
        >
          <div className="h-input-1 w-input-1 flex items-center justify-center rounded-full overflow-hidden">
            {item.renderFieldView("_picture", { size: 2 })}
          </div>
          {item.renderFieldView("_fullname", options)}
        </div>
      );
    case ViewComponentContext.INPUT_INLINE_MULTIPLE:
      return (
        <div
          className={`flex items-center space-x-1.5 ${
            options.onClick ? "cursor-pointer" : ""
          }`}
          onClick={options.onClick}
        >
          <div className="h-6 w-6 flex items-center justify-center rounded-full overflow-hidden">
            {item.renderFieldView("_picture", { size: 1 })}
          </div>
          {item.renderFieldView("_fullname", options)}
        </div>
      );
    default:
      break;
  }

  return item.renderFieldView("_fullname", options);
};

export default AccountPreviewView;
