import React from "react";
import {
  GraphandFieldJSON,
  GraphandFieldText,
  GraphandModel,
  GraphandValidationError,
} from "graphand-js";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryBuilder from "../../components/QueryBuilder";
import Button from "../../components/Button";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { AddFilterModalProps } from "./index";
import { Combobox } from "@headlessui/react";

const AddFilterStaticTemplate: React.FunctionComponent<
  GraphandFormTemplateParams & {
    model: typeof GraphandModel;
  }
> = ({
  formRef,
  handleSubmit,
  fields,
  isLoading,
  model,
  values,
  handleFieldChange,
}) => {
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`${isLoading ? "cursor-progress" : ""}`}
    >
      <div
        className={`space-y-6 transition mb-8 ${
          isLoading ? "opacity-50" : "opacity-100"
        }`}
      >
        {fields.render("label", {
          disabled: isLoading,
          placeholder: "Nouveau filtre",
        })}

        <QueryBuilder
          InputContainerComponent={({ children }) => (
            <div className="flex space-x-3 relative z-10">
              <div className="w-full">{children}</div>
              <Combobox.Button as={React.Fragment}>
                {({ open }) => (
                  <button
                    type="button"
                    className="border border-gray-200 flex-grow rounded-xl flex-shrink-0 border bg-white px-4 flex items-center truncate hover:bg-gray-100"
                  >
                    <span className="mr-1.5">Filtrer</span>
                    <FontAwesomeIcon
                      icon={["far", "angle-down"]}
                      className={`transition ${open ? "rotate-180" : ""}`}
                    />
                  </button>
                )}
              </Combobox.Button>
            </div>
          )}
          model={model}
          onChange={(query) => handleFieldChange("query", query())}
        />

        {model
          .getList({ query: values.query, count: true })
          .suspense((list) => {
            return <div>{list.count} éléments sélectionnés</div>;
          })}
      </div>
      <Button
        type="submit"
        className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
      >
        <FontAwesomeIcon
          icon={faLoader}
          size="lg"
          className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
            isLoading ? "opacity-100" : "opacity-0"
          }`}
        />
        Enregistrer
      </Button>
    </form>
  );
};

const AddFilterStatic: React.FunctionComponent<AddFilterModalProps> = ({
  model,
  onSave,
}) => {
  const _handleSubmit = (values: any) => {
    const filter = values;
    if (!filter.label) {
      throw new GraphandValidationError(
        "Field label is required",
        "label",
        "required"
      );
    }

    onSave(filter);
  };

  return (
    <GraphandForm
      fields={{
        label: new GraphandFieldText(),
        query: new GraphandFieldJSON(),
      }}
      onSubmit={_handleSubmit}
      template={(params: GraphandFormTemplateParams) => (
        <AddFilterStaticTemplate {...params} model={model} />
      )}
    />
  );
};

export default AddFilterStatic;
