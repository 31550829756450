import { Routes, Route, useLocation } from "react-router-dom";
import ProjectsList from "./pages/global/ProjectsList";
import CreateProject from "./pages/global/CreateProject";
import CreateOrganization from "./pages/global/CreateOrganization";
import LayoutGlobal from "./components/LayoutGlobal";
import MembersList from "./pages/global/MembersList";
import Payments from "./pages/global/Payments";
import Settings from "./pages/global/Settings";
import React, { FunctionComponent, useEffect, useState } from "react";
import InviteMembers from "./pages/global/InviteMembers";
import UserSettings from "./pages/global/UserSettings";
import ConfirmUser from "./pages/global/ConfirmUser";
import InvitationsList from "./pages/global/InvitationsList";
import PersonalInvitations from "./pages/global/PersonalInvitations";
import { Transition } from "@headlessui/react";
import LayoutNotLogged from "./components/LayoutNotLogged";
import RecoverPassword from "./pages/global/notLogged/RecoverPassword";
import Signup from "./pages/global/notLogged/Signup";
import Login from "./pages/global/notLogged/Login";

const NotLoggedGlobal: FunctionComponent<{
  loading: boolean;
  animationStage: number;
  onRouteChange: () => any;
}> = ({ loading, animationStage, onRouteChange }) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState<any>(location);
  const [loaded, setLoaded] = useState<boolean>(false);

  const ready = loaded && animationStage > -1;

  useEffect(() => {
    if (location !== displayLocation && ready) {
      onRouteChange();
      setTimeout(() => {
        setDisplayLocation(location);
      }, 300);
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => setLoaded(true));
  }, []);

  // const ready = loaded && animationStage === 0;

  return (
    <LayoutNotLogged ready={ready}>
      <Routes key={displayLocation.pathname} location={displayLocation}>
        <Route
          path="/recover"
          element={<RecoverPassword loading={loading} ready={ready} />}
        />
        <Route path="/signup">
          <Route
            path=":code"
            element={<Signup loading={loading} ready={ready} />}
          />
          <Route path="" element={<Signup loading={loading} ready={ready} />} />
        </Route>
        <Route path="*" element={<Login loading={loading} ready={ready} />} />
      </Routes>
    </LayoutNotLogged>
  );
};

const Global: FunctionComponent<{
  ready: boolean;
  logged: boolean;
  animationStage: number;
  setAnimationStage: React.Dispatch<React.SetStateAction<number>>;
}> = ({ ready, logged, animationStage, setAnimationStage }) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
      setDisplayLocation(location);
    }, 300);
  }, [location, displayLocation]);

  return (
    <>
      <Transition
        show={!logged || !ready}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100 z-50"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 z-50"
        leaveTo="opacity-0"
      >
        <div className="inset-0 fixed z-50 h-screen">
          <NotLoggedGlobal
            loading={logged}
            animationStage={logged || animationStage < 1 ? animationStage : 0}
            onRouteChange={() => setAnimationStage(-1)}
          />
        </div>
      </Transition>

      {logged ? (
        <LayoutGlobal ready={ready} transitioning={transitioning}>
          <Routes key={displayLocation.pathname} location={displayLocation}>
            <Route path="/join" element={<PersonalInvitations />} />
            <Route path="/invitations" element={<InvitationsList />} />
            <Route path="/invitations/new" element={<InviteMembers />} />
            <Route path="/members" element={<MembersList />} />
            <Route path="/payment" element={<Payments />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/create-project" element={<CreateProject />} />
            <Route path="/new" element={<CreateOrganization />} />
            <Route path="/account" element={<UserSettings />} />
            <Route path="/confirm" element={<ConfirmUser />} />
            <Route path="*" element={<ProjectsList />} />
          </Routes>
        </LayoutGlobal>
      ) : null}
    </>
  );
};

export default Global;
