import React, { useEffect, useRef, useState } from "react";
import {
  getGlobalClient,
  getProjectClient,
  projectClientSubject,
} from "./graphand";
import GraphandClient, {
  GraphandModelOrganization,
  GraphandModelProject,
  GraphandModelList,
  GraphandModelDataModel,
} from "graphand-js";
import { getCookie, removeCookie, setCookie } from "./cookies";
import { BehaviorSubject } from "rxjs";
import Organization from "../models/Organization";
import Account from "../models/Account";
import {
  QueryBuilderFilterState,
  QueryBuilderFilterTileProps,
} from "../components/QueryBuilder/QueryBuilderFilterTile";
import {
  getFiltersFromFilterConf,
  getOperatorIcon,
  getQuerySelection,
} from "./tools";
import Dropdown, { DropdownPosition } from "../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLetter } from "./formatter";
import { useTranslation } from "react-i18next";
import { getProjectMatch } from "./getProjectMatch";
import DataModel from "../models/DataModel";
import {
  FilterConf,
  FilterItem,
  GraphandModelConstructor,
  SelectionStackItem,
} from "./types";
import { useList } from "graphand-react";

export const useProject = function () {
  const client = getProjectClient();
  const [project, setProject] = useState<string | undefined>(
    client?._options.project
  );

  useEffect(() => {
    const projectClientSub = projectClientSubject.subscribe((client?) =>
      setProject(client?._options.project)
    );

    return () => projectClientSub.unsubscribe();
  }, []);

  return project;
};

export const useSelectionStack = function (
  model: GraphandModelConstructor,
  filter?: FilterItem
): {
  selectionStack: SelectionStackItem[];
  setSelectionStack: React.Dispatch<React.SetStateAction<SelectionStackItem[]>>;
  querySelection: any;
} {
  const [selectionStack, setSelectionStack] = useState<SelectionStackItem[]>(
    []
  );

  let querySelection = getQuerySelection(selectionStack);

  if (filter) {
    querySelection = { $and: [querySelection, filter.query] };
  }

  useEffect(() => {
    (async () => {
      const [totalCount, selectionCount] = await Promise.all([
        model.count({ query: filter?.query || {} }),
        model.count({ query: querySelection }),
      ]);

      if (totalCount === selectionCount) {
        if (selectionCount) {
          if (selectionStack.length > 1) {
            setSelectionStack([
              {
                query: {},
                inverse: false,
              },
            ]);
          }
        } else if (selectionStack.length) {
          setSelectionStack([]);
        }
      }
    })();
  }, [JSON.stringify(querySelection)]);

  return { selectionStack, setSelectionStack, querySelection };
};

export const useModelFilters = function (dataModel?: GraphandModelDataModel): {
  filterConfs: FilterConf<any>[];
  filters: FilterItem[];
  loading: boolean;
  addFilter: (filter: FilterConf<any>) => void;
  setFilters: (filters: FilterConf<any>[]) => void;
} {
  const { t } = useTranslation();
  const { project } = useCurrentProject();
  const confKey = `modelFilters:${dataModel?._scope}`;
  const filterConfs = project?.configuration[confKey] || [];
  const [{ filters, loading }, setState] = useState<{
    filters: FilterItem[];
    loading: boolean;
  }>({
    filters: [],
    loading: false,
  });

  const _reloadFilters = async () => {
    let _filters: FilterItem[] = [];
    setState((s) => ({ ...s, filtersModelLoading: true }));

    // @ts-ignore
    const client = dataModel?.constructor._client;

    if (!client) {
      return;
    }

    const model = client.getModel(dataModel._scope);

    await filterConfs.reduce(
      async (promise: Promise<any>, conf: FilterConf<any>) => {
        await promise;

        const converted = await getFiltersFromFilterConf(conf, model, t);
        _filters = _filters.concat(converted);
      },
      Promise.resolve()
    );

    setState({
      filters: _filters,
      loading: false,
    });
  };

  useEffect(() => {
    if (filterConfs?.length) {
      _reloadFilters();
    } else if (filters.length) {
      setState({
        filters: [],
        loading: false,
      });
    }
  }, [JSON.stringify(filterConfs)]);

  const setFilters = async (_filters: FilterConf<any>[]) => {
    setState((s) => ({ ...s, filtersModelLoading: true }));
    try {
      await project?.update({
        set: {
          configuration: { ...project.raw.configuration, [confKey]: _filters },
        },
      });
    } catch (e) {}
    setState((s) => ({ ...s, filtersModelLoading: false }));
  };

  const addFilter = async (filter: FilterConf<any>) => {
    setState((s) => ({ ...s, filtersModelLoading: true }));
    try {
      await project?.update({
        set: {
          configuration: {
            ...project.raw.configuration,
            [confKey]: [filter].concat(filterConfs).filter(Boolean),
          },
        },
      });
    } catch (e) {}
    setState((s) => ({ ...s, filtersModelLoading: false }));
  };

  return { filterConfs, filters, loading, addFilter, setFilters };
};

export const useModelConfiguration = function (
  dataModel?: GraphandModelDataModel,
  encode = true
): any {
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    if (dataModel) {
      const sub = dataModel?.subscribe?.(_reloadConfiguration);

      return () => sub?.unsubscribe?.();
    }
  }, [dataModel]);

  const _reloadConfiguration = async (_dataModel: DataModel) => {
    if (encode) {
      _dataModel.getConfiguration().then(setConfiguration);
    } else {
      setConfiguration(_dataModel.configuration);
    }
  };

  return configuration;
};

export const useCurrentProject = function () {
  const client = getProjectClient();
  const projectId = useProject();
  const Project = client?.getModel("Project");
  let _project = Project?.get(projectId);
  _project = _project?.then ? undefined : _project;
  const [state, setState] = useState<{
    loading: boolean;
    project: GraphandModelProject | null;
  }>({ loading: false, project: _project as GraphandModelProject });
  const [reload, setReload] = useState(0);

  const _loadProject = async () => {
    const Project = client?.getModel("Project");
    if (!Project) {
      return;
    }

    try {
      if (projectId && Project) {
        const project = await Project.get(projectId);
        setState({ project, loading: false });
        return project;
      } else {
        throw new Error();
      }
    } catch (e) {
      setState({ project: null, loading: false });
    }
  };

  useEffect(() => {
    if (projectId !== state.project?._id) {
      _loadProject();
    }
  }, [client?._uid, projectId]);

  useEffect(() => {
    let sub: any;

    if (state.project) {
      sub = state.project.subscribe(() => setReload((r) => r + 1));
    }

    return () => sub?.unsubscribe();
  }, [state.project?._id]);

  return state;
};

export const useLogged = function () {
  const globalClient = getGlobalClient();
  const [logged, setLogged] = useState(globalClient.authmanager.logged);

  useEffect(() => {
    let sub: any;
    const projectSub = projectClientSubject.subscribe((projectClient) => {
      sub?.unsubscribe();

      if (getProjectMatch() && projectClient) {
        sub = projectClient.authmanager.loggedSubject.subscribe(setLogged);
      } else {
        sub = globalClient.authmanager.loggedSubject.subscribe(setLogged);
      }
    });

    return () => {
      sub?.unsubscribe();
      projectSub.unsubscribe();
    };
  }, []);

  return logged || getProjectClient()?.authmanager.logged;
};

export const useCurrentAccount = function () {
  const client = getProjectClient();
  const [account, setAccount] = useState(client?.authmanager.user);
  const [loading, setLoading] = useState(client?.authmanager.loading);

  useEffect(() => {
    const accountSub = client?.authmanager.userSubject.subscribe(setAccount);
    const loadingSub = client?.authmanager.loadingSubject.subscribe(setLoading);

    return () => {
      accountSub?.unsubscribe();
      loadingSub?.unsubscribe();
    };
  }, [client?._uid]);

  return { account: account as Account, loading };
};

export const useCurrentUser = function () {
  const client = getGlobalClient();
  const [user, setUser] = useState(client?.authmanager.user);
  const [loading, setLoading] = useState(client?.authmanager.loading);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const userSub = client?.authmanager.userSubject.subscribe(setUser);
    const loadingSub = client?.authmanager.loadingSubject.subscribe(setLoading);

    return () => {
      userSub?.unsubscribe();
      loadingSub?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user?._id) {
      const userSub = user.subscribe((u: any) => {
        setUser(u);
        setReload((r) => r + 1);
      });

      return () => userSub.unsubscribe();
    }

    return () => null;
  }, [user?._id]);

  return { user, loading, reload };
};

export const organizationSubject = new BehaviorSubject(null);

export const setOrganization = (o: any) => {
  if (o) {
    setCookie("graphand:organization", o._id);
  } else {
    removeCookie("graphand:organization");
  }

  organizationSubject.next(o);
};

export const useOrganization = function (): {
  organization: Organization | null;
  list: GraphandModelList<GraphandModelOrganization> | undefined;
} {
  const client = getGlobalClient();
  const [organization, _setOrganization] = useState<Organization | null>(null);
  const [list, setList] =
    useState<GraphandModelList<GraphandModelOrganization>>();
  const [reload, setReload] = useState(0);

  useEffect(() => {
    let sub: any;

    if (organization) {
      sub = organization.subscribe(() => setReload((r) => r + 1));
    }

    return () => {
      sub?.unsubscribe();
    };
  }, [organization]);

  useEffect(() => {
    const orgSub = organizationSubject.subscribe(_setOrganization);

    const organizations = client
      .getModel("Organization")
      .getList({ query: { users: { $in: ["$currentUser"] } } });

    const sub = organizations.subscribe(
      (_list: GraphandModelList<GraphandModelOrganization>) => {
        setList(_list);

        if (!organization) {
          const org = getCookie("graphand:organization");
          const found = org && _list.find((i) => i._id == org);
          if (found) {
            _setOrganization(found);
          } else if (_list?.length) {
            _setOrganization(_list[0]);
          }
        }
      }
    );

    return () => {
      sub.unsubscribe();
      orgSub?.unsubscribe();
    };
  }, []);

  return { organization, list };
};

export function useAccountSettings<S>(
  key: string,
  defaultValue: S,
  validation?: (value: any) => any
): [S, (value: S) => void, boolean] {
  const { account } = useCurrentAccount();
  const client = getProjectClient();

  const [value, setValue] = useState(account?.currentSettings[key]);
  const prevValue = useRef<string>(
    JSON.stringify(value?.toString?.() || value)
  );

  useEffect(() => {
    const accountSub = account?.subscribe((a: Account) => {
      const _value = a?.currentSettings[key];
      const _valueStr = JSON.stringify(_value?.toString?.() || _value) || "";

      if (_valueStr !== prevValue.current) {
        setValue(_value ?? null);
        prevValue.current = _valueStr;
      }
    });

    return () => accountSub?.unsubscribe();
  }, [account?._id, client?._uid, account?._model._client._uid]);

  const _setSettings = (set: any) => {
    return account?.updateSettings(set);
  };

  let v: S | undefined = value;
  if (validation && !validation?.(v)) {
    v = defaultValue;
  }

  return [
    v ?? defaultValue,
    (value: S) => {
      const set = { [key]: value === defaultValue ? undefined : value };
      _setSettings(set);
    },
    value !== undefined,
  ];
}

export function useWizard(key: string): [boolean, () => any] {
  const [ignoredWizards, setIgnoredWizards, loaded] = useAccountSettings<
    string[]
  >("ignoredWizards", []);
  const [hideWizards] = useAccountSettings<boolean>("hideWizards", false);

  const _ignore = () => setIgnoredWizards(ignoredWizards.concat(key));

  return [loaded && !hideWizards && !ignoredWizards?.includes(key), _ignore];
}

export const useQueryBuilderFilterTile = (
  props: QueryBuilderFilterTileProps,
  params: {
    defaultState: QueryBuilderFilterState;
    operators?: string[];
    validateChange?: (
      state: QueryBuilderFilterState,
      props: QueryBuilderFilterTileProps
    ) => boolean;
    proxyAssign?: (
      state: QueryBuilderFilterState,
      props: QueryBuilderFilterTileProps
    ) => ((query: any) => any) | Promise<(query: any) => any>;
    proxyUnassign?: (
      state: QueryBuilderFilterState,
      props: QueryBuilderFilterTileProps
    ) => ((query: any) => any) | Promise<(query: any) => any>;
  }
): {
  state: QueryBuilderFilterState;
  setState: React.Dispatch<React.SetStateAction<QueryBuilderFilterState>>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  renderDropdown: any;
  renderCloseButton: any;
  renderLabel: any;
  disabled: boolean;
} => {
  const {
    defaultState,
    validateChange = () => true,
    operators,
    proxyAssign = (state, props) => {
      const { operator, value } = state;
      const { field } = props.filter.payload;
      if (!operator || !field) {
        return (q) => q;
      }

      return (query: any) => {
        query[field] = { [operator]: value };
        return query;
      };
    },
    proxyUnassign = (state, props) => {
      const { field } = props.filter.payload;
      if (!field) {
        return (q) => q;
      }

      return (query: any) => {
        delete query[field];
        return query;
      };
    },
  } = params;

  const [state, setState] = useState<QueryBuilderFilterState>({
    ...defaultState,
    ...(props.filter?.init || {}),
  });
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const changeTimeout = useRef<ReturnType<typeof setTimeout>>();
  const { t } = useTranslation();

  const { filter, onChange, onClose } = props;

  useEffect(() => {
    if (filter.init) {
      setState((s) => ({ ...s, ...filter.init }));
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (changeTimeout.current) {
      clearTimeout(changeTimeout.current);
    }

    changeTimeout.current = setTimeout(async () => {
      const _validChange = validateChange(state, props);

      if (_validChange) {
        const assignFn = await proxyAssign(state, props);
        onChange?.(assignFn);
      } else {
        const assignFn = await proxyUnassign(state, props);
        onChange?.(assignFn);
      }
    }, 300);
  }, [state]);

  const client = getProjectClient();

  const renderDropdown: any =
    state.operator && operators?.length ? (
      <Dropdown
        className="w-12 mt-1"
        position={DropdownPosition.CENTER}
        disabled={!onChange}
        button={
          <div className="overflow-visible bg-white z-0 min-w-7 rounded-lg shadow-lg px-2 text-blue-500 h-7 flex items-center justify-center text-sm">
            <FontAwesomeIcon icon={["far", getOperatorIcon(state.operator)]} />
          </div>
        }
        links={operators
          .filter((operator) => operator !== state.operator)
          .map((operator) => ({
            label: (
              <div className="w-full text-center">
                <FontAwesomeIcon icon={["far", getOperatorIcon(operator)]} />
              </div>
            ),
            onClick: () => setState((s) => ({ ...s, operator })),
          }))}
      />
    ) : null;

  const renderCloseButton = onClose ? (
    <button
      role="button"
      type="button"
      className="h-6 w-6 flex items-center justify-center cursor-pointer text-gray-500"
      onClick={async () => {
        const assignFn = await proxyUnassign(state, props);
        onClose(assignFn);
      }}
    >
      <FontAwesomeIcon icon={["far", "xmark"]} />
    </button>
  ) : null;

  const { scope, field } = filter.payload;
  const model = scope && client?.getModel(scope);

  if (!model || !model.fields[field]) {
    return {
      state,
      setState,
      inputRef,
      renderDropdown,
      renderCloseButton,
      renderLabel: null,
      disabled: !onChange,
    };
  }

  const renderLabel = (
    <span>
      {capitalizeFirstLetter(
        model.fields[field].__dataField?.name ||
          t(`labels.fields.${field}.default`)
      )}
    </span>
  );

  return {
    state,
    setState,
    inputRef,
    renderDropdown,
    renderCloseButton,
    renderLabel,
    disabled: !onChange,
  };
};
