import {
  GraphandField,
  GraphandFieldText,
  GraphandModelDataField,
} from "graphand-js";
import { camelCase } from "lodash";
import UpdateDataField from "../components/UpdateDataField";
import CreateDataField from "../components/CreateDataField";
import { DataFieldFieldType } from "../utils/enums";
import { ViewComponentProps } from "graphand-react";
import FieldIconView from "../fieldsViews/FieldIcon";
import { dataFieldTypes, getFieldTypeFromDataField } from "../utils/fields";

class DataField extends GraphandModelDataField {
  static previewField = "name";
  static listFields = ["_label"];
  static imageField = "_icon";
  static titleField = "name";
  static subtitleField = "slug";
  static createItemComponent = CreateDataField;
  static updateItemComponent = UpdateDataField;

  get graphandType(): DataFieldFieldType | null {
    return getFieldTypeFromDataField(this);
  }
}

DataField.hook("formChange", (values: any, { instance, isModified }: any) => {
  if ((!instance || instance.isTemporary()) && !isModified("slug")) {
    values.slug = values.name && camelCase(values.name);
  }
});

DataField.hook("formSubmit", (values: any, { instance, isModified }: any) => {
  if ((!instance || instance.isTemporary()) && !isModified("slug")) {
    values.slug = values.name && camelCase(values.name);
  }
});

DataField.customFields({
  _icon: new GraphandField({
    ViewComponent: (props: ViewComponentProps) => {
      const type = props.item.graphandType;
      if (!type) {
        return null;
      }

      const uiDataField = dataFieldTypes.get(type);

      return (
        <FieldIconView
          {...props}
          value={uiDataField?.icon || "question-circle"}
        />
      );
    },
  }),
  _graphandType: new GraphandFieldText({
    getter(_: any, item: DataField) {
      return item.graphandType;
    },
  }),
});

export default DataField;
