import { InfiniteList } from "graphand-react";
import React, { useEffect, useRef, useState } from "react";
import { getProjectClient } from "../../utils/graphand";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { isScrolledIntoView } from "../../utils/tools";
import ProjectTransition from "../../components/ProjectTransition";
import AppListCard from "../../components/AppListCard";
import { useAccountSettings } from "../../utils/hooks";
import { InfiniteListProps } from "graphand-react/dist/types/components/InfiniteList";
import { isEqual } from "lodash";

const AppsList = () => {
  const [recentApps, setRecentApps] = useAccountSettings<string[]>(
    "recentApps",
    []
  );
  const [search, setSearch] = useState("");
  const searchRef = useRef("");
  const [reload, setReload] = useState("");
  const navigate = useNavigate();
  const focusedRef = useRef<any>();
  const containerRecentSearch = useRef<any>();
  const containerListRef = useRef<any>();
  const firstAppRef = useRef<any>();
  const inputSearchRef = useRef<any>();
  const displayListRef = useRef(new Map());
  const client = getProjectClient();

  const DataModel = client?.getModel("DataModel");

  useEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const containerRef = search?.length
      ? containerListRef
      : containerRecentSearch;

    if (focusedRef.current?._id && containerRef.current) {
      const el = containerRef.current.querySelector(
        `#app-${focusedRef.current._id}`
      );
      if (el && !isScrolledIntoView(el)) {
        el.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }, [reload]);

  useEffect(() => {
    _setHandler();
    window.addEventListener("resize", _setHandler);

    return () => {
      window.removeEventListener("resize", _setHandler);
      _unsetHandler();
    };
  }, []);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  const AppsListRecentPageComponent: InfiniteListProps["PageComponent"] = ({
    list,
    getItemProps,
  }) => {
    const _list = list
      .filter((i) => recentApps.includes(i._id))
      .sort((a, b) => recentApps.indexOf(a._id) - recentApps.indexOf(b._id));

    // useEffect(() => {
    //   const ids = _list.map((i) => i._id);
    //   if (!isEqual(ids, recentApps)) {
    //     setRecentApps(ids);
    //   }
    // }, [_list]);

    return (
      <>
        {_list.map((item, index) => {
          const props = getItemProps(item, index);

          return props.renderItem();
        })}
      </>
    );
  };

  if (!DataModel) {
    return null;
  }

  const _downHandler = async (e?: KeyboardEvent) => {
    let key;
    if (e) {
      key = e.key;

      if (
        ["ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp", "Enter"].includes(
          key
        )
      ) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        inputSearchRef.current?.focus();

        return;
      }
    }

    const containerRef = searchRef.current?.length
      ? containerListRef
      : containerRecentSearch;

    const appsCards: HTMLDivElement[] = Array.from(
      containerRef.current?.children || []
    );

    const ids: string[] = appsCards
      .filter((el) => !el.classList.contains("hidden"))
      .map((el) => el.getAttribute("data-id") as string);

    const realIds = ids.filter((id) => !id.startsWith("_"));
    const realList = await DataModel.getList({ ids: realIds });

    const dataModels = [
      DataModel.get("_dataModelAccount"),
      DataModel.get("_dataModelMedia"),
    ]
      .concat(realList)
      .filter((i) => ids.includes(i._id))
      .sort((a, b) => ids.indexOf(a._id) - ids.indexOf(b._id));

    const list = dataModels.filter(
      (m) => displayListRef.current.get(m._id) ?? true
    );

    let focusedIndex = list.indexOf(focusedRef.current);
    if (focusedIndex < 0) {
      focusedRef.current = list[0];
      setReload((r) => r + 1);
      return;
    }

    if (!containerRef.current || !firstAppRef.current) {
      return;
    }

    const { width } = containerRef.current?.getBoundingClientRect();
    const { width: itemWidth } = firstAppRef.current?.getBoundingClientRect();
    const rowLength = Math.floor(width / itemWidth);

    switch (key) {
      case "ArrowLeft":
        focusedIndex -= 1;
        break;
      case "ArrowRight":
        focusedIndex += 1;
        break;
      case "ArrowDown":
        focusedIndex += rowLength;
        break;
      case "ArrowUp":
        focusedIndex -= rowLength;
        break;
      case "Enter":
        const _dataModel = focusedRef.current;
        if (_dataModel) {
          const link = _dataModel?.getLink();
          navigate(link);
        }
        break;
      default:
        break;
    }

    if (focusedIndex > -1 && list[focusedIndex]) {
      focusedRef.current = list[focusedIndex];
      setReload((r) => r + 1);
    }
  };

  const _reloadRecentSearchHeight = () => {
    if (containerRecentSearch.current && firstAppRef.current) {
      const { width } = containerRecentSearch.current?.getBoundingClientRect();
      const { width: itemWidth } = firstAppRef.current?.getBoundingClientRect();
      const rowLength = Math.floor(width / itemWidth);

      const recentAppsEl = Array.from(
        containerRecentSearch.current.children
      ) as HTMLDivElement[];

      recentAppsEl.slice(0, rowLength).forEach((el) => {
        el.classList.remove("hidden");
      });

      recentAppsEl.slice(rowLength).forEach((el) => {
        el.classList.add("hidden");
      });
    }
  };

  const _setHandler = () => {
    _reloadRecentSearchHeight();
    _unsetHandler();
    window.addEventListener("keydown", _downHandler);
  };

  const _unsetHandler = () => {
    window.removeEventListener("keydown", _downHandler);
  };

  return (
    <ProjectTransition>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="p-2 sm:p-3 lg:p-4">
          <div className="px-4 flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white">
            <div className="h-6 w-6 flex items-center">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              ref={inputSearchRef}
              className="w-full p-0 h-input overflow-hidden border-0 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0"
              type="search"
              placeholder="Rechercher ..."
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
            />
          </div>
        </div>

        <div className="space-y-6 md:space-y-8">
          {recentApps?.length && !search?.length ? (
            <div>
              <div className="font-bold text-lg px-2 sm:px-3 lg:px-4 text-gray-700 mb-2">
                Applications récentes
              </div>
              <InfiniteList
                model={DataModel}
                activeFn={(index) => Math.sqrt(index) * 150 + 200}
                opts={{
                  ids: recentApps.filter((id) => !id.startsWith("_")),
                }}
                staticItems={[
                  DataModel.get("_dataModelAccount"),
                  DataModel.get("_dataModelMedia"),
                ]}
                ContainerComponent={({ children }: any) => (
                  <div
                    className="grid grid-cols-sm sm:grid-cols-md overflow-visible px-2"
                    ref={containerRecentSearch}
                  >
                    {children}
                  </div>
                )}
                PageComponent={AppsListRecentPageComponent}
                ItemComponent={AppListCard}
                itemProps={({ item, index }: any) => {
                  if (!focusedRef.current && index === 0) {
                    focusedRef.current = item;
                    setReload((r) => r + 1);
                  }

                  let className;
                  if (containerRecentSearch.current && firstAppRef.current) {
                    const { width } =
                      containerRecentSearch.current?.getBoundingClientRect();
                    const { width: itemWidth } =
                      firstAppRef.current?.getBoundingClientRect();
                    const rowLength = Math.floor(width / itemWidth);
                    if (index > rowLength - 1) {
                      className = "hidden";
                    }
                  }

                  return {
                    isFocused: !search?.length && item === focusedRef.current,
                    search,
                    display: displayListRef.current.get(item._id) ?? true,
                    className,
                    setDisplay: (d: boolean) => {
                      if (d !== displayListRef.current.get(item._id)) {
                        displayListRef.current.set(item._id, d);
                        _downHandler();
                        setReload((r) => r + 1);
                      }
                    },
                    onMouseEnter: () => null,
                  };
                }}
              />
            </div>
          ) : null}

          <div className="pb-8">
            <div className="font-bold text-lg px-2 sm:px-3 lg:px-4 text-gray-700 mb-2">
              Liste des applications
            </div>
            <InfiniteList
              model={DataModel}
              activeFn={(index) => Math.sqrt(index) * 150 + 200}
              opts={{ pageSize: 100 }}
              staticItems={[
                DataModel.get("_dataModelAccount"),
                DataModel.get("_dataModelMedia"),
              ]}
              ContainerComponent={({ children }: any) => (
                <div
                  className="grid grid-cols-sm sm:grid-cols-md overflow-auto px-2 pb-6 md:pb-8"
                  ref={containerListRef}
                >
                  {children}
                </div>
              )}
              ItemComponent={AppListCard}
              itemProps={({ item, index }: any) => {
                if (search?.length && !focusedRef.current && index === 0) {
                  focusedRef.current = item;
                  setReload((r) => r + 1);
                }

                return {
                  firstAppRef: index === 0 ? firstAppRef : null,
                  isFocused: search?.length && item === focusedRef.current,
                  search,
                  display: displayListRef.current.get(item._id) ?? true,
                  setDisplay: (d: boolean) => {
                    displayListRef.current.set(item._id, d);
                    _downHandler();
                    setReload((r) => r + 1);
                  },
                  onMouseEnter: () => null,
                };
              }}
            />
          </div>
        </div>
      </div>
    </ProjectTransition>
  );
};

export default AppsList;
