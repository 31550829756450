import React from "react";
import { GraphandField, GraphandFieldRelation } from "graphand-js";
import { getProjectClient } from "../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const GraphandFieldIcon: React.FunctionComponent<{
  field: GraphandField;
  slug: string;
  theme?: string;
  className?: string;
}> = (props): any => {
  const { field, className, slug } = props;

  if (!field) {
    return null;
  }

  // @ts-ignore
  const type = field.constructor.__fieldType;
  const client = getProjectClient();

  if (!client) {
    return null;
  }

  if (type === "Relation" && "ref" in field) {
    const _field = field as GraphandFieldRelation;
    if (_field.ref.startsWith("Data:")) {
      const slug = _field.ref.replace("Data:", "");
      if (slug) {
        return client
          .getModel("DataModel")
          .get({ query: { slug } })
          .suspense(
            (dataModel) => {
              if (!dataModel) {
                return <FontAwesomeIcon icon={["fad", "link"]} />;
              }

              return dataModel.renderFieldView?.("_icon", {
                ...props,
                fallback: "link",
              });
            },
            { subscribe: true }
          );
      }
    }

    switch (_field.ref) {
      case "Account":
        return <FontAwesomeIcon className={className} icon={["fad", "user"]} />;
      case "Media":
        return (
          <FontAwesomeIcon className={className} icon={["fad", "photo-film"]} />
        );
      default:
        return <FontAwesomeIcon className={className} icon={["fad", "link"]} />;
    }
  }

  let icon: IconName;

  switch (type) {
    case "Id":
      icon = "hashtag";
      break;
    case "Text":
      icon = "font-case";
      break;
    case "Number":
      icon = "00";
      break;
    case "Boolean":
      icon = "power-off";
      break;
    case "Date":
      icon = "calendar";
      break;
    case "JSON":
      icon = "brackets-curly";
      break;
    default:
      icon = "question-circle";
      break;
  }

  if (slug === "_label") {
    return <FontAwesomeIcon className={className} icon={["fad", "tag"]} />;
  }

  return <FontAwesomeIcon className={className} icon={["fad", icon]} />;
};

export default GraphandFieldIcon;
