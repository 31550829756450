import React, { useEffect, useState } from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import { GraphandFieldRelation, GraphandModel } from "graphand-js";
import SelectItemsModal from "../../../modals/SelectItemsModal";
import { getProjectClient } from "../../../utils/graphand";
import { ModalCloseType } from "../../../components/Modal";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateItemModal from "../../../modals/CreateItemModal";
import { GraphandModelConstructor } from "../../../utils/types";
import {
  InputComponentContext,
  ViewComponentContext,
} from "../../../utils/enums";

const InputRelationSingle: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { slug, value, onChange, options, errors, item } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [createItemModal, setCreateItemModal] = useState(false);
  const { t } = useTranslation();
  const client = getProjectClient();

  useEffect(() => {
    if (options.open) {
      setModalOpen(true);
    }
  }, []);

  if (!client) {
    return null;
  }

  const field = props.field as GraphandFieldRelation;

  const model = client.getModel(field.ref) as GraphandModelConstructor;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  const itemValue = item?.get(slug, false);
  const reinitValue = options.reinitValue ?? itemValue;

  let query = options.query ?? field.query ?? {};

  const _handleCreate = async (item: GraphandModel) => {
    if (Object.keys(query).length) {
      const found = await model.count({
        query: { $and: [query, { _id: item._id }] },
      });

      if (found) {
        onChange?.(item._id);
      } else {
        alert("OVERFLOW_QUERY");
      }
    } else {
      onChange?.(item._id);
    }
  };

  const Container = ({ list, children }: any) => {
    if (options?.context === InputComponentContext.QUERY_BUILDER_TILE) {
      return (
        <button
          type="button"
          tabIndex={0}
          className={`flex items-center block w-full sm:text-sm max-w-64 overflow-hidden ${
            !list?.length || options?.disabled
              ? "opacity-50 cursor-default"
              : ""
          }`}
          onClick={() =>
            list?.length && !options?.disabled && setModalOpen(true)
          }
        >
          {children}
        </button>
      );
    }

    return (
      <button
        type="button"
        tabIndex={0}
        className={`flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button w-full sm:text-sm rounded-xl border bg-white ${
          errors?.length ? "border-red-700" : "border-gray-200"
        } ${
          !list?.length || options?.disabled ? "opacity-50 cursor-default" : ""
        }`}
        onClick={() => list?.length && !options?.disabled && setModalOpen(true)}
      >
        {options.beforeHelper ? (
          <div className="pl-4 text-base flex items-center text-gray-400 flex-shrink-0">
            {options.beforeHelper}
          </div>
        ) : null}

        <div className="px-4">{children}</div>

        {options.afterHelper ? (
          <div className="pr-4 text-base flex items-center text-gray-400 flex-shrink-0">
            {options.afterHelper}
          </div>
        ) : null}
      </button>
    );
  };

  return (
    <>
      <FieldInputContainer
        {...props}
        label={label}
        links={[
          {
            label: t("actions.select_none"),
            disabled: !value?.length,
            onClick: () => onChange?.(undefined),
          },
        ]}
        controls={[
          <button
            type="button"
            className="text-button hover:text-button-hover flex items-center"
            onClick={() => setCreateItemModal(true)}
          >
            <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
            {t("actions.create")}
          </button>,
        ]}
      >
        {model.getList({ query, count: true }).suspense(
          (list) => (
            <Container list={list}>
              {value ? (
                <div
                  className={`w-full h-input flex items-center justify-start text-base text-left overflow-hidden border-0 bg-transparent m-0 focus:outline-none focus:border-none focus:ring-0 ${
                    options.disabled ? "opacity-70" : ""
                  } ${options.inputClassName || ""}`}
                >
                  {model.get(value).suspense(
                    (i) => {
                      const context =
                        options?.context ===
                        InputComponentContext.QUERY_BUILDER_TILE
                          ? ViewComponentContext.INPUT_QUERY_BUILDER_TILE
                          : ViewComponentContext.INPUT_INLINE;

                      return i.renderDefaultFieldView({
                        context,
                      });
                    },
                    {
                      subscribe: true,
                      updateKey: value,
                    }
                  )}
                </div>
              ) : list.length ? (
                <div
                  className={`w-full h-input text-gray-500 flex items-center justify-start text-base text-left overflow-hidden border-0 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0 ${
                    options.disabled ? "opacity-70" : "hover:text-gray-800"
                  } ${options.inputClassName || ""}`}
                >
                  {t("actions.select_single")} ({list.count})
                </div>
              ) : (
                <div
                  className={`w-full h-input text-gray-500 flex items-center justify-start text-base text-left overflow-hidden border-0 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0 ${
                    options.disabled ? "opacity-70" : ""
                  } ${options.inputClassName || ""}`}
                >
                  La liste est vide
                </div>
              )}
            </Container>
          ),
          {
            subscribe: true,
            fallback: (
              <Container>
                <div
                  className={`w-full h-input text-gray-500 flex items-center justify-start text-base text-left overflow-hidden border-0 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0 ${
                    options.disabled ? "opacity-70" : ""
                  } ${options.inputClassName || ""}`}
                >
                  {t("labels.other.loading")}
                </div>
              </Container>
            ),
          }
        )}

        <FieldInputErrors
          errors={errors}
          label={label}
          slug={slug}
          model={model}
        />
      </FieldInputContainer>

      <SelectItemsModal
        isOpen={modalOpen}
        closeType={ModalCloseType.BACK}
        onClose={setModalOpen}
        value={value}
        query={query}
        multiple={false}
        autoSave
        onChange={(v) => {
          onChange?.(v);
          setModalOpen(false);
        }}
        model={model}
      />

      {model.createItemComponent ? (
        <CreateItemModal
          isOpen={createItemModal}
          onClose={setCreateItemModal}
          model={model}
          props={{ query }}
          onCreate={_handleCreate}
        />
      ) : null}
    </>
  );
};

export default InputRelationSingle;
