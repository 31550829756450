import React, { MutableRefObject, useRef, useState } from "react";
import WelcomeSettingsWizard from "../../../wizards/WelcomeSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

const SettingsHome = () => {
  const inputSearchRef = useRef<HTMLInputElement>();
  const [search, setSearch] = useState("");

  return (
    <div className="p-2 sm:p-4 lg:p-6">
      <div className="max-w-screen-md mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
          🏠️ Paramètres du projet
        </h1>

        <div className="space-y-6">
          <WelcomeSettingsWizard />

          <div className="space-y-4">
            <div className="px-4 flex items-center ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full sm:text-sm rounded-xl border bg-white">
              <div className="h-6 w-6 flex items-center">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <input
                ref={inputSearchRef as MutableRefObject<HTMLInputElement>}
                className="w-full p-0 h-input overflow-hidden border-0 bg-transparent m-0 rounded-xl focus:outline-none focus:border-none focus:ring-0"
                type="search"
                placeholder="Rechercher un paramètre ..."
                value={search}
                onChange={({ currentTarget: { value } }) => setSearch(value)}
              />
            </div>

            <ul className="space-y-2 list-disc pl-6">
              <li className="text-button underline hover:text-button-hover">
                <Link to="general">Général</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="schema">Schéma</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="security">Sécurité</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="tokens">Clés d'accès</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="sockethooks">Sockethooks</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="webhooks">Webhooks</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="environments">Environnements</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="aggregations">Aggrégations</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="search">Recherche</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="usage">Utilisation</Link>
              </li>
              <li className="text-button underline hover:text-button-hover">
                <Link to="interface">Interface</Link>
              </li>
              {/*<li className="cursor-pointer hover:underline">*/}
              {/*  Modifier le nom du projet*/}
              {/*</li>*/}
              {/*<li className="cursor-pointer hover:underline">Créer un modèle</li>*/}
              {/*<li className="cursor-pointer hover:underline">Ajouter un champ</li>*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHome;
