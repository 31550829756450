import { Fragment, FunctionComponent, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container: FunctionComponent<{
  wizardKey?: string;
  children: any;
  animate?: boolean;
  display?: boolean;
  onClose?: () => any;
  className?: string;
  contentClassName?: string;
  backdropClassName?: string;
  [key: string]: any;
}> = ({
  children,
  animate = true,
  onClose,
  className = "",
  contentClassName = "p-3 px-4 sm:p-4 lg:p-6",
  backdropClassName = "opacity-75",
  display = true,
  wizardKey,
  ...props
}) => {
  const prevDisplay = useRef(display);

  useEffect(() => {
    if (!display && prevDisplay.current && onClose) {
      onClose();
    }

    prevDisplay.current = display;
  }, [display]);

  if (!animate && !display) {
    return null;
  }

  const _content = (
    <div {...props} className={`relative ${className || ""}`}>
      {onClose ? (
        <div
          onClick={() => onClose()}
          className="absolute right-0 top-0 text-gray-300 z-30 w-10 h-10 hidden sm:flex items-center justify-center cursor-pointer hover:text-gray-500"
        >
          <FontAwesomeIcon icon={["far", "xmark"]} />
        </div>
      ) : null}
      <div
        className={`absolute z-0 inset-0 shadow-surface bg-white origin-bottom-center -bottom-1 rounded-2xl sm:rounded-xl lg:rounded-lg transform scale-95 ${contentClassName} ${backdropClassName}`}
      />
      <div
        className={`relative w-full h-full bg-white rounded-2xl sm:rounded-xl lg:rounded-lg shadow-surface z-10 ${contentClassName}`}
      >
        {children}
      </div>
    </div>
  );

  return animate ? (
    <Transition
      show={display}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 scale-95 -translate-y-1"
      enterTo="opacity-100 scale-100 translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 scale-100 translate-y-0"
      leaveTo="opacity-0 scale-95 -translate-y-1"
    >
      {_content}
    </Transition>
  ) : display ? (
    _content
  ) : null;
};

export default Container;
