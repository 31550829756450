import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  projectRouterLocationSubject,
  projectRouterNextLocationSubject,
} from "../Project";

const ProjectTransition: React.FunctionComponent<{
  children: any;
  duration?: number;
}> = ({ children, duration = 300 }) => {
  const [nextLocation, setNextLocation] = useState(
    projectRouterNextLocationSubject.getValue()
  );
  const [displayLocation, setDisplayLocation] = useState<any>(
    projectRouterLocationSubject.getValue()
  );

  useEffect(() => {
    const sub1 = projectRouterNextLocationSubject.subscribe(setNextLocation);
    const sub2 = projectRouterLocationSubject.subscribe(setDisplayLocation);

    return () => {
      sub1.unsubscribe();
      sub2.unsubscribe();
    };
  }, []);

  return (
    <Transition
      show={displayLocation?.pathname === nextLocation?.pathname}
      enter={`transition ease-out duration-${duration} delay-100`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={`transition ease-in duration-${duration}`}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      appear
      beforeLeave={() => {
        document.getElementById("projectContent")?.scrollTo(0, 0);
      }}
      afterLeave={() => {
        projectRouterLocationSubject.next(nextLocation);
      }}
    >
      {children}
    </Transition>
  );
};

export default ProjectTransition;
