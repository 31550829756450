import React, { useEffect, useRef, useState } from "react";

type MenuListItem = {
  key?: string | number;
  disabled?: boolean;
  label: React.FunctionComponent<{ isActive: boolean }> | string | JSX.Element;
};

export type MenuListProps = {
  activeKey?: string | number;
  list: MenuListItem[];
  onClick?: (item: MenuListItem) => void;
  children?: JSX.Element;
  ContainerComponent?: React.FunctionComponent<{
    children: any;
  }>;
  IndicatorComponent?: React.FunctionComponent<{
    width: number;
    height: number;
    left: number;
  }>;
};

const DefaultContainerComponent = ({ children }: any) => (
  <ul className="relative flex items-center space-x-4 border-b border-gray-300">
    {children}
  </ul>
);

const DefaultIndicatorComponent = ({
  width,
  height,
  left,
}: {
  width: number;
  height: number;
  left: number;
}) => {
  return (
    <div
      className="absolute transition-all bottom-0 h-1 bg-primary rounded-t-full"
      style={{ width, left }}
    />
  );
};

const MenuList: React.FunctionComponent<MenuListProps> = ({
  activeKey,
  list,
  onClick,
  ContainerComponent,
  IndicatorComponent,
  children,
}) => {
  const [indicator, setIndicator] = useState<{
    width: number;
    height: number;
    left: number;
  } | null>(null);
  const listItemsRef = useRef<any>({});
  const refreshTimeoutRef = useRef<any>();

  ContainerComponent = ContainerComponent ?? DefaultContainerComponent;
  IndicatorComponent = IndicatorComponent ?? DefaultIndicatorComponent;

  const _refreshOption = (_list: MenuListProps["list"]) => {
    if (!activeKey) {
      return;
    }

    const activeElem = _list.find((item: any) => item.key === activeKey);
    if (!activeElem?.key) {
      return;
    }

    const itemRef = listItemsRef.current[activeElem.key]?.current;
    if (itemRef) {
      const { width, height, left: absLeft } = itemRef.getBoundingClientRect();
      const left = absLeft - itemRef.parentNode.getBoundingClientRect().left;
      if (
        width === indicator?.width &&
        height === indicator?.height &&
        left === indicator?.left
      ) {
        return;
      }

      setIndicator({ width, height, left });
      return itemRef;
    }
  };

  useEffect(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    _refreshOption(list);
    refreshTimeoutRef.current = setTimeout(() => _refreshOption(list), 500);
  }, [activeKey, list]);

  const activeElem = activeKey && list.find((item) => item.key === activeKey);

  return (
    <div className="relative">
      <ContainerComponent>
        {list.map((item) => {
          if (item.key) {
            listItemsRef.current[item.key] = { current: undefined };
          }

          const isActive = item === activeElem;

          const content =
            typeof item.label === "function" ? (
              item.label({ isActive })
            ) : (
              <div
                className={`py-2 px-1.5 transition-colors truncate ${
                  isActive ? "text-primary" : "text-gray-500"
                }`}
              >
                {item.label}
              </div>
            );

          return (
            <li
              ref={item.key && listItemsRef.current[item.key]}
              onClick={() => !item.disabled && onClick?.(item)}
              className="z-10 cursor-pointer select-none"
            >
              {content}
            </li>
          );
        })}
        {children}
      </ContainerComponent>
      {indicator && list.find((item) => item === activeElem) ? (
        <IndicatorComponent
          width={indicator.width}
          height={indicator.height}
          left={indicator.left}
        />
      ) : null}
    </div>
  );
};

export default MenuList;
