import Modal, { ModalProps } from "../components/Modal";
import React, { useEffect, useState } from "react";
import Editor from "@draft-js-plugins/editor";
import { RichUtils, EditorState } from "draft-js";
import { _convertToHTML } from "../components/InputRte/_utils";

const InputTextRteModal: React.FunctionComponent<
  ModalProps & {
    state: any;
    onChange: (state: any) => void;
    editorProps?: any;
    children?: any;
  }
> = ({ editorProps, children, state, onChange, ...modalProps }) => {
  const [editorState, setEditorState] = useState<EditorState>(state);

  useEffect(() => {
    const _changed = _convertToHTML(state) !== _convertToHTML(editorState);
    if (_changed) {
      setEditorState(state);
    }
  }, [state]);

  useEffect(() => {
    if (editorState !== state) {
      onChange?.(editorState);
    }
  }, [editorState]);

  const _handleKeyCommand = (command: string) => {
    if (!editorState) {
      return;
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const _moveSelectionToEnd = (state = editorState) => {
    if (!state) {
      return;
    }

    state = EditorState.moveSelectionToEnd(state);
    setEditorState(EditorState.forceSelection(state, state.getSelection()));
  };

  return (
    <Modal
      defaultFullscreen
      {...modalProps}
      fullscreenClassName="absolute inset-0 flex flex-col json-editor-modal"
    >
      <div className="flex items-center p-1 w-full border-gray-200 border-b space-x-1">
        {children}
      </div>

      {editorState ? (
        <div
          className="cursor-text overflow-auto h-full"
          onClick={() => _moveSelectionToEnd()}
        >
          <div
            className="p-3 prose max-w-none"
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              handleKeyCommand={_handleKeyCommand}
              {...editorProps}
            />
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default InputTextRteModal;
