import React from "react";
import { ViewComponentProps } from "graphand-react";
import ViewText from "./Text";

const ViewId: React.FunctionComponent<ViewComponentProps> = (props) => {
  props.options.className = "text-sm select-all";

  return <ViewText {...props} />;
};

export default ViewId;
