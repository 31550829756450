import React from "react";
import { useCurrentAccount, useCurrentProject } from "../../utils/hooks";
import ProjectTransition from "../../components/ProjectTransition";
import { getProjectClient, projectClientSubject } from "../../utils/graphand";
import { useNavigate } from "react-router-dom";
import { oauthGlobal } from "../../utils/tools";
import { getProjectMatch } from "../../utils/getProjectMatch";

const AccountSettings = () => {
  const navigate = useNavigate();
  const client = getProjectClient();
  const { account } = useCurrentAccount();
  const { project } = useCurrentProject();

  if (!account || !client) {
    return null;
  }

  const _dissociate = async () => {
    if (!account?.email || !account?.password) {
      alert("Définir un email et un mot de passe");
      return;
    }

    await client?.dissociate();
    let _account;
    try {
      const Account = client?.getModel("Account");
      _account = await Account?.getCurrent();
    } catch (e) {}

    if (!_account) {
      if (getProjectMatch()) {
        getProjectClient()?.logout();
      } else {
        navigate("/");
        projectClientSubject.next(null);
        setTimeout(() => {
          window.location.reload();
        });
      }
    }
  };

  const _associate = async () => {
    const data: any = await oauthGlobal();
    if (!data?.accessToken) {
      return;
    }

    await client.associate(data.accessToken);
  };

  return (
    <ProjectTransition>
      {account.suspense(
        (a) => {
          return (
            <div className="p-10 text-lg max-w-screen-lg mx-auto">
              <h1 className="text-3xl font-bold mb-10">
                👋 Bienvenue sur le Tableau de bord de {project?.name},{" "}
                {a.firstname}
              </h1>

              <div className="space-y-4">
                <div>
                  {a?.raw.user ? (
                    <button
                      type="button"
                      className={`text-blue-500 hover:underline`}
                      onClick={_dissociate}
                    >
                      Dissocier mon compte Graphand
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`text-blue-500 hover:underline`}
                      onClick={_associate}
                    >
                      Associer mon compte Graphand
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
        { subscribe: true }
      )}
    </ProjectTransition>
  );
};

export default AccountSettings;
