import { addMonths } from "date-fns";
import Cookies from "universal-cookie";
import { getProjectMatch } from "./getProjectMatch";

const projectMatch = getProjectMatch();
let domain = projectMatch
  ? window.location.hostname.replace(projectMatch, "")
  : window.location.hostname;
export const cookiesClient = new Cookies();

export const getCookie = (key: string) => {
  return cookiesClient.get(key);
};

const defaultExpires = addMonths(new Date(), 1);
export const setCookie = (key: string, value: string, keep = false) => {
  const opts: any = {
    // domain,
    path: "/",
  };

  if (keep) {
    opts.expires = defaultExpires;
  }

  return cookiesClient.set(key, value, opts);
};

export const removeCookie = (key: string) => {
  return cookiesClient.remove(key, {
    // domain,
    path: "/",
  });
};
