import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { InputComponentProps } from "graphand-react";
import { useTranslation } from "react-i18next";
import { GraphandFieldJSON } from "graphand-js";
import FieldInputContainer from "../../../components/FieldInputContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonTheme } from "../../../components/Button";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import jsonrepair from "jsonrepair";
import JSONEditorModal from "../../../modals/JSONEditorModal";
import { Resizable } from "react-resizable";

const defaultCodeHeight = 177;

const InputJSONDefault: FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  const { options, field, slug } = props;
  const codeHeightKey = `codeHeight:${slug}`;

  const _inputRef = useRef();
  const inputRef = props.inputRef ?? _inputRef;
  const { t } = useTranslation();
  const [_value, setValue] = useState(
    JSON.stringify(props.value ?? {}, null, 2)
  );
  const value = _value?.length > 2 ? _value : options?.defaultValue ?? "{}";
  const [repaired, setRepaired] = useState(value);
  const [indented, setIndented] = useState(value);
  const [modalOpen, setModalOpen] = useState(false);
  const [codeHeight, setCodeHeight] = useState(
    parseInt(
      localStorage.getItem(codeHeightKey) || String(defaultCodeHeight),
      10
    ) || defaultCodeHeight
  );
  const required = (field as GraphandFieldJSON)?.required;
  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  if (label && required) {
    label += " *";
  }

  const _handleChange = (str: string) => {
    setValue(str);

    try {
      const repaired = jsonrepair(str);
      setRepaired(repaired);
    } catch (e) {}

    try {
      setIndented(JSON.stringify(JSON.parse(str), null, 2));
    } catch (e) {}
  };

  const _handleRepair = () => {
    const _repaired = repaired.replace('\n"', '"\n');
    setValue(_repaired);
  };

  const _handleIndent = () => {
    try {
      setValue(indented);
    } catch (e) {}
  };

  const _confirmChange = () => {
    let _value;

    try {
      _value = JSON.parse(value);
    } catch (e) {
      _value = JSON.parse(jsonrepair(value));
    }

    if (_value) {
      props.onChange?.(_value);
    }
  };

  useEffect(() => {
    const _str = JSON.stringify(props.value || {}, null, 2);
    if (_str !== value) {
      setValue(_str);
    }
  }, [props.value]);

  useEffect(() => {
    if (codeHeight === defaultCodeHeight) {
      localStorage.removeItem(codeHeightKey);
    } else {
      localStorage.setItem(codeHeightKey, String(codeHeight));
    }
  }, [codeHeight]);

  const controls = (
    <>
      <Button
        disabled={repaired === value}
        theme={ButtonTheme.inline}
        className="w-9 h-9"
        onClick={_handleRepair}
      >
        <FontAwesomeIcon icon={["far", "wand-magic-sparkles"]} />
      </Button>
      <Button
        disabled={repaired !== value || indented === value}
        theme={ButtonTheme.inline}
        className="w-9 h-9"
        onClick={_handleIndent}
      >
        <FontAwesomeIcon icon={["far", "indent"]} />
      </Button>
      <Button
        theme={ButtonTheme.inline}
        className="w-9 h-9"
        onClick={() => setModalOpen((o) => !o)}
      >
        <FontAwesomeIcon icon={["far", modalOpen ? "compress" : "expand"]} />
      </Button>
    </>
  );

  return (
    <>
      <FieldInputContainer {...props} label={label}>
        <Resizable
          axis="y"
          height={codeHeight}
          onResize={(e, { size }) => setCodeHeight(size.height)}
          handle={(handleAxis, ref) => (
            <div
              ref={ref as any}
              className={`h-3 w-3 m-3 border-b-2 border-r-2 border-gray-300 absolute bottom-0 right-0 cursor-pointer select-none handle-${handleAxis} flex items-center justify-center`}
              onDoubleClick={() => setCodeHeight(defaultCodeHeight)}
            />
          )}
        >
          <div className="flex flex-col ring-1 ring-transparent focus-within:border-button focus-within:ring-button block w-full rounded-xl border bg-white select-none">
            <CodeMirror
              ref={inputRef}
              className="rounded-t-xl overflow-hidden"
              value={value}
              height={`${codeHeight}px`}
              extensions={[json()]}
              onChange={_handleChange}
              onBlur={_confirmChange}
            />

            <div className="flex items-center p-1 w-full border-gray-200 border-t select-none">
              {controls}
            </div>
          </div>
        </Resizable>
      </FieldInputContainer>

      <JSONEditorModal
        isOpen={modalOpen}
        onClose={setModalOpen}
        codeMirrorProps={{
          value,
          onChange: setValue,
        }}
      >
        {controls}
      </JSONEditorModal>
    </>
  );
};

export default InputJSONDefault;
