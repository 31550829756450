import React, { useState } from "react";
import { InputComponentProps } from "graphand-react";
import InputTextSolid from "./_solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-duotone-svg-icons";
import Tippy from "@tippyjs/react";

const InputTextPassword: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const [display, setDisplay] = useState(false);

  props.options.type = display ? "text" : "password";

  props.options.afterHelper = (
    <Tippy content={display ? "Cacher" : "Afficher"} placement="bottom">
      <div
        className={`h-6 w-6 flex items-center justify-center cursor-pointer select-none ${
          display ? "text-button" : "hover:text-button"
        }`}
        onClick={() => setDisplay((d) => !d)}
      >
        <FontAwesomeIcon icon={display ? faEyeSlash : faEye} />
      </div>
    </Tippy>
  );

  props.options.inputClassName =
    display || !props.value?.length ? "text-base" : "text-2xl";

  return <InputTextSolid {...props} />;
};

export default InputTextPassword;
