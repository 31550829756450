import React from "react";
import { FunctionComponent } from "react";
import { InputComponentProps } from "graphand-react";
import InputBooleanCheckbox from "./_checkbox";
import InputBooleanSwitch from "./_switch";

const InputBoolean: FunctionComponent<Partial<InputComponentProps>> = (
  props
) => {
  let Comp;

  switch (props.options?.theme) {
    case "switch":
      Comp = InputBooleanSwitch;
      break;
    case "checkbox":
    default:
      Comp = InputBooleanCheckbox;
      break;
  }

  return React.createElement(Comp, props);
};

export default InputBoolean;
