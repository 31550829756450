import React from "react";
import { GraphandFieldText } from "graphand-js";
import { GraphandForm, GraphandFormTemplateParams } from "graphand-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { AddFilterModalProps } from "./index";
import { SelectOptionComponentProps } from "../../components/Select";
import { FilterMediaTypeOptions } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import getIconForMimetype from "../../utils/getIconForMimetype";
import getColorForMimetype from "../../utils/getColorForMimetype";
import { FilterConfType } from "../../utils/types";

const AddFilterMediaTypeOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps
> = ({ option, handleClick, selected }) => {
  const { t } = useTranslation();

  const label = t(`enums.FilterMediaTypeOptions.${option}.label`);
  const mimetype = t(`enums.FilterMediaTypeOptions.${option}.iconMimetype`);

  return (
    <div
      onClick={handleClick}
      className={`p-4 rounded-xl flex items-center space-x-2 cursor-pointer ${
        selected ? "bg-blue-200" : "bg-gray-100 hover:bg-blue-100"
      }`}
    >
      <FontAwesomeIcon
        className="text-4xl"
        style={{ color: getColorForMimetype(mimetype) }}
        icon={["fad", getIconForMimetype(mimetype)]}
      />
      <div>{label}</div>
    </div>
  );
};

const AddFilterMediaTypeTemplate: React.FunctionComponent<
  GraphandFormTemplateParams
> = ({ formRef, handleSubmit, fields, isLoading }) => {
  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`${isLoading ? "cursor-progress" : ""}`}
    >
      <div
        className={`space-y-6 transition mb-8 ${
          isLoading ? "opacity-50" : "opacity-100"
        }`}
      >
        {fields.render("types", {
          disabled: isLoading,
          placeholder: "Nouveau filtre",
          theme: "list",
          label: null,
          OptionComponent: AddFilterMediaTypeOptionComponent,
          ContainerComponent: ({ children }: any) => (
            <div className="flex flex-col space-y-2">{children}</div>
          ),
        })}
      </div>
      <Button
        type="submit"
        className="bg-button h-input flex items-center justify-center cursor-pointer text-center w-full shadow-button rounded-lg border border-white text-white"
      >
        <FontAwesomeIcon
          icon={faLoader}
          size="lg"
          className={`w-6 h-6 animate-spin mr-3 -ml-9 transition-opacity ${
            isLoading ? "opacity-100" : "opacity-0"
          }`}
        />
        Enregistrer
      </Button>
    </form>
  );
};

const AddFilterMediaType: React.FunctionComponent<AddFilterModalProps> = ({
  model,
  onSave,
}) => {
  const _handleSubmit = (values: any) => {
    const filter = { ...values, type: FilterConfType.MEDIA_TYPE };

    onSave(filter);
  };

  return (
    <GraphandForm
      fields={{
        types: new GraphandFieldText({
          options: Object.keys(FilterMediaTypeOptions).filter(
            (k) => !(Number(k) + 1)
          ),
          multiple: true,
        }),
      }}
      onSubmit={_handleSubmit}
      template={AddFilterMediaTypeTemplate}
    />
  );
};

export default AddFilterMediaType;
