import { AtomicBlockUtils, EditorState, RichUtils } from "draft-js";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import InputTextRteModal from "../../modals/InputTextRteModal";
import { GraphandModelMedia } from "graphand-js";
import {
  _convertFromHTML,
  _findLinkEntities,
  _convertToHTML,
  createRootPlugin,
  _customStyleMap,
} from "./_utils";
import InputRteToolsbar from "./Toolsbar";
import InputRteDecoratorLink from "./DecoratorLink";
import Editor from "@draft-js-plugins/editor";
import createFocusPlugin from "@draft-js-plugins/focus";

const focusPlugin = createFocusPlugin();

const rootPlugin = createRootPlugin({ focusDecorator: focusPlugin.decorator });

const plugins = [focusPlugin, rootPlugin];

type InputRteProps = {
  className?: string;
  inputRef?: MutableRefObject<any>;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const decorators = [
  {
    strategy: _findLinkEntities,
    component: InputRteDecoratorLink,
  },
];

const InputRte: React.FunctionComponent<InputRteProps> = ({
  className = "",
  inputRef: _inputRef,
  placeholder,
  value = "",
  onChange,
}) => {
  const __inputRef = useRef();
  const inputRef = _inputRef || __inputRef;
  const [modalOpen, setModalOpen] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(_convertFromHTML(value));
  });
  const [currentBlock, setCurrentBlock] = useState<any>();

  useEffect(() => {
    _confirmChange();
  }, [modalOpen]);

  const _confirmChange = () => {
    const html = _convertToHTML(editorState);
    onChange?.(html);
  };

  const _insertMedia = (media: GraphandModelMedia) => {
    setEditorState((prevState) => {
      const contentState = prevState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "media",
        "IMMUTABLE",
        { media }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(prevState, {
        currentContent: contentStateWithEntity,
      });
      return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    });
  };

  const _handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const _updateCurrentBlock = () => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockStartKey = selection.getStartKey();
    const _currentBlock = contentState.getBlockMap().get(blockStartKey);
    setCurrentBlock(_currentBlock);
  };

  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockStartKey = selection.getStartKey();
  const block = contentState.getBlockMap().get(blockStartKey);
  let activeLinkKey: string, activeLink: any;

  // @ts-ignore
  block.findEntityRanges((c) => {
    const cEntity = c.getEntity();
    if (cEntity) {
      const entity = contentState.getEntity(cEntity);
      if (entity.getType() === "LINK") {
        activeLinkKey = cEntity;
        activeLink = entity;
      }
    }
  });

  const _changeLink = (url: string) => {
    if (url?.length) {
      if (activeLink) {
        contentState.replaceEntityData(activeLinkKey, { url });
      } else {
        if (selection.isCollapsed()) {
          alert("Sélectionnez du texte pour créer un lien");
          return;
        }

        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey);
          url = linkInstance.getData().url;
        }

        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          { url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        setEditorState((s) => {
          const nextEditorState = EditorState.set(s, {
            currentContent: contentStateWithEntity,
          });

          return RichUtils.toggleLink(
            nextEditorState,
            nextEditorState.getSelection(),
            entityKey
          );
        });
      }
    } else if (activeLink) {
      setEditorState((s) => {
        return RichUtils.toggleLink(s, selection, null);
      });
    }
  };

  return (
    <>
      {!modalOpen ? (
        <div className={className}>
          <div
            onClick={() => inputRef.current?.focus()}
            className="cursor-text p-3 overflow-auto prose max-w-none"
            style={{
              height: "200px",
            }}
          >
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              ref={inputRef}
              decorators={decorators}
              placeholder={placeholder}
              onBlur={_confirmChange}
              handleKeyCommand={_handleKeyCommand}
              plugins={plugins}
              customStyleMap={_customStyleMap}
            />
          </div>

          <div className="flex items-center p-1 w-full border-gray-200 border-t space-x-1">
            <InputRteToolsbar
              currentBlock={currentBlock}
              updateCurrentBlock={_updateCurrentBlock}
              editorState={editorState}
              setEditorState={setEditorState}
              modalOpen={modalOpen}
              activeLink={activeLink}
              setModalOpen={setModalOpen}
              onInsertMedia={_insertMedia}
              onChangeLink={_changeLink}
            />
          </div>
        </div>
      ) : null}

      <InputTextRteModal
        isOpen={modalOpen}
        onClose={setModalOpen}
        state={editorState}
        onChange={setEditorState}
        editorProps={
          modalOpen
            ? {
                placeholder,
                plugins,
                decorators,
                customStyleMap: _customStyleMap,
              }
            : {}
        }
      >
        <InputRteToolsbar
          currentBlock={currentBlock}
          updateCurrentBlock={_updateCurrentBlock}
          editorState={editorState}
          setEditorState={setEditorState}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onInsertMedia={_insertMedia}
          onChangeLink={_changeLink}
          activeLink={activeLink}
        />
      </InputTextRteModal>
    </>
  );
};

export default InputRte;
