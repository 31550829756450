import React, { useRef } from "react";
import { GraphandFormTemplateParams } from "graphand-react";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { UpdateItemComponentProps } from "../utils/types";
import UpdateItemWrapper from "./UpdateItemWrapper";

const UpdateDataModelFormTemplate: React.FunctionComponent<
  GraphandFormTemplateParams & {
    setModalProps: UpdateItemComponentProps["setModalProps"];
  }
> = ({ formRef, handleSubmit, fields, isLoading }) => {
  const { t } = useTranslation();

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-4 ${isLoading ? "cursor-progress" : ""}`}
    >
      {fields.render("name")}
      {fields.render("configuration")}

      <Button type="submit">
        {t(isLoading ? "actions.updateLoading" : "actions.update")}
      </Button>
    </form>
  );
};

const UpdateDataModel: React.FunctionComponent<UpdateItemComponentProps> = (
  props
) => {
  const { current: template } = useRef((params: GraphandFormTemplateParams) => (
    <UpdateDataModelFormTemplate
      key="UpdateDataModelFormTemplate"
      {...params}
      setModalProps={props.setModalProps}
    />
  ));

  return <UpdateItemWrapper {...props} template={template} />;
};

export default UpdateDataModel;
