import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalCloseType, ModalProps } from "../components/Modal";
import { GraphandFieldJSON } from "graphand-js";
import InputJSONDefault from "../fields/inputs/JSON/_default";
import Button, { ButtonTheme } from "../components/Button";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import ViewJSON from "../fields/views/JSON";
import { ViewComponentContext } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aggregation from "../models/Aggregation";
import UpdateItemWrapper from "../components/UpdateItemWrapper";
import { GraphandFormTemplateParams } from "graphand-react";

const UpdatePipelineFormTemplate = ({
  formRef,
  isLoading,
  handleSubmit,
  fields,
}: GraphandFormTemplateParams) => {
  const { t } = useTranslation();

  return (
    <form
      ref={formRef}
      onSubmit={isLoading ? null : handleSubmit}
      className={`space-y-8 ${isLoading ? "cursor-progress" : ""}`}
    >
      <div className="space-y-4">
        {fields.render("pipeline", { label: null })}
      </div>
      <Button type="submit">
        {t(isLoading ? "actions.updateLoading" : "actions.update")}
      </Button>
    </form>
  );
};

const ExecuteAggregationModal: React.FunctionComponent<
  ModalProps & {
    aggregation?: Aggregation;
  }
> = ({ aggregation, ...modalProps }) => {
  const { t } = useTranslation();
  const swiperRef = useRef<any>();
  const [vars, setVars] = useState(aggregation?.defaultVars || {});
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any>(undefined);
  const [error, setError] = useState<any>();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  useEffect(() => {
    if (aggregation) {
      setVars(aggregation?.defaultVars || {});
    }
  }, [aggregation]);

  const _handleExecute = async () => {
    setLoading(true);
    try {
      const executor = aggregation?.execute(vars) as unknown as Promise<any>;
      const res = await executor;
      setResult(res);
    } catch (e: any) {
      setError(e);
    }
    await new Promise((resolve) => setTimeout(resolve, 100));
    swiperRef.current?.slideTo(1);
    setLoading(false);
  };

  const _handleSlideChange = (swiper: any = swiperRef.current) => {
    if (!swiper.activeIndex) {
      if (result) {
        setResult(undefined);
      }
      if (error) {
        setError(undefined);
      }
    }
  };

  return (
    <>
      <Modal
        title="Exécuter l'aggrégation"
        className={`overflow-hidden ${
          error ? "max-w-screen-sm" : "max-w-screen-md"
        }`}
        closeType={result ? ModalCloseType.BACK : ModalCloseType.DEFAULT}
        expandable
        {...modalProps}
        onClose={
          result ? () => swiperRef.current?.slideTo(0) : modalProps.onClose
        }
      >
        <div className="p-4 lg:p-6 pt-0 lg:pt-2">
          <Swiper
            allowTouchMove={false}
            autoHeight
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={_handleSlideChange}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              _handleSlideChange(swiper);
              setInterval(() => swiper.slideReset(), 100);
            }}
          >
            <SwiperSlide className="bg-white">
              <div className="space-y-4">
                <InputJSONDefault
                  field={new GraphandFieldJSON()}
                  options={{
                    label: null,
                  }}
                  value={vars}
                  onChange={setVars}
                  slug="vars"
                />

                <Button type="button" onClick={() => _handleExecute()}>
                  {t(loading ? "actions.executeLoading" : "actions.execute")}
                </Button>
              </div>
            </SwiperSlide>

            {aggregation && result && Object.keys(result).length ? (
              <SwiperSlide className="bg-white">
                <div className="space-y-4">
                  <ViewJSON
                    field={new GraphandFieldJSON()}
                    options={{
                      context: ViewComponentContext.PREVIEW_LINE,
                      label: null,
                    }}
                    item={aggregation}
                    slug="result"
                    value={result}
                  />

                  <div className="flex items-center justify-center space-x-2 w-full">
                    <Button
                      theme={ButtonTheme.light}
                      onClick={() => swiperRef.current?.slideTo(0)}
                    >
                      <FontAwesomeIcon
                        icon={["far", "angle-left"]}
                        className="text-lg mr-2"
                      />
                      Relancer
                    </Button>
                    <Button onClick={() => setUpdateModalOpen(true)}>
                      Modifier la pipeline
                    </Button>
                    <Button
                      theme={ButtonTheme.inline}
                      onClick={() => modalProps.onClose(false)}
                    >
                      Fermer
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
            ) : aggregation && error ? (
              <SwiperSlide className="flex flex-col items-center text-center space-y-4">
                <div className="h-20 w-20 bg-red-100 flex items-center justify-center rounded-full text-4xl text-red-700">
                  <FontAwesomeIcon icon={["fal", "triangle-exclamation"]} />
                </div>
                <p>
                  Une erreur est survenue lors de l'exécution de l'aggrégation :
                  <ul className="block">
                    {error.graphandErrors?.map((e: any) => (
                      <li className="font-bold">{e.message}</li>
                    ))}
                  </ul>
                </p>
                <div className="flex items-center justify-center space-x-2 w-full">
                  <Button
                    theme={ButtonTheme.light}
                    onClick={() => swiperRef.current?.slideTo(0)}
                  >
                    <FontAwesomeIcon
                      icon={["far", "angle-left"]}
                      className="text-lg mr-2"
                    />
                    Relancer
                  </Button>
                  <Button onClick={() => setUpdateModalOpen(true)}>
                    Modifier la pipeline
                  </Button>
                  <Button
                    theme={ButtonTheme.inline}
                    onClick={() => modalProps.onClose(false)}
                  >
                    Fermer
                  </Button>
                </div>
              </SwiperSlide>
            ) : null}
          </Swiper>
        </div>
      </Modal>

      {aggregation ? (
        <Modal
          title="Modifier la pipeline"
          className="max-w-screen-lg"
          expandable
          isOpen={updateModalOpen}
          onClose={() => {
            swiperRef.current?.slideTo(0);
            setUpdateModalOpen(false);
          }}
        >
          <div className="p-4 lg:p-6 pt-0 lg:pt-2">
            <UpdateItemWrapper
              model={Aggregation}
              item={aggregation}
              onUpdate={() => {
                swiperRef.current?.slideTo(0);
                setUpdateModalOpen(false);
              }}
              template={UpdatePipelineFormTemplate}
              title={t("title.updatePipeline")}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ExecuteAggregationModal;
