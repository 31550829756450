import React from "react";
import { ViewComponentProps } from "graphand-react";
import { ViewComponentContext } from "../utils/enums";
import Icon from "../components/Icon";

const FieldIconView: React.FunctionComponent<ViewComponentProps> = ({
  value,
  options,
}) => {
  switch (options.context) {
    case ViewComponentContext.LIST_LABEL_IMAGE:
      return (
        <div className="h-16 w-16 flex items-center justify-center flex-shrink-0">
          <Icon
            {...options}
            name={value}
            theme={options.theme ?? "fal"}
            size="2x"
          />
        </div>
      );
    default:
      break;
  }

  return <Icon {...options} name={value} theme={options.theme ?? "fal"} />;
};

export default FieldIconView;
