import Modal, { ModalProps } from "../components/Modal";
import React, { useState } from "react";
import { GraphandModelConstructor } from "../utils/types";
import { GraphandModel } from "graphand-js";
import { deepEqual } from "fast-equals";

const CreateItemModal: React.FunctionComponent<
  ModalProps & {
    model: GraphandModelConstructor;
    props?: any;
    onCreate?: (item: GraphandModel) => void;
    defaults?: any;
  }
> = (props) => {
  const [_modalProps, _setModalProps] = useState<Partial<ModalProps>>({});
  const { model, props: _props, onCreate, defaults, ...__modalProps } = props;

  if (!model.createItemComponent) {
    return null;
  }

  const CreateItemComponent = model.createItemComponent;

  const setModalProps: React.Dispatch<
    React.SetStateAction<Partial<ModalProps>>
  > = (data) => {
    if (typeof data === "function") {
      data = data(_modalProps);
    }

    if (!deepEqual(data, _modalProps)) {
      _setModalProps(data);
    }
  };

  const modalProps = { ...__modalProps, ..._modalProps };

  return (
    <Modal
      title="Créer un nouvel élément"
      className="max-w-screen-md"
      expandable
      {...modalProps}
    >
      <div className="p-4 lg:p-6 pt-0 lg:pt-2">
        <CreateItemComponent
          model={model}
          setModalProps={setModalProps}
          initialValues={defaults}
          onCreate={async (item) => {
            if (item) {
              await onCreate?.(item);
            }

            modalProps.onClose(false);
          }}
          {...(_props || {})}
        />
      </div>
    </Modal>
  );
};

export default CreateItemModal;
