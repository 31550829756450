import React, { useEffect, useState } from "react";
import { InputComponentProps, useList } from "graphand-react";
import { useTranslation } from "react-i18next";
import Select, {
  SelectOptionComponentProps,
  SelectProps,
} from "../../../components/Select";
import FieldInputContainer from "../../../components/FieldInputContainer";
import FieldInputErrors from "../../../components/FieldInputErrors";
import { getProjectClient } from "../../../utils/graphand";
import { GraphandFieldRelation, GraphandModel } from "graphand-js";
import { GraphandModelConstructor } from "../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateItemModal from "../../../modals/CreateItemModal";
import SelectItemsModal from "../../../modals/SelectItemsModal";
import { ModalCloseType } from "../../../components/Modal";
import {
  InputComponentContext,
  ViewComponentContext,
} from "../../../utils/enums";
import Tippy from "@tippyjs/react";
import { deepEqual } from "fast-equals";

const InputRelationMultipleOptionComponent: React.FunctionComponent<
  SelectOptionComponentProps & {
    model: GraphandModelConstructor;
  }
> = (props) => {
  const { option, handleClick, model } = props;
  // @ts-ignore
  const { previewField } = model;
  return (
    <div onClick={handleClick}>
      {previewField
        ? model.get(option).suspense(
            (item) =>
              item.renderFieldView(previewField, {
                context: ViewComponentContext.INPUT_INLINE_MULTIPLE,
              }),
            option
          )
        : option}
    </div>
  );
};

const InputRelationMultiple: React.FunctionComponent<
  Partial<InputComponentProps>
> = (props) => {
  const { slug, onChange, options, errors, inputRef } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSelectionOpen, setModalSelectionOpen] = useState(false);
  const [createItemModal, setCreateItemModal] = useState(false);
  const { t } = useTranslation();
  const client = getProjectClient();
  const field = props.field as GraphandFieldRelation;
  const model = client?.getModel(field.ref) as GraphandModelConstructor;
  const value = Array.isArray(props.value)
    ? props.value
    : [props.value].filter(Boolean);
  const listValue = useList(model, { ids: value });

  useEffect(() => {
    if (listValue?.ids && listValue?.ids?.length !== value?.length) {
      onChange?.(listValue?.ids);
    }
  }, [JSON.stringify(listValue?.ids)]);

  useEffect(() => {
    if (options.open) {
      setModalOpen(true);
    }
  }, []);

  if (!client) {
    return null;
  }

  let label = "label" in options ? options.label : field?.__dataField?.name;
  if (label === undefined) {
    label = t(`labels.fields.${slug}.default`);
  }

  let query = options.query ?? field.query ?? {};

  const _handleCreate = async (item: GraphandModel) => {
    if (Object.keys(query).length) {
      const found = await model.count({
        query: { $and: [query, { _id: item._id }] },
      });

      if (found) {
        onChange?.(value.concat(item._id));
      }
    } else {
      onChange?.(value.concat(item._id));
    }
  };

  const _handleChange = async (v: any) => {
    const newListValue = await model.getList({ ids: v });
    if (newListValue?.ids && newListValue?.ids?.length !== v?.length) {
      v = newListValue.ids;
    }

    onChange?.(v);
  };

  let SelectContainer: SelectProps["ContainerComponent"];
  let OptionComponent: SelectProps["OptionComponent"] = (_props) => (
    <InputRelationMultipleOptionComponent {..._props} model={model} />
  );

  if (options.context === InputComponentContext.QUERY_BUILDER_TILE) {
    SelectContainer = () => {
      return (
        <button className="hover:text-primary" type="button">
          {t(
            `text.xSelected.${
              value?.length > 1 ? "multiple" : value?.length ? "single" : "none"
            }`,
            {
              count: value?.length || 0,
            }
          )}
        </button>
      );
    };
  }

  return (
    <>
      <FieldInputContainer
        {...props}
        label={label}
        links={[
          {
            label: t("actions.select_none"),
            disabled: !value?.length,
            onClick: () => onChange?.(undefined),
          },
        ]}
        controls={[
          value?.length ? (
            <Tippy content={t("actions.display_selection")} placement="bottom">
              <button
                type="button"
                className="text-gray-500 hover:text-gray-700 flex items-center truncate"
                onClick={() => setModalSelectionOpen(true)}
              >
                <FontAwesomeIcon icon={["far", "eye"]} className="mr-2" />
              </button>
            </Tippy>
          ) : null,
          <button
            type="button"
            className="text-button hover:text-button-hover flex items-center truncate"
            onClick={() => setCreateItemModal(true)}
          >
            <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
            {t("actions.create")}
          </button>,
        ].filter(Boolean)}
      >
        {model.getList({ query, count: true }).suspense(
          (list) => {
            return (
              <Select
                value={value}
                inlineValue
                multiple
                ContainerComponent={SelectContainer}
                disabled={!list.count}
                hasError={Boolean(errors?.length)}
                inputRef={inputRef}
                onChange={_handleChange}
                onClick={() => list.length && setModalOpen(true)}
                OptionComponent={OptionComponent}
                options={list.ids}
                beforeHelper={options.beforeHelper}
                afterHelper={options.afterHelper}
              />
            );
          },
          {
            fallback: (
              <Select
                value={value}
                inlineValue
                multiple
                hasError={Boolean(errors?.length)}
                inputRef={inputRef}
                onChange={(v) => onChange?.(v)}
                OptionComponent={(_props) => (
                  <InputRelationMultipleOptionComponent
                    {..._props}
                    model={model}
                  />
                )}
                options={[]}
                beforeHelper={options.beforeHelper}
                afterHelper={options.afterHelper}
              />
            ),
          }
        )}

        <FieldInputErrors
          errors={errors}
          slug={slug}
          label={label}
          model={model}
        />
      </FieldInputContainer>

      <SelectItemsModal
        isOpen={modalSelectionOpen}
        closeType={ModalCloseType.BACK}
        onClose={setModalSelectionOpen}
        value={value}
        query={{ $and: [query, { _id: { $in: value } }] }}
        onChange={(v) => {
          _handleChange(v).then(() => {
            setModalSelectionOpen(false);
          });
        }}
        model={model}
      />

      <SelectItemsModal
        isOpen={modalOpen}
        closeType={ModalCloseType.BACK}
        onClose={setModalOpen}
        value={value}
        query={query}
        onChange={(v) => {
          _handleChange(v).then(() => {
            setModalOpen(false);
          });
        }}
        model={model}
        globalActions={[
          {
            label: t("actions.display_selection"),
            disabled: !value?.length,
            onClick: () => {
              setModalSelectionOpen(true);
              setModalOpen(false);
            },
          },
        ]}
      />

      {model.createItemComponent ? (
        <CreateItemModal
          isOpen={createItemModal}
          onClose={setCreateItemModal}
          model={model}
          props={{ query }}
          onCreate={_handleCreate}
        />
      ) : null}
    </>
  );
};

export default InputRelationMultiple;
