import { snakeCase } from "lodash";

export const slugCase = (input: string) => {
  return snakeCase(input).replace(/_/g, "-");
};

export const capitalizeFirstLetter = (input: string = "") => {
  if (!input) {
    return "";
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
};
