import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModelList, { ModelListWrapperRaw } from "../../../components/ModelList";
import { getProjectClient } from "../../../utils/graphand";
import ConfigureDataModelModal from "../../../modals/ConfigureDataModelModal";
import { GraphandModel } from "graphand-js";
import DataModel from "../../../models/DataModel";
import DataModelIconModal from "../../../modals/DataModelIconModal";
import DataModelLabelModal from "../../../modals/DataModelLabelModal";
import CreateItemModal from "../../../modals/CreateItemModal";
import Container from "../../../components/Container";

const SettingsSchema = () => {
  const { t } = useTranslation();
  const client = getProjectClient();
  const [configureModalItem, setConfigureModalItem] = useState<GraphandModel>();
  const [iconModalItem, setIconModalItem] = useState<GraphandModel>();
  const [labelModalItem, setLabelModalItem] = useState<GraphandModel>();
  const [createModelModal, setCreateModelModal] = useState(false);
  const [createFieldModalItem, setCreateFieldModalItem] =
    useState<GraphandModel>();

  if (!client) {
    return null;
  }

  return (
    <>
      <div className="sm:p-4 lg:p-6">
        <div className="w-full max-w-screen-md mx-auto">
          <h1 className="text-2xl lg:text-3xl font-bold w-full hidden sm:block mb-3">
            🛠 {t("labels.settings.schema")}
          </h1>

          <Container contentClassName="p-2 sm:p-4 lg:p-6 rounded-none sm:rounded-xl lg:rounded-lg">
            <ModelList
              WrapperComponent={ModelListWrapperRaw}
              model={client.getModel("DataModel")}
              defaultListFields={["_label", "_fields"]}
              // inlineActions={(item) => {
              //   return [
              //     !item.isConfigured
              //       ? {
              //           label: t("actions.configure_model"),
              //           onClick: () => setConfigureModalItem(item),
              //           icon: "gear",
              //         }
              //       : null,
              //   ].filter(Boolean);
              // }}
              listActions={(item) => {
                return [
                  !item?.isTemporary()
                    ? {
                        label: "Modifier l'icone",
                        onClick: () => setIconModalItem(item),
                      }
                    : null,
                  item.multiple && !item?.isTemporary()
                    ? {
                        label: "Modifier le libellé",
                        onClick: () => setLabelModalItem(item),
                      }
                    : null,
                  {
                    label: "Ajouter un champ",
                    onClick: () => setCreateFieldModalItem(item),
                  },
                ].filter(Boolean);
              }}
              labelField={{
                image: "_icon",
                title: "name",
                subtitle: "_scope",
              }}
              staticItems={[
                DataModel.get("_dataModelAccount"),
                DataModel.get("_dataModelMedia"),
              ]}
            />
          </Container>
        </div>
      </div>

      <ConfigureDataModelModal
        dataModel={configureModalItem as DataModel}
        isOpen={Boolean(configureModalItem)}
        onClose={() => setConfigureModalItem(undefined)}
      />

      <DataModelIconModal
        dataModel={iconModalItem as DataModel}
        isOpen={Boolean(iconModalItem)}
        onClose={() => setIconModalItem(undefined)}
      />

      <DataModelLabelModal
        dataModel={labelModalItem as DataModel}
        isOpen={Boolean(labelModalItem)}
        onClose={() => setLabelModalItem(undefined)}
      />

      <CreateItemModal
        model={client.getModel("DataModel")}
        isOpen={createModelModal}
        onClose={setCreateModelModal}
      />

      <CreateItemModal
        model={client.getModel("DataField")}
        props={{
          dataModel: createFieldModalItem as DataModel,
        }}
        isOpen={Boolean(createFieldModalItem)}
        onClose={() => setCreateFieldModalItem(undefined)}
      />
    </>
  );
};

export default SettingsSchema;
