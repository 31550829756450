import React, { MutableRefObject, useRef } from "react";
import { MediaPlayerProps } from "./index";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const MediaPlayerPdf: React.FunctionComponent<MediaPlayerProps> = ({
  media,
}) => {
  const containerRef = useRef<HTMLDivElement>();

  return (
    <div
      ref={containerRef as MutableRefObject<HTMLDivElement>}
      className="select-none w-full h-96 overflow-auto border border-gray-200"
    >
      <Document key={media._id} file={{ url: media.getUrl({ name: true }) }}>
        <Page
          pageNumber={1}
          width={containerRef.current?.getBoundingClientRect().width}
        />
      </Document>
    </div>
  );
};

export default MediaPlayerPdf;
