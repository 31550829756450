import React, { useState } from "react";
import { GraphandModel } from "graphand-js";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import Dropdown, { DropdownPosition } from "./Dropdown";
import { SelectionStackItem } from "../utils/types";
import { DropdownLink } from "./Dropdown";
import UpdateItemModal from "../modals/UpdateItemModal";

const SelectionControls: React.FunctionComponent<{
  model: typeof GraphandModel;
  query: any;
  selectionStack: SelectionStackItem[];
  setSelectionStack: React.Dispatch<React.SetStateAction<SelectionStackItem[]>>;
  onAddFilter: () => void;
  onDeleteList: () => void;
  onUpdateList: (values: any) => void;
  show?: boolean;
}> = ({
  model,
  query,
  setSelectionStack,
  onAddFilter,
  onDeleteList,
  onUpdateList,
  show = true,
}) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return (
    <>
      {model
        .getList({
          query,
          count: true,
        })
        .suspense((selection) => {
          return (
            <Transition
              appear
              className="sticky w-full py-2 px-6 bottom-0 z-30"
              style={{ marginTop: 0 }}
              show={!!selection.count && show}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-2"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-2"
              afterLeave={() => {
                if (show) {
                  setSelectionStack([]);
                }
              }}
            >
              <div className="h-20 sm:h-24 w-full bg-button rounded-3xl sm:rounded-2xl flex items-center justify-between p-4 sm:p-6 text-white space-x-3">
                <div className="truncate">
                  {t(
                    selection.count > 1
                      ? "text.xSelected.multiple"
                      : selection.count
                      ? "text.xSelected.single"
                      : "text.xSelected.none",
                    { count: selection.count }
                  )}
                </div>
                <div className="flex items-center space-x-2 sm:space-x-4">
                  <Button
                    className="h-12 text-sm sm:text-base sm:hidden"
                    onClick={() => setSelectionStack([])}
                  >
                    {t("actions.empty")}
                  </Button>
                  <Button
                    className="h-12 text-sm sm:text-base hidden sm:flex"
                    onClick={() => setSelectionStack([])}
                  >
                    {t("actions.empty_selection")}
                  </Button>
                  <Dropdown
                    position={DropdownPosition.RIGHT_BOTTOM}
                    button={
                      <Button className="h-12 w-12">
                        <FontAwesomeIcon
                          icon={["far", "colon"]}
                          className="rotate-90"
                        />
                      </Button>
                    }
                    onClose={() => {
                      if (confirmDelete) {
                        setConfirmDelete(false);
                      }
                    }}
                    links={[
                      {
                        label: t("actions.save_filter"),
                        onClick: () => onAddFilter(),
                      },
                      {
                        label: t("actions.empty_selection"),
                        onClick: () => setSelectionStack([]),
                      },
                      {
                        label: t("actions.update_all"),
                        onClick: () => setUpdateModalOpen(true),
                      },
                      (confirmDelete
                        ? {
                            label: t("actions.confirm_delete"),
                            theme: "danger",
                            onClick: () => onDeleteList(),
                          }
                        : {
                            label: t("actions.delete_all"),
                            onClick: (e: MouseEvent) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setConfirmDelete(true);
                            },
                          }) as DropdownLink,
                    ]}
                  />
                </div>
              </div>
            </Transition>
          );
        })}

      <UpdateItemModal
        isOpen={updateModalOpen}
        onClose={setUpdateModalOpen}
        model={model}
        item={new model()}
        handleUpdate={onUpdateList}
      />
    </>
  );
};

export default SelectionControls;
