import React from "react";
import Container from "../Container";
import { useAccountSettings } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { getQuerySelection } from "../../utils/tools";
import { InfiniteList } from "graphand-react";
import ModelGridItemComponent from "./ItemComponent";
import ModelGridPageComponent from "./PageComponent";
import ModelGridEmptyComponent from "./EmptyComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModelListProps } from "../../utils/types";

const ModelGrid: React.FunctionComponent<ModelListProps> = ({
  model,
  defaultSort = "-_id",
  filter,
  selectionStack,
  setSelectionStack,
  WrapperComponent = Container,
  globalActions,
  reloadQueryBuilder,
  disableFilters,
  createProps,
  onCreate,
  handleItemClick,
  inlineActions,
  listActions,
  subquery,
  ...props
}) => {
  const infoField = props.infoField ?? model.infoField;
  let labelField: ModelListProps["labelField"] = props.labelField ?? {
    image: model.imageField,
    title: model.titleField,
    subtitle: model.subtitleField,
  };

  const [sort, setSort] = useAccountSettings<string>(
    `sort:${model.scope}`,
    defaultSort
  );
  const { t } = useTranslation();
  const query = filter ? { $and: [filter.query, subquery] } : subquery;

  // useEffect(() => {
  //   if (selectionStack?.length) {
  //     setSelectionStack?.([]);
  //   }
  // }, [filter]);

  const displaySelection = selectionStack && setSelectionStack;
  const querySelection = displaySelection && getQuerySelection(selectionStack);

  const PageComponent = ({ list, getItemProps }: any) => (
    <ModelGridPageComponent
      list={list}
      getItemProps={getItemProps}
      model={model}
      querySelection={querySelection}
    />
  );

  const LoadingComponent = () => {
    return (
      <>
        <div className="absolute w-full bottom-0 flex items-center justify-center h-24">
          <div className="inline-flex items-center justify-center h-12 w-12 animate-spin text-primary">
            <FontAwesomeIcon icon={["far", "loader"]} size="2x" />
          </div>
        </div>
        <div className="h-24" />
      </>
    );
  };

  return (
    <WrapperComponent toolsbar={null}>
      <InfiniteList
        model={model}
        activeFn={(index) => {
          const t = Math.pow(index, 0.7) * 200 + 150;
          const b = index * 75;
          return t < b ? b : t;
        }}
        watchItemIndex={-5}
        opts={{
          query,
          sort,
          pageSize: 20,
        }}
        ContainerComponent={({ count, children }: any) => (
          <div className="grid grid-cols-lg gap-2 sm:gap-3 lg:gap-4 mt-5 relative">
            {children}
          </div>
        )}
        ItemComponent={ModelGridItemComponent}
        PageComponent={PageComponent}
        LoadingComponent={LoadingComponent}
        EmptyComponent={ModelGridEmptyComponent}
        itemProps={({ item }) => ({
          setSelectionStack,
          displaySelection,
          onClick: handleItemClick,
          imageField: labelField?.image,
          titleField: labelField?.title,
          subtitleField: labelField?.subtitle,
          infoField,
          inlineActions:
            typeof inlineActions === "function"
              ? inlineActions(item)
              : inlineActions,
          listActions:
            typeof listActions === "function" ? listActions(item) : listActions,
        })}
      />
    </WrapperComponent>
  );
};

export default ModelGrid;
