import React from "react";
import InputRteBlockImage from "./BlockImage";

const InputRteBlockAtomic: React.FunctionComponent<any> = (props) => {
  const {
    block,
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    contentState,
    blockStyleFn,
    preventScroll,
    style,
    ...elementProps
  } = props;

  const entityKey = block.getEntityAt(0);
  if (!entityKey) {
    return null;
  }

  const entity = contentState.getEntity(entityKey);
  if (!entity) {
    return null;
  }

  const type = entity.getType();

  let render = null;

  if (type === "media") {
    const { media } = entity.getData();

    render = media.suspense((m: any) => {
      if (m.mimetype.includes("image")) {
        return (
          <InputRteBlockImage
            entity={entity}
            entityKey={entityKey}
            contentState={contentState}
            elementProps={elementProps}
          />
        );
      }
    });
  }

  return render;
};

export default InputRteBlockAtomic;
