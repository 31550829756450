import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link
      to="/"
      className="text-gray-700 flex items-center cursor-pointer select-none"
    >
      <img alt="logo" src="/images/logo.png" className="h-9" />
    </Link>
  );
};

export default Logo;
