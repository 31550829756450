import { GraphandFieldNumber, GraphandModelEsMapping } from "graphand-js";
import EsMappingCountView from "../fieldsViews/EsMappingCount";

class EsMapping extends GraphandModelEsMapping {
  static previewField = "name";
}

EsMapping.customFields({
  _count: new GraphandFieldNumber({
    ViewComponent: EsMappingCountView,
    unit: "enregistrements",
  }),
});

export default EsMapping;
