import React from "react";
import { useCurrentAccount, useCurrentProject } from "../../utils/hooks";
import ConfigureAccountWizard from "../../wizards/ConfigureAccount";
import CreateModelWizard from "../../wizards/CreateModel";
import InviteWizard from "../../wizards/Invite";
import ProjectTransition from "../../components/ProjectTransition";
import SelectAccountPictureFieldWizard from "../../wizards/SelectAccountPictureField";

const Dashboard = () => {
  const { account } = useCurrentAccount();
  const { project } = useCurrentProject();

  return (
    <ProjectTransition>
      <div className="p-2 sm:p-6 lg:p-10 text-lg max-w-screen-lg mx-auto">
        <h1 className="text-3xl font-bold p-4 sm:p-0 mb-4 sm:mb-6 lg:mb-10">
          👋 Bienvenue sur le Tableau de bord de {project?.name},{" "}
          {account?.firstname}
        </h1>

        <div className="space-y-4">
          {/*<ConfigureAccountWizard />*/}
          {/*<CreateModelWizard />*/}
          {/*<InviteWizard />*/}
          {/*<SelectAccountPictureFieldWizard wizardKey="select-account-picture-field" />*/}
        </div>
      </div>
    </ProjectTransition>
  );
};

export default Dashboard;
